
    import { Component, Vue } from 'vue-property-decorator';
    // import PassengersCustomer from "@/Module/Global/Components/Sidebar/PassengersCustomer.vue";  
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    import DatePicker from 'vue2-datepicker';
    import moment from "moment";
    @Component({
        components:{
            // PassengersCustomer,
            CitySearch,
            DatePicker
        }
    })
    export default class widgetTour extends Vue {
        fromCity:any="";
        checkin_date:any="";
        checkout_date:any="";
        selectedTourType:any="things-to-do"
        tourType:any=[
            { id: 1, name: "Things to Do", value:"things-to-do"},
            { id: 2, name: "Extended Touring", value:"extedned-touring"},
        ]
    }
