
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class Footer extends Vue {
        @Prop({
            default:{
                followUs:true,  
                alignLinks:'',  
                facebookLink:'',
                twitterLink:'',
                instagramLink:'',     
                copyRight:'Copyright 2021@ eRoam Pty Ltd.',                  
            }
        }) content;
    }
