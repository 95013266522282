
    import { Component, Vue } from 'vue-property-decorator';
    // import PassengersCustomer from "@/Module/Global/Components/Sidebar/PassengersCustomer.vue";  
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    import DatePicker from 'vue2-datepicker';
    import moment from "moment";
    @Component({
        components:{
            // PassengersCustomer,
            CitySearch,
            DatePicker
        }
    })
    export default class widgetHotel extends Vue {
        fromCity:any="";
        checkin_date:any="";
        checkout_date:any="";
    }
