export const Italian: any = {
   creatingFor: 'Creazione itinerario per',
   creatingNew: 'Creazione nuovo itinerario cliente',
   searchTitleItinerary: 'Itinerario',
   searchTitleFlights: 'Voli e treni',
   searchTitleHotel: 'Hotel e B&B',
   searchTitleTours: 'Tour e biglietti',
   searchTitlePackages: 'Pacchetti',
   searchTitleRentalcars: 'Auto a noleggio e Camper',
   searchTitleTransfers: 'Trasferimenti',
   searchTitleCruises: 'Crociere',
   searchTitleEvents: 'Eventi',
   searchTitleInsurance: 'Assicurazione',
   searchInstructions: 'Aggiungi la località di partenza e seleziona \'Cerca\'. Aggiungi le posizioni aggiuntive nel passaggio successivo, durante la conferma del percorso.',
   searchAddPlaces: 'Posizione di partenza',
   searcPlaceholder: 'Posizioni',
   searchError: '* Seleziona due posizioni',
   searchBlurb: 'Crea il tuo itinerario. Aggiungi ciascuna delle località che desideri visitare digitando la destinazione e fai clic su Invio per aggiungerle.',
   consultantProfile: 'Profilo del consulente',
   consultantSearchCust: 'Clienti',
   Searchdata: 'Cerca',
   consultantSearchBlurb: 'Trova le prenotazioni di un cliente',
   consultantSearchCustButton: 'Cerca clienti',
   consultantSearchCont: 'Cerca contatto',
   consultantQuick: 'Accesso rapido',
   consultantActive: 'Prenotazioni',
   ActiveData: 'Attivo',
   consultantActiveBlurb: 'Visualizza l\'elenco delle prenotazioni attive',
   consultantActiveButton: 'Visualizza prenotazioni attive',
   consultantPending: 'Prenotazioni',
   PendingData: 'In attesa',
   consultantPendingBlurb: 'Visualizza l\'elenco delle prenotazioni in sospeso',
   consultantPendingButton: 'View In attesa prenotazioni',
   consultantItineraries: 'Itinerari attivi',
   consultantCustomers: 'clienti',
   consultantAccount: 'Riepilogo account',
   consultantNotices: 'Notifiche e Annunci',
   consultantTaskList: 'Task List',
   consultantTaskYours: 'delle attività',
   consultantTaskNotFound: 'operazione non trovata',
   consultantTaskConfirmCompleteMsg: 'Sei sicuro di voler completa attività contrassegnate come complete',
   consultantCustName: 'Nome cliente',
   consultantActiveAgency: 'Visualizza prenotazioni agenzie attive',
   consultantPendingAgency: 'Visualizza prenotazioni agenzie in sospeso',
   consultantTaskCustName: 'Nome cliente',
   consultantTaskBookingId: 'ID prenotazione',
   consultantTaskNote: 'Note',
   consultantTaskDate: 'Seleziona data',
   consultantTaskAddButton: 'Aggiungi attività',
   consultantTaskEditButton: 'Modifica attività',
   consultantTaskCompleted: 'Completato',
   consultantTaskactions: 'Azioni',
   consultantTasksnoozed: 'Posticipato',
   consultantTasktasks: 'Attività',
   consultantTasksNone: 'Sembra aggiornato, ben fatto',
   consultantMidOffice: 'Ufficio centrale',
   consultantMidOfficeUpload: 'Carica dati in ufficio centrale',
   sidebarHeading: 'Conferma percorso',
   siderbarInstructions: 'Aggiungi tutti i luoghi di interesse e impostali nell\'ordine desiderato. Seleziona una data di partenza e la durata del soggiorno in ciascuna località. Se si attiva l\'inventario preselezionato, perfezionare i risultati tramite le opzioni di inventario per ciascuna posizione.',
   sidebarOptionDeparture: 'Partenza e viaggiatori',
   sidebarDepDate: 'Data di partenza',
   sidebarDepSelect: 'Selezionare la data di partenza dalla prima posizione',
   sidebarDepError: 'Errore, selezionare un data di partenza.',
   travellersNumber: 'Numero di viaggiatori',
   siderbarnewInstructions: 'Aggiungi tutti i luoghi di interesse e impostali nell\'ordine desiderato. Seleziona una data di partenza e la durata del soggiorno in ciascuna località. Se si attiva l\'inventario preselezionato, perfezionare i risultati tramite le opzioni di inventario per ciascuna posizione.',
   sidebarnewOptionDeparture: 'Partenza e viaggiatori',
   depDate: 'Data di partenza',
   sidebarnewDepSelect: 'Selezionare la data di partenza dalla prima posizione',
   sidebarnewDepError: 'Errore, selezionare un data di partenza.',
   sidebarTravellersNumber: 'Numero di viaggiatori',
   Rooms: 'Camera | Camere',
   adults: 'Adulti',
   children: 'Bambini',
   childAgeLimits: 'Età (0-17)',
   Infants: 'Neonati',
   infantAgesLimit: 'Età (1 e sotto)',
   childAges: 'Quanti anni hanno bambini con cui viaggi (al momento della partenza)',
   ageOfChild: 'Età del bambino',
   infantConfirm: 'È un bambino di età inferiore a uno (al momento della partenza)',
   infantLengthPlural: 'I bambini sono',
   infantLengthSingular: 'È il bambino',
   infantLengthEnd: 'di età inferiore a uno (al momento della partenza)',
   infantLapStart: 'I',
   infantLapPlural: 'neonati',
   infantLapSingular: 'neonati',
   infantLapEnd: 'viaggeranno sul grembo degli adulti?',
   infantsLap: 'I neonati viaggeranno sul grembo degli adulti?',
   sidebarnewInventoryOps: 'Inventario e opzioni di localizzazione,',
   sidebarViewTours: 'vista del gruppo di Tours',
   sidebarnewRouteOptions: 'seleziona le opzioni che si desidera includere all\'interno di questo percorso.',
   siderbarnewAutoPopLoc: 'automaticamente con sedi trend.',
   siderbarnewAutoPopInv: 'Auto inventario popolano utilizzando le preferenze del cliente.',
   arrivalDate: 'Data di arrivo',
   confirmRouteButton: 'Conferma percorso',
   redirectInventory: 'Inventario itinerario',
   sidebarInventoryAlert: 'Aggiungi e rimuovi inventario viaggio all\'I Tinerary a destra. </p> Nota: l\'aggiunta di un tour di più giorni bloccherà le date delle posizioni precedenti in atto.',
   redirectActivities: 'Scegli attività',
   sidebarActivitiesAlert: 'Gestisci l\'inventario all\'interno dell\'itinerario selezionando il segmento a sinistra. Per aggiungere, rimuovere o riordinare posizioni, seleziona il pulsante Visualizza mappa / percorso.',
   redirectHotelList: 'Scegli hotel',
   sidebarHotelListAlert: 'Scegli un nuovo hotel dall\'elenco. </p> In alto ci sono le caratteristiche degli hotel attualmente selezionati.',
   redirectHotel: 'Scegli la tua camera',
   sidebarHotelAlert: 'Scegli la tua camera per aggiungere questo hotel all\'itinerario',
   redirectToursList: 'Scegli un tour',
   sidebarToursListAlert: 'Scegli un tour dall\'elenco. </p> Fai clic su un tour per visualizzare informazioni più dettagliate, prezzi e per visualizzare le partenze',
   redirectTour: 'Scegli una partenza',
   sidebarTourAlert: 'Scorri verso il basso per trovare un elenco di date di partenza con il loro prezzo. </p> O aggiungi questo tour a il tuo itinerario o prenota direttamente il tour',
   redirectTransport: 'Scegli trasporto',
   sidebarTransportAlert: ',',
   checkoutAlert: 'Inserisci i dettagli del passeggero per completare la prenotazione',
   checkout: 'Cassa',
   redirectPreview: 'Anteprima itinerario',
   redirectPreviewPDF: 'Anteprima PDF',
   sidebarnewInventoryOptions: 'Opzioni inventario',
   errorMsg: 'Errore visualizzare qui',
   remove: 'Rimuovi',
   sidebarnewInclusiveTourButton: 'Tour di gruppo incluso',
   sidebanewNotes: 'Aggiungi questo luogo al percorso',
   sidebarnewReturnTripOption: 'Opzione viaggio di ritorno',
   sidebarnewRoundTripForm: 'Seleziona un\'origine e un ritorno. Se desideri includere voli di ritorno.',
   to: 'A',
   night: 'Notti',
   tourDuration: 'Durata',
   contact: 'Contatto',
   checkinDate: 'Data check-in',
   checkoutDate: 'Data checkout',
   supplierReference: 'Fornitore',
   propertyReference: 'Riferimento prenotazione',
   transport: 'Trasporto',
   service: 'Servizio',
   inventoryTravelClass: 'Classe di viaggio',
   departureLocation: 'Posizione di partenza',
   arrivalAirport: 'Posizione di arrivo',
   status: 'Stato',
   departuretime: 'Ora di partenza',
   hotels: 'Hotel',
   hotelPricePerNight: 'Prezzo per notte',
   hotelPropertyRating: 'Classificazione proprietà',
   searchbyPropertyName: 'Ricerca per nome proprietà',
   search: 'Cerca',
   refundtype: 'Tipo di rimborso',
   days: 'Giorni',
   location: 'Posizione',
   pernight: 'per notte',
   pendingItineraries: 'Itinerari in sospeso',
   hotelownArrangementPropertyName: 'Nome proprietà *',
   hotelownArrangementPropertyNotes: 'Note proprietà *',
   validationMsg: 'Questo campo è obbligatorio.',
   emailValidationMsg: 'Indirizzo email campo deve essere un indirizzo email valido.',
   contactMinValidationMsg: 'Il campo Numero contatto deve contenere almeno 10 caratteri.',
   contactMaxValidationMsg: 'Il campo Numero contatto deve contenere 11 caratteri.',
   ownArrangementImage: 'Immagine',
   ownArrangementHotelimage: 'Immagine hotel',
   ownArrangementImgClick: 'Clicca o trascina l\'immagine dell\'hotel qui per caricare',
   ownArrangementFileTypeMsg: '(supportati tipo di file .jpeg, .jpg, .png)',
   hotelownArrangementPropertyaddress: 'Indirizzo Proprietà *',
   hotelownArrangementPropertyContact: 'Proprietà Numero di telefono *',
   hotelownArrangementPropertyemail: 'Proprietà e-mail *',
   hotelownArrangementDurationDetails: 'Durata',
   hotelownArrangementPropertyCheckindate: 'dettagli\'check-in Data *',
   hotelownArrangementPropertyCheckintime: 'Ora check-in *',
   hotelownArrangementPropertyCheckouttime: 'Indirizzo proprietà *',
   hotelownArrangementPropertyCheckoutdate: 'Data check-out *',
   ownArrangementPricingDetails: 'Dettagli prezzo',
   ownArrangementCurrency: 'Valuta *',
   hotelownArrangementCurrency: 'Valuta acquisto *',
   hotelownArrangementPropertyPrice: 'Prezzo di vendita *',
   hotelownArrangementPropertybookingref: 'Riferimento prenotazione *',
   ownArrangementPurchasingdetails: 'Dettagli acquisto',
   ownArrangementPurchasePrice: 'Prezzo d\'acquisto *',
   hotelownArrangementPropertySupplier: 'Fornitore *',
   hotelownArrangementPropertyInvoice: 'Includi questo prezzo di sistemazione nella fattura finale.',
   hotelownArrangementPropertyRefund: 'Questo prodotto di sistemazione propria è rimborsabile.',
   cancelButton: 'Annulla',
   addtoitinerary: 'Aggiungi all\'itinerario',
   ownArrangementBasicDetails: 'Dettagli hotel di base',
   activityownArrangementActivityname: 'Nome attività *',
   activityownArrangementAddress: 'Indirizzo *',
   activityownArrangementPhnno: 'Numero di telefono *',
   activityownArrangementEmail: 'Email *',
   activityownArrangementWeburl: 'URL Web',
   activityownArrangementImage: 'Immagine',
   ownArrangementActivityImgClick: 'Fai clic o trascina immagine attività qui per caricare',
   activityownArrangementActivityImage: 'Immagine attività',
   activityownArrangementSellingPrice: 'Prezzo di vendita *',
   activityownArrangementSupplierReference: 'Fornitore',
   activityownArrangementPurchaseCurrency: 'Valuta di acquisto *',
   activityownArrangementPropertyReference: 'Riferimento prenotazione *',
   ownArrangementComments: 'Commenti',
   activityownArrangementComments: 'Commenti',
   operator: 'Operatore',
   ownArrangementTravelClass: 'Classe',
   ownArrngementArrivalTime: 'Arrivo Ora',
   ownArrangementAirlineReference: 'Riferimento compagnia aerea',
   ownArrangementGdsReference: 'Riferimento GDS',
   downloadPDF: 'Scarica PDF',
   expandAll: 'Espandi tutto',
   collapseAll: 'Comprimi tutto',
   showMore: 'Mostra altro',
   showLess: 'Mostra meno',
   accommodation: 'Alloggio',
   name: 'Nome',
   checkIn: 'Check-in',
   checkOut: 'Check-out',
   checkOutDash: 'Check-out',
   price: 'Prezzo',
   PerNight: 'Per notte',
   roomDetails: 'Dettagli camera',
   roomType: 'Tipo di camera',
   pendingBookingNights: 'Notti',
   nonRefundable: 'Non rimborsabile',
   refundable: 'Rimborsabile',
   ownArrangement: 'Disposizione propria',
   toursActivities: 'Tour / Attività',
   viewitenaryActivityname: 'Nome attività',
   viewitenaryAddress: 'Indirizzo',
   viewitenaryPhone: 'Telefono',
   viewitenarySellingPice: 'Prezzo di vendita',
   email: 'Email',
   date: 'Data',
   duration: 'Durata',
   code: 'Codice',
   startDate: 'Data inizio',
   finishDate: 'Data fine',
   endDate: 'Data di fine',
   aud: 'AUD',
   view: 'Visualizza',
   noDataFound: 'Nessun record trovato',
   notProvided: 'Non fornito',
   pendingBookingPage: 'X',
   pendingBookingCustomerName: 'Nome cliente',
   pendingBookingDepdate: 'Dip. Data',
   pendingBookingCreatedOn: 'Creato il',
   travellers: 'Viaggiatori',
   pendingBookingTotalPrice: 'Prezzo totale',
   ownArrangementContactDetails: 'Dettagli contatto',
   sortby: 'Ordina per:',
   titleOfActivity: 'Titolo',
   activityCode: 'Codice attività:',
   viator: 'Viator',
   from: 'Da',
   perPerson: 'per persona',
   category: 'Categoria',
   sidebarActivityName: 'Cerca per nome attività',
   readmore: 'Ulteriori informazioni',
   hotelPickupIncluded: 'Prelievo dall\'hotel incluso:',
   activityDetaildeparturePoint: 'Punto di partenza:',
   overview: 'Panoramica',
   importantInfo: 'Informazioni importanti',
   details: 'Dettagli',
   activityDetailInclusions: 'Inclusioni',
   activityDetailExclusions: 'Esclusioni',
   activityDetailAdditionalinfo: 'Informazioni aggiuntive',
   activityDetailVoucherinfo: 'Informazioni sul voucher',
   activityDetailLocalOperatorInformation: 'Informazionilocale',
   activityDetailLocalOperatorInformationlope: 'sull\'operatore\'Informazioni complete sull\'operatore, compresi i numeri di telefono locali a destinazione, sono incluse nel voucher di conferma. I nostri responsabili di prodotto selezionare solo gli operatori più esperti e affidabili in ogni destinazione, eliminando le congetture per voi, e garantire la pace della mente.',
   activityDetailCancellationPolicy: 'La politica di cancellazione',
   activityDetailScheduleAndPricing: 'Pianificazione e',
   activityDetailScheduleAndPricingContent: 'prezzi\'I nostri prezzi è costantemente aggiornato per garantire ricevi sempre il prezzo più basso possibile - lo garantiamo al 100%.',
   activityDetailBookActivity: 'Prenota attività',
   activityDetailDeparts: 'Partenza',
   activityDetailPickup: 'Ritiro:',
   activityDetailCheckedPickup: 'Devo essere ritirato',
   activityDetailaddActivity: 'Aggiungi attività',
   activityDetailSpecialRequirements: 'Requisiti speciali',
   activityDetailSelectLocation: 'Seleziona posizione',
   activityDetailEnterPickupLocation: 'Inserisci luogo di ritiro *',
   noRecordFound: 'Nessun record trovato',
   sidebarTransportCustomerPricing: 'Prezzi cliente',
   sidebarTransportCustomerPricingContent: 'Analisi prezzi itinerario compreso il costo totale del cliente e per PAX.',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'Costo totale AUD inc. IVA',
   sidebarTransportPricePerPaxAudincTax: 'Prezzo per PAX AUD inc. Imposta',
   sidebarTransportCostPerDay: 'Costo al giorno:',
   sidebarTransportCost: '458,00',
   sidebarTransportTotalCost: 'Costo totale:',
   sidebarTransportTotal: 'AUD 785',
   sidebarTransportAgencyCost: 'Costo agenzia',
   sidebarTransportOne: 'Uno',
   sidebarTransportTwo: 'Due',
   sidebarTransportThree: 'Tre',
   sidebarTransportFilters: 'Filtri di trasporto',
   sidebarTransportType: 'Tipo di trasporto',
   return: 'ritorno',
   transportOnlyRates: 'Disattiva la vista agente per visualizzare le tariffe al dettaglio per i clienti',
   transportOnlySaveQuotation: 'Salva preventivo',
   transportOnlyBookItinerary: 'Itinerario del libro',
   transportOnlyCustomerPricing: 'Prezzo cliente',
   transportOnlyAgencyCost: 'Costo agenzia',
   transportOnlyFilters: 'Filtri',
   transportOnlyDepartureLocation: 'Luogo di partenza',
   transportOnlyDestinationLocation: 'Posizione di destinazione',
   transportOnlyReturnDate: 'Data di ritorno',
   transportOnlyParistoRome: 'Parigi a Roma',
   transportOnlyFilterContent: 'Lunedì 1 aprile 2019',
   change: 'Modifica',
   transportOnlyvueling: 'Vueling',
   nonStop: 'Non-stop',
   departs: 'Partenza',
   transportOnlyParisOrlyAirport: 'Aeroporto di Parigi Orly (ORY)',
   transportOnlyArrive: 'Arrivo',
   transportOnlyFco: 'Roma - Stazione Roma Tiburtina (FCO)',
   transportOnlyKiwi: 'Kiwi.com',
   transportOnlyMystifly: 'Mystifly',
   transportOnlySabre: 'Sabre',
   transportOnlyEconomy: 'Economia',
   transportOnlySelect: 'Seleziona',
   transportOnlyRouteDetails: 'Dettagli percorso',
   transportOnlyCalendar: 'Lunedì 1 aprile 2019',
   transportOnlyFlight: 'Volo',
   transportOnlyShowDetails: 'Mostra dettagli',
   transportOnlyParis: 'Parigi - Gare de Lyon',
   transportOnlyMilan: 'Milano - CentralStation',
   transportOnlyBaggageDetails: 'Dettagli bagaglio',
   transportOnlyPersonalBaggage: 'Bagaglio personale',
   transportOnlyFree: 'Gratuito',
   transportOnlyCheckedBaggage: 'Bagaglio registrato',
   transportOnlyAddCheckedBaggage: 'Aggiungi bagaglio registrato',
   transportOnlyAddCabinBaggage: 'Aggiungi bagaglio a mano',
   transportOnlyInFlight: 'Componenti aggiuntivi a',
   transportOnlyPassengerPricingDetails: 'bordo\' Dettagli passeggeri e prezzi',
   transportOnlyPassenger: 'Passeggero',
   transportOnlyonexAdult: '1 adulto',
   transportOnlyBaseFare: 'Tariffa di base',
   transportOnlyBaggage: 'Bagaglio',
   transportOnlyFeesAndTaxes: 'Altre tariffe / tasse',
   transportOnlyTermsConditions: 'Termini e condizioni Condizioni',
   transportOnlyNetRate: 'Tariffa netta',
   transportOnlyCommision: 'Commissione',
   checkinTime: 'Ora check-in',
   checkoutTime: 'Ora di Partenza',
   searchCustomerName: 'Ricerca per Cliente Nome',
   confirmroute: 'Conferma percorso',
   logout: 'Logout',
   searchbypropertyname: 'Cerca per nome Proprietà',
   hotelDetailshotelRoom: '(1 camera)',
   hotelDetailRoomsRates: 'Camere e tariffe',
   hotelDetailAmenities: 'hotel Servizi',
   hotelDetailCheckInInstructions: 'Check In Istruzioni',
   hotelDetailsFeesOptionalExtras: 'Tariffe / Extra facoltativi',
   hotelDetailValueAddedServices: 'Servizi a valore aggiunto',
   hotelDetailCheckin: 'Check-in',
   hotelDetailhotelCheckout: 'Check-out:',
   hotelDetailBedPreferencese: 'Preferenza letto:',
   hotelDetailViewCancellationPolicy: 'Visualizza politica di cancellazione',
   hotelDetailRoomDetailAmenities: 'Servizi di dettaglio camera',
   hotelDetailRoomAmenities: 'Servizi camera',
   hotelDetailGuests: 'Ospiti:',
   hotelDetailSubtotal: 'Totale parziale:',
   hotelTotal: 'Totale',
   hotelDetailTaxRecoveryServiceFee: 'Recupero fiscale e spese di servizio:',
   hotelDetailSpecialCheckInInstructions: 'Istruzioni speciali per il check-in',
   hotelDetailadditionalInformation: 'Ulteriori informazioni',
   headerLogin: 'Accesso',
   headerLogout: 'Logout',
   hide: 'Nascondi',
   depart: 'Partenza',
   arrive: 'Arrivo',
   flightNumber: 'Numero volo',
   routeDetails: 'Dettagli percorso',
   wifi: 'WIFI',
   included: 'INCLUSO',
   airCondition: 'Aria condizionata',
   passengerPricingDetails: 'Dettagli passeggeri e prezzi',
   supplier: 'Fornitore',
   passenger: 'Passeggero',
   passengers: 'Passeggeri',
   flightMystifly: 'Mystifly',
   flight2Adult: '2 x adulti',
   baggage: 'Bagaglio',
   otherFeesTaxes: 'Altre tariffe / tasse',
   free: 'GRATUITO',
   netRate: 'Tariffa netta',
   select: 'Seleziona',
   floatZero: '0.00',
   doubleZerofloat: '00 .00',
   tv: 'TV',
   flightEconomy: 'Economia',
   viewMore: 'Visualizza altro',
   flightOnBoardAddOns: 'Componenti aggiuntivi integrati',
   loginSignIn: 'Accedi',
   customerNameOrEmail: 'Nome cliente o email',
   searchBy: 'Cerca per',
   firstName: 'Nome',
   lastName: 'Cognome',
   gender: 'Sesso',
   phone: 'Telefono',
   submitButton: 'Invia',
   your: 'Il tuo',
   createdOn: 'Creato su',
   nights: 'Notti',
   totalPrice: 'Prezzo totale',
   emailUserName: 'Nome utente o email',
   userName: 'Nome utente',
   password: 'Passw ord',
   enterPassword: 'Inserisci password',
   enterCustomerName: 'Inserisci nome cliente',
   taskEnterBookingId: 'Inserisci ID prenotazione',
   commission: 'Commissione',
   costPerDay: 'Costo per giorno',
   pricePerPAX: 'Prezzo per PAX',
   totalCost: 'Costo totale',
   netCost: 'Costo netto',
   includingTax: 'inc. Imposta',
   priceSummary: 'Riepilogo prezzi',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: 'Ripartizione dei prezzi dell\'itinerario compreso il costo totale del cliente e per PAX',
   sidebarHeadingPreviewItinerary: 'Anteprima itinerario',
   sidebarHeadingSaveQuotation: 'Salva preventivo',
   sidebarHeadingBookItinerary: 'Itinerario del libro',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: 'Disattiva la vista agente per visualizzare le tariffe al dettaglio per cliente.',
   agentView: 'Visualizzazione agente',
   customerView: 'Visualizzazione cliente',
   save: 'Salva',
   travelInsurance: 'Assicurazione di viaggio',
   no: 'No',
   addTravel: 'Aggiungi viaggio',
   plus: 'Più',
   basic: 'Base',
   inventoryMedicalExpenseTripCancellation: 'Spese mediche, Annullamento viaggio',
   inventoryAssistanceServices: 'Assistenza Servizi,',
   inventoryLostBaggageMore: 'Lost bagagli + More',
   audWithFloating: 'AUD 00.00',
   inventoryComparsionAndTerms: 'Comparsion e le condizioni',
   customerCare: 'Customer Care',
   premium: 'Premium',
   inventoryExploreBenefitsOfPayingPremiumMsg: 'Scopri i vantaggi di pagare per Premium',
   previous: 'precedente',
   continue: 'Continua',
   saveAndContinue: 'Salva & Continua',
   BacktoInventory: 'Torna',
   AcceptandContinue: 'Accetta e continua',
   busBud: 'all\'inventario\' BusBud',
   edit: 'Modifica',
   delete: 'Elimina',
   checked: 'Controllato',
   checkoutBusListINBusAddonMsg: 'Componenti aggiuntivi bus',
   BaseFarePerPax: 'Tariffa base (per persona)',
   onexAdult: '1 x Adulto',
   oneRoom: 'Camera 1',
   CancellationPolicy: 'Politica di cancellazione',
   expedia: 'Expedia',
   roomSleep: 'Sonno in camera',
   checkOutActivityListviator: 'Viator',
   checkOutActivityDetails: 'Dettagli attività',
   option: 'Opzione',
   groupInclusiveTourOptions: 'Opzioni tour incluso di gruppo',
   matchCurrentRouteMsg: 'Attualmente ci sono 219 gruppi tour inclusi che corrispondono al percorso corrente.',
   errorDisplayHere: 'Visualizzazione errori qui',
   tourFilters: 'Filtri tour',
   tailorMadeTours: 'Tour personalizzati',
   tailorMadeOptionsMsg: 'Seleziona le opzioni che desideri per visualizzare solo le opzioni personalizzate',
   tailorMadeToursItineryMsg: 'Visualizza solo tour su misura per itinerari',
   MonthOfDeparture: 'Mese di partenza',
   selectDate: 'Seleziona data',
   destination: 'Destinazione',
   tourExperience: 'Esperienza del tour',
   preferredSupplier: 'Fornitore preferito',
   style: 'Stile',
   size: 'Dimensioni',
   start: 'Inizio',
   notAvailbale: 'N / A',
   inclusions: 'Inclusioni',
   day: 'Giorno',
   meals: 'Pasti',
   breakfast: 'Colazione',
   lunch: 'Pranzo',
   dinner: 'Cena',
   tourDetailsNoMealMsg: 'Non ci sono pasti inclusi in questo giorno.',
   locationsToVisit: 'Luoghi da visitare',
   additionalInformation: 'Ulteriori informazioni',
   departureNotFound: 'Partenze non trovate',
   additionalInformationNotFound: 'Non ci sono ulteriori informazioni disponibili',
   departureAndPricing: 'Partenze e prezzi',
   departing: 'Partenza',
   finishing: 'Finitura',
   availability: 'Disponibilità',
   totalFrom: 'Totale da',
   perAdult: 'per Adulto',
   perChild: 'per bambino',
   departureGuaranteed: 'partenza garantita',
   onSale: 'in vendita',
   onRequest: 'su richiesta',
   addToRoute: 'Aggiungi al percorso',
   termsConditions: 'Termini e condizioni',
   available: 'Disponibile',
   finish: 'Fine',
   stars: 'Stelle',
   reviews: 'Recensioni',
   rating: 'Valutazione',
   default: 'default',
   title: 'Titolo',
   lengthOfTour: 'Durata del Tour',
   optionsSelected: 'opzioni selezionate',
   tourNotFound: 'Banco non trovato',
   validTo: 'valido a',
   lodging: 'alloggi',
   validFrom: 'Valido da',
   perNight: 'per notte',
   sortBy: 'Ordina per',
   viewItineraryGITtours: 'GIT tours',
   inventoryAddNewAccommodation: 'Scegli un altro hotel',
   inventoryAddNewTransport: 'Aggiungi nuovo trasporto',
   passengersAvoidBoardingComplicationMsg: 'Usa tutti i nomi (Nome e Cognome) esattamente come appaiono nel Passaporto / ID per evitare complicazioni di imbarco.',
   passengersRequiredFlightSegmentMsg: 'Obbligatorio per il segmento di volo internazionale.',
   dateOfBirth: 'Data di nascita',
   passengersTourSpecificField: 'Campo specifico del tour.',
   passengersTourSegmentMsg: 'Obbligatorio per il tour \'X\' Segmento',
   visaRequriements: 'Richieste di visto',
   passengersreferencePurposeMsg: 'Un visto o un visto di transito aeroportuale può essere necessario per viaggiare anche se non fermandosi nel paese di sosta. Questi requisiti sono solo a scopo di riferimento. Per ulteriori informazioni contattare l\'ambasciata o il ministero degli Esteri.',
   passengersDeniedDestinationIssueMsg: 'ERoam non è responsabile per i passeggeri a cui è stato negato l\'imbarco o l\'ingresso a qualsiasi destinazione a causa di problemi di visto.',
   givenNames: 'Nome fornito',
   passengerNames: 'Nome specificato *',
   surname: 'Cognome',
   passengersurname: 'Cognome *',
   familyname: 'Cognome',
   PassengerGender: 'Sesso *',
   paymenttotalprice: 'Prezzo totale',
   paymentnewprice: 'Nuovo prezzo',
   emailAddress: 'Indirizzo e-mail',
   passengeremailAddress: 'Indirizzo e-mail *',
   contactNumber: 'Numero di contatto',
   passengercontactNumber: 'Numero di contatto *',
   passengersIndividualInventorySpecificationMsg: 'I campi sottostanti sono obbligatori a causa di specifiche dell\'inventario individuali, come voli interni, requisiti del tour ecc.',
   Nationality: 'Nazionalità',
   PassengerNationality: 'Nazionalità *',
   confirmBooking: 'Conferma prenotazione',
   receiptNumber: 'Numero ricevuta',
   paymentAddReceiptNumberMsg: 'Aggiungi il numero di ricevuta del pagamento elaborato.',
   PaymentDeniedDestinationIssueMsg: 'A il visto o il transito aeroportuale possono essere necessari per il viaggio. I requisiti trovati qui sono solo a scopo di riferimento. Contatta l\'ambasciata o il tuo ministero degli esteri per ulteriori informazioni. eRoam non è responsabile per i passeggeri a cui è stato negato l\'imbarco o l\'ingresso in qualsiasi destinazione a causa di problemi di visto.',
   amount: 'Importo *',
   paymentEnterAmountMsg: 'Inserisci un importo che viene effettuato.',
   paymentEnterTypeMsg: 'Inserisci il tipo di pagamento',
   paymentType: 'Pagamento Digitare *',
   paymentOnceSubmittedMsg: 'Dopo aver inviato i dettagli del pagamento, la prenotazione sarà confermata.',
   payment: 'Pagamento',
   printPDF: 'Stampa PDF',
   custom: 'Personalizzato',
   additionalNotes: 'Note aggiuntive',
   list: 'Elenco',
   checkinDash: 'Check-in',
   detailedSummary: 'Riepilogo dettagliato',
   dailySummary: 'Riepilogo giornaliero',
   tripSummary: 'Riepilogo viaggio',
   transportName: 'Nome trasporto',
   activities: 'Attività',
   basicTransportDetails: 'Dettagli trasporto di base',
   transportOwnArrangementRefundMsg: 'Prodotto di trasporto con disposizione propria rimborsabile',
   transportOwnArrangementPricingMsg: 'Includere questo prezzo di trasporto con disposizione propria nella fattura finale .',
   salePrice: 'Prezzo di vendita',
   transportOwnArrangementAddLegtoArrangement: 'Aggiungi tappa all\'accordo',
   arrivalLocation: 'Posizione di arrivo',
   transportOwnArrangementAddNewLeg: 'Aggiungi una nuova tratta',
   arrival: 'Arrivo',
   departure: 'Partenza',
   fromTo: 'Da - A',
   selectThePlaceToReturn: 'Seleziona il luogo di restituzione',
   selectAReturn: 'Seleziona un ritorno',
   returnToAnotherCity: 'Ritorna in un\'altra città',
   selectPlaceOrigin: 'Seleziona il luogo di origine.',
   selectOrigin: 'Seleziona un\'origine.',
   accomodationSummary: 'Riepilogo sistemazione.',
   transportSummary: 'Riepilogo trasporto',
   activitySummary: 'Riepilogo attività',
   taskListConformDeleteMsg: 'Conferma che desideri per eliminare Task?',
   ConfirmActionTitle: 'Conferma azione',
   passengertitle: 'Titolo *',
   hotelListTotalCost: 'Totale Costo',
   nonstop: 'Non-Stop',
   selected: 'Selezionato',
   inventoryAddNewHotels: 'Aggiungi nuovi hotel',
   inventoryAddOwnArrangement: 'Aggiungi disposizione propria',
   propertylocation: 'Posizione proprietà',
   inventoryAddNewToursTickets: 'Scegli un altro tour e biglietti',
   inventoryContactPhone: 'Telefono di contatto',
   inventoryContactEmail: 'Email di contatto',
   flight: 'Volo',
   time: 'Ora',
   bookingId: 'ID prenotazione',
   bookingNote: 'Nota di prenotazione',
   stops: 'Ferma',
   layOverDuration: 'Durata sosta',
   itineraryPdfImageText: 'Fai clic o trascina qui l\'immagine dell\'itinerario per caricare',
   confirm: 'Conferma',
   changePassword: 'Modifica password',
   oldPasswordErrorMsg: 'Inserisci vecchia password',
   newPasswordErrorMsg: 'Inserisci nuova password',
   confirmPasswordErrorMsg: 'Conferma password non corrispondente',
   oldPassword: 'Vecchia password',
   confirmPassword: 'Conferma password',
   newPassword: 'Nuova password',
   visitedLocations: 'Luoghi da visitare',
   checkoutFlightListINFlightAddonMsg: 'Componenti aggiuntivi in ​​volo',
   checkoutmystifly: 'Mystifly',
   tourDetailstitle: 'Dettagli tour',
   update_total_cost: 'Aggiorna costo totale',
   updateCostConfirmCompleteMsg: 'Sei sicuro, vuoi continuare con una commissione negativa?',
   confirmButton: 'Conferma',
   norating: 'Nessuna valutazione',
   adult: 'Adulto',
   child: 'Bambino',
   PrimaryPassenger: 'Passeggero principale (Lead Pax)',
   hotelbed: 'Hotelbed',
   activityownemail: 'Email fornitore',
   activityownweb: 'Sito Web',
   activityownphone: 'Telefono',
   activityownsupplier: 'Fornitore',
   hotelDetails: 'Dettagli hotel',
   room: 'Camera',
   ownArrangementActivityBasicDetails: 'Dettagli attività di base',
   booking: 'Prenotazione',
   tour: 'Tour',
   oopsTheActivity: 'Attività',
   oopsTheTour: 'Il tour',
   oopsTheFlight: 'Il volo',
   notAvailable: 'non è al momento disponibile.',
   inventoryAddHotelOwnArrangement: 'Disposizione propria (alloggio)',
   inventoryAddToursOwnArrangement: 'Disposizione propria (tour e biglietto)',
   inventoryAddReplaceTransport: 'Scegli un altro trasporto',
   inventoryAddTransportOwnArrangement: 'Disposizione propria (Trasporto)',
   noProductSelected: 'Nessun prodotto selezionato',
   taskSelectDate: 'Seleziona data',
   taskSelecgender: 'Seleziona sesso',
   age: 'Età',
   relation: 'Relazione',
   activityTime: 'Ora *',
   activityDate: 'Data *',
   accountId: 'ID account',
   nationality: 'Nazionalità',
   city: 'Città',
   address: 'Indirizzo',
   postcode: 'CAP',
   region: 'Regione',
   ontarioResident: 'Ontario Resident',
   prepaidGratuities: 'prepagata mance',
   mobilePhone: 'Contatti',
   titleBooking: 'Titolo',
   genderBooking: 'sesso',
   emailBooking: 'Indirizzo e-mail',
   paymentAmount: 'Importo',
   checkoutRceiptNumber: 'pagamento\'numero di ricevuta',
   loadPnr: 'Carica PNR',
   activityownArrangementPropertyInvoice: 'Includi questo prezzo nella fattura finale.',
   activityownArrangementPropertyRefund: 'Questo è rimborsabile.',
   bookingVoucherfileType: '(Tipi di file supportati .jpeg, .jpg, .png, .pdf)',
   bookingVouchermsg: 'Fai clic qui per caricare il voucher',
   carrierName: 'Nome del vettore',
   departureDateandTime: 'Data e ora di partenza\'Data e ora di',
   arrivalDateandTime: 'arrivo',
   airlineReference: 'Riferimento compagnia aerea',
   pnrNumber: 'Inserisci PNR',
   pnrInstructions: 'Inserire un codice PNR valido per ottenere l\'itinerario',
   termsandconditions: 'Termini e condizioni',
   total: 'Totale',
   baggageAdult: 'Bagaglio per adulti / bambini',
   baggageChild: 'Bagaglio per bambini',
   gitWarningMessage: 'Hai selezionato tour non valido per questa città',
   summeryConfirmCompleteMsg: 'Sei sicuro, vuoi continuare con la selezione di tour non valida?',
   tourType: 'Tipo di tour',
   SearchPlace: 'Proprietà Search Place (Luogo di ricerca) Cerca luoghi',
   SearchCity: 'Cerca città Cerca città',
   manager: 'Manager',
   product: 'Prodotto',
   finance: 'Finanza',
   admin: 'Admin',
   viewProductManager: 'Visualizza Product Manager',
   viewfinanceManager: 'Visualizza Finance Manager',
   viewAdminManager: 'Visualizza Admin Manager',
   viewsupplierManager: 'Visualizza Responsabile Fornitore',
   viewmarketingManager: 'Visualizza Marketing Manager',
   DaterangeFrom: 'Intervallo di date da',
   DaterangeTo: 'Intervallo di date a',
   DataType: 'Tipo di dati',
   FileType: 'Tipo di file',
   downloadNow: 'Scarica ora',
   ConsultantName: 'Nome consulente',
   SupplierName: 'Nome fornitore',
   ClientFile: 'Client File',
   pay: 'Pagare',
   TypeofPayment: 'Tipo di pagamento',
   Login: 'Accedi',
   exportData: 'Esporta dati',
   roomNumber: 'Camera',
   storeName: 'Nome punto vendita',
   manageUsers: 'Gestire gli utenti',
   manageBrands: 'Gestire i brand',
   viewReports: 'Visualizza report',
   brand: 'Marchio',
   manageLicensee: 'Gestisci licenziatario',
   ManageLicensee: 'Cerca licenziatario',
   OnboardNewLicensee: 'A bordo del nuovo licenziatario',
   CreateNewPackage: 'Creare un nuovo pacchettoCreate a New Package',
   consultantArchived: 'Prenotazioni',
   ArchivedData: 'Archiviati',
   consultantArchivedBlurb: 'Visualizza l\'elenco delle prenotazioni archiviate',
   consultantArchivedButton: 'Visualizza prenotazioni archiviate',
   consultantArchivedAgency: 'Visualizza prenotazioni agenzie archiviate',
   archivedItineraries: 'Itinerari archiviati',
   taskDay: 'Seleziona giorno',
   action: 'Azione',
   mobile: 'Mobile',
   taskSelectDay: 'Seleziona giorno',
   DateandTime: 'Data e ora',
   addtransport: 'Aggiungi trasporto',
   addhotels: 'Aggiungi Hotel',
   addactivities: 'Aggiungi attività',
   manageclientpreferences: 'Gestire le preferenze client',
   Return: 'Ritorno',
   oneway: 'senso unico',
   multycity: 'Città di Multy',
   flyingfrom: 'Volare da',
   selectclass: 'Seleziona classe',
   returndate: 'Data di ritorno',
   comingsoon: 'Prossimamente',
   thingstodo: 'Cose da fare',
   extendedtouring: 'Touring esteso',
   selectinterest: 'Seleziona Interesse',
   searchpnr: 'Cerca PNR',
   googlesearch: 'Ricerca Google',
   package: 'Pacchetto',
   addcustomer: 'Aggiungi cliente',
   geoLocationManager: 'Gestore della posizione GEO',
   supplieroperatorManager: 'Responsabile Fornitore/Operatore',
   yieldManager: 'Gestore dei rendimenti',
   packageBuilder: 'Generatore di pacchetti',
   plotmaps: 'Tracciare mappe',
   reporting: 'Reporting',
   CSVDownload: 'CSV Scarica',
   makePayments: 'Effettua pagamenti',
   packageName: 'Nome pacchetto',
   uploadImages: 'Carica immagini',
   toursandtickets: 'Tour e biglietti',
   hotel: 'Hotel',
   packageOverview: 'Panoramica del pacchetto',
   packageItinerary: 'Itinerario del pacchetto',
   Optional: 'Opzionale',
   createpackagefromtour: 'Crea pacchetto dal tour',
   Currency: 'Valuta',
   companyName: 'Nome dell\'azienda',
   Phone: 'Telefono (incluso il prefisso internazionale)',
   url: 'Url',
   uploadLogo: 'Carica logo',
   selectProduct: 'Seleziona prodotto',
   hotelSupplier: 'Fornitore hotel',
   tourSupplier: 'Fornitore Tour',
   transportSupplier: 'Fornitore di trasporto',
   otherSupplier: 'Altro Fornitore',
   selectSettings: 'Seleziona Impostazioni',
   defaultLanguage: 'Lingua predefinita',
   defaultCurrency: 'Valuta predefinita',
   chooseTemplate: 'Scegli modello',
   paymentGateway: 'Gateway di pagamento',
   selectSubscription: 'Seleziona abbonamento',
   licenceType: 'Tipo di licenza',
   howmanylicences: 'Quante licenze?',
   addons: 'Componenti aggiuntivi',
   paymentTerms: 'Termini di pagamento',
   licenseenameoremail: 'Nome licenziatario o e-mail',
   createProfilesandClientDetails: 'Creazione di profili e gestione dei dettagli del client',
   passportNumber: 'Numero di passaporto',
   passportissuedate: 'Data di emissione del passaporto',
   passportExpiryDate: 'Data di scadenza del passaporto',
   passportissuingcountry: 'Paese di emissione di passaporti',
   clientContact: 'Contatto cliente',
   Phonehome: 'Telefono (casa)',
   Phonework: 'Telefono (lavoro)',
   phonemobile: 'Telefono (cellulare)',
   AddressLinOne: 'Indirizzo (riga 1)',
   AddressLineTwo: 'Indirizzo (riga due)',
   StateProvinceRegion: 'Stato / Provincia / Regione',
   zipPostalCode: 'CAP / CODICE postale',
   country: 'Paese',
   newsletter: 'Newsletter',
   companyABN: 'Azienda ABN',
   paName: 'Nome PA',
   clientLoyaltyPrograms: 'Programmi fedeltà clienti',
   srno: 'Mr No',
   loyaltyType: 'Tipo fedeltà',
   providerName: 'Nome provider',
   membershipNumber: 'Numero di iscrizione',
   clientPreferences: 'Preferenze client',
   airline: 'Compagnia aerea',
   airlines: 'Compagnie aeree',
   travelClass: 'Classe di viaggio',
   preferredSeating: 'Posti a sedere preferiti',
   mealRequest: 'Richiesta pasto',
   Hotels: 'Hotel(i)',
   hotelChain: 'Catena alberghiera',
   Cars: 'Auto(e)',
   carRental: 'Autonoleggio',
   CarType: 'Tipo di auto',
   interests: 'Interessi(i)',
   linkedprofiles: 'Profili collegati',
   Preferreddestinations: 'Destinazioni preferite',
   businessPreferences: 'Preferenze aziendali',
   otherPreferences: 'Altre preferenze',
   pendingBookings: 'Prenotazioni in sospeso',
   archiveBookings: 'Prenotazioni Archivio',
   activeBookings: 'Prenotazioni attive',
   viewdetails: 'Visualizza dettagli',
   update: 'Aggiornamento',
   bookingmanagement: 'Gestione prenotazioni',
   bookingDashboard: 'Dashboard di prenotazione',
   bookingoverview: 'panoramica della prenotazione',
   Generalbookingdetails: 'Dettagli generali sulla prenotazione.',
   type: 'digitare',
   created: 'Creato',
   segmentoverview: 'Panoramica del segmento',
   totalnumberofsegment: 'Il numero totale di prodotti/segmenti e i segmenti Confermati in sospeso.',
   segmentsconfirmedvstotalsegment: 'Segmenti confermati rispetto al totale dei segmenti inclusi nella prenotazione.',
   amountremainingforclient: 'Importo rimanente al cliente da pagare prima della data di scadenza del saldo.',
   outstandingAmount: 'Importo inevaso',
   outstanding: 'Eccezionale',
   balanceDue: 'Saldo dovuto',
   bookingDetails: 'Dettagli prenotazione',
   user: 'Utente',
   consultantNotes: 'Note dei consulenti',
   historynotfound: 'Storia non trovata',
   leadpaxdetails: 'Lead Pax Dettagli',
   additionalpaxdetails: 'Ulteriori dettagli Pax',
   locationdetails: 'Dettagli posizione',
   selectedHotels: 'Alberghi selezionati',
   confirmed: 'Confermato',
   snooze: 'Snooze',
   upload: 'Caricare',
   novoucherfound: 'Nessun voucher trovato',
   Nopaymentsfound: 'Nessun pagamento trovato',
   newvoucher: 'Carica un nuovo voucher per questa prenotazione',
   allocation: 'Allocazione',
   selectedtoursandtickets: 'Tour e biglietti selezionati',
   offeredIn: 'Offerta In',
   tofrom: 'A / DA',
   bookingRef: 'Prenotazione Ref',
   selectedTransport: 'Trasporto selezionato',
   segmentmessage: 'Confermando questo segmento, si riconosce che questo prodotto è stato prenotato con il fornitore, Al di fuori di questa applicazione. Al fine di completare la conferma, si prega di compilare il campo qui sotto.',
   bookingReference: 'Riferimento prenotazione',
   suppliersbookingreference: 'Immettere il riferimento di prenotazione del fornitore',
   confirmsegment: 'Conferma segmento',
   pickupTime: 'Tempo di ritiro',
   totalDuration: 'Durata totale',
   layoverTime: 'Disporre nel tempo',
   ticketType: 'Tipo di biglietto',
   class: 'Classe',
   provider: 'Provider',
   viewcustomer: 'Visualizza cliente',
   costofitinerary: 'Costo totale dell\'itinerario',
   paid: 'Pagato',
   receiptofallpayments: 'Ricevute di tutti i pagamenti fino ad oggi',
   balanceduetocustomer: 'Saldo dovuto al riscossionio dal cliente',
   recordcustomerpayment: 'Registrare un pagamento cliente di seguito.',
   completePayment: 'Pagamento completo',
   cancel: 'Annulla',
   accommodationORtransportation: 'Selezionare almeno un alloggio o un trasporto.',
   preferredPropertyClass: 'Classe di proprietà preferita',
   transportation: 'Trasporto',
   preferredtransporttype: 'Tipo di trasporto preferito',
   preferredtransportclass: 'Classe di trasporto preferita',
   agegroup: 'Gruppo di età',
   interest: 'Interesse',
   activityinterest: 'Interesse attività',
   applytoalllocations: 'Applica a tutte le posizioni',
   applythislocation: 'Applica questa posizione',
   apply: 'Applicare',
   hotelDescription: 'Descrizione hotel',
   activityDescription: 'Descrizione attività',
   checkoutcancelationdescription: 'È un fatto da tempo stabilito che un lettore sarà distratto dal contenuto leggibile di una pagina quando si guarda il suo layout. Il punto di utilizzare Lorem Ipsum è che ha una distribuzione più o meno normale delle lettere, invece di usare \'Contenuto qui, contenuto qui\', facendolo sembrare un inglese leggibile. Molti pacchetti di desktop publishing ed editor di pagine web ora utilizzano Lorem Ipsum come testo del modello predefinito, e una ricerca di \'lorem ipsum\' scoprirà molti siti web ancora nella loro infanzia. Diverse versioni si sono evolute nel corso degli anni, a volte per caso, a volte di proposito (umorismo iniettato e simili).',
   OverrideQuotation: 'Salva/Sostituisci offerta',
   DuplicateQuotation: 'Virgoletta duplicata',
   OriginCity: 'Città di origine',
   ReturnCity: 'Città di ritorno',
   AddTransport: 'Aggiungi trasporto',
   AddHotels: 'Aggiungi Hotel',
   AddActivities: 'Aggiungi attività',
   ManagePreferences: 'Gestire le preferenze client',
   searchPNR: 'Cerca PNR',
   oneWay: 'senso unico',
   multiCity: 'Città Multi',
   
};
