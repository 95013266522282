
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

@Component
export default class Currency extends Vue {
    isActive: boolean = false;
    data: any = {
        currencies: [
            { flag: 'au', value: 'AUD', title: 'AUD' }, 
            { flag: 'nz', value: 'NZD', title: 'NZD' }, 
            { flag: 'us', value: 'USD', title: 'USD' },
            { flag: 'gb', value: 'GBP', title: 'GBP' },
            { flag: 'ae', value: 'AED', title: 'AED' },
            { flag: 'eu', value: 'EUR', title: 'EUR' },
            { flag: 'jp', value: 'JPY', title: 'JPY' },
            { flag: 'mx', value: 'MXN', title: 'MXN' },
            { flag: 'za', value: 'ZAR', title: 'ZAR' },
            { flag: 'th', value: 'THB', title: 'THB' },
            { flag: 'id', value: 'IDR', title: 'IDR' },
            { flag: 'dk', value: 'DKK', title: 'DKK' }
        ],
        flag: '',
        instyle: { 
            display: 'inline'
        }
    };
    props: any = {
        iconStyle:{
            type: String,
        }
    };

    selected: any= {
        title: 'AUD',
        exchange_rate:0
    };
    theStyle: any = {
        display: this.isActive ? 'inline' : 'none'
    }
    // mounted(){
    //     //this.changeCurrency('AUD','au');  
    //     //this.data.flag = 'au';
    //     let vm = this;
    //     if(vm.$route.path == '/'){
    //         vm.$store.dispatch("changeCurrency", vm.$store.state.organisation.baseCurrency);
    //         vm.$store.dispatch("changeCurrencyRate", 1);
    //     }
    // }
    selectCurrency(){
        this.isActive = !this.isActive;
    }
    changeCurrency(currency: string, flag: string){ 
        let vm = this;
        // vm.$store.dispatch('SetLoading',1);
        // vm.getCurrencyRate(vm.$store.state.currency, currency);
        // vm.$store.state.currency = currency;
        // vm.$store.dispatch("changeCurrency", currency);
        vm.selected.title = currency;
        vm.isActive = false;
        
    }
    // getCurrencyRate(oldCurrency:any,newCurrency:any){
    //     let vm = this;
    //     let { baseCurrency } = vm.$store.state.organisation;
    //     let currencyRequest = {from: baseCurrency, to: newCurrency}
    //     ApiRequest.getCurrencyRate(currencyRequest).then(function(res){
    //         res = res.data;
    //         if(res.code == 200){  
    //            vm.$store.state.organisation.gdpr_state=0;
    //             vm.$store.dispatch('SetLoading',0);
    //             vm.$store.dispatch("changeCurrencyRate", res.data.exchange_rate);
    //         }else{
    //             vm.$store.dispatch('SetLoading',0);
    //             vm.$store.dispatch("changeCurrencyRate", 1);
    //            vm.$store.dispatch("changeCurrency", baseCurrency);
    //         }
    //     }).catch(function(error){
    //         vm.$store.dispatch('SetLoading',0);
    //         vm.$store.dispatch("changeCurrencyRate", 1);
    //         vm.$store.dispatch("changeCurrency", baseCurrency);
    //     });
    // }

    // @Watch ('$store.getters.currency') changeCommission (newValue, oldValvue){
    //     this.selected.title = newValue;
    // }
    // @Watch ('$store.getters.exchange_rate') changeExchangeRate (newValue, oldValvue){
    //     this.selected.exchange_rate = newValue;
    // }
}
