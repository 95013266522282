
import { Component, Vue} from "vue-property-decorator";
import { applicationName } from "@/plugins/constants";
import _ from "lodash";

@Component

export default class packagesTab extends Vue {
  name:any= "packagesTab";
  interestOption: any = [
    { value: 0, text: "Select Interest" },
    { value: 1, text: "Golfing" },
    { value: 2, text: "Spa" },
    { value: 3, text: "Climbing" },
  ];
  interest: any = [];
  applicationName: any = applicationName;

  searchRequest: any = [];
  searching = false;
  
}
