
    import { Component, Vue, Watch } from 'vue-property-decorator';    
    import widgetFlightOneWay5 from "@/Module/Global/components/website/widgetSearch/widgetFlightOneWay.vue";
    import widgetFlightReturn5 from "@/Module/Global/components/website/widgetSearch/widgetFlightReturn.vue";
    import widgetFlightMultiCity5 from "@/Module/Global/components/website/widgetSearch/widgetFlightMultiCity.vue";
    @Component({
        components:{            
            widgetFlightOneWay5,
            widgetFlightReturn5,
            widgetFlightMultiCity5
        }
    })
    export default class widgetFlight extends Vue {
        selectedFlightType:any="oneway";   
        flightCount:any = 0;     
        flightType:any=[
            { id: 1, name: "One Way", value:"oneway"},
            { id: 2, name: "Return", value:"return"},
            { id: 3, name: "Multi City", value:"multicity"},
        ]

        @Watch('selectedFlightType') checkFlightTypeChange(oldValue, newValue){            
            this.$root.$emit("multicityChecked",this.selectedFlightType);
        }
    }
