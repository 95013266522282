export const ChineseTraditional: any = {
   creatingFor: '為...創建路線',
   creatingNew: '為新客戶創建路線',
   searchTitleItinerary: '路線',
   searchTitleFlights: '航班和火車',
   searchTitleHotel: '酒店和BnB\'s',
   searchTitleTours: '旅遊和門票',
   searchTitlePackages: '套餐',
   searchTitleRentalcars: '租車和房車',
   searchTitleTransfers: '轉機',
   searchTitleCruises: '巡遊',
   searchTitleEvents: '事件',
   searchTitleInsurance: '保險',
   searchInstructions: '添加出發地點，然後選擇\'搜索\'。在下一步中，在“確認路線”中添加其他位置。',
   searchAddPlaces: '出發位置',
   searcPlaceholder: '位置',
   searchError: '*請選擇兩個位置',
   searchBlurb: '創建行程”。通過鍵入目的地來添加您希望訪問的每個位置，然後單擊Enter以添加它們。',
   consultantProfile: '顧問資料',
   consultantSearchCust: '客戶',
   Searchdata: '搜索',
   consultantSearchBlurb: '查找客戶的預訂',
   consultantSearchCustButton: '搜索客戶',
   consultantSearchCont: '搜索聯繫人',
   consultantQuick: '快速訪問',
   consultantActive: '預訂',
   ActiveData: '有效',
   consultantActiveBlurb: '查看您的有效預訂列表',
   consultantActiveButton: '查看當前的預訂',
   consultantPending: '預訂',
   PendingData: '待處理',
   consultantPendingBlurb: '查看您待處理的預訂列表',
   consultantPendingButton: '查看待處理的預訂',
   consultantItineraries: '活動路線',
   consultantCustomers: '客戶',
   consultantAccount: '帳戶摘要',
   consultantNotices: '通知和通知',
   consultantTaskList: '任務列表',
   consultantTaskYours: '您的任務”，任務',
   consultantTaskNotFound: '未找到“',
   consultantTaskConfirmCompleteMsg: '確定要完成”標記為完成的任務',
   consultantCustName: '客戶名稱',
   consultantActiveAgency: '查看有效的代理商預訂',
   consultantPendingAgency: '查看待處理的代理商預訂',
   consultantTaskCustName: '客戶名稱',
   consultantTaskBookingId: '預訂ID',
   consultantTaskNote: '註釋',
   consultantTaskDate: '選擇日期',
   consultantTaskAddButton: '添加任務',
   consultantTaskEditButton: '編輯任務',
   consultantTaskCompleted: '完成',
   consultantTaskactions: '操作',
   consultantTasksnoozed: '延後',
   consultantTasktasks: '任務',
   consultantTasksNone: '好像您是最新的，做得很好',
   consultantMidOffice: '辦公室中部',
   consultantMidOfficeUpload: '將數據上傳到辦公室中部',
   sidebarHeading: '確認路線”',
   siderbarInstructions: '添加所有名勝古蹟，並將其設置為所需的順序。選擇每個位置的出發日期和停留時間。如果選擇預先選擇的庫存，則通過每個位置的庫存選項優化結果。',
   sidebarOptionDeparture: '出發和旅行的人',
   sidebarDepDate: '出發日期',
   sidebarDepSelect: '選擇要離開第一個地點的日期',
   sidebarDepError: '錯誤，請選擇一個',
   travellersNumber: '旅客人數',
   siderbarnewInstructions: '添加所有名勝古蹟並按所需順序設置。選擇每個位置的出發日期和停留時間。如果選擇預先選擇的庫存，則通過每個位置的庫存選項優化結果。',
   sidebarnewOptionDeparture: '出發和旅行的人',
   depDate: '出發日期',
   sidebarnewDepSelect: '選擇要離開第一個地點的日期',
   sidebarnewDepError: '錯誤，請選擇一個',
   sidebarTravellersNumber: '旅客人數',
   Rooms: '房間',
   adults: '成人',
   children: '兒童',
   childAgeLimits: '年齡（0-17）',
   Infants: '嬰兒',
   infantAgesLimit: '年齡（1歲及以下）',
   childAges: '年齡”您要與之（啟程時），',
   ageOfChild: '同行的孩子“孩子的年齡',
   infantConfirm: '一歲以下的嬰兒（啟程時）',
   infantLengthPlural: '是嬰兒',
   infantLengthSingular: '是嬰兒',
   infantLengthEnd: '（未滿一個人的年齡（出發時）',
   infantLapStart: '該',
   infantLapPlural: '嬰兒',
   infantLapSingular: '嬰兒”，嬰兒',
   infantLapEnd: '在成人腿上旅行嗎？',
   infantsLap: '嬰兒將在成人腿上旅行嗎？',
   sidebarnewInventoryOps: '庫存和位置選項',
   sidebarViewTours: '查看團體旅遊',
   sidebarnewRouteOptions: '請選擇要包含在此路線中的選項。',
   siderbarnewAutoPopLoc: '自動填充趨勢位置。',
   siderbarnewAutoPopInv: '使用客戶偏好自動填充廣告資源',
   arrivalDate: '到達日期” ，',
   confirmRouteButton: '確認路線',
   redirectInventory: '行程清單',
   sidebarInventoryAlert: '向I添加和刪除行程清單”在右邊。 </ p>請注意：添加多日遊將鎖定以前的位置日期。',
   redirectActivities: '選擇活動',
   sidebarActivitiesAlert: '通過選擇左側的細分來管理行程中的庫存”。要添加，刪除或重新排序位置，請選擇“查看地圖/路線”按鈕。',
   redirectHotelList: '選擇酒店',
   sidebarHotelListAlert: '從列表中選擇新酒店”。 </ p>您當前位於頂部的酒店特色。',
   redirectHotel: '選擇您的房間',
   sidebarHotelAlert: '選擇您的房間以將該酒店添加到行程中”，行程”，行程”',
   redirectToursList: '選擇“選擇',
   sidebarToursListAlert: '從列表中。 </ p>單擊遊覽以查看更多詳細信息，價格並查看出發地',
   redirectTour: '選擇出發地',
   sidebarTourAlert: '向下滾動以找到出發日期及其價格的列表。” </ p>可以將此遊覽添加到您的行程或直接預訂行程',
   redirectTransport: '選擇運輸',
   sidebarTransportAlert: '，',
   checkoutAlert: '”輸入乘客詳細信息以完成預訂',
   checkout: '結帳',
   redirectPreview: '行程預覽',
   redirectPreviewPDF: '預覽PDF',
   sidebarnewInventoryOptions: '庫存選項',
   errorMsg: '此處顯示錯誤”',
   remove: '刪除',
   sidebarnewInclusiveTourButton: '團體旅遊',
   sidebanewNotes: '將此地點添加到路線',
   sidebarnewReturnTripOption: '回程選項',
   sidebarnewRoundTripForm: '請選擇起點和回程”。如果您希望包括回程航班。',
   to: '至',
   night: '夜',
   tourDuration: '時長',
   contact: '聯繫',
   checkinDate: '入住日期',
   checkoutDate: '退房日期',
   supplierReference: '供應商',
   propertyReference: '預訂參考',
   transport: '運輸',
   service: '服務',
   inventoryTravelClass: '旅行艙位',
   departureLocation: '出發地點',
   arrivalAirport: '到達地點',
   status: '狀態',
   departuretime: '出發時間',
   hotels: '酒店',
   hotelPricePerNight: '每晚價格',
   hotelPropertyRating: '物業評分',
   searchbyPropertyName: '按屬性名稱搜索',
   search: '搜索',
   refundtype: '退款類型',
   days: '天數',
   location: '位置',
   pernight: '每晚',
   pendingItineraries: '待定路線',
   hotelownArrangementPropertyName: '屬性名稱*',
   hotelownArrangementPropertyNotes: '屬性註釋*',
   validationMsg: '此字段為必填項。',
   emailValidationMsg: '電子郵件地址”字段必須是有效的電子郵件。',
   contactMinValidationMsg: '聯繫電話字段必須至少包含10個字符。',
   contactMaxValidationMsg: '聯繫電話字段必須至少包含11個字符。',
   ownArrangementImage: '圖片',
   ownArrangementHotelimage: '酒店圖片',
   ownArrangementImgClick: '點擊或拖動酒店圖片”在這裡上傳',
   ownArrangementFileTypeMsg: '（支持的文件類型.jpeg，.jpg，.png）',
   hotelownArrangementPropertyaddress: '屬性地址*',
   hotelownArrangementPropertyContact: '屬性聯繫人電話號碼*',
   hotelownArrangementPropertyemail: '屬性電子郵件*',
   hotelownArrangementDurationDetails: '持續時間詳細信息',
   hotelownArrangementPropertyCheckindate: '簽到日期*',
   hotelownArrangementPropertyCheckintime: '入住時間*',
   hotelownArrangementPropertyCheckouttime: '物業地址*',
   hotelownArrangementPropertyCheckoutdate: '退房日期*',
   ownArrangementPricingDetails: '價格明細',
   ownArrangementCurrency: '貨幣*',
   hotelownArrangementCurrency: '購買貨幣*',
   hotelownArrangementPropertyPrice: '售價*',
   hotelownArrangementPropertybookingref: '預訂參考*',
   ownArrangementPurchasingdetails: '購買明細',
   ownArrangementPurchasePrice: '購買價格*',
   hotelownArrangementPropertySupplier: '供應商*',
   hotelownArrangementPropertyInvoice: '在最終發票中包含此安排住宿定價。',
   hotelownArrangementPropertyRefund: '此安排住宿產品可退款',
   cancelButton: '取消”。 ，',
   addtoitinerary: '添加到行程',
   ownArrangementBasicDetails: '基本酒店詳細信息',
   activityownArrangementActivityname: '活動名稱*',
   activityownArrangementAddress: '地址*',
   activityownArrangementPhnno: '電話號碼*',
   activityownArrangementEmail: '電子郵件*',
   activityownArrangementWeburl: 'Web URL',
   activityownArrangementImage: '圖像',
   ownArrangementActivityImgClick: '單擊或拖動”活動圖像在這裡上傳',
   activityownArrangementActivityImage: '活動圖片',
   activityownArrangementSellingPrice: '售價*',
   activityownArrangementSupplierReference: '供應商',
   activityownArrangementPurchaseCurrency: '購買幣種*',
   activityownArrangementPropertyReference: '預訂參考*',
   ownArrangementComments: '評論',
   activityownArrangementComments: '評論',
   operator: '運營商',
   ownArrangementTravelClass: '班級',
   ownArrngementArrivalTime: '到達”時間',
   ownArrangementAirlineReference: '航空公司參考',
   ownArrangementGdsReference: 'GDS參考',
   downloadPDF: '下載PDF',
   expandAll: '全部展開',
   collapseAll: '全部折疊',
   showMore: '顯示更多',
   showLess: '顯示較少',
   accommodation: '住宿',
   name: '姓名',
   checkIn: '簽到” ，',
   checkOut: '退房',
   checkOutDash: '退房',
   price: '價格',
   PerNight: '每晚',
   roomDetails: '房間詳細信息',
   roomType: '房間類型',
   pendingBookingNights: '夜',
   nonRefundable: '不可退款',
   refundable: '可退款',
   ownArrangement: '自己的安排',
   toursActivities: '旅行/活動',
   viewitenaryActivityname: '活動名稱',
   viewitenaryAddress: '地址',
   viewitenaryPhone: '電話',
   viewitenarySellingPice: '銷售價格',
   email: '電子郵件',
   date: '日期',
   duration: '持續時間',
   code: '代碼',
   startDate: '開始日期',
   finishDate: '完成日期',
   endDate: '結束日期',
   aud: '澳元',
   view: '查看',
   noDataFound: '找到記錄',
   notProvided: '未“未提供',
   pendingBookingPage: 'X',
   pendingBookingCustomerName: '客戶名稱',
   pendingBookingDepdate: '部門”。日期',
   pendingBookingCreatedOn: '創建於',
   travellers: '旅行者',
   pendingBookingTotalPrice: '總價',
   ownArrangementContactDetails: '聯繫方式',
   sortby: '排序方式：',
   titleOfActivity: '標題',
   activityCode: '活動代碼：',
   viator: '訪客',
   from: '發件人',
   perPerson: '每人” ，',
   category: '類別',
   sidebarActivityName: '按活動名稱搜索',
   readmore: '閱讀更多',
   hotelPickupIncluded: '包含酒店接客服務',
   activityDetaildeparturePoint: '出發地點：',
   overview: '概述',
   importantInfo: '重要信息',
   details: '詳細信息',
   activityDetailInclusions: '包含',
   activityDetailExclusions: '排除” ，',
   activityDetailAdditionalinfo: '其他信息',
   activityDetailVoucherinfo: '優惠券信息',
   activityDetailLocalOperatorInformation: '本地運營商信息',
   activityDetailLocalOperatorInformationlope: '完整的運營商信息，包括目的地的本地電話號碼”都包含在您的確認憑證中。我們的產品經理只選擇每個目的地中經驗最豐富，最可靠的運營商，從而為您消除了猜測，並確保您放心。',
   activityDetailCancellationPolicy: '取消政策',
   activityDetailScheduleAndPricing: '計劃和定價',
   activityDetailScheduleAndPricingContent: '我們的定價會不斷更新，以確保您總是獲得最低的價格-我們100％保證。',
   activityDetailBookActivity: '預訂活動',
   activityDetailDeparts: '出發',
   activityDetailPickup: '提貨：”，提貨',
   activityDetailCheckedPickup: '我需要',
   activityDetailaddActivity: '添加活動',
   activityDetailSpecialRequirements: '特殊要求',
   activityDetailSelectLocation: '選擇位置',
   activityDetailEnterPickupLocation: '輸入提貨地點*',
   noRecordFound: '未找到記錄',
   sidebarTransportCustomerPricing: '客戶定價',
   sidebarTransportCustomerPricingContent: '行程定價明細，包括客戶費用總額和按PAX。',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'Total Cost AUD inc。”。稅',
   sidebarTransportPricePerPaxAudincTax: '每PAX AUD的價格”。稅',
   sidebarTransportCostPerDay: '每日成本：',
   sidebarTransportCost: '458.00',
   sidebarTransportTotalCost: '總成本：',
   sidebarTransportTotal: '785澳元',
   sidebarTransportAgencyCost: '代理商成本',
   sidebarTransportOne: '一個',
   sidebarTransportTwo: '兩個',
   sidebarTransportThree: '三個',
   sidebarTransportFilters: '運輸過濾器',
   sidebarTransportType: '運輸類型”',
   return: '退貨',
   transportOnlyRates: '關閉代理商視圖以顯示客戶的零售價。',
   transportOnlySaveQuotation: '保存報價',
   transportOnlyBookItinerary: '預訂行程',
   transportOnlyCustomerPricing: '客戶定價',
   transportOnlyAgencyCost: '代理商費用',
   transportOnlyFilters: '過濾器',
   transportOnlyDepartureLocation: '出發地點',
   transportOnlyDestinationLocation: '目的地位置',
   transportOnlyReturnDate: '返回日期',
   transportOnlyParistoRome: '巴黎到羅馬”，，',
   transportOnlyFilterContent: '2019年4月1日星期一',
   change: '更改',
   transportOnlyvueling: '伏林',
   nonStop: '直航',
   departs: '出發',
   transportOnlyParisOrlyAirport: '巴黎奧利機場（ORY）”',
   transportOnlyArrive: '到達',
   transportOnlyFco: '羅馬-火車站（FCO)',
   transportOnlyKiwi: '羅馬蒂布蒂納\'Kiwi.com\'',
   transportOnlyMystifly: '米斯蒂夫',
   transportOnlySabre: '佩刀',
   transportOnlyEconomy: '經濟',
   transportOnlySelect: '精選',
   transportOnlyRouteDetails: '路線詳細信息',
   transportOnlyCalendar: '2019年4月1日，星期一',
   transportOnlyFlight: '航班',
   transportOnlyShowDetails: '顯示詳細信息',
   transportOnlyParis: '巴黎-里昂火車站”，車站',
   transportOnlyMilan: '米蘭-中央“',
   transportOnlyBaggageDetails: '行李詳細信息',
   transportOnlyPersonalBaggage: '個人行李',
   transportOnlyFree: '免費',
   transportOnlyCheckedBaggage: '托運行李，',
   transportOnlyAddCheckedBaggage: '添加托運行李',
   transportOnlyAddCabinBaggage: '添加隨身行李',
   transportOnlyInFlight: '機上附加組件',
   transportOnlyPassengerPricingDetails: '乘客和價格明細',
   transportOnlyPassenger: '乘客',
   transportOnlyonexAdult: '1 x成人',
   transportOnlyBaseFare: '基本票價',
   transportOnlyBaggage: '行李',
   transportOnlyFeesAndTaxes: '其他費用/稅金',
   transportOnlyTermsConditions: '條款和條件”條件',
   transportOnlyNetRate: '淨費率',
   transportOnlyCommision: '佣金',
   checkinTime: '簽到時間” ，',
   checkoutTime: '結帳時間',
   searchCustomerName: '按客戶名稱搜索',
   confirmroute: '確認路線',
   logout: '登出',
   searchbypropertyname: '按物業名稱搜索',
   hotelDetailshotelRoom: '（1間客房）',
   hotelDetailRoomsRates: '客房和房價',
   hotelDetailAmenities: '酒店便利設施',
   hotelDetailCheckInInstructions: '入住”說明',
   hotelDetailsFeesOptionalExtras: '費用/可選附加項',
   hotelDetailValueAddedServices: '增值服務',
   hotelDetailCheckin: '簽到',
   hotelDetailhotelCheckout: '簽出：',
   hotelDetailBedPreferencese: '床偏好設置：',
   hotelDetailViewCancellationPolicy: '查看取消政策',
   hotelDetailRoomDetailAmenities: '房間詳細設施，',
   hotelDetailRoomAmenities: '房間設施',
   hotelDetailGuests: '客人：',
   hotelDetailSubtotal: '小計：',
   hotelTotal: '總計',
   hotelDetailTaxRecoveryServiceFee: '退稅和服務費：',
   hotelDetailSpecialCheckInInstructions: '特殊入住說明',
   hotelDetailadditionalInformation: '其他信息',
   headerLogin: '登錄',
   headerLogout: '註銷',
   hide: '隱藏',
   depart: '出發',
   arrive: '到達',
   flightNumber: '航班號',
   routeDetails: '路線詳細信息',
   wifi: 'WIFI',
   included: '已包含',
   airCondition: '空調',
   passengerPricingDetails: '乘客和價格詳細信息',
   supplier: '供應商',
   passenger: '乘客',
   passengers: '乘客',
   flightMystifly: '我的飛行',
   flight2Adult: '2 x成人',
   baggage: '行李',
   otherFeesTaxes: '其他費用/稅項',
   free: '免費',
   netRate: '淨費率',
   select: '選擇',
   floatZero: '0.00”，“',
   doubleZerofloat: '00.00',
   tv: '電視',
   flightEconomy: '經濟',
   viewMore: '查看更多” ，',
   flightOnBoardAddOns: '機載插件',
   loginSignIn: '登錄',
   customerNameOrEmail: '客戶名稱或電子郵件',
   searchBy: '搜索依據',
   firstName: '名字',
   lastName: '姓氏',
   gender: '性別',
   phone: '電話',
   submitButton: '提交',
   your: '您的”',
   createdOn: '創建時間',
   nights: '晚上',
   totalPrice: '總價',
   emailUserName: '電子郵件或用戶名',
   userName: '用戶名',
   password: '密碼',
   enterPassword: '輸入密碼',
   enterCustomerName: '輸入客戶名',
   taskEnterBookingId: '輸入預訂ID',
   commission: '佣金',
   costPerDay: '每日費用',
   pricePerPAX: '每份PAX的價格”，含稅',
   totalCost: '總費用',
   netCost: '淨費用',
   includingTax: '”。稅',
   priceSummary: '價格摘要',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: '包含客戶總費用和按PAX的行程定價明細。',
   sidebarHeadingPreviewItinerary: '預覽行程',
   sidebarHeadingSaveQuotation: '保存報價',
   sidebarHeadingBookItinerary: '預訂行程',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: '關閉代理商視圖以顯示零售價”客戶。',
   agentView: '代理商視圖',
   customerView: '客戶視圖',
   save: '保存',
   travelInsurance: '旅行保險',
   no: '否',
   addTravel: '添加旅行',
   plus: '加',
   basic: '基本',
   inventoryMedicalExpenseTripCancellation: '醫療費用，行程取消',
   inventoryAssistanceServices: '協助”服務',
   inventoryLostBaggageMore: '超重行李',
   audWithFloating: '00.00澳元',
   inventoryComparsionAndTerms: '比較和條款',
   customerCare: '客戶服務',
   premium: '高級',
   inventoryExploreBenefitsOfPayingPremiumMsg: '探索支付保費的好處',
   previous: '上一個',
   continue: '繼續',
   saveAndContinue: '保存” ＆繼續',
   BacktoInventory: '返回庫存',
   AcceptandContinue: '接受並繼續',
   busBud: 'BusBud',
   edit: '編輯',
   delete: '刪除',
   checked: '已檢查',
   checkoutBusListINBusAddonMsg: '公交附加件',
   BaseFarePerPax: '基本票價（每人）',
   onexAdult: '1 x Adult',
   oneRoom: 'Room 1',
   CancellationPolicy: 'Cancellation Policy',
   expedia: 'Expedia',
   roomSleep: 'Room sleep',
   checkOutActivityListviator: 'Viator',
   checkOutActivityDetails: 'Activity Details',
   option: 'Option',
   groupInclusiveTourOptions: 'Group Inclusive Tour Options',
   matchCurrentRouteMsg: '目前有219組” ，與您當前路線相匹配的包容性旅行。',
   errorDisplayHere: '此處顯示錯誤',
   tourFilters: '旅行過濾器',
   tailorMadeTours: '量身定制的旅行',
   tailorMadeOptionsMsg: '請選擇您要選擇的選項只能顯示量身定制的選項',
   tailorMadeToursItineryMsg: '僅顯示量身定制的行程',
   MonthOfDeparture: '出發月份',
   selectDate: '選擇日期',
   destination: '目的地',
   tourExperience: '遊覽體驗',
   preferredSupplier: '首選供應商',
   style: '風格”',
   size: '尺寸',
   start: '開始',
   notAvailbale: '不適用',
   inclusions: '包含物',
   day: '日',
   meals: '餐食',
   breakfast: '早餐',
   lunch: '午餐',
   dinner: '晚餐',
   tourDetailsNoMealMsg: '這一天不包括餐點”。 ，',
   locationsToVisit: '要參觀的地點',
   additionalInformation: '其他信息',
   departureNotFound: '找不到出發地',
   additionalInformationNotFound: '沒有更多可用信息”，可用情況',
   departureAndPricing: '出發地和價格',
   departing: '出發地',
   finishing: '完成情況',
   availability: '',
   totalFrom: '總計',
   perAdult: '每次”成人',
   perChild: '每個孩子',
   departureGuaranteed: '可保證出發',
   onSale: '出售',
   onRequest: '應要求',
   addToRoute: '添加到路線',
   termsConditions: '條款和條件',
   available: '可用',
   finish: '完成',
   stars: '星級',
   reviews: '評論” ，',
   rating: '評分',
   default: '默認',
   title: '標題',
   lengthOfTour: '遊覽時間',
   optionsSelected: '選擇的選項',
   tourNotFound: '未找到遊覽',
   validTo: '有效期',
   lodging: '住宿',
   validFrom: '有效期自',
   perNight: '每晚',
   sortBy: '排序方式',
   viewItineraryGITtours: 'GIT遊覽',
   inventoryAddNewAccommodation: '選擇另一家酒店',
   inventoryAddNewTransport: '添加新交通工具',
   passengersAvoidBoardingComplicationMsg: '使用與護照/身份證上顯示的名稱完全相同的名稱（Given和姓氏），以避免登機。',
   passengersRequiredFlightSegmentMsg: '必填”國際航班航段。',
   dateOfBirth: '出生日期',
   passengersTourSpecificField: '旅遊特定領域',
   passengersTourSegmentMsg: 'X航段所需',
   visaRequriements: '簽證安排',
   passengersreferencePurposeMsg: '旅行時可能需要簽證或機場過境簽證，即使不是停在中轉國家。這些要求僅供參考。請與使館或外交部聯繫以獲取更多信息。',
   passengersDeniedDestinationIssueMsg: 'eRoam對因簽證問題而被拒絕登機或進入任何目的地的任何乘客不承擔任何責任。',
   givenNames: '給定姓名',
   passengerNames: '給定姓名*',
   surname: '姓氏',
   passengersurname: '姓*',
   familyname: '姓氏',
   PassengerGender: '性別*',
   paymenttotalprice: '總價',
   paymentnewprice: '新價格',
   emailAddress: '電子郵件地址',
   passengeremailAddress: '電子郵件地址*',
   contactNumber: '聯繫電話',
   passengercontactNumber: '聯繫電話*',
   passengersIndividualInventorySpecificationMsg: '以下字段為必填項取決於個別的庫存規範，例如內部航班，旅行要求等。',
   Nationality: '國籍',
   PassengerNationality: '國籍*',
   confirmBooking: '確認預訂',
   receiptNumber: '收據編號',
   paymentAddReceiptNumberMsg: '添加已處理付款的收據編號。',
   PaymentDeniedDestinationIssueMsg: 'A”旅行可能需要簽證或機場過境簽證。在此處找到的資產僅供參考。請聯繫使館或外交部以獲取更多信息。對於因簽證問題而被拒絕登機或進入任何目的地的任何乘客，eRoam概不負責。',
   amount: '金額*',
   paymentEnterAmountMsg: '輸入正在的金額',
   paymentEnterTypeMsg: '付款“輸入付款方式',
   paymentType: '付款”鍵入*',
   paymentOnceSubmittedMsg: '一旦提交了付款詳細信息，便會確認預訂。',
   payment: '付款',
   printPDF: '打印PDF',
   custom: '自定義',
   additionalNotes: '其他說明',
   list: '列表',
   checkinDash: '簽到',
   detailedSummary: '詳細摘要',
   dailySummary: '每日摘要',
   tripSummary: '旅行摘要',
   transportName: '運輸名稱',
   activities: '活動',
   basicTransportDetails: '基本運輸明細',
   transportOwnArrangementRefundMsg: '此自家安排運輸產品可退款。',
   transportOwnArrangementPricingMsg: '在最終發票中包含此自有安排運輸價格”。',
   salePrice: '售價',
   transportOwnArrangementAddLegtoArrangement: '添加腿到安排',
   arrivalLocation: '到達位置',
   transportOwnArrangementAddNewLeg: '添加新腿',
   arrival: '到達',
   departure: '出發',
   fromTo: '從-到',
   selectThePlaceToReturn: '選擇返回的地方',
   selectAReturn: '選擇回程',
   returnToAnotherCity: '返回其他城市',
   selectPlaceOrigin: '選擇原點。',
   selectOrigin: '選擇原點',
   accomodationSummary: '住宿摘要',
   transportSummary: '運輸摘要',
   activitySummary: '活動摘要',
   taskListConformDeleteMsg: '請確認您要刪除任務？',
   ConfirmActionTitle: '確認操作',
   passengertitle: '標題*',
   hotelListTotalCost: '總計”費用',
   nonstop: '不間斷',
   selected: '選定',
   inventoryAddNewHotels: '添加新酒店',
   inventoryAddOwnArrangement: '添加自己的安排',
   propertylocation: '屬性位置',
   inventoryAddNewToursTickets: '選擇其他遊覽和門票',
   inventoryContactPhone: '聯繫電話',
   inventoryContactEmail: '聯繫電子郵件',
   flight: '航班”',
   time: '時間',
   bookingId: '預訂ID',
   bookingNote: '預訂註釋',
   stops: '停止',
   layOverDuration: '停留時間',
   itineraryPdfImageText: '單擊或拖動此處的行程圖像以上傳',
   confirm: '確認',
   changePassword: '更改密碼',
   oldPasswordErrorMsg: '輸入舊密碼',
   newPasswordErrorMsg: '輸入新密碼',
   confirmPasswordErrorMsg: '密碼確認不匹配',
   oldPassword: '舊密碼',
   confirmPassword: '確認密碼',
   newPassword: '新密碼',
   visitedLocations: '訪問位置',
   checkoutFlightListINFlightAddonMsg: '飛行中的附件',
   checkoutmystifly: 'Mystifly',
   tourDetailstitle: '遊覽詳細信息',
   update_total_cost: '更新總費用',
   updateCostConfirmCompleteMsg: '您確定要繼續負佣金嗎？',
   confirmButton: '確認',
   norating: '無評分',
   adult: '成人',
   child: '孩子',
   PrimaryPassenger: '主要乘客（領導人數）” ，',
   hotelbed: '酒店床',
   activityownemail: '供應商電子郵件',
   activityownweb: '網站',
   activityownphone: '電話',
   activityownsupplier: '供應商',
   hotelDetails: '酒店詳細信息',
   room: '房間',
   ownArrangementActivityBasicDetails: '基本活動詳細信息',
   booking: '預訂',
   tour: '遊覽',
   oopsTheActivity: '活動',
   oopsTheTour: '旅行',
   oopsTheFlight: '航班',
   notAvailable: '目前不可用。',
   inventoryAddHotelOwnArrangement: '自己的安排（住宿）',
   inventoryAddToursOwnArrangement: '自己的安排（旅遊和票務）',
   inventoryAddReplaceTransport: '選擇其他交通工具',
   inventoryAddTransportOwnArrangement: '自己的安排（運輸）',
   noProductSelected: '未產品',
   taskSelectDate: '選擇“選擇日期',
   taskSelecgender: '選擇性別',
   age: '年齡',
   relation: '關係',
   activityTime: '時間*',
   activityDate: '日期*',
   accountId: '帳戶ID',
   nationality: '國籍',
   city: '城市”',
   address: '地址',
   postcode: '郵政編碼',
   region: '地區',
   ontarioResident: '安大略省居民',
   prepaidGratuities: '預付小費',
   mobilePhone: '聯繫方式',
   titleBooking: '標題',
   genderBooking: '性別',
   emailBooking: '電子郵件地址',
   paymentAmount: '付款金額',
   checkoutRceiptNumber: '收據編號”',
   loadPnr: '加載PNR',
   activityownArrangementPropertyInvoice: '在最終發票中包含此價格。',
   activityownArrangementPropertyRefund: '這是可退款的',
   bookingVoucherfileType: '（（支持的文件類型.jpeg，.jpg，.png，.pdf）',
   bookingVouchermsg: '點擊此處上傳優惠券',
   carrierName: '承運人姓名',
   departureDateandTime: '出發日期和時間',
   arrivalDateandTime: '到達日期和時間',
   airlineReference: '航空公司參考',
   pnrNumber: '輸入PNR',
   pnrInstructions: '請輸入有效的PNR編號以獲取行程',
   termsandconditions: '條款和條件',
   total: '總計” ，',
   baggageAdult: '成人/兒童行李',
   baggageChild: '兒童行李',
   gitWarningMessage: '您為該城市選擇了無效的旅行團',
   summeryConfirmCompleteMsg: '您確定要繼續使用無效的旅行選擇嗎？',
   tourType: '旅行類型',
   SearchPlace: '搜尋地點 |搜尋地點',
   SearchCity: '搜索城市 |搜尋城市',
   manager: '經理',
   product: '產品',
   finance: '金融',
   admin: '管理',
   viewProductManager: '檢視產品管理員',
   viewfinanceManager: '檢視財務經理',
   viewAdminManager: '檢視管理員管理員管理員',
   viewsupplierManager: '檢視供應商經理',
   viewmarketingManager: '檢視行銷經理',
   DaterangeFrom: '日期範圍',
   DaterangeTo: '日期範圍到',
   DataType: '資料類型',
   FileType: '檔案類型',
   downloadNow: '立即下載',
   ConsultantName: '顧問姓名',
   SupplierName: '供應商名稱',
   ClientFile: '用戶端檔案',
   pay: '支付',
   TypeofPayment: '付款類型',
   Login: '登入',
   exportData: '匯出資料',
   roomNumber: '房間',
   storeName: '商店名稱',
   manageUsers: '管理使用者',
   manageBrands: '管理品牌',
   viewReports: '檢視報告',
   brand: '品牌',
   manageLicensee: '管理被授權',
   ManageLicensee: '搜尋被授權者',
   OnboardNewLicensee: '船上新持牌人',
   CreateNewPackage: '建立新套件',
   consultantArchived: '預訂',
   ArchivedData: '存檔',
   consultantArchivedBlurb: '檢視已歸檔預訂的清單',
   consultantArchivedButton: '檢視已存檔的預訂',
   consultantArchivedAgency: '檢視已存檔的代理預訂',
   archivedItineraries: '已存檔的行程',
   taskDay: '選擇"天',
   action: '行動',
   mobile: '移動',
   taskSelectDay: '選擇"天',
   DateandTime: '日期與時間',
   addtransport: '新增傳輸',
   addhotels: '新增酒店',
   addactivities: '新增活動',
   manageclientpreferences: '管理用戶端偏好設定',
   Return: '返回',
   oneway: '單向',
   multycity: '瑪律蒂市',
   flyingfrom: '飛行從',
   selectclass: '選擇類',
   returndate: '退貨日期',
   comingsoon: '即將推出',
   thingstodo: '需要做的事情',
   extendedtouring: '擴展旅遊',
   selectinterest: '選擇興趣',
   searchpnr: '搜尋 PNR',
   googlesearch: '谷歌搜索',
   package: '包',
   addcustomer: '新增客戶',
   geoLocationManager: 'GEO 位置管理員',
   supplieroperatorManager: '供應商/管理員經理',
   yieldManager: '產量經理',
   packageBuilder: '套件產生器',
   plotmaps: '繪製地圖',
   reporting: '報告',
   CSVDownload: 'CSV 下載',
   makePayments: '付款',
   packageName: '套件名稱',
   uploadImages: '上傳圖片',
   toursandtickets: '旅遊和門票',
   hotel: '酒店',
   packageOverview: '包概述',
   packageItinerary: '套餐行程',
   Optional: '選',
   createpackagefromtour: '從旅遊建立套件',
   Currency: '貨幣',
   companyName: '公司名稱',
   Phone: '電話(包括國家/地區代碼)',
   url: '網址',
   uploadLogo: '上傳徽標',
   selectProduct: '選擇產品',
   hotelSupplier: '酒店供應商',
   tourSupplier: '旅遊供應商',
   transportSupplier: '運輸供應商',
   otherSupplier: '其他供應商',
   selectSettings: '選擇設定',
   defaultLanguage: '預設語言',
   defaultCurrency: '預設貨幣',
   chooseTemplate: '選擇樣本',
   paymentGateway: '支付閘道',
   selectSubscription: '選擇訂閱',
   licenceType: '授權類型',
   howmanylicences: '有多少牌照?',
   addons: '載入項目',
   paymentTerms: '付款條款',
   licenseenameoremail: '被授權人名稱或電子郵件',
   createProfilesandClientDetails: '建立設定檔與管理客戶詳細資訊',
   passportNumber: '護照號碼',
   passportissuedate: '護照簽發日期',
   passportExpiryDate: '護照有效期',
   passportissuingcountry: '護照簽發國',
   clientContact: '客戶連絡人',
   Phonehome: '電話(家庭)',
   Phonework: '電話(工作)',
   phonemobile: '電話(手機)',
   AddressLinOne: '位址(第一行)',
   AddressLineTwo: '位址(第二行)',
   StateProvinceRegion: '州 /省 /地區',
   zipPostalCode: '郵遞區號',
   country: '國家',
   newsletter: '通訊',
   companyABN: '公司 ABN',
   paName: 'PA 名稱',
   clientLoyaltyPrograms: '客戶忠誠度計劃',
   srno: '不先生',
   loyaltyType: '忠誠度類型',
   providerName: '提供者名稱',
   membershipNumber: '會員編號',
   clientPreferences: '用戶端偏好設定',
   airline: '航空公司',
   airlines: '航空公司(S)',
   travelClass: '旅行艙位',
   preferredSeating: '首選座位',
   mealRequest: '膳食要求',
   Hotels: '酒店',
   hotelChain: '連鎖酒店',
   Cars: '汽車',
   carRental: '汽車租賃',
   CarType: '汽車類型',
   interests: '利息',
   linkedprofiles: '連結的設定檔',
   Preferreddestinations: '首選目的地',
   businessPreferences: '業務偏好',
   otherPreferences: '其他選項',
   pendingBookings: '待定預訂',
   archiveBookings: '存檔預訂',
   activeBookings: '活動預訂',
   viewdetails: '詳細資訊',
   update: '更新',
   bookingmanagement: '預訂管理',
   bookingDashboard: '預訂儀錶板',
   bookingoverview: '預訂概述',
   Generalbookingdetails: '一般預訂詳情。',
   type: '類型',
   created: '建立',
   segmentoverview: '區隔概述',
   totalnumberofsegment: '產品/細分市場的總數和未確認的市場區隔。',
   segmentsconfirmedvstotalsegment: '已確認的細分與預訂中包含的總細分。',
   amountremainingforclient: '客戶在餘額到期日之前支付的剩餘金額。',
   outstandingAmount: '未繳金額',
   outstanding: '優秀',
   balanceDue: '到期餘額',
   bookingDetails: '預訂詳情',
   user: '使用者',
   consultantNotes: '顧問說明',
   historynotfound: '找不到歷史記錄',
   leadpaxdetails: '潛在顧客詳細資訊',
   additionalpaxdetails: '其他 Pax 詳細資訊',
   locationdetails: '位置詳細資訊',
   selectedHotels: '精選酒店',
   confirmed: '證實',
   snooze: '打盹',
   upload: '上傳',
   novoucherfound: '未找到憑證',
   Nopaymentsfound: '沒有找到付款',
   newvoucher: '上傳此預訂的新憑證',
   allocation: '配置',
   selectedtoursandtickets: '精選旅遊和門票',
   offeredIn: '提供於',
   tofrom: '到 / 從',
   bookingRef: '預訂參考',
   selectedTransport: '選取的傳輸',
   segmentmessage: '通過確認此市場區隔,您確認此產品已在此應用程式之外向供應商預訂。為了完成確認,請填寫下面的欄位。',
   bookingReference: '預訂參考',
   suppliersbookingreference: '輸入供應商的預訂參考',
   confirmsegment: '確認段',
   pickupTime: '接機時間',
   totalDuration: '總持續時間',
   layoverTime: '隨時間進行鋪設',
   ticketType: '票證類型',
   class: '類別',
   provider: '供應商',
   viewcustomer: '檢視客戶',
   costofitinerary: '行程總費用',
   paid: '支付',
   receiptofallpayments: '迄今所有付款的收據',
   balanceduetocustomer: '應付從客戶那裡收取的餘額',
   recordcustomerpayment: '在下面記錄客戶付款。',
   completePayment: '完成付款',
   cancel: '取消',
   accommodationORtransportation: '• 請選擇至少一個住宿或交通服務。',
   preferredPropertyClass: '偏好屬性類別',
   transportation: '運輸',
   preferredtransporttype: '首選傳輸類型',
   preferredtransportclass: '首選傳輸類',
   agegroup: '年齡組',
   interest: '興趣',
   activityinterest: '活動興趣',
   applytoalllocations: '適用於所有地點',
   applythislocation: '套用此位置',
   apply: '套用',
   hotelDescription: '酒店描述',
   activityDescription: '活動描述',
   checkoutcancelationdescription: '長期以來,讀者在查看頁面佈局時,會因頁面的可讀內容而分心,這是一個長期確立的事實。使用 Lorem Ipsum 的要點是,它或多或少地按字母分佈,而不是使用"此處的內容,此處的內容",使其看起來像可讀的英語。許多桌面發佈包和網頁編輯現在使用Lorem Ipsum作為他們的預設模型文本,搜索"lorem ipsum"將發現許多網站仍處於起步階段。多年來,各種版本都發生了變化,有時是偶然的,有時是故意的(注入幽默等)。',
   OverrideQuotation: '儲存/覆寫報價',
   DuplicateQuotation: '重複報價',
   OriginCity: '起源城市',
   ReturnCity: '返回城市',
   AddTransport: '新增傳輸',
   AddHotels: '新增酒店',
   AddActivities: '新增活動',
   ManagePreferences: '管理用戶端偏好設定',
   searchPNR: '搜尋 PNR',
   oneWay: '單向',
   multiCity: '多城市',
};
