import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist';
import * as types from './global/mutation-types';
import moment from 'moment';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    loginInformation: {},
    step1: {},
    step2: {},
    step3: {},
    isLoading: false,
    domainId:"",
    fxrecordsList:[]
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],

  mutations: {
    [types.SET_LOGIN_INFORMATION_VALUE](state: any, payload) {
      state.loginInformation = payload;
    },
    [types.SET_STEP1_VALUE](state: any, payload) {
      state.step1 = payload;
    },
    [types.SET_STEP2_VALUE](state: any, payload) {
      state.step2 = payload;
    },
    [types.SET_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [types.DOMAIN_ID](state, payload) {
      state.domainId = payload;
    },
    [types.FX_RECORD_ADD](state, payload) {
      state.fxrecordsList = payload;
    },
    
  },
  actions: {
    setLoginInfomationValue({ commit }, payload) {
      commit(types.SET_LOGIN_INFORMATION_VALUE, payload);
    },
    setStep1Value({ commit }, payload) {
      commit(types.SET_STEP1_VALUE, payload);
    },
    setStep2Value({ commit }, payload) {
      commit(types.SET_STEP2_VALUE, payload);
    },
    SetLoading({ commit }, loading) {
      commit(types.SET_LOADING, loading);
    },
    SetDomainId({ commit }, payload) {
      commit(types.DOMAIN_ID, payload);
    },
    SetFxRecords({ commit }, payload) {
      commit(types.FX_RECORD_ADD, payload);
    },
    
  },
  getters: {
    //agentView: (state: any) => state.agentView,
  }
});
