export const Hindi: any = {
   creatingFor: 'बनाने के लिए यात्रा कार्यक्रम',
   creatingNew: 'नए ग्राहक यात्रा कार्यक्रम बनाना',
   searchTitleItinerary: 'यात्रा कार्यक्रम',
   searchTitleFlights: 'उड़ानें और ट्रेनें',
   searchTitleHotel: 'होटल और BnB \' s',
   searchTitleTours: 'पर्यटन और टिकट',
   searchTitlePackages: 'पैकेज',
   searchTitleRentalcars: 'किराये की कारें और मोटर वाहन',
   searchTitleTransfers: 'स्थानान्तरण',
   searchTitleCruises: 'परिभ्रमण',
   searchTitleEvents: 'कार्यक्रम',
   searchTitleInsurance: 'बीमा',
   searchInstructions: 'अपना प्रस्थान स्थान जोड़ें और\' खोज \'चुनें। कन्फर्म रूट के दौरान, अगले चरण पर अतिरिक्त स्थानों को जोड़ें।',
   searchAddPlaces: 'प्रस्थान स्थान',
   searcPlaceholder: 'स्थान',
   searchError: '* कृपया दो स्थानों का चयन करें',
   searchBlurb: 'अपना कार्यक्रम बनाएँ। अपने गंतव्य को टाइप करके आप जिन स्थानों पर जाना चाहते हैं उनमें से प्रत्येक को जोड़ें और उन्हें जोड़ने के लिए Enter पर क्लिक करें।',
   consultantProfile: 'सलाहकार प्रोफ़ाइल',
   consultantSearchCust: 'ग्राहक',
   Searchdata: 'खोज',
   consultantSearchBlurb: 'ग्राहक की बुकिंग',
   consultantSearchCustButton: 'खोजें\' ग्राहक खोजें',
   consultantSearchCont: 'खोज संपर्क',
   consultantQuick: 'त्वरित पहुँच',
   consultantActive: 'बुकिंग',
   ActiveData: 'सक्रिय',
   consultantActiveBlurb: 'आप सक्रिय बुकिंग की सूची देखें',
   consultantActiveButton: 'सक्रिय बुकिंग देखें',
   consultantPending: 'बुकिंग',
   PendingData: 'लंबित',
   consultantPendingBlurb: 'लंबित बुकिंग की सूची देखें',
   consultantPendingButton: 'देखें लंबित बुकिंग',
   consultantItineraries: 'सक्रिय कार्यक्रम',
   consultantCustomers: 'ग्राहक',
   consultantAccount: 'खाता सारांश',
   consultantNotices: 'सूचनाएं और नोटिस',
   consultantTaskList: 'कार्य सूची',
   consultantTaskYours: 'आपके कार्य',
   consultantTaskNotFound: 'कार्य नहीं मिला',
   consultantTaskConfirmCompleteMsg: 'क्या आप वाकई पूरा करना चाहते हैं? संपूर्ण कार्य',
   consultantCustName: 'ग्राहक का नाम',
   consultantActiveAgency: 'सक्रिय एजेंसी बुकिंग देखें',
   consultantPendingAgency: 'लंबित एजेंसी बुकिंग देखें',
   consultantTaskCustName: 'ग्राहक नाम',
   consultantTaskBookingId: 'बुकिंग आईडी',
   consultantTaskNote: 'नोट्स',
   consultantTaskDate: 'चयन तिथि',
   consultantTaskAddButton: 'कार्य जोड़ें',
   consultantTaskEditButton: 'कार्य संपादित करें',
   consultantTaskCompleted: 'पूर्ण',
   consultantTaskactions: 'क्रियाएँ',
   consultantTasksnoozed: 'स्नूज़्ड',
   consultantTasktasks: 'कार्य',
   consultantTasksNone: 'आपको लगता है कि आज तक, अच्छी तरह से किया गया है',
   consultantMidOffice: 'मिड ऑफ़िस',
   consultantMidOfficeUpload: 'अपलोड डेटा टू मिड ऑफ़िस',
   sidebarHeading: 'कन्फर्म रूट',
   siderbarInstructions: 'ब्याज के सभी स्थान जोड़ें और उन्हें वांछित क्रम में सेट करें। प्रत्येक स्थान पर रहने की तिथि और लंबाई का चयन करें। यदि पूर्व-चयनित इन्वेंट्री में चयन किया जाता है, तो प्रत्येक स्थान के लिए इन्वेंट्री विकल्पों के माध्यम से परिणामों को परिष्कृत करें।',
   sidebarOptionDeparture: 'प्रस्थान और यात्री',
   sidebarDepDate: 'प्रस्थान तिथि',
   sidebarDepSelect: 'पहले स्थान से प्रस्थान करने की तिथि का चयन करें।',
   sidebarDepError: 'त्रुटि, कृपया चुनें। प्रस्थान की तारीख।',
   travellersNumber: 'यात्रियों की संख्या',
   siderbarnewInstructions: 'सभी रुचि के स्थानों को जोड़ें और उन्हें वांछित क्रम में सेट करें। प्रत्येक स्थान पर रहने की तिथि और लंबाई का चयन करें। यदि पूर्व-चयनित इन्वेंट्री में चयन किया जाता है, तो प्रत्येक स्थान के लिए इन्वेंट्री विकल्पों के माध्यम से परिणामों को परिष्कृत करें।',
   sidebarnewOptionDeparture: 'प्रस्थान और यात्री',
   depDate: 'प्रस्थान तिथि',
   sidebarnewDepSelect: 'पहले स्थान से प्रस्थान करने की तिथि का चयन करें।',
   sidebarnewDepError: 'त्रुटि, कृपया चुनें। प्रस्थान की तारीख।',
   sidebarTravellersNumber: 'यात्रियों की संख्या',
   Rooms: 'कमरे',
   adults: 'वयस्क',
   children: 'बच्चे',
   childAgeLimits: 'युग (0-17)',
   Infants: 'शिशु',
   infantAgesLimit: 'युग (1 और नीचे)',
   childAges: 'कितने साल पुराने हैं \' आपसाथ यात्रा कर रहे हैं(प्रस्थान के समय)',
   ageOfChild: 'बच्चे की आयु\', उम्र के बच्चे',
   infantConfirm: 'जिन बच्चों के\'एक वर्ष से कम(प्रस्थान के समय)',
   infantLengthPlural: 'क्या',
   infantLengthSingular: 'शिशु हैं शिशु है',
   infantLengthEnd: 'एक वर्ष से कम आयु (प्रस्थान के समय)',
   infantLapStart: 'विल',
   infantLapPlural: 'शिशु',
   infantLapSingular: 'शिशु',
   infantLapEnd: 'वयस्क गोद में यात्रा?',
   infantsLap: 'क्या शिशु वयस्कों की गोद में यात्रा करेंगे?',
   sidebarnewInventoryOps: 'सूची और स्थान विकल्प',
   sidebarViewTours: 'समूह भ्रमण देखें',
   sidebarnewRouteOptions: 'कृपया चुनें कि आप इस मार्ग में कौन से विकल्प शामिल करना चाहते हैं।',
   siderbarnewAutoPopLoc: 'ट्रेंडिंग स्थानों के साथ ऑटो-पॉप्युलेट।',
   siderbarnewAutoPopInv: 'ग्राहक प्राथमिकताओं का उपयोग करते हुए ऑटो सूची बनाएँ।',
   arrivalDate: 'आगमन तिथि',
   confirmRouteButton: 'रूट की पुष्टि करें',
   redirectInventory: 'यात्रा कार्यक्रम',
   sidebarInventoryAlert: 'I में ट्रिप इन्वेंटरी जोड़ें और निकालें दाईं ओर की टेनेरी। </ p> कृपया ध्यान दें: एक बहु दिन के दौरे को जोड़ने से पिछले स्थानों की तारीखें लॉक हो जाएंगी।',
   redirectActivities: 'क्रियाएँ चुनें',
   sidebarActivitiesAlert: 'बाईं ओर खंड का चयन करके यात्रा कार्यक्रम के भीतर इन्वेंट्री प्रबंधित करें। स्थानों को जोड़ने, हटाने या फिर से क्रम में लाने के लिए, मानचित्र / मार्ग बटन देखें।',
   redirectHotelList: 'होटल चुनें',
   sidebarHotelListAlert: 'सूची में से एक नया होटल चुनें। </ p> आप शीर्ष पर वर्तमान चयनित होटल सुविधाएँ हैं।',
   redirectHotel: 'अपना कमरा चुनें',
   sidebarHotelAlert: 'यात्रा के लिए इस होटल को जोड़ने के लिए अपना कमरा चुनें',
   redirectToursList: 'एक टूर चुनें',
   sidebarToursListAlert: 'सूची से एक टूर चुनें। </ p> अधिक विस्तृत जानकारी, मूल्य निर्धारण और प्रस्थान देखने के लिए एक दौरे पर क्लिक करें',
   redirectTour: 'एक प्रस्थान चुनें',
   sidebarTourAlert: 'उनके मूल्य के साथ प्रस्थान तिथियों की सूची खोजने के लिए नीचे स्क्रॉल करें। </ p> या तो इस दौरे को जोड़ें। अपने यात्रा कार्यक्रम या सीधे टूर बुक करें',
   redirectTransport: 'ट्रांसपोर्ट चुनें',
   sidebarTransportAlert: '',
   checkoutAlert: 'बुकिंग पूरा करने के लिए यात्री विवरण दर्ज करें',
   checkout: 'चेकआउट',
   redirectPreview: 'यात्रा पूर्वावलोकन',
   redirectPreviewPDF: 'पूर्वावलोकन पीडीएफ',
   sidebarnewInventoryOptions: 'इन्वेंटरी विकल्प',
   errorMsg: 'त्रुटि प्रदर्शन',
   remove: 'निकालें',
   sidebarnewInclusiveTourButton: 'समूह समावेशी यात्रा',
   sidebanewNotes: 'इस स्थान को मार्ग में जोड़ें',
   sidebarnewReturnTripOption: 'वापसी यात्रा विकल्प',
   sidebarnewRoundTripForm: 'कृपया एक उत्पत्ति और वापसी का चयन करें। यदि आप वापसी उड़ानें शामिल करना चाहते हैं।',
   to: 'टू',
   night: 'नाइट्स',
   tourDuration: 'अवधि',
   contact: 'संपर्क',
   checkinDate: 'चेकइन डेट',
   checkoutDate: 'चेकआउट डेट',
   supplierReference: 'आपूर्तिकर्ता',
   propertyReference: 'बुकिंग संदर्भ',
   transport: 'परिवहन',
   service: 'सेवा',
   inventoryTravelClass: 'यात्रा वर्ग',
   departureLocation: 'प्रस्थान स्थान',
   arrivalAirport: 'आगमन स्थान',
   status: 'स्थिति',
   departuretime: 'प्रस्थान समय',
   hotels: 'होटल',
   hotelPricePerNight: 'मूल्य प्रति रात',
   hotelPropertyRating: 'संपत्ति रेटिंग',
   searchbyPropertyName: 'संपत्ति के नाम से खोजें',
   search: 'खोज',
   refundtype: 'वापसी प्रकार',
   days: 'दिन',
   location: 'स्थान',
   pernight: 'प्रति रात',
   pendingItineraries: 'लंबित यात्रा कार्यक्रम',
   hotelownArrangementPropertyName: 'संपत्ति का नाम *',
   hotelownArrangementPropertyNotes: 'संपत्ति नोट *',
   validationMsg: 'यह क्षेत्र आवश्यक है।',
   emailValidationMsg: 'ईमेल पता फ़ील्ड एक मान्य ईमेल होनी चाहिए।',
   contactMinValidationMsg: 'संपर्क नंबर फ़ील्ड में कम से कम 10 वर्ण होने चाहिए।',
   contactMaxValidationMsg: 'संपर्क नंबर फ़ील्ड में 11 वर्ण होने चाहिए।',
   ownArrangementImage: 'छवि',
   ownArrangementHotelimage: 'होटल छवि',
   ownArrangementImgClick: 'होटल छवि पर क्लिक करें या खींचें यहाँ अपलोड करने के लिए',
   ownArrangementFileTypeMsg: '(समर्थित फ़ाइल प्रकार .jpeg, .jpg, .png)',
   hotelownArrangementPropertyaddress: 'संपत्ति पता *',
   hotelownArrangementPropertyContact: 'संपत्ति संपर्क फोन नंबर *',
   hotelownArrangementPropertyemail: 'संपत्ति ईमेल *',
   hotelownArrangementDurationDetails: 'अवधि विवरण',
   hotelownArrangementPropertyCheckindate: 'चेक-इन दिनांक *',
   hotelownArrangementPropertyCheckintime: 'चेक-इन टाइम *',
   hotelownArrangementPropertyCheckouttime: 'संपत्ति पता *',
   hotelownArrangementPropertyCheckoutdate: 'चेक-आउट दिनांक *',
   ownArrangementPricingDetails: 'मूल्य निर्धारण विवरण',
   ownArrangementCurrency: 'मुद्रा *',
   hotelownArrangementCurrency: 'खरीद मुद्रा *',
   hotelownArrangementPropertyPrice: 'विक्रय मूल्य *',
   hotelownArrangementPropertybookingref: 'बुकिंग संदर्भ *',
   ownArrangementPurchasingdetails: 'क्रय विवरण',
   ownArrangementPurchasePrice: 'खरीद मूल्य *',
   hotelownArrangementPropertySupplier: 'आपूर्तिकर्ता *',
   hotelownArrangementPropertyInvoice: 'अंतिम इनवॉइस में इस व्यवस्था व्यवस्था मूल्य निर्धारण को शामिल करें।',
   hotelownArrangementPropertyRefund: 'यह स्वयं की व्यवस्था के लिए उत्पाद की वापसी योग्य है।',
   cancelButton: 'रद्द',
   addtoitinerary: 'यात्रा कार्यक्रम में जोड़ें',
   ownArrangementBasicDetails: 'मूल होटल विवरण',
   activityownArrangementActivityname: 'गतिविधि का नाम *',
   activityownArrangementAddress: 'पता *',
   activityownArrangementPhnno: 'फोन नंबर *',
   activityownArrangementEmail: 'ईमेल *',
   activityownArrangementWeburl: 'वेब यूआरएल',
   activityownArrangementImage: 'छवि',
   ownArrangementActivityImgClick: 'क्लिक या ड्रैग गतिविधि चित्र यहाँ अपलोड करें',
   activityownArrangementActivityImage: 'एक्टिविटी इमेज',
   activityownArrangementSellingPrice: 'सेलिंग प्राइस *',
   activityownArrangementSupplierReference: 'सप्लायर',
   activityownArrangementPurchaseCurrency: 'परचेज करेंसी *',
   activityownArrangementPropertyReference: 'बुकिंग रेफरेंस *',
   ownArrangementComments: 'कमेंट्स',
   activityownArrangementComments: 'कमेंट्स',
   operator: 'ऑपरेटर',
   ownArrangementTravelClass: 'क्लास',
   ownArrngementArrivalTime: 'अराइवल समय',
   ownArrangementAirlineReference: 'एयरलाइन संदर्भ',
   ownArrangementGdsReference: 'जीडीएस संदर्भ',
   downloadPDF: 'पीडीएफ डाउनलोड करें',
   expandAll: 'सभी का विस्तार करें',
   collapseAll: 'सभी को संक्षिप्त करें',
   showMore: 'अधिक दिखाएं',
   showLess: 'कम दिखाएं',
   accommodation: 'आवास',
   name: 'नाम',
   checkIn: 'चेक इन',
   checkOut: 'चेक आउट',
   checkOutDash: 'चेक-आउट',
   price: 'मूल्य',
   PerNight: 'प्रति रात्रि',
   roomDetails: 'कक्ष विवरण',
   roomType: 'कक्ष प्रकार',
   pendingBookingNights: 'रातें',
   nonRefundable: 'गैर-वापसी योग्य',
   refundable: 'वापसी योग्य',
   ownArrangement: 'स्वयं की व्यवस्था आदि',
   toursActivities: 'भ्रमण / गतिविधियाँ',
   viewitenaryActivityname: 'गतिविधि का नाम',
   viewitenaryAddress: 'पता',
   viewitenaryPhone: 'फोन',
   viewitenarySellingPice: 'विक्रय मूल्य',
   email: 'ईमेल',
   date: 'तिथि',
   duration: 'अवधि',
   code: 'कोड',
   startDate: 'आरंभ तिथि',
   finishDate: 'समाप्ति तिथि',
   endDate: 'अंतिम तिथि',
   aud: 'AUD',
   view: 'दृश्य',
   noDataFound: 'नो रिकॉर्ड फाउंड',
   notProvided: 'नॉट प्रोवाइड',
   pendingBookingPage: 'एक्स',
   pendingBookingCustomerName: 'कस्टमर नेम',
   pendingBookingDepdate: 'डिप  दिनांक',
   pendingBookingCreatedOn: 'निर्मित',
   travellers: 'यात्री',
   pendingBookingTotalPrice: 'कुल मूल्य',
   ownArrangementContactDetails: 'संपर्क विवरण',
   sortby: 'क्रमबद्ध करें:',
   titleOfActivity: 'शीर्षक',
   activityCode: 'गतिविधि कोड:',
   viator: 'विटोर',
   from: 'से',
   perPerson: 'प्रति व्यक्ति',
   category: 'श्रेणी',
   sidebarActivityName: 'गतिविधि नाम से खोज',
   readmore: 'और पढ़ें',
   hotelPickupIncluded: 'होटल पिकअप शामिल हैं:',
   activityDetaildeparturePoint: 'प्रस्थान बिंदु:',
   overview: 'अवलोकन',
   importantInfo: 'महत्वपूर्ण जानकारी',
   details: 'विवरण',
   activityDetailInclusions: 'निष्कर्ष',
   activityDetailExclusions: 'बहिष्करण',
   activityDetailAdditionalinfo: 'अतिरिक्त जानकारी',
   activityDetailVoucherinfo: 'वाउचर जानकारी',
   activityDetailLocalOperatorInformation: 'स्थानीय ऑपरेटर की जानकारी',
   activityDetailLocalOperatorInformationlope: 'पूरा ऑपरेटर जानकारी, अपने गंतव्य पर स्थानीय टेलीफोन नंबर सहित, आपके पुष्टिकरण वाउचर पर शामिल हैं। हमारे उत्पाद प्रबंधक प्रत्येक गंतव्य में केवल सबसे अनुभवी और विश्वसनीय ऑपरेटरों का चयन करते हैं, जो आपके लिए अनुमान को हटाते हैं, और आपकी मन की शांति सुनिश्चित करते हैं।',
   activityDetailCancellationPolicy: 'रद्दीकरण नीति',
   activityDetailScheduleAndPricing: 'अनुसूची और मूल्य निर्धारण',
   activityDetailScheduleAndPricingContent: 'हमारी कीमत सुनिश्चित करने के लिए लगातार अपडेट किया जाता है। हमेशा न्यूनतम मूल्य प्राप्त करें - हम इसकी 100% गारंटी देते हैं।',
   activityDetailBookActivity: 'बुक एक्टिविटी',
   activityDetailDeparts: 'डिपार्टमेंट्स',
   activityDetailPickup: 'पिक अप: पिक अप',
   activityDetailCheckedPickup: 'मुझेकरने की आवश्यकता है',
   activityDetailaddActivity: 'एक्टिविटी जोड़ें',
   activityDetailSpecialRequirements: 'स्पेशल रिक्वायरमेंट्स',
   activityDetailSelectLocation: 'स्थान का चयन करें',
   activityDetailEnterPickupLocation: 'पिक पिक लोकेशन *',
   noRecordFound: 'नो रिकॉर्ड पाया',
   sidebarTransportCustomerPricing: 'ग्राहक मूल्य निर्धारण',
   sidebarTransportCustomerPricingContent: 'ग्राहक लागत सहित यात्रा कार्यक्रम मूल्य निर्धारण और PAX द्वारा।',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'कुल लागत AUD इंक। टैक्स',
   sidebarTransportPricePerPaxAudincTax: 'मूल्य प्रति पैक्स AUD इंक। कर',
   sidebarTransportCostPerDay: 'लागत प्रति दिन:',
   sidebarTransportCost: '458.00',
   sidebarTransportTotalCost: 'कुल लागत:',
   sidebarTransportTotal: 'AUD 785',
   sidebarTransportAgencyCost: 'एजेंसी की लागत',
   sidebarTransportOne: 'एक',
   sidebarTransportTwo: 'दो',
   sidebarTransportThree: 'तीन',
   sidebarTransportFilters: 'परिवहन फ़िल्टर',
   sidebarTransportType: 'परिवहन का प्रकार',
   return: 'वापसी',
   transportOnlyRates: 'ग्राहकों के लिए खुदरा दरों को प्रदर्शित करने के लिए एजेंट को बंद करें।',
   transportOnlySaveQuotation: 'उद्धरण सहेजें',
   transportOnlyBookItinerary: 'पुस्तक यात्रा कार्यक्रम',
   transportOnlyCustomerPricing: 'ग्राहक मूल्य निर्धारण',
   transportOnlyAgencyCost: 'एजेंसी लागत',
   transportOnlyFilters: 'फ़िल्टर',
   transportOnlyDepartureLocation: 'प्रस्थान स्थान',
   transportOnlyDestinationLocation: 'डेस्टिनेशन लोकेशन',
   transportOnlyReturnDate: 'रिटर्न डेट',
   transportOnlyParistoRome: 'पेरिस टू रोम',
   transportOnlyFilterContent: 'मंडे, 1 अप्रैल 2019',
   change: 'चेंज',
   transportOnlyvueling: 'वुलिंग',
   nonStop: 'नॉनस्टॉप',
   departs: 'डिपार्टमेंट्स',
   transportOnlyParisOrlyAirport: 'पेरिस ओरली एयरपोर्ट (ORY)',
   transportOnlyArrive: 'आगमन',
   transportOnlyFco: 'रोम - रोमा टिबर्टिना रेलवे स्टेशन (FCO)',
   transportOnlyKiwi: 'कीवी डॉट कॉम',
   transportOnlyMystifly: 'मिस्टीफली',
   transportOnlySabre: 'सेबर',
   transportOnlyEconomy: 'इकोनॉमी',
   transportOnlySelect: 'सिलेक्ट',
   transportOnlyRouteDetails: 'रूट डिटेल्स',
   transportOnlyCalendar: 'सोमवार 1 अप्रैल, 2019',
   transportOnlyFlight: 'उड़ान',
   transportOnlyShowDetails: 'विवरण दिखाएं',
   transportOnlyParis: 'पेरिस - गारे दे ल्यों',
   transportOnlyMilan: 'मिलन - सेंट्रलस्टेशन',
   transportOnlyBaggageDetails: 'सामान का विवरण',
   transportOnlyPersonalBaggage: 'व्यक्तिगत सामान',
   transportOnlyFree: 'मुक्त',
   transportOnlyCheckedBaggage: 'चेक किए गए सामान',
   transportOnlyAddCheckedBaggage: 'चेक किए गए सामान जोड़ें',
   transportOnlyAddCabinBaggage: 'केबिन बैगेज जोड़ें',
   transportOnlyInFlight: 'इन-फ्लाइट ऐड-ऑन',
   transportOnlyPassengerPricingDetails: 'पैसेंजर एंड प्राइसिंग डिटेल्स',
   transportOnlyPassenger: 'पैसेंजर',
   transportOnlyonexAdult: '1 एक्स एडल्ट',
   transportOnlyBaseFare: 'बेस फेयर',
   transportOnlyBaggage: 'बैगेज',
   transportOnlyFeesAndTaxes: 'अन्य फीस / टैक्स',
   transportOnlyTermsConditions: 'शर्तें और शर्तें',
   transportOnlyNetRate: 'शुद्ध दर',
   transportOnlyCommision: 'कमिशन',
   checkinTime: 'चेकइन टाइम',
   checkoutTime: 'चेकआउट टाइम',
   searchCustomerName: 'ग्राहक का नाम खोजें',
   confirmroute: 'मार्ग की पुष्टि करें',
   logout: 'लॉगआउट',
   searchbypropertyname: 'संपत्ति का नाम खोजें',
   hotelDetailshotelRoom: '(1 कमरा)',
   hotelDetailRoomsRates: 'कमरे और दरें',
   hotelDetailAmenities: 'होटल सुविधाएं',
   hotelDetailCheckInInstructions: 'चेक इन निर्देश',
   hotelDetailsFeesOptionalExtras: 'शुल्क / वैकल्पिक अतिरिक्त',
   hotelDetailValueAddedServices: 'मूल्य वर्धित सेवाएँ',
   hotelDetailCheckin: 'चेक इन',
   hotelDetailhotelCheckout: 'चेक आउट:',
   hotelDetailBedPreferencese: 'बेड प्रेफ़रेन्सेज़:',
   hotelDetailViewCancellationPolicy: 'रद्दीकरण नीति देखें',
   hotelDetailRoomDetailAmenities: 'कक्ष विस्तार सुविधाएँ',
   hotelDetailRoomAmenities: 'कक्ष सुविधाएँ',
   hotelDetailGuests: 'मेहमान:',
   hotelDetailSubtotal: 'सबटोटल:',
   hotelTotal: 'टोटल',
   hotelDetailTaxRecoveryServiceFee: 'टैक्स रिकवरी एंड सर्विस शुल्क:',
   hotelDetailSpecialCheckInInstructions: 'विशेष जांच के निर्देश',
   hotelDetailadditionalInformation: 'अतिरिक्त सूचना',
   headerLogin: 'लॉगिन',
   headerLogout: 'लॉगआउट',
   hide: 'छिपाएँ',
   depart: 'विभाग',
   arrive: 'आगमन',
   flightNumber: 'उड़ान संख्या',
   routeDetails: 'मार्ग विवरण',
   wifi: 'वाईफ़ाई',
   included: 'शामिल',
   airCondition: 'एयर कंडीशन',
   passengerPricingDetails: 'यात्री और मूल्य निर्धारण विवरण',
   supplier: 'आपूर्तिकर्ता',
   passenger: 'यात्री',
   passengers: 'यात्री',
   flightMystifly: 'रहस्यमय ढंग से',
   flight2Adult: '2 एक्स एडल्ट',
   baggage: 'बैगेज',
   otherFeesTaxes: 'अदर फीस / टैक्स',
   free: 'फ्री',
   netRate: 'नेट रेट',
   select: 'सिलेक्ट',
   floatZero: '0.00',
   doubleZerofloat: '00',
   tv: 'टीवी',
   flightEconomy: 'इकोनॉमी',
   viewMore: 'और देखें',
   flightOnBoardAddOns: 'ऑन-बोर्ड ऐड-ऑन',
   loginSignIn: 'साइन इन',
   customerNameOrEmail: 'कस्टमर नेम या ईमेल',
   searchBy: 'द्वारा खोजें',
   firstName: 'फर्स्ट नेम',
   lastName: 'लास्ट नेम',
   gender: 'जेंडर',
   phone: 'फोन',
   submitButton: 'सबमिट',
   your: 'योर',
   createdOn: 'निर्मित',
   nights: 'रातें',
   totalPrice: 'कुल मूल्य',
   emailUserName: 'ईमेल या उपयोगकर्ता नाम',
   userName: 'उपयोगकर्ता नाम',
   password: 'ww ord',
   enterPassword: 'पासवर्ड दर्ज करें',
   enterCustomerName: 'ग्राहक का नाम दर्ज करें',
   taskEnterBookingId: 'Enter Booking Id',
   commission: 'Commission',
   costPerDay: 'Cost Per Day',
   pricePerPAX: 'Price Per PAX',
   totalCost: 'Total Cost',
   netCost: 'Net Cost',
   includingTax: 'inc। कर',
   priceSummary: 'मूल्य सारांश',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: 'ग्राहक लागत सहित PAX और द्वारा यात्रा कार्यक्रम मूल्य निर्धारण।',
   sidebarHeadingPreviewItinerary: 'पूर्वावलोकन यात्रा कार्यक्रम',
   sidebarHeadingSaveQuotation: 'उद्धरण सहेजें',
   sidebarHeadingBookItinerary: 'पुस्तक यात्रा कार्यक्रम',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: 'के लिए खुदरा दरों को प्रदर्शित करने के लिए एजेंट दृश्य बंद करें ग्राहक।',
   agentView: 'एजेंट दृश्य',
   customerView: 'ग्राहक दृश्य',
   save: 'सहेजें',
   travelInsurance: 'यात्रा बीमा',
   no: 'नहीं',
   addTravel: 'यात्रा जोड़ें',
   plus: 'प्लस',
   basic: 'मूल',
   inventoryMedicalExpenseTripCancellation: 'चिकित्सा व्यय, यात्रा रद्द',
   inventoryAssistanceServices: 'सहायता सेवाएँ',
   inventoryLostBaggageMore: 'लॉस्ट बैगेज + मोर',
   audWithFloating: 'AUD 00.00',
   inventoryComparsionAndTerms: 'कम्पेरशन एंड टर्म्स',
   customerCare: 'कस्टमर केयर',
   premium: 'प्रीमियम',
   inventoryExploreBenefitsOfPayingPremiumMsg: 'प्रीमियम के भुगतान के लाभों का अन्वेषण करें',
   previous: 'पिछला',
   continue: 'कंटिन्यू',
   saveAndContinue: 'सेव करें और जारी',
   BacktoInventory: 'वस्तु-सूचीपर वापस',
   AcceptandContinue: 'स्वीकार करें और जारी रखें',
   busBud: 'BusBud',
   edit: 'संपादन',
   delete: 'नष्ट',
   checked: 'चेक किए गए',
   checkoutBusListINBusAddonMsg: 'बस में ऐड-ऑन',
   BaseFarePerPax: 'बेस किराया (प्रति पैक्स)',
   onexAdult: '1 एक्स एडल्ट',
   oneRoom: 'रूम 1',
   CancellationPolicy: 'कैंसिलेशन पॉलिसी',
   expedia: 'एक्सपीडिया',
   roomSleep: 'रूम स्लीप',
   checkOutActivityListviator: 'वायटोर',
   checkOutActivityDetails: 'एक्टिविटी डिटेल्स',
   option: 'ऑप्शन',
   groupInclusiveTourOptions: 'ग्रुप इनक्लूसिव टूर ऑप्शंस',
   matchCurrentRouteMsg: 'वर्तमान में 219 ग्रुप हैं। ऐसे समावेशी दौरे जो आपके वर्तमान मार्ग से मेल खाते हैं।',
   errorDisplayHere: 'एरर डिस्प्ले यहाँ',
   tourFilters: 'टूर फ़िल्टर्स',
   tailorMadeTours: 'टेलर-मेड टूर्स',
   tailorMadeOptionsMsg: 'कृपया चुनें कि आप कौन से विकल्प चुनते हैं। केवल दर्जी विकल्पों को प्रदर्शित करने के लिए',
   tailorMadeToursItineryMsg: 'केवल दर्जी के लिए दर्जी का प्रदर्शन',
   MonthOfDeparture: 'प्रस्थान का महीना',
   selectDate: 'चयन तिथि',
   destination: 'गंतव्य',
   tourExperience: 'यात्रा का अनुभव',
   preferredSupplier: 'पसंदीदा आपूर्तिकर्ता',
   style: 'शैली',
   size: 'आकार',
   start: 'प्रारंभ',
   notAvailbale: 'एन / ए',
   inclusions: 'निष्कर्ष',
   day: 'दिवस',
   meals: 'भोजन',
   breakfast: 'नाश्ता',
   lunch: 'दोपहर का भोजन',
   dinner: 'रात का खाना',
   tourDetailsNoMealMsg: 'इस दिन कोई भोजन शामिल नहीं हैं।',
   locationsToVisit: 'स्थान पर जाएँ',
   additionalInformation: 'अतिरिक्त सूचना',
   departureNotFound: 'विभाग नहीं मिले',
   additionalInformationNotFound: 'अधिक जानकारी उपलब्ध नहीं',
   departureAndPricing: 'विभाग और मूल्य निर्धारण',
   departing: 'प्रस्थान',
   finishing: 'परिष्करण',
   availability: 'उपलब्धता',
   totalFrom: 'कुल से',
   perAdult: 'प्रति वयस्क',
   perChild: 'प्रति बच्चा',
   departureGuaranteed: 'प्रस्थान की गारंटी',
   onSale: 'बिक्री पर',
   onRequest: 'अनुरोध पर',
   addToRoute: 'मार्ग में जोड़ें',
   termsConditions: 'नियम और शर्तें',
   available: 'उपलब्ध',
   finish: 'खत्म',
   stars: 'सितारे',
   reviews: 'समीक्षाएं',
   rating: 'रेटिंग',
   default: 'डिफॉल्ट',
   title: 'शीर्षक',
   lengthOfTour: 'टूर की लंबाई',
   optionsSelected: 'विकल्प चयनित',
   tourNotFound: 'टूर नहीं मिला',
   validTo: 'वैलिड टू',
   lodging: 'लॉजिंग',
   validFrom: 'वैलिड फ्रॉम',
   perNight: 'पेर नाइट',
   sortBy: 'सॉर्ट बाय ’',
   viewItineraryGITtours: 'tours जीआईटी टूर’',
   inventoryAddNewAccommodation: 'एक और होटल चुनें ’',
   inventoryAddNewTransport: 'Transport न्यू ट्रांसपोर्ट जोड़ें’',
   passengersAvoidBoardingComplicationMsg: 'names सभी नामों (दिए गए और उपनाम) का उपयोग ठीक उसी तरह करें जैसे वे पासपोर्ट / आईडी में किसी भी बोर्डिंग जटिलताओं से बचने के लिए करते हैं। ’',
   passengersRequiredFlightSegmentMsg: 'ired आवश्यक। इंटरनेशनल फ्लाइट सेगमेंट के लिए।',
   dateOfBirth: 'डेट ऑफ बर्थ',
   passengersTourSpecificField: 'टूर स्पेसिफिक फील्ड।',
   passengersTourSegmentMsg: 'टूर फॉर एक्स `सेगमेंट',
   visaRequriements: 'वीज़ा रिक्वायरमेंट्स',
   passengersreferencePurposeMsg: 'वीज़ा या एयरपोर्ट ट्रांजिट वीजा यात्रा के लिए आवश्यक हो सकता है, जब न हो देश को बचाने की होड़ में। ये आवश्यकताएं केवल संदर्भ उद्देश्यों के लिए हैं। अधिक जानकारी के लिए दूतावास या विदेश मंत्रालय से संपर्क करें।',
   passengersDeniedDestinationIssueMsg: 'वीजा के मुद्दों के कारण किसी भी गंतव्य पर सवार होने या प्रवेश करने से इनकार किए गए यात्रियों के लिए eRoam उत्तरदायी नहीं है।',
   givenNames: 'दिए गए नाम',
   passengerNames: 'दिए गए नाम *',
   surname: 'उपनाम',
   passengersurname: '। उपनाम *',
   familyname: 'फ़ैमिलीनेम',
   PassengerGender: 'जेंडर *',
   paymenttotalprice: 'टोटल प्राइस',
   paymentnewprice: 'न्यू प्राइस',
   emailAddress: 'ईमेल एड्रेस',
   passengeremailAddress: 'ईमेल एड्रेस *',
   contactNumber: 'कॉन्टेक्ट नंबर',
   passengercontactNumber: 'कॉन्टेक्ट नंबर *',
   passengersIndividualInventorySpecificationMsg: 'नीचे फील्ड्स आवश्यक हैं। व्यक्तिगत इन्वेंटरी विनिर्देशों के कारण, जैसे आंतरिक उड़ानें, दौरे की आवश्यकताएं आदि',
   Nationality: 'राष्ट्रीयता',
   PassengerNationality: 'राष्ट्रीयता *',
   confirmBooking: 'बुकिंग की पुष्टि करें',
   receiptNumber: 'रसीद संख्या',
   paymentAddReceiptNumberMsg: 'संसाधित भुगतान की रसीद संख्या जोड़ें।',
   PaymentDeniedDestinationIssueMsg: 'यात्रा के लिए वीजा या एयरपोर्ट ट्रांजिट वीजा आवश्यक हो सकता है। यहां पाए जाने वाले पुनर्खरीद केवल पुनर्भरण उद्देश्यों के लिए हैं। अधिक जानकारी के लिए दूतावास या अपने विदेश मंत्रालय से संपर्क करें। eRoam किसी भी यात्री के लिए उत्तरदायी नहीं है, जिसे वीजा मुद्दों के कारण बोर्डिंग से या किसी भी गंतव्य में प्रवेश करने से वंचित किया जाता है।',
   amount: 'राशि *',
   paymentEnterAmountMsg: 'एक राशि दर्ज करें जो की जा रही है।',
   paymentEnterTypeMsg: 'प्रवेश का प्रकार दर्ज करें',
   paymentType: 'भुगतान। टाइप * ’, *',
   paymentOnceSubmittedMsg: 'एक बार भुगतान विवरण प्रस्तुत करने के बाद, बुकिंग की पुष्टि हो जाएगी।’',
   payment: 'भुगतान ’',
   printPDF: 'PDF प्रिंट पीडीएफ’',
   custom: 'कस्टम ’',
   additionalNotes: 'AdditionalNotes अतिरिक्त नोट्स’',
   list: 'सूची ’',
   checkinDash: '-चेक-इन’',
   detailedSummary: '’ विस्तृत सारांश',
   dailySummary: 'डेली समरी',
   tripSummary: 'ट्रिप सारांश',
   transportName: 'ट्रांसपोर्ट नेम',
   activities: 'एक्टिविटीज',
   basicTransportDetails: 'बेसिक ट्रांसपोर्ट डिटेल्स',
   transportOwnArrangementRefundMsg: 'यह ओन अरेंजमेंट ट्रांसपोर्ट प्रोडक्ट रिफंडेबल है। ',
   transportOwnArrangementPricingMsg: 'अंतिम खुशी में इसे स्वयं अरेंजमेंट ट्रांसपोर्ट प्राइसिंग शामिल करें।',
   salePrice: 'बिक्री मूल्य',
   transportOwnArrangementAddLegtoArrangement: 'ऐड लेग टू अरेंजमेंट',
   arrivalLocation: 'अराइवल लोकेशन',
   transportOwnArrangementAddNewLeg: 'एक नया लेग जोड़ें',
   arrival: 'आगमन',
   departure: 'प्रस्थान',
   fromTo: 'फ्रॉम - टू',
   selectThePlaceToReturn: 'सेलेक्ट टू द प्लेस टू रिटर्न',
   selectAReturn: 'एक वापसी का चयन करें',
   returnToAnotherCity: 'दूसरे शहर में लौटें',
   selectPlaceOrigin: 'मूल स्थान का चयन करें।',
   selectOrigin: 'एक मूल का चयन करें।',
   accomodationSummary: 'आवास सारांश।',
   transportSummary: 'परिवहन सारांश',
   activitySummary: 'गतिविधि सारांश',
   taskListConformDeleteMsg: 'कृपया पुष्टि करें कि आप चाहते हैं। कार्य को हटाने के लिए?',
   ConfirmActionTitle: 'कार्रवाई की पुष्टि करें',
   passengertitle: 'शीर्षक *',
   hotelListTotalCost: 'कुल लागत',
   nonstop: 'गैर-रोकें',
   selected: 'चयनित',
   inventoryAddNewHotels: 'नए होटल जोड़ें',
   inventoryAddOwnArrangement: 'खुद की व्यवस्था जोड़ें',
   propertylocation: 'संपत्ति का स्थान',
   inventoryAddNewToursTickets: 'दूसरे दौरे और टिकट चुनें',
   inventoryContactPhone: 'संपर्क फोन',
   inventoryContactEmail: 'संपर्क ईमेल',
   flight: 'उड़ान',
   time: 'टाइम',
   bookingId: 'बुकिंग आईडी',
   bookingNote: 'बुकिंग नोट',
   stops: 'स्टॉप्स',
   layOverDuration: 'लेओओवर ड्यूरेशन',
   itineraryPdfImageText: 'अपलोड करने के लिए यहां क्लिक करें या ड्रैग इटीनरी इमेज',
   confirm: 'कन्फर्म',
   changePassword: 'पासवर्ड बदलें',
   oldPasswordErrorMsg: 'पुराना पासवर्ड डालें',
   newPasswordErrorMsg: 'नया पासवर्ड दर्ज करें',
   confirmPasswordErrorMsg: 'पासवर्ड पुष्टिकरण doesn\'t मिलान',
   oldPassword: 'पुराना पासवर्ड',
   confirmPassword: 'पासवर्ड की पुष्टि करें',
   newPassword: 'नया पासवर्ड',
   visitedLocations: 'स्थानों पर जाने के लिए',
   checkoutFlightListINFlightAddonMsg: 'इन फ्लाइट एड-ऑन',
   checkoutmystifly: 'मिस्टिफली',
   tourDetailstitle: 'टूर डिटेल्स',
   update_total_cost: 'अपडेट टोटल कॉस्ट',
   updateCostConfirmCompleteMsg: 'आर यू श्योर, वॉन्ट टू कंटिन्यू विद निगेटिव कमिशन?',
   confirmButton: 'कन्फर्म',
   norating: 'नो रेटिंग',
   adult: 'एडल्ट',
   child: 'चाइल्ड',
   PrimaryPassenger: 'प्राइमरी पैसेंजर (लीड पैक्स)',
   hotelbed: 'होटलबेड',
   activityownemail: 'आपूर्तिकर्ता ईमेल',
   activityownweb: 'वेबसाइट',
   activityownphone: 'फोन',
   activityownsupplier: 'आपूर्तिकर्ता',
   hotelDetails: 'Details होटल विवरण ’',
   room: 'कमरा’, Room',
   ownArrangementActivityBasicDetails: 'Basicबुनियादी गतिविधि विवरण ’',
   booking: 'ing बुकिंग’',
   tour: 'यात्रा ’',
   oopsTheActivity: 'द एक्टिविटी’',
   oopsTheTour: 'द टूर ’',
   oopsTheFlight: 'द फ्लाइट’',
   notAvailable: 'अभी उपलब्ध नहीं है। ’',
   inventoryAddHotelOwnArrangement: 'range ओन अरेंजमेंट (एकोमोडेशन)’',
   inventoryAddToursOwnArrangement: ') ओन अरेंजमेंट (टूर एंड टिकट) ’',
   inventoryAddReplaceTransport: 'Another चूज अदर ट्रांसपोर्ट’',
   inventoryAddTransportOwnArrangement: 'Ar ओन अरेंजमेंट ’ (परिवहन)',
   noProductSelected: 'नो प्रोडक्ट सिलेक्टेड',
   taskSelectDate: 'सलेक्ट डेट',
   taskSelecgender: 'सलेक्ट जेंडर',
   age: 'ऐज',
   relation: 'रिलेशन',
   activityTime: 'टाइम *',
   activityDate: 'डेट *',
   accountId: 'अकाउंट आईडी',
   nationality: 'नेशनलिटी',
   city: 'सिटी',
   address: 'पता',
   postcode: 'पोस्ट कोड',
   region: 'क्षेत्र',
   ontarioResident: 'ओंटारियो निवासी',
   prepaidGratuities: 'प्रीपेड ग्रेच्युटी',
   mobilePhone: 'संपर्क',
   titleBooking: 'शीर्षक',
   genderBooking: 'लिंग',
   emailBooking: 'ईमेल पता',
   paymentAmount: 'भुगतान राशि',
   checkoutRceiptNumber: 'रसीद संख्या',
   loadPnr: 'पीएनआर लोड',
   activityownArrangementPropertyInvoice: 'करें इस मूल्य को अंतिम चालान में शामिल करें।',
   activityownArrangementPropertyRefund: 'यह रिफंडेबल है।',
   bookingVoucherfileType: '(समर्थित फ़ाइल प्रकार .jpeg, .jpg, .png, .pdf)',
   bookingVouchermsg: 'वाउचर अपलोड करने के लिए यहां क्लिक करें',
   carrierName: 'कैरियर का नाम',
   departureDateandTime: 'प्रस्थान तिथि और समय',
   arrivalDateandTime: 'आगमन तिथि और समय',
   airlineReference: 'एयरलाइन संदर्भ',
   pnrNumber: 'पीएनआर दर्ज करें',
   pnrInstructions: 'कृपया यात्रा करने के लिए वैध पीएनआर नंबर दर्ज करें',
   termsandconditions: 'नियम और शर्तें',
   total: 'कुल',
   baggageAdult: 'एडल्ट / चाइल्ड बैगेज',
   baggageChild: 'चाइल्ड बैगेज',
   gitWarningMessage: 'आपने इस शहर के लिए अमान्य टूर',
   summeryConfirmCompleteMsg: 'आर यू श्योर, वांटेड टू कंटिन्यू विद अमान्य टूर चयन?',
   tourType: 'टूर टाइप ? ',
   SearchPlace: 'सर्च प्लेस । खोज स्थान',
   SearchCity: 'खोज शहर । खोज शहर',
   manager: 'प्रबंधक',
   product: 'उत्पाद',
   finance: 'वित्त',
   admin: 'व्यवस्थापक',
   viewProductManager: 'उत्पाद प्रबंधक देखें',
   viewfinanceManager: 'वित्त प्रबंधक देखें',
   viewAdminManager: 'व्यवस्थापक प्रबंधक देखें',
   viewsupplierManager: 'देखें सप्लायर मैनेजर',
   viewmarketingManager: 'मार्केटिंग मैनेजर देखें',
   DaterangeFrom: 'दिनांक सीमा से',
   DaterangeTo: 'तिथि सीमा के लिए',
   DataType: 'डेटा प्रकार',
   FileType: 'फाइल टाइप',
   downloadNow: 'अब डाउनलोड करें',
   ConsultantName: 'सलाहकार का नाम',
   SupplierName: 'आपूर्तिकर्ता का नाम',
   ClientFile: 'क्लाइंट फाइल',
   pay: 'भुगतान',
   TypeofPayment: 'भुगतान का प्रकार',
   Login: 'लॉग इन करो',
   exportData: 'निर्यात डेटा',
   roomNumber: 'कमरा',
   storeName: 'स्टोर का नाम',
   manageUsers: 'उपयोगकर्ताओं को प्रबंधित करें',
   manageBrands: 'ब्रांडों का प्रबंधन करें',
   viewReports: 'देखें रिपोर्ट',
   brand: 'ब्रांड',
   manageLicensee: 'लाइसेंसी प्रबंधन',
   ManageLicensee: 'लाइसेंसी खोजें',
   OnboardNewLicensee: 'जहाज पर नई लाइसेंसी',
   CreateNewPackage: 'एक नया पैकेज बनाएं',
   consultantArchived: 'बुकिंग',
   ArchivedData: 'संग्रहीत',
   consultantArchivedBlurb: 'आपकी संग्रहीत बुकिंग की सूची देखें',
   consultantArchivedButton: 'आर्काइव बुकिंग देखें',
   consultantArchivedAgency: 'आर्काइव एजेंसी बुकिंग देखें',
   archivedItineraries: 'संग्रहीत यात्रा कार्यक्रम',
   taskDay: 'दिन का चयन करें',
   action: 'कार्रवाई',
   mobile: 'मोबाइल',
   taskSelectDay: 'दिन का चयन करें',
   DateandTime: 'तिथि और समय',
   addtransport: 'परिवहन जोड़ें',
   addhotels: 'होटल जोड़ें',
   addactivities: 'गतिविधियां जोड़ें',
   manageclientpreferences: 'क्लाइंट प्राथमिकताओं का प्रबंधन करें',
   Return: 'वापसी',
   oneway: 'एक रास्ता',
   multycity: 'मुल्ल्टी शहर',
   flyingfrom: 'से उड़ान',
   selectclass: 'कक्षा का चयन करें',
   returndate: 'वापसी की तारीख',
   comingsoon: 'जल्दी ही आगमन',
   thingstodo: 'करने के लिए चीजें',
   extendedtouring: 'विस्तारित टूरिंग',
   selectinterest: 'ब्याज का चयन करें',
   searchpnr: 'खोज पीएनआर',
   googlesearch: 'गूगल खोज',
   package: 'पैकेज',
   addcustomer: 'ग्राहक जोड़ें',
   geoLocationManager: 'जियो लोकेशन मैनेजर',
   supplieroperatorManager: 'आपूर्तिकर्ता/ऑपरेटर प्रबंधक',
   yieldManager: 'यील्ड मैनेजर',
   packageBuilder: 'पैकेज बिल्डर',
   plotmaps: 'प्लॉट के नक्शे',
   reporting: 'रिपोर्टिंग',
   CSVDownload: 'सीएसवी डाउनलोड',
   makePayments: 'भुगतान करें',
   packageName: 'पैकेज का नाम',
   uploadImages: 'छवियां अपलोड करें',
   toursandtickets: 'पर्यटन और टिकट',
   hotel: 'होटल',
   packageOverview: 'पैकेज अवलोकन',
   packageItinerary: 'पैकेज यात्रा कार्यक्रम',
   Optional: 'वैकल्पिक',
   createpackagefromtour: 'टूर से पैकेज बनाएं',
   Currency: 'मुद्रा',
   companyName: 'कंपनी का नाम',
   Phone: 'फोन (देश कोड सहित)',
   url: 'Url',
   uploadLogo: 'लोगो अपलोड करें',
   selectProduct: 'उत्पाद का चयन करें',
   hotelSupplier: 'होटल सप्लायर',
   tourSupplier: 'टूर सप्लायर',
   transportSupplier: 'परिवहन आपूर्तिकर्ता',
   otherSupplier: 'अन्य आपूर्तिकर्ता',
   selectSettings: 'सेटिंग्स का चयन करें',
   defaultLanguage: 'डिफ़ॉल्ट भाषा',
   defaultCurrency: 'डिफ़ॉल्ट मुद्रा',
   chooseTemplate: 'टेम्पलेट चुनें',
   paymentGateway: 'पेमेंट गेटवे',
   selectSubscription: 'सदस्यता का चयन करें',
   licenceType: 'लाइसेंस प्रकार',
   howmanylicences: 'कितने लाइसेंस?',
   addons: 'ऐड - ऑन',
   paymentTerms: 'भुगतान की शर्तें',
   licenseenameoremail: 'लाइसेंसी नाम या ईमेल',
   createProfilesandClientDetails: 'प्रोफाइल बनाएं और क्लाइंट विवरण प्रबंधित करें',
   passportNumber: 'पासपोर्ट नंबर',
   passportissuedate: 'पासपोर्ट जारी करने की तिथि',
   passportExpiryDate: 'पासपोर्ट एक्सपायरी डेट',
   passportissuingcountry: 'पासपोर्ट जारी करने वाला देश',
   clientContact: 'ग्राहक संपर्क',
   Phonehome: 'फोन (घर)',
   Phonework: 'फोन (काम)',
   phonemobile: 'फोन (मोबाइल)',
   AddressLinOne: 'पता (लाइन वन)',
   AddressLineTwo: 'पता (लाइन दो)',
   StateProvinceRegion: 'राज्य/प्रांत/क्षेत्र',
   zipPostalCode: 'ज़िप /पोस्टल कोड',
   country: 'देश',
   newsletter: 'न्यूज़ लैटर',
   companyABN: 'कंपनी एबीएन',
   paName: 'पीए नाम',
   clientLoyaltyPrograms: 'क्लाइंट लॉयल्टी प्रोग्राम',
   srno: 'श्री नहीं',
   loyaltyType: 'लॉयल्टी टाइप',
   providerName: 'प्रदाता का नाम',
   membershipNumber: 'सदस्यता संख्या',
   clientPreferences: 'ग्राहक प्राथमिकताएं',
   airline: 'एयरलाइन',
   airlines: 'एयरलाइन (एस)',
   travelClass: 'यात्रा वर्ग',
   preferredSeating: 'पसंदीदा बैठने',
   mealRequest: 'भोजन का अनुरोध',
   Hotels: 'होटल',
   hotelChain: 'होटल चेन',
   Cars: 'कार',
   carRental: 'कार रेंटल',
   CarType: 'कार प्रकार',
   interests: 'ब्याज',
   linkedprofiles: 'लिंक किए गए प्रोफाइल',
   Preferreddestinations: 'पसंदीदा गंतव्य',
   businessPreferences: 'व्यावसायिक प्राथमिकताएं',
   otherPreferences: 'अन्य प्राथमिकताएं',
   pendingBookings: 'लंबित बुकिंग',
   archiveBookings: 'आर्काइव बुकिंग',
   activeBookings: 'सक्रिय बुकिंग',
   viewdetails: 'देखें विवरण',
   update: 'अद्यतन',
   bookingmanagement: 'बुकिंग प्रबंधन',
   bookingDashboard: 'बुकिंग डैशबोर्ड',
   bookingoverview: 'बुकिंग अवलोकन',
   Generalbookingdetails: 'सामान्य बुकिंग विवरण।',
   type: 'प्रकार',
   created: 'बनाया',
   segmentoverview: 'सेगमेंट अवलोकन',
   totalnumberofsegment: 'उत्पादों/खंडों की कुल संख्या, और बकाया पुष्ट खंड।',
   segmentsconfirmedvstotalsegment: 'सेगमेंट की पुष्टि बनाम कुल सेगमेंट बुकिंग में शामिल हैं।',
   amountremainingforclient: 'शेष नियत तिथि से पहले भुगतान करने के लिए ग्राहक के लिए शेष राशि।',
   outstandingAmount: 'बकाया राशि',
   outstanding: 'बकाया',
   balanceDue: 'शेष देय',
   bookingDetails: 'बुकिंग विवरण',
   user: 'उपयोगकर्ता',
   consultantNotes: 'सलाहकार नोट्स',
   historynotfound: 'इतिहास नहीं मिला',
   leadpaxdetails: 'लीड पैक्स विवरण',
   additionalpaxdetails: 'अतिरिक्त पैक्स विवरण',
   locationdetails: 'स्थान विवरण',
   selectedHotels: 'चयनित होटल',
   confirmed: 'पुष्टि होना',
   snooze: 'स्नूज़',
   upload: 'अपलोड',
   novoucherfound: 'कोई वाउचर नहीं मिला',
   Nopaymentsfound: 'कोई भुगतान नहीं मिला',
   newvoucher: 'इस बुकिंग के लिए एक नया वाउचर अपलोड करें',
   allocation: 'आवंटन',
   selectedtoursandtickets: 'चयनित पर्यटन और टिकट',
   offeredIn: 'में की पेशकश की',
   tofrom: 'से /',
   bookingRef: 'बुकिंग रेफरी',
   selectedTransport: 'चयनित परिवहन',
   segmentmessage: 'इस सेगमेंट की पुष्टि करके, आप स्वीकार कर रहे हैं कि इस उत्पाद को आपूर्तिकर्ता के साथ बुक किया गया है, इस एप्लिकेशन के बाहर। पुष्टि को पूरा करने के लिए, नीचे दिए गए फ़ील्ड को पूरा करें।',
   bookingReference: 'बुकिंग संदर्भ',
   suppliersbookingreference: 'आपूर्तिकर्ता की बुकिंग संदर्भ दर्ज करें',
   confirmsegment: 'सेगमेंट की पुष्टि करें',
   pickupTime: 'पिक-अप समय',
   totalDuration: 'कुल अवधि',
   layoverTime: 'समय के साथ रखना',
   ticketType: 'टिकट प्रकार',
   class: 'कक्षा',
   provider: 'प्रदाता',
   viewcustomer: 'ग्राहक देखें',
   costofitinerary: 'यात्रा कार्यक्रम की कुल लागत',
   paid: 'भुगतान किया',
   receiptofallpayments: 'आज तक सभी भुगतानों की रसीदें',
   balanceduetocustomer: 'ग्राहक से एकत्र होने के कारण शेष राशि',
   recordcustomerpayment: 'नीचे दिए गए ग्राहक भुगतान को रिकॉर्ड करें।',
   completePayment: 'पूरा भुगतान',
   cancel: 'रद्द करना',
   accommodationORtransportation: '* कम से कम एक आवास या परिवहन का चयन करें।',
   preferredPropertyClass: 'पसंदीदा संपत्ति वर्ग',
   transportation: 'परिवहन',
   preferredtransporttype: 'पसंदीदा परिवहन प्रकार',
   preferredtransportclass: 'पसंदीदा परिवहन वर्ग',
   agegroup: 'आयु वर्ग',
   interest: 'ब्याज',
   activityinterest: 'गतिविधि ब्याज',
   applytoalllocations: 'सभी स्थानों पर आवेदन करें',
   applythislocation: 'इस स्थान पर लागू करें',
   apply: 'लागू करना',
   hotelDescription: 'होटल विवरण',
   activityDescription: 'गतिविधि विवरण',
   checkoutcancelationdescription: 'यह एक लंबे समय से स्थापित तथ्य यह है कि एक पाठक अपने लेआउट को देखते समय एक पृष्ठ की पठनीय सामग्री से विचलित हो जाएगा। Lorem Ipsum का उपयोग करने की बात यह है कि इसमें अक्षरों का अधिक या कम सामान्य वितरण है, जैसा कि \'यहां सामग्री, सामग्री यहां\' का उपयोग करने का विरोध किया गया है, जिससे यह पठनीय अंग्रेजी की तरह दिखता है। कई डेस्कटॉप प्रकाशन पैकेज और वेब पेज संपादक अब अपने डिफ़ॉल्ट मॉडल टेक्स्ट के रूप में लोरेम इप्सम का उपयोग करते हैं, और \'लोरेम इप्सम\' की खोज उनकी प्रारंभिक अवस्था में अभी भी कई वेब साइटों को उजागर करेगी। विभिन्न संस्करणों के वर्षों में विकसित किया है, कभी दुर्घटना से, कई बार उद्देश्य पर (हास्य इंजेक्शन और पसंद) ।',
   OverrideQuotation: 'सेव/ओवरराइड कोटेशन',
   DuplicateQuotation: 'डुप्लीकेट कोटेशन',
   OriginCity: 'मूल शहर',
   ReturnCity: 'वापसी शहर',
   AddTransport: 'परिवहन जोड़ें',
   AddHotels: 'होटल जोड़ें',
   AddActivities: 'गतिविधियां जोड़ें',
   ManagePreferences: 'क्लाइंट प्राथमिकताओं का प्रबंधन करें',
   searchPNR: 'खोज पीएनआर',
   oneWay: 'एक रास्ता',
   multiCity: 'मल्टी सिटी',
};
