
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { i18n } from '@/plugins/i18n';
    import FlagIcon from 'vue-flag-icon';
    Vue.use(FlagIcon);

    @Component
    export default class Languages extends Vue {
        isActive: boolean = false;
        data: any = {
            languages: [
                { flag: 'gb', language: 'en', title: 'English' },
                { flag: 'es', language: 'es', title: 'Español' },
                { flag: 'it', language: 'it', title: 'Italiano' },
                { flag: 'de', language: 'de', title: 'Deutsche' },
                { flag: 'fr', language: 'fr', title: 'French' },
                { flag: 'gu', language: 'gu', title: 'Gujarati' },
            { flag: 'gr', language: 'gr', title: 'Greek' },
                // { flag: 'jp', language: 'jp', title: 'Japanese' }
            ],
            flag: 'en',
            instyle: {
                display: 'inline'
            }
        };
        props: any = {
            iconStyle: {
                type: String
            }
        };
        theStyle: any = {
            display: this.isActive ? 'inline' : 'none',
        };

        mounted() {
            let vm = this;
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
            document.head.appendChild(recaptchaScript)
            vm.data.flag = this.$store.state.language.flag;
        }
        selectLanguage() {
            this.isActive = !this.isActive;
        }
        changeLanguage(locale: string, flag: string, title:string) {
            let vm = this;
            i18n.locale = locale;
            vm.data.flag = locale;
            vm.isActive = false;
            // vm.$store.dispatch("changeLanguage",{flag:locale,title:title});
        }
    };

