// Create by Nathan Gunn 19/12/2019

export const constants: any = {
    version: '2.1.4.3'
  };

  export const clientName = 'eRoam';
  export const login_logo = 'logo.png';
  export const header_logo = 'logo.svg';
  
  export const domainName: any = '';
  // export const domainName: any = 'etg.eroam.com';
  export const applicationName: any ="develop";
  export const images: any = {
    noThumbnail: 'https://imagescdn.eroam.com/no-image-thumbnail.jpg',
    noImage: "https://imagescdn.eroam.com/no-image.jpg",
    noImageBus: 'https://imagescdn.eroam.com/bus-no-image.jpg',
  }
  
  export const personTitles: any = [
    {value: "", text: "Select Title"},
    {value: "Mr", text: "Mr"},
    {value: "Mrs", text: "Mrs"},
    {value: "Ms", text: "Ms"},
    {value: "Mx", text: "Mx"},
    {value: "Miss", text: "Miss"},
    {value: "Mstr", text: "Mstr"},
    {value: "Inf", text: "Inf"},
    {value: "Dr", text: "Dr"},
    {value: "Sir", text: "Sir"},
    {value: "Prof", text: "Prof"},
    {value: "Other",text: "Other"}
  ]
  
  export const currencies: any = [
    { flag: 'au', value: 'AUD', text: 'AUD' },
    { flag: 'nz', value: 'NZD', text: 'NZD' },
    { flag: 'us', value: 'USD', text: 'USD' },
    { flag: 'gb', value: 'GBP', text: 'GBP' },
    { flag: 'ae', value: 'AED', text: 'AED' },
    { flag: 'eu', value: 'EUR', text: 'EUR' },
    { flag: 'jp', value: 'JPY', text: 'JPY' },
    { flag: 'in', value: 'INR', text: 'INR' },
    { flag: 'mx', value: 'MXN', text: 'MXN' },
    { flag: 'za', value: 'ZAR', text: 'ZAR' },
    { flag: 'th', value: 'THB', text: 'THB' },
    { flag: 'id', value: 'IDR', text: 'IDR' },
    { flag: 'dk', value: 'DKK', text: 'DKK' }
  ]
  
  export const genderlist:any=[
    {value: "",text:"Select Gender"},
    {value: "Male",text: "Male"},
    {value: "Female",text: "Female"},
    {value: "Other",text: "Other"},
  ]
  
  export const paymentOptions: any =[
    {value: "", text: 'Please select type',},
    {value: "credit_card_offline", text: 'Credit Card (Offline)', method: 'creditcard'},
    {value: "debit_card_offline", text: 'Debit Card (Offline)', method: 'deditcard'},
    {value: "cash", text: 'Cash (Offline)', method: 'cash'},
    {value: "bank_deposit", text: 'Bank Deposit (Offline)', method: 'bankdeposit'},
    {value: "invoice", text: 'Invoice (Offline)', method: 'invoice' },
  ]
  
  export const months: any = [
    {
      value: "",
          text: "Select Month"
    },
      {
          value: "01",
          text: "January"
      },
      {
          value: "02",
          text: "February"
      },
      {
          value: "03",
          text: "March"
      },
      {
          value: "04",
          text: "April"
      },
      {
          value: "05",
          text: "May"
      },
      {
          value: "06",
          text: "June"
      },
      {
          value: "07",
          text: "July"
      },
      {
          value: "08",
          text: "August"
      },
      {
          value: "09",
          text: "September"
      },
      {
          value: "10",
          text: "October"
      },
      {
          value: "11",
          text: "November"
      },
      {
          value: "12",
          text: "December"
      }
  ]
  
  export const years: any = [
    {
      value: "",
          text: "Select Year"
    },
      {
          value: "20",
          text: "2020"
      },
      {
          value: "21",
          text: "2021"
      },
      {
          value: "22",
          text: "2022"
      },
      {
          value: "23",
          text: "2023"
      },
      {
          value: "24",
          text: "2024"
      },
      {
          value: "25",
          text: "2025"
      }
  ]
  
  export const countries: any = [
    { text: 'Select Country', value: ''},
    { text: 'Afghanistan', value: 'AF', dial_code:"+93" },
    { text: 'Åland Islands', value: 'AX',dial_code:"+358" },
    { text: 'Albania', value: 'AL',dial_code: "+355"},
    { text: 'Algeria', value: 'DZ' ,dial_code: "+213"},
    { text: 'American Samoa', value: 'AS',dial_code: "+1684"},
    { text: 'AndorrA', value: 'AD',dial_code: "+376"},
    { text: 'Angola', value: 'AO',dial_code: "+244"},
    { text: 'Anguilla', value: 'AI',dial_code: "+1264"},
    { text: 'Antarctica', value: 'AQ',dial_code: "+672"},
    { text: 'Antigua and Barbuda', value: 'AG',dial_code: "+1268"},
    { text: 'Argentina', value: 'AR',dial_code: "+54"},
    { text: 'Armenia', value: 'AM',dial_code: "+374"},
    { text: 'Aruba', value: 'AW', dial_code: "+297"},
    { text: 'Australia', value: 'AU', dial_code: "+61"},
    { text: 'Austria', value: 'AT', dial_code: "+43"},
    { text: 'Azerbaijan', value: 'AZ', dial_code: "+994"},
    { text: 'Bahamas', value: 'BS', dial_code: "+1242"},
    { text: 'Bahrain', value: 'BH', dial_code: "+973"},
    { text: 'Bangladesh', value: 'BD',dial_code: "+880"},
    { text: 'Barbados', value: 'BB' ,dial_code: "+1246"},
    { text: 'Belarus', value: 'BY',dial_code: "+375"},
    { text: 'Belgium', value: 'BE',dial_code: "+32"},
    { text: 'Belize', value: 'BZ', dial_code: "+501"},
    { text: 'Benin', value: 'BJ' ,dial_code: "+229"},
    { text: 'Bermuda', value: 'BM',dial_code: "+1441"},
    { text: 'Bhutan', value: 'BT', dial_code: "+975"},
    { text: 'Bolivia', value: 'BO', dial_code: "+591"},
    { text: 'Bosnia and Herzegovina', value: 'BA',dial_code: "+387"},
    { text: 'Botswana', value: 'BW', dial_code: "+267"},
    { text: 'Bouvet Island', value: 'BV',dial_code: "+47"},
    { text: 'Brazil', value: 'BR', dial_code: "+55"},
    { text: 'British Indian Ocean Territory', value: 'IO' ,dial_code: "+246"},
    { text: 'Brunei Darussalam', value: 'BN',dial_code: "+673"},
    { text: 'Bulgaria', value: 'BG' , dial_code: "+359"},
    { text: 'Burkina Faso', value: 'BF' ,dial_code: "+226"},
    { text: 'Burundi', value: 'BI' , dial_code: "+257"},
    { text: 'Cambodia', value: 'KH' ,dial_code: "+855"},
    { text: 'Cameroon', value: 'CM' , dial_code: "+237"},
    { text: 'Canada', value: 'CA' ,dial_code: "+1"},
    { text: 'Cape Verde', value: 'CV' ,dial_code: "+238"},
    { text: 'Cayman Islands', value: 'KY' ,dial_code: "+ 345"},
    { text: 'Central African Republic', value: 'CF', dial_code: "+236"},
    { text: 'Chad', value: 'TD' ,dial_code: "+235"},
    { text: 'Chile', value: 'CL' ,dial_code: "+56"},
    { text: 'China', value: 'CN' ,dial_code: "+86"},
    { text: 'Christmas Island', value: 'CX', dial_code: "+61"},
    { text: 'Cocos (Keeling) Islands', value: 'CC', dial_code: "+61"},
    { text: 'Colombia', value: 'CO', dial_code: "+57" },
    { text: 'Comoros', value: 'KM', dial_code: "+269"},
    { text: 'Congo', value: 'CG' ,dial_code: "+242"},
    { text: 'Congo, The Democratic Republic of the', value: 'CD', dial_code: "+243"},
    { text: 'Cook Islands', value: 'CK', dial_code: "+682"},
    { text: 'Costa Rica', value: 'CR', dial_code: "+506"},
    { text: 'Cote D\'Ivoire', value: 'CI', dial_code: "+225"},
    { text: 'Croatia', value: 'HR', dial_code: "+385"},
    { text: 'Cuba', value: 'CU' ,dial_code: "+53"},
    { text: 'Cyprus', value: 'CY', dial_code: "+357"},
    { text: 'Czech Republic', value: 'CZ', dial_code: "+420"},
    { text: 'Denmark', value: 'DK' ,dial_code: "+45"},
    { text: 'Djibouti', value: 'DJ' ,dial_code: "+253"},
    { text: 'Dominica', value: 'DM', dial_code: "+1767"},
    { text: 'Dominican Republic', value: 'DO', dial_code: "+1767"},
    { text: 'Ecuador', value: 'EC' ,dial_code: "+593"},
    { text: 'Egypt', value: 'EG' ,dial_code: "+20"},
    { text: 'El Salvador', value: 'SV' ,dial_code: "+503"},
    { text: 'Equatorial Guinea', value: 'GQ' ,dial_code: "+240"},
    { text: 'Eritrea', value: 'ER' ,dial_code: "+291"},
    { text: 'Estonia', value: 'EE' ,dial_code: "+372"},
    { text: 'Ethiopia', value: 'ET' ,dial_code: "+251"},
    { text: 'Falkland Islands (Malvinas)', value: 'FK', dial_code: "+500"},
    { text: 'Faroe Islands', value: 'FO', dial_code: "+298"},
    { text: 'Fiji', value: 'FJ' ,dial_code: "+679"},
    { text: 'Finland', value: 'FI' ,dial_code: "+358"},
    { text: 'France', value: 'FR' ,dial_code: "+33"},
    { text: 'French Guiana', value: 'GF' ,dial_code: "+594"},
    { text: 'French Polynesia', value: 'PF', dial_code: "+689"},
    { text: 'French Southern Territories', value: 'TF' ,dial_code: "+262"},
    { text: 'Gabon', value: 'GA' ,dial_code: "+241"},
    { text: 'Gambia', value: 'GM' ,dial_code: "+220"},
    { text: 'Georgia', value: 'GE' ,dial_code: "+995"},
    { text: 'Germany', value: 'DE' ,dial_code: "+49"},
    { text: 'Ghana', value: 'GH' ,dial_code: "+233"},
    { text: 'Gibraltar', value: 'GI' ,dial_code: "+350"},
    { text: 'Greece', value: 'GR' ,dial_code: "+30"},
    { text: 'Greenland', value: 'GL' ,dial_code: "+299"},
    { text: 'Grenada', value: 'GD' ,dial_code: "+1473"},
    { text: 'Guadeloupe', value: 'GP' ,dial_code: "+590"},
    { text: 'Guam', value: 'GU' ,dial_code: "+1 671"},
    { text: 'Guatemala', value: 'GT' ,dial_code: "+502"},
    { text: 'Guernsey', value: 'GG' ,dial_code: "+44"},
    { text: 'Guinea', value: 'GN' ,dial_code: "+224"},
    { text: 'Guinea-Bissau', value: 'GW' ,dial_code: "+245"},
    { text: 'Guyana', value: 'GY' ,dial_code: "+245"},
    { text: 'Haiti', value: 'HT' ,dial_code: "+509"},
    { text: 'Heard Island and Mcdonald Islands', value: 'HM' ,dial_code: "+0"},
    { text: 'Holy See (Vatican City State)', value: 'VA' ,dial_code: "+379"},
    { text: 'Honduras', value: 'HN' ,dial_code: "+504"},
    { text: 'Hong Kong', value: 'HK' ,dial_code: "+852"},
    { text: 'Hungary', value: 'HU' ,dial_code: "+36"},
    { text: 'Iceland', value: 'IS' ,dial_code: "+354"},
    { text: 'India', value: 'IN' , dial_code: "+91" },
    { text: 'Indonesia', value: 'ID' ,dial_code: "+62"},
    { text: 'Iran, Islamic Republic Of', value: 'IR' ,dial_code: "+98"},
    { text: 'Iraq', value: 'IQ' ,dial_code: "+964"},
    { text: 'Ireland', value: 'IE' ,dial_code: "+353"},
    { text: 'Isle of Man', value: 'IM' ,dial_code: "+44"},
    { text: 'Israel', value: 'IL' ,dial_code: "+972"},
    { text: 'Italy', value: 'IT' ,dial_code: "+39"},
    { text: 'Jamaica', value: 'JM' ,dial_code: "+1876"},
    { text: 'Japan', value: 'JP' ,dial_code: "+81"},
    { text: 'Jersey', value: 'JE' ,dial_code: "+44"},
    { text: 'Jordan', value: 'JO' ,dial_code: "+962"},
    { text: 'Kazakhstan', value: 'KZ', dial_code: "+7"},
    { text: 'Kenya', value: 'KE' ,dial_code: "+254"},
    { text: 'Kiribati', value: 'KI' ,dial_code: "+686"},
    { text: 'Korea, Democratic People\'S Republic of', value: 'KP' ,dial_code: "+850"},
    { text: 'Korea, Republic of', value: 'KR' ,dial_code: "+82"},
    { text: 'Kuwait', value: 'KW' ,dial_code: "+965"},
    { text: 'Kyrgyzstan', value: 'KG' ,dial_code: "+996"},
    { text: 'Lao People\'S Democratic Republic', value: 'LA' ,dial_code: "+856"},
    { text: 'Latvia', value: 'LV' ,dial_code: "+371"},
    { text: 'Lebanon', value: 'LB', dial_code: "+961"},
    { text: 'Lesotho', value: 'LS' ,dial_code: "+266"},
    { text: 'Liberia', value: 'LR' ,dial_code: "+231"},
    { text: 'Libyan Arab Jamahiriya', value: 'LY' ,dial_code: "+218"},
    { text: 'Liechtenstein', value: 'LI' ,dial_code: "+423"},
    { text: 'Lithuania', value: 'LT' ,dial_code: "+370"},
    { text: 'Luxembourg', value: 'LU' ,dial_code: "+352"},
    { text: 'Macao', value: 'MO' ,dial_code: "+853"},
    { text: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' ,dial_code: "+389"},
    { text: 'Madagascar', value: 'MG' ,dial_code: "+261"},
    { text: 'Malawi', value: 'MW' ,dial_code: "+265"},
    { text: 'Malaysia', value: 'MY' ,dial_code: "+60"},
    { text: 'Maldives', value: 'MV' ,dial_code: "+960"},
    { text: 'Mali', value: 'ML' ,dial_code: "+223"},
    { text: 'Malta', value: 'MT' ,dial_code: "+356"},
    { text: 'Marshall Islands', value: 'MH' ,dial_code: "+692"},
    { text: 'Martinique', value: 'MQ' ,dial_code: "+596"},
    { text: 'Mauritania', value: 'MR' ,dial_code: "+222"},
    { text: 'Mauritius', value: 'MU' ,dial_code: "+230"},
    { text: 'Mayotte', value: 'YT' ,dial_code: "+262"},
    { text: 'Mexico', value: 'MX' ,dial_code: "+52"},
    { text: 'Micronesia, Federated States of', value: 'FM' ,dial_code: "+691"},
    { text: 'Moldova, Republic of', value: 'MD' ,dial_code: "+373"},
    { text: 'Monaco', value: 'MC' ,dial_code: "+377"},
    { text: 'Mongolia', value: 'MN' ,dial_code: "+976"},
    { text: 'Montserrat', value: 'MS' ,dial_code: "+1664"},
    { text: 'Morocco', value: 'MA' ,dial_code: "+212"},
    { text: 'Mozambique', value: 'MZ' ,dial_code: "+258"},
    { text: 'Myanmar', value: 'MM' ,dial_code: "+95"},
    { text: 'Namibia', value: 'NA' ,dial_code: "+264"},
    { text: 'Nauru', value: 'NR' ,dial_code: "+674"},
    { text: 'Nepal', value: 'NP' ,dial_code: "+977"},
    { text: 'Netherlands', value: 'NL' ,dial_code: "+31"},
    { text: 'Netherlands Antilles', value: 'AN' ,dial_code: "+599"},
    { text: 'New Caledonia', value: 'NC' ,dial_code: "+687"},
    { text: 'New Zealand', value: 'NZ' ,dial_code: "+64"},
    { text: 'Nicaragua', value: 'NI' ,dial_code: "+505"},
    { text: 'Niger', value: 'NE', dial_code: "+227"},
    { text: 'Nigeria', value: 'NG' ,dial_code: "+234"},
    { text: 'Niue', value: 'NU' ,dial_code: "+683"},
    { text: 'Norfolk Island', value: 'NF' ,dial_code: "+672"},
    { text: 'Northern Mariana Islands', value: 'MP' ,dial_code: "+1670"},
    { text: 'Norway', value: 'NO' ,dial_code: "+47"},
    { text: 'Oman', value: 'OM' ,dial_code: "+968"},
    { text: 'Pakistan', value: 'PK' ,dial_code: "+92"},
    { text: 'Palau', value: 'PW' ,dial_code: "+680"},
    { text: 'Palestinian Territory, Occupied', value: 'PS' ,dial_code: "+970"},
    { text: 'Panama', value: 'PA' ,dial_code: "+507"},
    { text: 'Papua New Guinea', value: 'PG' ,dial_code: "+675"},
    { text: 'Paraguay', value: 'PY' ,dial_code: "+595"},
    { text: 'Peru', value: 'PE' ,dial_code: "+51"},
    { text: 'Philippines', value: 'PH' ,dial_code: "+63"},
    { text: 'Pitcairn', value: 'PN' ,dial_code: "+64"},
    { text: 'Poland', value: 'PL' ,dial_code: "+48"},
    { text: 'Portugal', value: 'PT' ,dial_code: "+351"},
    { text: 'Puerto Rico', value: 'PR' ,dial_code: "+1939"},
    { text: 'Qatar', value: 'QA' ,dial_code: "+974"},
    { text: 'Reunion', value: 'RE' ,dial_code: "+262"},
    { text: 'Romania', value: 'RO' ,dial_code: "+40"},
    { text: 'Russian Federation', value: 'RU' ,dial_code: "+7"},
    { text: 'RWANDA', value: 'RW' ,dial_code: "+250"},
    { text: 'Saint Helena', value: 'SH' ,dial_code: "+290"},
    { text: 'Saint Kitts and Nevis', value: 'KN' ,dial_code: "+1869"},
    { text: 'Saint Lucia', value: 'LC' ,dial_code: "+1758"},
    { text: 'Saint Pierre and Miquelon', value: 'PM' ,dial_code: "+508"},
    { text: 'Saint Vincent and the Grenadines', value: 'VC' ,dial_code: "+1784"},
    { text: 'Samoa', value: 'WS' ,dial_code: "+685"},
    { text: 'San Marino', value: 'SM' ,dial_code: "+378",},
    { text: 'Sao Tome and Principe', value: 'ST' ,dial_code: "+239"},
    { text: 'Saudi Arabia', value: 'SA' ,dial_code: "+966"},
    { text: 'Senegal', value: 'SN' ,dial_code: "+221"},
    { text: 'Serbia and Montenegro', value: 'CS',dial_code: "+381"},
    { text: 'Seychelles', value: 'SC' ,dial_code: "+248"},
    { text: 'Sierra Leone', value: 'SL' ,dial_code: "+232"},
    { text: 'Singapore', value: 'SG' ,dial_code: "+65"},
    { text: 'Slovakia', value: 'SK' ,dial_code: "+421"},
    { text: 'Slovenia', value: 'SI' ,dial_code: "+386"},
    { text: 'Solomon Islands', value: 'SB' ,dial_code: "+677"},
    { text: 'Somalia', value: 'SO' ,dial_code: "+252"},
    { text: 'South Africa', value: 'ZA' ,dial_code: "+27"},
    { text: 'South Georgia and the South Sandwich Islands', value: 'GS' ,dial_code: "+500"},
    { text: 'Spain', value: 'ES' ,dial_code: "+34"},
    { text: 'Sri Lanka', value: 'LK' ,dial_code: "+94"},
    { text: 'Sudan', value: 'SD' ,dial_code: "+249"},
    { text: 'Suriname', value: 'SR' ,dial_code: "+597"},
    { text: 'Svalbard and Jan Mayen', value: 'SJ' ,dial_code: "+47"},
    { text: 'Swaziland', value: 'SZ' ,dial_code: "+268"},
    { text: 'Sweden', value: 'SE' ,dial_code: "+46"},
    { text: 'Switzerland', value: 'CH' ,dial_code: "+41"},
    { text: 'Syrian Arab Republic', value: 'SY' ,dial_code: "+963"},
    { text: 'Taiwan, Province of China', value: 'TW' ,dial_code: "+886"},
    { text: 'Tajikistan', value: 'TJ' ,dial_code: "+992"},
    { text: 'Tanzania, United Republic of', value: 'TZ' ,dial_code: "+255"},
    { text: 'Thailand', value: 'TH' ,dial_code: "+66"},
    { text: 'Timor-Leste', value: 'TL' ,dial_code: "+670"},
    { text: 'Togo', value: 'TG' ,dial_code: "+228"},
    { text: 'Tokelau', value: 'TK' ,dial_code: "+690"},
    { text: 'Tonga', value: 'TO' ,dial_code: "+676"},
    { text: 'Trinidad and Tobago', value: 'TT' ,dial_code: "+1868"},
    { text: 'Tunisia', value: 'TN' ,dial_code: "+216"},
    { text: 'Turkey', value: 'TR' ,dial_code: "+90"},
    { text: 'Turkmenistan', value: 'TM' ,dial_code: "+993"},
    { text: 'Turks and Caicos Islands', value: 'TC' ,dial_code: "+1649"},
    { text: 'Tuvalu', value: 'TV' ,dial_code: "+688"},
    { text: 'Uganda', value: 'UG' ,dial_code: "+256"},
    { text: 'Ukraine', value: 'UA' ,dial_code: "+380"},
    { text: 'United Arab Emirates', value: 'AE' ,dial_code: "+971"},
    { text: 'United Kingdom', value: 'GB' ,dial_code: "+44"},
    { text: 'United States', value: 'US' ,dial_code: "+1"},
    { text: 'United States Minor Outlying Islands', value: 'UM' ,dial_code: "+1581"},
    { text: 'Uruguay', value: 'UY' ,dial_code: "+598"},
    { text: 'Uzbekistan', value: 'UZ' ,dial_code: "+998"},
    { text: 'Vanuatu', value: 'VU' ,dial_code: "+678"},
    { text: 'Venezuela', value: 'VE' ,dial_code: "+58"},
    { text: 'Viet Nam', value: 'VN' ,dial_code: "+84"},
    { text: 'Virgin Islands, British', value: 'VG' ,dial_code: "+1 284"},
    { text: 'Virgin Islands, U.S.', value: 'VI' ,dial_code: "+1 340"},
    { text: 'Wallis and Futuna', value: 'WF' ,dial_code: "+681"},
    { text: 'Western Sahara', value: 'EH', dial_code: "+732"},
    { text: 'Yemen', value: 'YE' ,dial_code: "+967"},
    { text: 'Zambia', value: 'ZM' ,dial_code: "+260"},
    { text: 'Zimbabwe', value: 'ZW', dial_code: "+263"}
  ];
  
  export const nationalities:any = [ 
    {value: '',text:"Select Nationality"},
    {value: 3, text: "Australian",dial_code: "+61"},
    {value: 34, text: "Afghan", dial_code:"+93"},
    {value: 35, text: "Albanian", dial_code: "+355"},
    {value: 36, text: "Algerian", dial_code: "+213"},
    {value: 158, text: "American", dial_code: "+1684"},
    {value: 38, text: "Andorran", dial_code: "+376"},
    {value: 40, text: "Armenian", dial_code: "+374"},
    {value: 39, text: "Angolan", dial_code: "+244"},
    {value: 41, text: "Austrian" ,dial_code: "+43"},
    {value: 42, text: "Azerbaijani" ,dial_code: "+994"},
    {value: 2, text: "Argentinian" ,dial_code: "+54"},
    {value: 1, text: "British" ,dial_code: "+246"},
    {value: 43, text: "Bahraini", dial_code: "+973"},
    {value: 44, text: "Bangladeshi",dial_code: "+880"},
    {value: 45, text: "Barbadian", dial_code: "+1246"},
    {value: 46, text: "Belarusian", dial_code: "+375"},
    {value: 47, text: "Belizean", dial_code: "+501"},
    {value: 48, text: "Beninese", dial_code: "+229"},
    {value: 49, text: "Bermudian", dial_code: "+1441"},
    {value: 50, text: "Bhutanese", dial_code: "+975"},
    {value: 51, text: "Bolivian", dial_code: "+591"},
    {value: 52, text: "Bosnian", dial_code: "+387"},
    {value: 53, text: "Botswanan",dial_code: "+267"},
    {value: 54, text: "Bulgarian", dial_code: "+359"},
    {value: 55, text: "Burkinese" ,dial_code: "+226"},
    {value: 56, text: "Burundian",dial_code: "+257"},
    {value: 7, text: "Canadian", dial_code: "+1"},
    {value: 8, text: "Chinese", dial_code: "+86"},
    {value: 9, text: "Colombian",dial_code: "+57"},
    {value: 10, text: "Cuban",dial_code: "+53"},
    {value: 57, text: "Cambodian", dial_code: "+855"},
    {value: 58, text: "Cameroonian",dial_code: "+237"},
    {value: 59, text: "Cape Verdean", dial_code: "+238"},
    {value: 60, text: "Chadian", dial_code: "+235"},
    {value: 61, text: "Chilean" ,dial_code: "+56"},
    {value: 62, text: "Congolese" ,dial_code: "+242"},
    {value: 63, text: "Costa Rican", dial_code: "+506"},
    {value: 64, text: "Croat", dial_code: "+385"},
    {value: 65, text: "Cypriot", dial_code: "+357"},
    {value: 66, text: "Czech", dial_code: "+420"},
    {value: 67, text: "Danish", dial_code: "+45"},
    {value: 11, text: "Dominican", dial_code: "+1767"},
    {value: 68, text: "Djiboutian", dial_code: "+253"},
    {value: 69, text: "Dominican", dial_code: "+1767"},
    {value: 26, text: "Dutch", dial_code: "+31"},
    {value: 157, text: "Emirati" ,dial_code: "+971"},
    {value: 12, text: "Ecuadorean" ,dial_code: "+593"},
    {value: 70, text: "Egyptian", dial_code: "+20"},
    {value: 71, text: "Eritrean", dial_code: "+291"},
    {value: 72, text: "Estonian", dial_code: "+372"},
    {value: 73, text: "Ethiopian",dial_code: "+251"},
    {value: 74, text: "Fijian" ,dial_code: "+679"},
    {value: 75, text: "Finnish" , dial_code: "+594"},
    {value: 76, text: "French Polynesian", dial_code: "+689"},
    {value: 14, text: "French", dial_code: "+33"},
    {value: 125, text: 'Guinean' ,dial_code: "+224"},
    {value: 77, text: "Gabonese", dial_code: "+241"},
    {value: 78, text: "Gambian", dial_code: "+220"},
    {value: 79, text: "Georgian", dial_code: "+995"},
    {value: 15, text: "German" ,dial_code: "+49"},
    {value: 16, text: "Guatemalan",dial_code: "+502"},
    {value: 80, text: "Ghanaian" ,dial_code: "+233"},
    {value: 81, text: "Greek",dial_code: "+30"},
    {value: 82, text: "Grenadian",dial_code: "+1473"},
    {value: 83, text: "Guinean" ,dial_code: "+224"},
    {value: 84, text: "Guyanese" ,dial_code: "+245"},
    {value: 17, text: "Haitian" ,dial_code: "+509"},
    {value: 18, text: "Honduran", dial_code: "+504"},
    {value: 85, text: "Hungarian", dial_code: "+36"},
    {value: 19, text: "Indian" , dial_code: "+91"},
    {value: 20, text: "Ireland", dial_code: "+353"},
    {value: 21, text: "Israeli", dial_code: "+972"},
    {value: 22, text: "Italian", dial_code: "+39"},
    {value: 86, text: "Icelandic", dial_code: "+354"},
    {value: 87, text: "Indonesian", dial_code: "+62"},
    {value: 88, text: "Iranian" ,dial_code: "+98"},
    {value: 89, text: "Iraqi", dial_code: "+964"},
    {value: 23, text: "Japanese" ,dial_code: "+81"},
    {value: 90, text: "Jamaican" ,dial_code: "+1876"},
    {value: 91, text: "Jordanian" ,dial_code: "+962"},
    {value: 92, text: "Kazakh" ,dial_code: "+7"},
    {value: 93, text: "Kenyan" ,dial_code: "+254"},
    {value: 95, text: "Kuwaiti" ,dial_code: "+965"},
    {value: 96, text: "Latvian" ,dial_code: "+371"},
    {value: 97, text: "Lebanese" ,dial_code: "+961"},
    {value: 98, text: "Liberian" ,dial_code: "+231"},
    {value: 99, text: '"Libyan"' ,dial_code: "+218"},
    {value: 100, text: "Lithuanian" ,dial_code: "+370"},
    {value: 101, text: "LUXEMBOURG" ,dial_code: "+352"},
    {value: 102, text: "Madagascan" ,dial_code: "+261"},
    {value: 103, text: "Malawian" ,dial_code: "+265"},
    {value: 104, text: "Malaysian" ,dial_code: "+60"},
    {value: 105, text: "Maldivian" ,dial_code: "+960"},
    {value: 106, text: "Malian" ,dial_code: "+223"},
    {value: 107, text: "Maltese" ,dial_code: "+356"},
    {value: 108, text: "Mauritanian" ,dial_code: "+222"},
    {value: 109, text: "Mauritian" ,dial_code: "+230"},
    {value: 110, text: "Monacan" ,dial_code: "+377"},
    {value: 111, text: "Mongolian" ,dial_code: "+976"},
    {value: 112, text: "Montenegrin" ,dial_code: "+1664"},
    {value: 113, text: "Moroccan" ,dial_code: "+212"},
    {value: 114, text: "Mozambica" ,dial_code: "+258"},
    {value: 25, text: "Mexican" ,dial_code: "+52"},
    {value: 94, text: "North Korean" ,dial_code: "+672"},
    {value: 115, text: "Namibian" ,dial_code: "+264"},
    {value: 116, text: "Nepalese" ,dial_code: "+977"},
    {value: 117, text: "New Zealand" ,dial_code: "+64"},
    {value: 118, text: "Nicaraguan" ,dial_code: "+505"},
    {value: 119, text: "Nigerien", dial_code: "+227"},
    {value: 120, text: "Nigerian" ,dial_code: "+234"},
    {value: 121, text: "Norwegian" ,dial_code: "+47"},
    {value: 122, text: "Omani" , dial_code: "+968"},
    {value: 123, text: "Pakistani" ,dial_code: "+92"},
    {value: 124, text: "Panamanian" ,dial_code: "+507"},
    {value: 126, text: "Paraguayan" ,dial_code: "+595"},
    {value: 127, text: "Peruvian" ,dial_code: "+51"},
    {value: 27, text: "Philippine" ,dial_code: "+63"},
    {value: 128, text: "Polish" ,dial_code: "+48"},
    {value: 129, text: "Portuguese" ,dial_code: "+351"},
    {value: 130, text: "Qatari" ,dial_code: "+974"},
    {value: 131, text: "Romanian" ,dial_code: "+40"},
    {value: 132, text: "Rwandan" ,dial_code: "+250"},
    {value: 13, text: "Salvadorean" ,dial_code: "+503"},
    {value: 37, text: "Samoan" ,dial_code: "+685"},
    {value: 133, text: "Saudi Arabian",dial_code: "+966"},
    {value: 134, text: "Senegalese" ,dial_code: "+221"},
    {value: 135, text: "Serbian" ,dial_code: "+381"},
    {value: 136, text: "Sierra Leonian", dial_code: "+232"},
    {value: 137, text: "Singaporean" ,dial_code: "+65"},
    {value: 138, text: "Slovak" ,dial_code: "+421"},
    {value: 139, text: "Slovenian" ,dial_code: "+386"},
    {value: 140, text: "Slomoni" ,dial_code: "+707"},
    {value: 141, text: "Somali" ,dial_code: "+252"},
    {value: 142, text: "South African" ,dial_code: "+27"},
    {value: 24, text: "South Korean" ,dial_code: "+82"},
    {value: 28, text: "Spanish" ,dial_code: "+34"},
    {value: 29, text: "Swedish" ,dial_code: "+46"},
    {value: 30, text: "Swiss" ,dial_code: "+41"},
    {value: 143, text: "Sri Lankan" ,dial_code: "+94"},
    {value: 144, text: "Sudanese" ,dial_code: "+249"},
    {value: 145, text: "Surinamese" ,dial_code: "+597"},
    {value: 146, text: "Swazi" ,dial_code: "+268"},
    {value: 31, text: "Taiwanese" ,dial_code: "+886"},
    {value: 147, text: "Tajik" ,dial_code: "+992"},
    {value: 148, text: "Thai", dial_code: "+66"},
    {value: 149, text: "Togolese" ,dial_code: "+228"},
    {value: 150, text: "Trinidadian" ,dial_code: "+1868"},
    {value: 151, text: "Tunisian",dial_code: "+216"},
    {value: 152, text: "Turkish" ,dial_code: "+90"},
    {value: 153, text: "Turkoman" ,dial_code: "+993"},
    {value: 154, text: "Tuvaluan" ,dial_code: "+688"},
    {value: 159, text: "Uruguayan" ,dial_code: "+598"},
    {value: 160, text: "Uzbek" ,dial_code: "+998"},
    {value: 155, text: "Ugandan" ,dial_code: "+256"},
    {value: 156, text: "Ukrainian" ,dial_code: "+380"},
    {value: 32, text: "Venezuelan" ,dial_code: "+58"},
    {value: 33, text: "Vietnamese" ,dial_code: "+84"},
    {value: 161, text: "Vanuatuan" ,dial_code: "+678"},
    {value: 162, text: "Yemeni" , dial_code: "+967"},
    {value: 163, text: "Zambian" ,dial_code: "+260"}
   ]
  
  export const roomType = [
    {value: "", text: 'Please select',},
    {value: 'High floor', text: 'High floor'},
    {value: 'Low floor', text: "Low floor"},        
    {value: 'inter-connecting"', text: "inter-connecting"},        
    {value: 'Standard room', text: "Standard room"},        
    {value: 'Suite', text: "Suite"},  
    {value: 'Special request', text: "Special request"},             
  ];
  
  export const carType = [
    {value: "", text: 'Please select',},
    {value: 'Economy', text: 'Economy'},
    {value: 'Compact', text: "Compact"},        
    {value: 'Standard', text: "Standard"},        
    {value: 'Intermediate', text: "Intermediate"},        
    {value: 'Full-SizeSUV', text: "Full-SizeSUV"}, 
    {value: 'Mini Van', text: "Mini Van"},
    {value: 'Convertible', text: "Convertible"},
    {value: 'Specialty Cars', text: "Specialty Cars"},
    {value: 'Pick-Up', text: "Pick-Up"},
    {value: 'Premium & Luxury', text: "Premium & Luxury"},
    {value: 'Vans & Trucks', text: "Vans & Trucks"},
    {value: 'Campervan', text: "Campervan"},
    {value: 'Motorhome', text: "Motorhome"},
    {value: 'CamperCar', text: "CamperCar"},
    
  ];
  
  export const Interest =  [
    { name: 'Cricket', code: 'Cricket' },
    { name: 'Reading', code: 'Reading' },
    { name: 'Wellness', code: 'Wellness' },
    { name: 'Nightlife', code: 'Nightlife' },
    { name: 'Business Hotels', code: 'Business Hotels' },
    { name: 'Golf', code: 'Golf' },
    { name: 'Wine', code: 'Wine' },
    { name: 'Attractions', code: 'Attractions' },
  ];
  
  export const loyaltyTypes = [
    {value: "", text: 'Please select'},
    {value:'Frequent Flyer', text: 'Frequent Flyer'},
    {value: "Hotel loyalty", text: "Hotel loyalty"},
    {value: 'Car Rental Loyalty', text: 'Car Rental Loyalty'},
    {value: "Other", text: "Other"}  
  ];
  
  export const ContactMethod = [
    {value: "", text: 'Please select'},
    {value: 'Email', text: 'Email'},
    {value: 'Work Phone', text: "Work Phone"},        
    {value: 'Mobile Phone', text: "Mobile Phone"},        
    {value: 'PA Name"', text: "PA Name"},              
  ];
  
  export const AirlineTypes = [
    {value: "", text: 'Please select'}, 
    {value: "Adria Airways", text: "Adria Airways"},
    {value: "Aegean", text: "Aegean"},
    {value: "Aer Lingus", text: "Aer Lingus"},
    {value: "Aeroflot Russi", text: "Aeroflot Russi"}, 
    {value: "Aerolineas Arg", text: "Aerolineas Arg"},
    {value: "Aeromexico", text: "Aeromexico"},
    {value: "Air Algerie", text: "Air Algerie"},
    {value: "Air Arabia Egy", text: "Air Arabia Egy"},
    {value: "Air Arabia Mar", text: "Air Arabia Mar"},
    {value: "Air Arabia", text: "Air Arabia"},
    {value: "Air Asia India", text: "Air Asia India"},
    {value: "Air Astana", text: "Air Astana"},
    {value: "Air Austral", text: "Air Austral"},
    {value: "Air Baltic", text: "Air Baltic"},
    {value: "Air Berlin", text: "Air Berlin"},
    {value: "Air Botswana", text: "Air Botswana"},
    {value: "Air Canada", text: "Air Canada"},
    {value: "Air Carnival", text: "Air Carnival"},
    {value: "Air China", text: "Air China"},
    {value: "Air Costa", text: "Air Costa"},
    {value: "Air Europa", text: "Air Europa"},
    {value: "Air France", text: "Air France"},
    {value: "Air India Expr", text: "Air India Expr"},
    {value: "Air India", text: "Air India"},
    {value: "Air Macau", text: "Air Macau"},
    {value: "Air Malta", text: "Air Malta"},
    {value: "Air Mauritius", text: "Air Mauritius"},
    {value: "Air Moldova", text: "Air Moldova"},
    {value: "Air Serbia", text: "Air Serbia"},
    {value: "Air Seychelles", text: "Air Seychelles"},
    {value: "Air Tahiti Nui", text: "Air Tahiti Nui"},
    {value: "Air Transat", text: "Air Transat"},
    {value: "Air Zimbabwe", text: "Air Zimbabwe"},
    {value: "AirAsia Zest", text: "AirAsia Zest"},
    {value: "Airpegasus", text: "Airpegasus"},
    {value: "Alaska", text: "Alaska"},
    {value: "Alitalia", text: "Alitalia"},
    {value: "All Nippon", text: "All Nippon"},
    {value: "Allegiant", text: "Allegiant"},
    {value: "American", text: "American"},
    {value: "Ariana", text: "Ariana"},
    {value: "Asian Wings", text: "Asian Wings"},
    {value: "Asiana", text: "Asiana"},
    {value: "ASKY", text: "ASKY"},
    {value: "Atlas global", text: "Atlas global"},
    {value: "Austrian", text: "Austrian"},
    {value: "Avianca", text: "Avianca"},
    {value: "Azerbaijan", text: "Azerbaijan"},
    {value: "Azores", text: "Azores"},
    {value: "Azul", text: "Azul"},
    {value: "Bangkok", text: "Bangkok"},
    {value: "Belavia", text: "Belavia"},
    {value: "Bhutan", text: "Bhutan"},
    {value: "Biman", text: "Biman"},
    {value: "Blue", text: "Blue"},
    {value: "bmi regional", text: "bmi regional"},
    {value: "BoA", text: "BoA"},
    {value: "British", text: "British"},
    {value: "Brussels", text: "Brussels"},
    {value: "Buddha", text: "Buddha"},
    {value: "Bulgaria", text: "Bulgaria"},
    {value: "Business", text: "Business"},
    {value: "Cambodia Angko", text: "Cambodia Angko"},
    {value: "Caribbean", text: "Caribbean"},
    {value: "Cathay Dragon", text: "Cathay Dragon"},
    {value: "Cathay Pacific", text: "Cathay Pacific"},
    {value: "China Eastern", text: "China Eastern"},
    {value: "China Southern", text: "China Southern"},
    {value: "China United", text: "China United"},
    {value: "City Jet", text: "City Jet"},
    {value: "Condor", text: "Condor"},
    {value: "Copa", text: "Copa"},
    {value: "Corsair", text: "Corsair"},
    {value: "Croatia", text: "Croatia"},
    {value: "Czech", text: "Czech"},
    {value: "Delta", text: "Delta"},
    {value: "Deutsche", text: "Deutsche"},
    {value: "Druk", text: "Druk"},
    {value: "Eastar Jet", text: "Eastar Jet"},
    {value: "Easy Jet", text: "Easy Jet"},
    {value: "Egyptair", text: "Egyptair"},
    {value: "El Al", text: "El Al"},
    {value: "Emirates", text: "Emirates"},
    {value: "Ethiopian", text: "Ethiopian"},
    {value: "Etihad Airways", text: "Etihad Airways"},
    {value: "Eurowings", text: "Eurowings"},
    {value: "EVA", text: "EVA"},
    {value: "Fiji Airways", text: "Fiji Airways"},
    {value: "Finnair", text: "Finnair"},
    {value: "Fly Scoot", text: "Fly Scoot"},
    {value: "Flybe", text: "Flybe"},
    {value: "FlyDubai", text: "FlyDubai"},
    {value: "flynas", text: "flynas"},
    {value: "Frontier", text: "Frontier"},
    {value: "Garuda Indonesia", text: "Garuda Indonesia"},
    {value: "Germania", text: "Germania"},
    {value: "Go", text: "Go"},
    {value: "Gol Linhas", text: "Gol Linhas"},
    {value: "Gulf", text: "Gulf"},
    {value: "Hahn", text: "Hahn"},
    {value: "Hainan", text: "Hainan"},
    {value: "Hawaiian", text: "Hawaiian"},
    {value: "Himalaya", text: "Himalaya"},
    {value: "Hong Kong", text: "Hong Kong"},
    {value: "HOP! Air Franc", text: "HOP! Air Franc"},
    {value: "Iberia", text: "Iberia"},
    {value: "Iceland", text: "Iceland"},
    {value: "Indigo", text: "Indigo"},
    {value: "Indonesia", text: "Indonesia"},
    {value: "Interjet", text: "Interjet"},
    {value: "Iran", text: "Iran"},
    {value: "Iraqi Airways", text: "Iraqi Airways"},
    {value: "Japan", text: "Japan"},
    {value: "Jazeera", text: "Jazeera"},
    {value: "Jeju", text: "Jeju"},  
    {value: "Jet Airways", text: "Jet Airways"},
    {value: "Jet Konnect", text: "Jet Konnect"},
    {value: "Jet2.com", text: "Jet2.com"},
    {value: "Jetairfly", text: "Jetairfly"},
    {value: "JetBlue", text: "JetBlue"},
    {value: "JetLite", text: "JetLite"},
    {value: "Jetstar Asia", text: "Jetstar Asia"},
    {value: "Jetstar Japan", text: "Jetstar Japan"},
    {value: "Jin", text: "Jin"},
    {value: "Juneyao", text: "Juneyao"},
    {value: "Kaya", text: "Kaya"},
    {value: "Kenya Airways", text: "Kenya Airways"},
    {value: "KLM Royal Dutc", text: "KLM Royal Dutc"},
    {value: "Korean", text: "Korean"},
    {value: "Kuwait Airways", text: "Kuwait Airways"},
    {value: "La Compagnie", text: "La Compagnie"},
    {value: "Lao", text: "Lao"},
    {value: "Latam", text: "Latam"},
    {value: "LOT Polish", text: "LOT Polish"},
    {value: "Lux", text: "Lux"},
    {value: "Mahan", text: "Mahan"},
    {value: "Malaysia", text: "Malaysia"},
    {value: "Malindo", text: "Malindo"},
    {value: "Mango", text: "Mango"},
    {value: "Mea", text: "Mea"},
    {value: "Meridiana", text: "Meridiana"},
    {value: "MIAT Mongolian", text: "MIAT Mongolian"},
    {value: "Mihin Lanka", text: "Mihin Lanka"},
    {value: "Monarch", text: "Monarch"},
    {value: "Nepal", text: "Nepal"},
    {value: "NIKI", text: "NIKI"},
    {value: "Northwest", text: "Northwest"},
    {value: "Norwegian", text: "Norwegian"},
    {value: "Oman ", text: "Oman "},
    {value: "Pakistan", text: "Pakistan"},
    {value: "Peach", text: "Peach"},
    {value: "Pegasus", text: "Pegasus"},
    {value: "Philippine", text: "Philippine"},
    {value: "Porter", text: "Porter"},
    {value: "Qatar Airways", text: "Qatar Airways"},
    {value: 'Qantas Airways', text: 'Qantas Airways'},
    {value: "Royal", text: "Royal"},
    {value: "Royal Brunei", text: "Royal Brunei"},
    {value: "Royal Jordania", text: "Royal Jordania"},
    {value: "Ryanair", text: "Ryanair"},
    {value: "S7", text: "S7"},
    {value: "Saudi Arabian", text: "Saudi Arabian"},
    {value: "Saudia", text: "Saudia"},
    {value: "Scandinavian", text: "Scandinavian"},
    {value: "Shaheen", text: "Shaheen"},
    {value: "Shandong", text: "Shandong"},
    {value: "Shanghai", text: "Shanghai"},
    {value: "Shenzhen", text: "Shenzhen"},
    {value: "Sichuan", text: "Sichuan"},
    {value: "Silk", text: "Silk"},
    {value: "Singapore", text: "Singapore"},
    {value: "SkyWork", text: "SkyWork"},
    {value: "SmartWings", text: "SmartWings"},
    {value: "South African", text: "South African"},
    {value: "Southern", text: "Southern"},
    {value: "Spice Jet", text: "Spice Jet"},
    {value: "Spirit", text: "Spirit"},
    {value: "SriLankan", text: "SriLankan"},
    {value: "Star", text: "Star"},
    {value: "Sun Country", text: "Sun Country"},
    {value: "SunExpress", text: "SunExpress"},
    {value: "Sunwing", text: "Sunwing"},
    {value: "Swiss Global", text: "Swiss Global"},
    {value: "Swiss Internat", text: "Swiss Internat"},
    {value: "Syrian Arab", text: "Syrian Arab"},
    {value: "TAAG", text: "TAAG"},
    {value: "TACA", text: "TACA"},
    {value: "TAME", text: "TAME"},
    {value: "TAP Portugal", text: "TAP Portugal"},
    {value: "TAROM", text: "TAROM"},
    {value: "Thai Airasia X", text: "Thai Airasia X"},
    {value: "Thai AirAsia", text: "Thai AirAsia"},
    {value: "Thai Airways", text: "Thai Airways"},
    {value: "Thai Lion", text: "Thai Lion"},
    {value: "THAI Smile", text: "THAI Smile"},
    {value: "Thomas Cook", text: "Thomas Cook"},
    {value: "Thomsonfly", text: "Thomsonfly"},
    {value: "Tianjin", text: "Tianjin"},
    {value: "Tibet", text: "Tibet"},
    {value: "Tiger", text: "Tiger"},
    {value: "Transavia", text: "Transavia"},
    {value: "TruJet", text: "TruJet"},
    {value: "TUIfly", text: "TUIfly"},
    {value: "Tunisair", text: "Tunisair"},
    {value: "Turkish", text: "Turkish"},
    {value: "Ukraine", text: "Ukraine"},
    {value: "UNI", text: "UNI"},
    {value: "United", text: "United"},
    {value: "Ural", text: "Ural"},
    {value: "US Airways", text: "US Airways"},
    {value: "UTair", text: "UTair"},
    {value: "Uzbekistan", text: "Uzbekistan"},
    {value: "Vietjet", text: "Vietjet"},
    {value: "Vietnam", text: "Vietnam"},
    {value: "Virgin Atlanti", text: "Virgin Atlanti"},
    {value: "Virgin Austral", text: "Virgin Austral"},
    {value: "Vistara", text: "Vistara"},
    {value: "Viva Aerobus", text: "Viva Aerobus"},
    {value: "Viva Columbia", text: "Viva Columbia"},
    {value: "Volaris", text: "Volaris"},
    {value: "Volotea", text: "Volotea"},
    {value: "Vueling", text: "Vueling"},
    {value: "WestJet", text: "WestJet"},
    {value: "Wizz", text: "Wizz"},
    {value: "WOW", text: "WOW"},
    {value: "Xiamen", text: "Xiamen"},
    {value: "XL", text: "XL"},
    {value: "Zav", text: "Zav"},
    {value: "Zoom", text: "Zoom"},
    {value: "New Zealand", text: "New Zealand"},                                      
  ];
    
  export const TravelClasses= [
    {value: "", text: 'Please select',},
    {value: 'Economy', text: 'Economy'},
    {value: 'Premium Economy', text: "Premium Economy"},        
    {value: 'Business', text: "Business"},                                      
    {value: 'First', text: "First"},                                      
    {value: 'Suite', text: "Suite"},                                      
    ];
  
  export const PreferredSeatings = [
      {value: "", text: 'Please select',},
      {value: 'Forward', text: 'Forward'},
      {value: 'Aisle', text: "Aisle"},        
      {value: 'Window', text: "Window"},                                    
      {value: 'None', text: "None"}, 
      {value: 'Add Seat Number.', text: "Add Seat Number."},                                      
  ];
  
  export const MealRequest = [
    {value: "", text: 'Please select',},
    {value: 'BBML - baby', text: 'BBML - baby'},
    {value: 'BLML - bland', text: "BLML - bland"},        
    {value: 'DBML - diabetic', text: "DBML - diabetic"},                                      
    {value: 'FPML - fruit platter', text: "FPML - fruit platter"},                                      
    {value: 'GFML - gluten-free"', text: "GFML - gluten-free"}, 
    {value: 'HFML - high fibre"', text: "HFML - high fibre"}, 
    {value: 'HNML - Hindu', text: 'HNML - Hindu'},
    {value: 'KSML - kosher', text: 'KSML - kosher'},
    {value: 'LCML - low calorie', text: 'LCML - low calorie'},
    {value: 'LFML - low fat/cholesterol', text: 'LFML - low fat/cholesterol'},
    {value: 'LPML - low protein', text: 'LPML - low protein'},
    {value: 'LSML - low sodium', text: 'LSML - low sodium'},
    {value: 'MOML - Muslim', text: 'MOML - Muslim'},
    {value: 'NLML - non-lactose', text: 'NLML - non-lactose'},
    {value: 'ORML - Oriental', text: 'ORML - Oriental'},
    {value: 'RVML -  raw vegetarian', text: 'RVML -  raw vegetarian'},
    {value: 'SFML - seafood', text: 'SFML - seafood'},
    {value: 'SPML -  special', text: 'SPML -  special'},
    {value: 'TFML - fruit plate', text: 'TFML - fruit plate'},
    {value: 'VGML - vegetarian (Vegan)', text: 'VGML - vegetarian (Vegan)'},
    {value: 'VLML - vegetarian lacto-ovo (allows milk and eggs)', text: 'VLML - vegetarian lacto-ovo (allows milk and eggs)'},
    {value: 'Other', text: 'Other'},                                     
  ];
  
  export const carRentals = [
    {value: "", text: 'Please select',},
    {value: 'Alamo', text: 'Alamo'},
    {value: 'Apollo', text: 'Apollo'},        
    {value: 'Avis', text: 'Avis'},
    {value: 'Bargain', text: 'Bargain'},
    {value: 'Britz', text: 'Britz'},
    {value: 'Budget', text: 'Budget'},
    {value: 'Delta', text: 'Delta'},                                                                       
    {value: 'Dollar', text: 'Dollar'},
    {value: 'Enterprise', text: 'Enterprise'},
    {value: 'Europcar', text: 'Europcar'},
    {value: 'Hertz', text: 'Hertz'},
    {value: 'Jucy', text: 'Jucy'},
    {value: 'KEA', text: 'KEA'},
    {value: 'Keddy', text: 'Keddy'},
    {value: 'Maui', text: 'Maui'},
    {value: 'National', text: 'National'},
    {value: 'Sixt', text: 'Sixt'},
    {value: 'Spaceships', text: 'Spaceships'},
    {value: 'Thrifty', text: 'Thrifty'},
    {value: 'Travellers', text: 'Travellers'},
    {value: 'Other', text: 'Other'},
  ];
  
  export const hotelChain  = [
    {value: "", text: 'Please select',},
    {value: 'Accor', text: 'Accor'},
    {value: 'Aman Resorts', text: "Aman Resorts"},        
    {value: 'APA Group"', text: "APA Group"},   
    {value: 'Ascott Limited (The)"', text: "Ascott Limited (The)"},                                   
    {value: 'Avari Hotels', text: "Avari Hotels"},                                      
    {value: 'Banyan Tree Holdings', text: "Banyan Tree Holdings"}, 
    {value: 'Barrière', text: "Barrière"},  
    {value: 'Belmond Limited', text: "Belmond Limited"},
    {value: 'Best Western Hotels', text: "Best Western Hotels"},
    {value: 'Blackstone Group (The)', text: "Blackstone Group (The)"},
    {value: 'BTG Homeinn hotel group', text: "BarrBTG Homeinn hotel groupière"},
    {value: 'Centara Hotels & Resorts', text: "Centara Hotels & Resorts"},
    {value: 'China Lodging Group', text: "China Lodging Group"},
    {value: 'Choice Hotels International', text: "Choice Hotels International"},
    {value: 'Dalata Hotel Group[25]', text: "Dalata Hotel Group[25]"},
    {value: 'Dedica Anthology Hotels (The)', text: "Dedica Anthology Hotels (The)"},                                    
    {value: 'Dorchester Collection', text: "Dorchester Collection"},
    {value: 'Drury Hotels', text: "Drury Hotels"},
    {value: 'Dusit Thani Group', text: "Dusit Thani Group"},
    {value: 'Elite Hotels of Sweden', text: "Elite Hotels of Sweden"},
    {value: 'Extended Stay America', text: "Extended Stay America"},
    {value: 'First Hotels', text: "First Hotels"},
    {value: 'Four Seasons Hotels and Resorts', text: "Four Seasons Hotels and Resorts"},
    {value: 'General Hotel Management (GHM)', text: "General Hotel Management (GHM)"},
    {value: 'GreenTree Inns', text: "GreenTree Inns"},
    {value: 'Hilton Worldwide', text: "Hilton Worldwide"},
    {value: 'Hongkong and Shanghai Hotels', text: "Hongkong and Shanghai Hotels"},
    {value: 'Hoshino Resorts', text: "Hoshino Resorts"},
    {value: 'Hyatt Hotels Corporation', text: "Hyatt Hotels Corporation"},
    {value: 'Indian Hotels Company Limited (IHCL)', text: "Indian Hotels Company Limited (IHCL)"},
    {value: 'InterContinental Hotels Group (IHG)', text: "InterContinental Hotels Group (IHG)"},
    {value: 'Interstate Hotels & Resorts', text: 'Interstate Hotels & Resorts'},
    {value: 'InTown Suites', text: 'InTown Suites'},
    {value: 'Jinjiang International', text: 'Jinjiang International'},
    {value: 'Jumeirah', text: 'Jumeirah'},
    {value: 'Kempinski', text: 'Kempinski'},
    {value: 'Langham Hospitality Group', text: 'Langham Hospitality Group'},
    {value: 'Lifestyle Holidays Group', text: 'Lifestyle Holidays Group'},
    {value: 'Ligula Hospitality Group', text: 'Ligula Hospitality Group'},
    {value: 'Loews Hotels', text: 'Loews Hotels'},
    {value: 'Lotte Hotels & Resorts', text: 'Lotte Hotels & Resorts'},
    {value: 'Magnuson Hotels', text: 'Magnuson Hotels'},
    {value: 'Mandarin Oriental Hotel Group', text: 'Mandarin Oriental Hotel Group'},
    {value: 'Marriott International', text: 'Marriott International'},
    {value: 'Meliá Hotels International', text: 'Meliá Hotels International'},
    {value: 'MGM Resorts International', text: 'MGM Resorts International'},
    {value: 'Millennium & Copthorne Hotels', text: 'Millennium & Copthorne Hotels'},
    {value: 'Minor Hotels', text: 'Minor Hotels'},
    {value: 'NH Hotel Group[58]', text: 'NH Hotel Group[58]'},
    {value: 'Nordic Choice Hotels', text: 'Nordic Choice Hotels'},
    {value: 'Oberoi Group (The)', text: 'Oberoi Group (The)'},
    {value: 'Okura Nikko Hotel Management[60]', text: 'Okura Nikko Hotel Management[60]'},
    {value: 'Omni Hotels & Resorts', text: 'Omni Hotels & Resorts'},
    {value: 'OYO', text: 'OYO'},
    {value: 'Pan Pacific Hotels and Resorts', text: 'Pan Pacific Hotels and Resorts'},
    {value: 'Prince Hotels', text: 'Prince Hotels'},
    {value: 'Principal Hotel Company', text: 'Principal Hotel Company'},
    {value: 'Radisson Hotel Group', text: 'Radisson Hotel Group'},
    {value: 'Red Lion Hotels Corporation', text: 'Red Lion Hotels Corporation'},
    {value: 'Red Roof Inn', text: 'Red Roof Inn'},
    {value: 'RIU Hotels & Resorts', text: 'RIU Hotels & Resorts'},
    {value: 'Rocco Forte Hotels', text: 'Rocco Forte Hotels'},
    {value: 'Rosewood Hotel Group', text: 'Rosewood Hotel Group'},
    {value: 'Scandic Hotels', text: 'Scandic Hotels'},
    {value: 'Shangri-La Hotels and Resorts', text: 'Shangri-La Hotels and Resorts'},
    {value: 'Shilo Inns', text: 'Shilo Inns'},
    {value: 'Soneva', text: 'Soneva'},
    {value: 'Tokyu Hotels', text: 'Tokyu Hotels'},
    {value: 'Toyoko Inn', text: 'Toyoko Inn'},
    {value: 'Travelodge', text: 'Travelodge'},
    {value: 'Treebo', text: 'Treebo'},
    {value: 'Walt Disney Parks and Resorts', text: 'Walt Disney Parks and Resorts'},
    {value: 'Warwick Hotels and Resorts', text: 'Warwick Hotels and Resorts'},
    {value: 'Westgate Resorts', text: 'Westgate Resorts'},
    {value: 'Whitbread', text: 'Whitbread'},
    {value: 'Wyndham Hotels and Resorts', text: 'Wyndham Hotels and Resorts'},
    {value: 'Other', text: 'Other'},
  ];
  
  export const stores = [
    {value: "STA Travel Reisebüro Wien Neubaugasse", text: "STA Travel Reisebüro Wien Neubaugasse"},
    {value: "STA Travel Reisebüro Wien Campus", text: "STA Travel Reisebüro Wien Campus"},
    {value: "STA Travel Reisebüro Innsbruck", text: "STA Travel Reisebüro Innsbruck"},
    {value: "STA Travel Reisebüro Salzburg", text: "STA Travel Reisebüro Salzburg"},
    {value: "STA Travel Reisebüro Graz", text: "STA Travel Reisebüro Graz"}
  ];
  export const Rating =  [
          { text: 'Please select', value: ""},
          { text: '1 Star', value: '1 Star', code:1 },
          { text: '2 Star', value: '2 Star', code:2 },
          { text: '3 Star', value: '3 Star', code:3 },
          { text: '4 Star', value: '4 Star', code:4 },
          { text: '5 Star', value: '5 Star', code:5 }
      ];
  export const timeZone = [
    { value:""  , text:"Select Timezone"},
    { value:"280"  , text:"UTC -11:00 Pacific/Niue"},
    { value:"31"  , text:"UTC -11:00 Pacific/Pago_Pago"},
    { value:"377"  , text:"UTC -11:00 Pacific/Midway"},
    { value:"286"  , text:"UTC -10:00 Pacific/Tahiti"},
    { value:"119"  , text:"UTC -10:00 Pacific/Rarotonga"},
    { value:"407"  , text:"UTC -10:00 Pacific/Honolulu"},
    { value:"287"  , text:"UTC -09:30 Pacific/Marquesas"},
    { value:"288"  , text:"UTC -09:00 Pacific/Gambier"},
    { value:"406"  , text:"UTC -09:00 America/Adak"},
    { value:"295"  , text:"UTC -08:00 Pacific/Pitcairn"},
    { value:"400"  , text:"UTC -08:00 America/Anchorage"},
    { value:"401"  , text:"UTC -08:00 America/Juneau"},
    { value:"402"  , text:"UTC -08:00 America/Sitka"},
    { value:"403"  , text:"UTC -08:00 America/Metlakatla"},
    { value:"404"  , text:"UTC -08:00 America/Yakutat"},
    { value:"405"  , text:"UTC -08:00 America/Nome"},
    { value:"264"  , text:"UTC -07:00 America/Hermosillo"},
    { value:"265"  , text:"UTC -07:00 America/Tijuana"},
    { value:"106"  , text:"UTC -07:00 America/Creston"},
    { value:"107"  , text:"UTC -07:00 America/Dawson_Creek"},
    { value:"108"  , text:"UTC -07:00 America/Fort_Nelson"},
    { value:"109"  , text:"UTC -07:00 America/Vancouver"},
    { value:"110"  , text:"UTC -07:00 America/Whitehorse"},
    { value:"111"  , text:"UTC -07:00 America/Dawson"},
    { value:"398"  , text:"UTC -07:00 America/Phoenix"},
    { value:"399"  , text:"UTC -07:00 America/Los_Angeles"},
    { value:"261"  , text:"UTC -06:00 America/Mazatlan"},
    { value:"262"  , text:"UTC -06:00 America/Chihuahua"},
    { value:"263"  , text:"UTC -06:00 America/Ojinaga"},
    { value:"275"  , text:"UTC -06:00 America/Managua"},
    { value:"83"  , text:"UTC -06:00 America/Belize"},
    { value:"353"  , text:"UTC -06:00 America/El_Salvador"},
    { value:"100"  , text:"UTC -06:00 America/Regina"},
    { value:"101"  , text:"UTC -06:00 America/Swift_Current"},
    { value:"102"  , text:"UTC -06:00 America/Edmonton"},
    { value:"103"  , text:"UTC -06:00 America/Cambridge_Bay"},
    { value:"104"  , text:"UTC -06:00 America/Yellowknife"},
    { value:"105"  , text:"UTC -06:00 America/Inuvik"},
    { value:"127"  , text:"UTC -06:00 America/Costa_Rica"},
    { value:"396"  , text:"UTC -06:00 America/Denver"},
    { value:"397"  , text:"UTC -06:00 America/Boise"},
    { value:"143"  , text:"UTC -06:00 Pacific/Galapagos"},
    { value:"178"  , text:"UTC -06:00 America/Guatemala"},
    { value:"183"  , text:"UTC -06:00 America/Tegucigalpa"},
    { value:"256"  , text:"UTC -05:00 America/Mexico_City"},
    { value:"257"  , text:"UTC -05:00 America/Cancun"},
    { value:"258"  , text:"UTC -05:00 America/Merida"},
    { value:"259"  , text:"UTC -05:00 America/Monterrey"},
    { value:"260"  , text:"UTC -05:00 America/Matamoros"},
    { value:"266"  , text:"UTC -05:00 America/Bahia_Banderas"},
    { value:"284"  , text:"UTC -05:00 America/Panama"},
    { value:"285"  , text:"UTC -05:00 America/Lima"},
    { value:"77"  , text:"UTC -05:00 America/Eirunepe"},
    { value:"78"  , text:"UTC -05:00 America/Rio_Branco"},
    { value:"95"  , text:"UTC -05:00 America/Atikokan"},
    { value:"96"  , text:"UTC -05:00 America/Winnipeg"},
    { value:"97"  , text:"UTC -05:00 America/Rainy_River"},
    { value:"98"  , text:"UTC -05:00 America/Resolute"},
    { value:"99"  , text:"UTC -05:00 America/Rankin_Inlet"},
    { value:"122"  , text:"UTC -05:00 Pacific/Easter"},
    { value:"126"  , text:"UTC -05:00 America/Bogota"},
    { value:"389"  , text:"UTC -05:00 America/Chicago"},
    { value:"390"  , text:"UTC -05:00 America/Indiana/Tell_City"},
    { value:"391"  , text:"UTC -05:00 America/Indiana/Knox"},
    { value:"392"  , text:"UTC -05:00 America/Menominee"},
    { value:"393"  , text:"UTC -05:00 America/North_Dakota/Center"},
    { value:"394"  , text:"UTC -05:00 America/North_Dakota/New_Salem"},
    { value:"395"  , text:"UTC -05:00 America/North_Dakota/Beulah"},
    { value:"142"  , text:"UTC -05:00 America/Guayaquil"},
    { value:"201"  , text:"UTC -05:00 America/Jamaica"},
    { value:"215"  , text:"UTC -05:00 America/Cayman"},
    { value:"4"  , text:"UTC -04:00 America/Antigua"},
    { value:"5"  , text:"UTC -04:00 America/Anguilla"},
    { value:"296"  , text:"UTC -04:00 America/Puerto_Rico"},
    { value:"46"  , text:"UTC -04:00 America/Aruba"},
    { value:"303"  , text:"UTC -04:00 America/Asuncion"},
    { value:"50"  , text:"UTC -04:00 America/Barbados"},
    { value:"58"  , text:"UTC -04:00 America/St_Barthelemy"},
    { value:"61"  , text:"UTC -04:00 America/La_Paz"},
    { value:"62"  , text:"UTC -04:00 America/Kralendijk"},
    { value:"71"  , text:"UTC -04:00 America/Campo_Grande"},
    { value:"72"  , text:"UTC -04:00 America/Cuiaba"},
    { value:"74"  , text:"UTC -04:00 America/Porto_Velho"},
    { value:"75"  , text:"UTC -04:00 America/Boa_Vista"},
    { value:"76"  , text:"UTC -04:00 America/Manaus"},
    { value:"79"  , text:"UTC -04:00 America/Nassau"},
    { value:"89"  , text:"UTC -04:00 America/Blanc-Sablon"},
    { value:"90"  , text:"UTC -04:00 America/Toronto"},
    { value:"91"  , text:"UTC -04:00 America/Nipigon"},
    { value:"92"  , text:"UTC -04:00 America/Thunder_Bay"},
    { value:"93"  , text:"UTC -04:00 America/Iqaluit"},
    { value:"94"  , text:"UTC -04:00 America/Pangnirtung"},
    { value:"354"  , text:"UTC -04:00 America/Lower_Princes"},
    { value:"357"  , text:"UTC -04:00 America/Grand_Turk"},
    { value:"369"  , text:"UTC -04:00 America/Port_of_Spain"},
    { value:"379"  , text:"UTC -04:00 America/New_York"},
    { value:"380"  , text:"UTC -04:00 America/Detroit"},
    { value:"381"  , text:"UTC -04:00 America/Kentucky/Louisville"},
    { value:"382"  , text:"UTC -04:00 America/Kentucky/Monticello"},
    { value:"383"  , text:"UTC -04:00 America/Indiana/Indianapolis"},
    { value:"128"  , text:"UTC -04:00 America/Havana"},
    { value:"384"  , text:"UTC -04:00 America/Indiana/Vincennes"},
    { value:"385"  , text:"UTC -04:00 America/Indiana/Winamac"},
    { value:"130"  , text:"UTC -04:00 America/Curacao"},
    { value:"386"  , text:"UTC -04:00 America/Indiana/Marengo"},
    { value:"387"  , text:"UTC -04:00 America/Indiana/Petersburg"},
    { value:"388"  , text:"UTC -04:00 America/Indiana/Vevay"},
    { value:"139"  , text:"UTC -04:00 America/Dominica"},
    { value:"140"  , text:"UTC -04:00 America/Santo_Domingo"},
    { value:"412"  , text:"UTC -04:00 America/St_Vincent"},
    { value:"413"  , text:"UTC -04:00 America/Caracas"},
    { value:"414"  , text:"UTC -04:00 America/Tortola"},
    { value:"415"  , text:"UTC -04:00 America/St_Thomas"},
    { value:"162"  , text:"UTC -04:00 America/Grenada"},
    { value:"174"  , text:"UTC -04:00 America/Guadeloupe"},
    { value:"181"  , text:"UTC -04:00 America/Guyana"},
    { value:"185"  , text:"UTC -04:00 America/Port-au-Prince"},
    { value:"211"  , text:"UTC -04:00 America/St_Kitts"},
    { value:"224"  , text:"UTC -04:00 America/St_Lucia"},
    { value:"237"  , text:"UTC -04:00 America/Marigot"},
    { value:"249"  , text:"UTC -04:00 America/Martinique"},
    { value:"251"  , text:"UTC -04:00 America/Montserrat"},
    { value:"14"  , text:"UTC -03:00 Antarctica/Palmer"},
    { value:"15"  , text:"UTC -03:00 Antarctica/Rothera"},
    { value:"19"  , text:"UTC -03:00 America/Argentina/Buenos_Aires"},
    { value:"20"  , text:"UTC -03:00 America/Argentina/Cordoba"},
    { value:"21"  , text:"UTC -03:00 America/Argentina/Salta"},
    { value:"22"  , text:"UTC -03:00 America/Argentina/Jujuy"},
    { value:"23"  , text:"UTC -03:00 America/Argentina/Tucuman"},
    { value:"24"  , text:"UTC -03:00 America/Argentina/Catamarca"},
    { value:"25"  , text:"UTC -03:00 America/Argentina/La_Rioja"},
    { value:"26"  , text:"UTC -03:00 America/Argentina/San_Juan"},
    { value:"27"  , text:"UTC -03:00 America/Argentina/Mendoza"},
    { value:"28"  , text:"UTC -03:00 America/Argentina/San_Luis"},
    { value:"29"  , text:"UTC -03:00 America/Argentina/Rio_Gallegos"},
    { value:"30"  , text:"UTC -03:00 America/Argentina/Ushuaia"},
    { value:"59"  , text:"UTC -03:00 Atlantic/Bermuda"},
    { value:"64"  , text:"UTC -03:00 America/Belem"},
    { value:"65"  , text:"UTC -03:00 America/Fortaleza"},
    { value:"66"  , text:"UTC -03:00 America/Recife"},
    { value:"67"  , text:"UTC -03:00 America/Araguaina"},
    { value:"68"  , text:"UTC -03:00 America/Maceio"},
    { value:"69"  , text:"UTC -03:00 America/Bahia"},
    { value:"70"  , text:"UTC -03:00 America/Sao_Paulo"},
    { value:"73"  , text:"UTC -03:00 America/Santarem"},
    { value:"85"  , text:"UTC -03:00 America/Halifax"},
    { value:"86"  , text:"UTC -03:00 America/Glace_Bay"},
    { value:"87"  , text:"UTC -03:00 America/Moncton"},
    { value:"88"  , text:"UTC -03:00 America/Goose_Bay"},
    { value:"350"  , text:"UTC -03:00 America/Paramaribo"},
    { value:"120"  , text:"UTC -03:00 America/Santiago"},
    { value:"121"  , text:"UTC -03:00 America/Punta_Arenas"},
    { value:"408"  , text:"UTC -03:00 America/Montevideo"},
    { value:"154"  , text:"UTC -03:00 Atlantic/Stanley"},
    { value:"164"  , text:"UTC -03:00 America/Cayenne"},
    { value:"171"  , text:"UTC -03:00 America/Thule"},
    { value:"84"  , text:"UTC -02:30 America/St_Johns"},
    { value:"294"  , text:"UTC -02:00 America/Miquelon"},
    { value:"63"  , text:"UTC -02:00 America/Noronha"},
    { value:"168"  , text:"UTC -02:00 America/Godthab"},
    { value:"177"  , text:"UTC -02:00 Atlantic/South_Georgia"},
    { value:"129"  , text:"UTC -01:00 Atlantic/Cape_Verde"},
    { value:"209"  , text:"UTC +14:00 Pacific/Kiritimati"},
    { value:"363"  , text:"UTC +13:00 Pacific/Fakaofo"},
    { value:"367"  , text:"UTC +13:00 Pacific/Tongatapu"},
    { value:"419"  , text:"UTC +13:00 Pacific/Apia"},
    { value:"208"  , text:"UTC +13:00 Pacific/Enderbury"},
    { value:"282"  , text:"UTC +12:45 Pacific/Chatham"},
    { value:"9"  , text:"UTC +12:00 Antarctica/McMurdo"},
    { value:"279"  , text:"UTC +12:00 Pacific/Nauru"},
    { value:"281"  , text:"UTC +12:00 Pacific/Auckland"},
    { value:"333"  , text:"UTC +12:00 Asia/Kamchatka"},
    { value:"334"  , text:"UTC +12:00 Asia/Anadyr"},
    { value:"370"  , text:"UTC +12:00 Pacific/Funafuti"},
    { value:"378"  , text:"UTC +12:00 Pacific/Wake"},
    { value:"153"  , text:"UTC +12:00 Pacific/Fiji"},
    { value:"418"  , text:"UTC +12:00 Pacific/Wallis"},
    { value:"207"  , text:"UTC +12:00 Pacific/Tarawa"},
    { value:"239"  , text:"UTC +12:00 Pacific/Majuro"},
    { value:"240"  , text:"UTC +12:00 Pacific/Kwajalein"},
    { value:"271"  , text:"UTC +11:00 Pacific/Noumea"},
    { value:"273"  , text:"UTC +11:00 Pacific/Norfolk"},
    { value:"34"  , text:"UTC +11:00 Antarctica/Macquarie"},
    { value:"290"  , text:"UTC +11:00 Pacific/Bougainville"},
    { value:"330"  , text:"UTC +11:00 Asia/Magadan"},
    { value:"331"  , text:"UTC +11:00 Asia/Sakhalin"},
    { value:"332"  , text:"UTC +11:00 Asia/Srednekolymsk"},
    { value:"337"  , text:"UTC +11:00 Pacific/Guadalcanal"},
    { value:"156"  , text:"UTC +11:00 Pacific/Pohnpei"},
    { value:"157"  , text:"UTC +11:00 Pacific/Kosrae"},
    { value:"417"  , text:"UTC +11:00 Pacific/Efate"},
    { value:"33"  , text:"UTC +10:30 Australia/Lord_Howe"},
    { value:"12"  , text:"UTC +10:00 Antarctica/DumontDUrville"},
    { value:"289"  , text:"UTC +10:00 Pacific/Port_Moresby"},
    { value:"35"  , text:"UTC +10:00 Australia/Hobart"},
    { value:"36"  , text:"UTC +10:00 Australia/Currie"},
    { value:"37"  , text:"UTC +10:00 Australia/Melbourne"},
    { value:"38"  , text:"UTC +10:00 Australia/Sydney"},
    { value:"40"  , text:"UTC +10:00 Australia/Brisbane"},
    { value:"41"  , text:"UTC +10:00 Australia/Lindeman"},
    { value:"328"  , text:"UTC +10:00 Asia/Vladivostok"},
    { value:"329"  , text:"UTC +10:00 Asia/Ust-Nera"},
    { value:"155"  , text:"UTC +10:00 Pacific/Chuuk"},
    { value:"179"  , text:"UTC +10:00 Pacific/Guam"},
    { value:"248"  , text:"UTC +10:00 Pacific/Saipan"},
    { value:"39"  , text:"UTC +09:30 Australia/Broken_Hill"},
    { value:"42"  , text:"UTC +09:30 Australia/Adelaide"},
    { value:"43"  , text:"UTC +09:30 Australia/Darwin"},
    { value:"302"  , text:"UTC +09:00 Pacific/Palau"},
    { value:"325"  , text:"UTC +09:00 Asia/Chita"},
    { value:"326"  , text:"UTC +09:00 Asia/Yakutsk"},
    { value:"327"  , text:"UTC +09:00 Asia/Khandyga"},
    { value:"364"  , text:"UTC +09:00 Asia/Dili"},
    { value:"190"  , text:"UTC +09:00 Asia/Jayapura"},
    { value:"203"  , text:"UTC +09:00 Asia/Tokyo"},
    { value:"212"  , text:"UTC +09:00 Asia/Pyongyang"},
    { value:"213"  , text:"UTC +09:00 Asia/Seoul"},
    { value:"45"  , text:"UTC +08:45 Australia/Eucla"},
    { value:"10"  , text:"UTC +08:00 Antarctica/Casey"},
    { value:"267"  , text:"UTC +08:00 Asia/Kuala_Lumpur"},
    { value:"268"  , text:"UTC +08:00 Asia/Kuching"},
    { value:"291"  , text:"UTC +08:00 Asia/Manila"},
    { value:"44"  , text:"UTC +08:00 Australia/Perth"},
    { value:"60"  , text:"UTC +08:00 Asia/Brunei"},
    { value:"324"  , text:"UTC +08:00 Asia/Irkutsk"},
    { value:"341"  , text:"UTC +08:00 Asia/Singapore"},
    { value:"371"  , text:"UTC +08:00 Asia/Taipei"},
    { value:"124"  , text:"UTC +08:00 Asia/Shanghai"},
    { value:"182"  , text:"UTC +08:00 Asia/Hong_Kong"},
    { value:"189"  , text:"UTC +08:00 Asia/Makassar"},
    { value:"244"  , text:"UTC +08:00 Asia/Ulaanbaatar"},
    { value:"246"  , text:"UTC +08:00 Asia/Choibalsan"},
    { value:"247"  , text:"UTC +08:00 Asia/Macau"},
    { value:"11"  , text:"UTC +07:00 Antarctica/Davis"},
    { value:"319"  , text:"UTC +07:00 Asia/Novosibirsk"},
    { value:"320"  , text:"UTC +07:00 Asia/Barnaul"},
    { value:"321"  , text:"UTC +07:00 Asia/Tomsk"},
    { value:"322"  , text:"UTC +07:00 Asia/Novokuznetsk"},
    { value:"323"  , text:"UTC +07:00 Asia/Krasnoyarsk"},
    { value:"361"  , text:"UTC +07:00 Asia/Bangkok"},
    { value:"131"  , text:"UTC +07:00 Indian/Christmas"},
    { value:"416"  , text:"UTC +07:00 Asia/Ho_Chi_Minh"},
    { value:"187"  , text:"UTC +07:00 Asia/Jakarta"},
    { value:"188"  , text:"UTC +07:00 Asia/Pontianak"},
    { value:"206"  , text:"UTC +07:00 Asia/Phnom_Penh"},
    { value:"222"  , text:"UTC +07:00 Asia/Vientiane"},
    { value:"245"  , text:"UTC +07:00 Asia/Hovd"},
    { value:"112"  , text:"UTC +06:30 Indian/Cocos"},
    { value:"243"  , text:"UTC +06:30 Asia/Yangon"},
    { value:"18"  , text:"UTC +06:00 Antarctica/Vostok"},
    { value:"51"  , text:"UTC +06:00 Asia/Dhaka"},
    { value:"318"  , text:"UTC +06:00 Asia/Omsk"},
    { value:"80"  , text:"UTC +06:00 Asia/Thimphu"},
    { value:"125"  , text:"UTC +06:00 Asia/Urumqi"},
    { value:"195"  , text:"UTC +06:00 Indian/Chagos"},
    { value:"205"  , text:"UTC +06:00 Asia/Bishkek"},
    { value:"216"  , text:"UTC +06:00 Asia/Almaty"},
    { value:"217"  , text:"UTC +06:00 Asia/Qyzylorda"},
    { value:"278"  , text:"UTC +05:45 Asia/Kathmandu"},
    { value:"194"  , text:"UTC +05:30 Asia/Kolkata"},
    { value:"226"  , text:"UTC +05:30 Asia/Colombo"},
    { value:"13"  , text:"UTC +05:00 Antarctica/Mawson"},
    { value:"292"  , text:"UTC +05:00 Asia/Karachi"},
    { value:"317"  , text:"UTC +05:00 Asia/Yekaterinburg"},
    { value:"359"  , text:"UTC +05:00 Indian/Kerguelen"},
    { value:"362"  , text:"UTC +05:00 Asia/Dushanbe"},
    { value:"365"  , text:"UTC +05:00 Asia/Ashgabat"},
    { value:"409"  , text:"UTC +05:00 Asia/Samarkand"},
    { value:"410"  , text:"UTC +05:00 Asia/Tashkent"},
    { value:"218"  , text:"UTC +05:00 Asia/Aqtobe"},
    { value:"219"  , text:"UTC +05:00 Asia/Aqtau"},
    { value:"220"  , text:"UTC +05:00 Asia/Atyrau"},
    { value:"221"  , text:"UTC +05:00 Asia/Oral"},
    { value:"254"  , text:"UTC +05:00 Indian/Maldives"},
    { value:"3"  , text:"UTC +04:30 Asia/Kabul"},
    { value:"197"  , text:"UTC +04:30 Asia/Tehran"},
    { value:"2"  , text:"UTC +04:00 Asia/Dubai"},
    { value:"7"  , text:"UTC +04:00 Asia/Yerevan"},
    { value:"283"  , text:"UTC +04:00 Asia/Muscat"},
    { value:"48"  , text:"UTC +04:00 Asia/Baku"},
    { value:"305"  , text:"UTC +04:00 Indian/Reunion"},
    { value:"313"  , text:"UTC +04:00 Europe/Astrakhan"},
    { value:"314"  , text:"UTC +04:00 Europe/Saratov"},
    { value:"315"  , text:"UTC +04:00 Europe/Ulyanovsk"},
    { value:"316"  , text:"UTC +04:00 Europe/Samara"},
    { value:"338"  , text:"UTC +04:00 Indian/Mahe"},
    { value:"163"  , text:"UTC +04:00 Asia/Tbilisi"},
    { value:"253"  , text:"UTC +04:00 Indian/Mauritius"},
    { value:"16"  , text:"UTC +03:00 Antarctica/Syowa"},
    { value:"297"  , text:"UTC +03:00 Asia/Gaza"},
    { value:"298"  , text:"UTC +03:00 Asia/Hebron"},
    { value:"47"  , text:"UTC +03:00 Europe/Mariehamn"},
    { value:"304"  , text:"UTC +03:00 Asia/Qatar"},
    { value:"306"  , text:"UTC +03:00 Europe/Bucharest"},
    { value:"309"  , text:"UTC +03:00 Europe/Moscow"},
    { value:"54"  , text:"UTC +03:00 Europe/Sofia"},
    { value:"310"  , text:"UTC +03:00 Europe/Simferopol"},
    { value:"55"  , text:"UTC +03:00 Asia/Bahrain"},
    { value:"311"  , text:"UTC +03:00 Europe/Volgograd"},
    { value:"312"  , text:"UTC +03:00 Europe/Kirov"},
    { value:"336"  , text:"UTC +03:00 Asia/Riyadh"},
    { value:"82"  , text:"UTC +03:00 Europe/Minsk"},
    { value:"349"  , text:"UTC +03:00 Africa/Mogadishu"},
    { value:"351"  , text:"UTC +03:00 Africa/Juba"},
    { value:"355"  , text:"UTC +03:00 Asia/Damascus"},
    { value:"368"  , text:"UTC +03:00 Europe/Istanbul"},
    { value:"372"  , text:"UTC +03:00 Africa/Dar_es_Salaam"},
    { value:"373"  , text:"UTC +03:00 Europe/Kiev"},
    { value:"374"  , text:"UTC +03:00 Europe/Uzhgorod"},
    { value:"375"  , text:"UTC +03:00 Europe/Zaporozhye"},
    { value:"376"  , text:"UTC +03:00 Africa/Kampala"},
    { value:"132"  , text:"UTC +03:00 Asia/Nicosia"},
    { value:"133"  , text:"UTC +03:00 Asia/Famagusta"},
    { value:"137"  , text:"UTC +03:00 Africa/Djibouti"},
    { value:"144"  , text:"UTC +03:00 Europe/Tallinn"},
    { value:"147"  , text:"UTC +03:00 Africa/Asmara"},
    { value:"151"  , text:"UTC +03:00 Africa/Addis_Ababa"},
    { value:"152"  , text:"UTC +03:00 Europe/Helsinki"},
    { value:"420"  , text:"UTC +03:00 Asia/Aden"},
    { value:"421"  , text:"UTC +03:00 Indian/Mayotte"},
    { value:"176"  , text:"UTC +03:00 Europe/Athens"},
    { value:"192"  , text:"UTC +03:00 Asia/Jerusalem"},
    { value:"196"  , text:"UTC +03:00 Asia/Baghdad"},
    { value:"202"  , text:"UTC +03:00 Asia/Amman"},
    { value:"204"  , text:"UTC +03:00 Africa/Nairobi"},
    { value:"210"  , text:"UTC +03:00 Indian/Comoro"},
    { value:"214"  , text:"UTC +03:00 Asia/Kuwait"},
    { value:"223"  , text:"UTC +03:00 Asia/Beirut"},
    { value:"229"  , text:"UTC +03:00 Europe/Vilnius"},
    { value:"231"  , text:"UTC +03:00 Europe/Riga"},
    { value:"235"  , text:"UTC +03:00 Europe/Chisinau"},
    { value:"238"  , text:"UTC +03:00 Indian/Antananarivo"},
    { value:"1"  , text:"UTC +02:00 Europe/Andorra"},
    { value:"6"  , text:"UTC +02:00 Europe/Tirane"},
    { value:"269"  , text:"UTC +02:00 Africa/Maputo"},
    { value:"270"  , text:"UTC +02:00 Africa/Windhoek"},
    { value:"17"  , text:"UTC +02:00 Antarctica/Troll"},
    { value:"276"  , text:"UTC +02:00 Europe/Amsterdam"},
    { value:"277"  , text:"UTC +02:00 Europe/Oslo"},
    { value:"32"  , text:"UTC +02:00 Europe/Vienna"},
    { value:"293"  , text:"UTC +02:00 Europe/Warsaw"},
    { value:"49"  , text:"UTC +02:00 Europe/Sarajevo"},
    { value:"307"  , text:"UTC +02:00 Europe/Belgrade"},
    { value:"52"  , text:"UTC +02:00 Europe/Brussels"},
    { value:"308"  , text:"UTC +02:00 Europe/Kaliningrad"},
    { value:"56"  , text:"UTC +02:00 Africa/Bujumbura"},
    { value:"335"  , text:"UTC +02:00 Africa/Kigali"},
    { value:"81"  , text:"UTC +02:00 Africa/Gaborone"},
    { value:"339"  , text:"UTC +02:00 Africa/Khartoum"},
    { value:"340"  , text:"UTC +02:00 Europe/Stockholm"},
    { value:"343"  , text:"UTC +02:00 Europe/Ljubljana"},
    { value:"344"  , text:"UTC +02:00 Arctic/Longyearbyen"},
    { value:"345"  , text:"UTC +02:00 Europe/Bratislava"},
    { value:"347"  , text:"UTC +02:00 Europe/San_Marino"},
    { value:"356"  , text:"UTC +02:00 Africa/Mbabane"},
    { value:"114"  , text:"UTC +02:00 Africa/Lubumbashi"},
    { value:"117"  , text:"UTC +02:00 Europe/Zurich"},
    { value:"134"  , text:"UTC +02:00 Europe/Prague"},
    { value:"135"  , text:"UTC +02:00 Europe/Berlin"},
    { value:"136"  , text:"UTC +02:00 Europe/Busingen"},
    { value:"138"  , text:"UTC +02:00 Europe/Copenhagen"},
    { value:"145"  , text:"UTC +02:00 Africa/Cairo"},
    { value:"148"  , text:"UTC +02:00 Europe/Madrid"},
    { value:"149"  , text:"UTC +02:00 Africa/Ceuta"},
    { value:"411"  , text:"UTC +02:00 Europe/Vatican"},
    { value:"159"  , text:"UTC +02:00 Europe/Paris"},
    { value:"422"  , text:"UTC +02:00 Africa/Johannesburg"},
    { value:"167"  , text:"UTC +02:00 Europe/Gibraltar"},
    { value:"423"  , text:"UTC +02:00 Africa/Lusaka"},
    { value:"424"  , text:"UTC +02:00 Africa/Harare"},
    { value:"184"  , text:"UTC +02:00 Europe/Zagreb"},
    { value:"186"  , text:"UTC +02:00 Europe/Budapest"},
    { value:"199"  , text:"UTC +02:00 Europe/Rome"},
    { value:"225"  , text:"UTC +02:00 Europe/Vaduz"},
    { value:"228"  , text:"UTC +02:00 Africa/Maseru"},
    { value:"230"  , text:"UTC +02:00 Europe/Luxembourg"},
    { value:"232"  , text:"UTC +02:00 Africa/Tripoli"},
    { value:"234"  , text:"UTC +02:00 Europe/Monaco"},
    { value:"236"  , text:"UTC +02:00 Europe/Podgorica"},
    { value:"241"  , text:"UTC +02:00 Europe/Skopje"},
    { value:"252"  , text:"UTC +02:00 Europe/Malta"},
    { value:"255"  , text:"UTC +02:00 Africa/Blantyre"},
    { value:"8"  , text:"UTC +01:00 Africa/Luanda"},
    { value:"272"  , text:"UTC +01:00 Africa/Niamey"},
    { value:"274"  , text:"UTC +01:00 Africa/Lagos"},
    { value:"299"  , text:"UTC +01:00 Europe/Lisbon"},
    { value:"300"  , text:"UTC +01:00 Atlantic/Madeira"},
    { value:"57"  , text:"UTC +01:00 Africa/Porto-Novo"},
    { value:"352"  , text:"UTC +01:00 Africa/Sao_Tome"},
    { value:"358"  , text:"UTC +01:00 Africa/Ndjamena"},
    { value:"366"  , text:"UTC +01:00 Africa/Tunis"},
    { value:"113"  , text:"UTC +01:00 Africa/Kinshasa"},
    { value:"115"  , text:"UTC +01:00 Africa/Bangui"},
    { value:"116"  , text:"UTC +01:00 Africa/Brazzaville"},
    { value:"123"  , text:"UTC +01:00 Africa/Douala"},
    { value:"141"  , text:"UTC +01:00 Africa/Algiers"},
    { value:"146"  , text:"UTC +01:00 Africa/El_Aaiun"},
    { value:"150"  , text:"UTC +01:00 Atlantic/Canary"},
    { value:"158"  , text:"UTC +01:00 Atlantic/Faroe"},
    { value:"160"  , text:"UTC +01:00 Africa/Libreville"},
    { value:"161"  , text:"UTC +01:00 Europe/London"},
    { value:"165"  , text:"UTC +01:00 Europe/Guernsey"},
    { value:"175"  , text:"UTC +01:00 Africa/Malabo"},
    { value:"191"  , text:"UTC +01:00 Europe/Dublin"},
    { value:"193"  , text:"UTC +01:00 Europe/Isle_of_Man"},
    { value:"200"  , text:"UTC +01:00 Europe/Jersey"},
    { value:"233"  , text:"UTC +01:00 Africa/Casablanca"},
    { value:"301"  , text:"UTC Atlantic/Azores"},
    { value:"53"  , text:"UTC Africa/Ouagadougou"},
    { value:"342"  , text:"UTC Atlantic/St_Helena"},
    { value:"346"  , text:"UTC Africa/Freetown"},
    { value:"348"  , text:"UTC Africa/Dakar"},
    { value:"360"  , text:"UTC Africa/Lome"},
    { value:"118"  , text:"UTC Africa/Abidjan"},
    { value:"166"  , text:"UTC Africa/Accra"},
    { value:"169"  , text:"UTC America/Danmarkshavn"},
    { value:"170"  , text:"UTC America/Scoresbysund"},
    { value:"172"  , text:"UTC Africa/Banjul"},
    { value:"173"  , text:"UTC Africa/Conakry"},
    { value:"180"  , text:"UTC Africa/Bissau"},
    { value:"198"  , text:"UTC Atlantic/Reykjavik"},
    { value:"227"  , text:"UTC Africa/Monrovia"},
    { value:"242"  , text:"UTC Africa/Bamako"},
    { value:"250"  , text:"UTC Africa/Nouakchott"},
    
  ]  
  
  export const menuCategory: any =[
    {text: "Select Page Category",value: ''},
    {text:'Tennis', value:'Tennis'},
    {text:'Supercars', value:'Supercars'},
    {text:'Rugby Union', value:'Rugby Union'},
    {text:'MotoGP™', value:'MotoGP™'},
    {text:'Horse Racing', value:'Horse Racing'},
    {text:'Isle of Man Road Races', value:'Isle of Man Road Races'},
    {text:'Formula 1®', value:'Formula 1®'},
    {text:'Motorsport', value:'Motorsport'},
    {text:'Special Events', value:'Special Events'},
    {text:'Cricket', value:'Cricket'},
    //{text:'Rugby', value:'Rugby'},
    {text:'Rugby League', value:'Rugby League'},
    {text:'AFL', value:'AFL'},
    {text:'Netball', value:'Netball'},
    {text:'Soccer', value:'Soccer'},
    {text:'Cruise', value:'Cruise'},
  ]
  export const menuCategoryLink: any =[
    {slug:'events/tennis', megaMenuCategory:'Tennis'},
    {slug:'events/v8-supercars', megaMenuCategory:'Supercars'},
    {slug:'events/rwc2023', megaMenuCategory:'Rugby Union'},//events/rugby-league
    {slug:'events/motogp', megaMenuCategory:'MotoGP™'},
    {slug:'events/horse-racing', megaMenuCategory:'Horse Racing'},
    {slug:'events/isle-of-man', megaMenuCategory:'Isle of Man Road Races'},
    {slug:'events/formula-one', megaMenuCategory:'Formula 1®'},
    {slug:'events/motorsport', megaMenuCategory:'Motorsport'},
    {slug:'events/special-events', megaMenuCategory:'Special Events'},
    {slug:'events/cricket', megaMenuCategory:'Cricket'},
    //{slug:'events/rugby', megaMenuCategory:'Rugby'},
    {slug:'events/rugby-league', megaMenuCategory:'Rugby League'},
    {slug:'events/afl', megaMenuCategory:'AFL'},
    {slug:'events/netball', megaMenuCategory:'Netball'},
    {slug:'events/soccer', megaMenuCategory:'Soccer'},
    {slug:'sports-cruises', megaMenuCategory:'Cruise'},
  ]
  export const menuCategoryLinkOtherServer: any =[
    {slug:'', megaMenuCategory:'Tennis'},
    {slug:'', megaMenuCategory:'Supercars'},
    {slug:'', megaMenuCategory:'Rugby Union'},
    {slug:'', megaMenuCategory:'MotoGP™'},
    {slug:'', megaMenuCategory:'Horse Racing'},
    {slug:'', megaMenuCategory:'Isle of Man Road Races'},
    {slug:'', megaMenuCategory:'Formula 1®'},
    {slug:'', megaMenuCategory:'Motorsport'},
    {slug:'', megaMenuCategory:'Special Events'},
    {slug:'', megaMenuCategory:'Cricket'},
    //{slug:'', megaMenuCategory:'Rugby'},
    {slug:'', megaMenuCategory:'Rugby League'},
    {slug:'', megaMenuCategory:'AFL'},
    {slug:'', megaMenuCategory:'Netball'},
    {slug:'', megaMenuCategory:'Soccer'},
    {slug:'', megaMenuCategory:'Cruise'},
  ]
  export const menuMainCategory: any =[
    {text: "Select Page Main Category",value: ''},
    {text:'Travel Packages', value:'sports'},
    {text:'Cruise', value:'Cruise'},
    {text:'Book Online', value:'Book Online'},
    {text:'About', value:'ABOUT'}, 
    {text:'Group Travels', value:'groupTravels'}, 
    {text:'Fan Zone', value:'FanZone'}, 
    {text:'COVID-19 Update', value:'covid19'}, 
  ]
  
  
  export const propTypes = [
    {
      value: "string",
      text: "String"
    },
    {
      value: "number",
      text: "Number"
    },
    {
      value: "boolean",
      text: "Boolean"
    },
    {
      value: "text-area",
      text: "Text Area"
    },
    {
      value: "image",
      text: "Image"
    },
    {
      value: "multiple-images",
      text: "Multiple Images"
    },
    {
      value: "component",
      text: "Component"
    }
  ]
  
  export const eventOptionForEnquire: any =[
    {text: "Select event are you interested in?",value: ''},
    {text:'AFL - ANZAC Day Clash', value:'AFL - ANZAC Day Clash'},
    {text:'Basketball 2022 - All Star Weekend', value:'Basketball 2022 - All Star Weekend'},
    {text:'Cricket 2022 - T20 World Cup', value:'Cricket 2022 - T20 World Cup'},
    {text:'Cricket 2023 - World Cup', value:'Cricket 2023 - World Cup'}, 
    {text:'Cycling 2022 - Tour de France', value:'Cycling 2022 - Tour de France'}, 
    {text:'Experience 2021 - Darry Beattie Tour', value:'Experience 2021 - Darry Beattie Tour'}, 
    {text:'Experience 2021 - Ride Day, Phillip Island', value:'Experience 2021 - Ride Day, Phillip Island'},
    {text: "Experience 2021 - Ride Day, Sydney",value: 'Experience 2021 - Ride Day, Sydney'},
    {text: "Experience 2021 - Ride Day, The Bend",value: 'Experience 2021 - Ride Day, The Bend'},
    {text: "Formula 1 2021 - Australia",value: 'Formula 1 2021 - Australia'},
    {text: "Formula 1 2021 - Singapore",value: 'Formula 1 2021 - Singapore'},
    {text: "Formula 1 2022 - Monaco",value: 'Formula 1 2022 - Monaco'},
    {text: "Golf 2022 - US Masters",value: 'Golf 2022 - US Masters'},
    {text: "Horse Racing 2021 - Birdsville Races",value: 'Horse Racing 2021 - Birdsville Races'},
    {text: "Horse Racing 2021 - Cox Plate",value: 'Horse Racing 2021 - Cox Plate'},
    {text: "Horse Racing 2021 - Darwin Cup",value: 'Horse Racing 2021 - Darwin Cup'},
    {text: "Horse Racing 2021 - Melbourne Cup Carnival",value: 'Horse Racing 2021 - Melbourne Cup Carnival'},
    {text: "Horse Racing 2021 - The Everest",value: 'Horse Racing 2021 - The Everest'},
    {text: "Horse Racing 2022 - Birdsville Races",value: 'Horse Racing 2022 - Birdsville Races'},
    {text: "Horse Racing 2022 - Darwin Cup",value: 'Horse Racing 2022 - Darwin Cup'},
    {text: "Horse Racing 2022 - Melbourne Cup Carnival",value: 'Horse Racing 2022 - Melbourne Cup Carnival'},
    {text: "MotoGP 2021 - Australia",value: 'MotoGP 2021 - Australia'},
    {text: "MotoGP 2022 - Assen",value: 'MotoGP 2022 - Assen'},
    {text: "MotoGP 2022 - Catalunya",value: 'MotoGP 2022 - Catalunya'},
    {text: "MotoGP 2022 - Indonesia",value: 'MotoGP 2022 - Indonesia'},
    {text: "MotoGP 2022 - Japan",value: 'MotoGP 2022 - Japan'},
    {text: "MotoGP 2022 - Malaysia",value: 'MotoGP 2022 - Malaysia'},
    {text: "MotoGP 2022 - Mugello",value: 'MotoGP 2022 - Mugello'},
    {text: "MotoGP 2022 - Thailand",value: 'MotoGP 2022 - Thailand'},
    {text: "Motorsport 2021 - Finke Desert Race",value: 'Motorsport 2021 - Finke Desert Race'},
    {text: "Motorsport 2022 - Bathurst 12 Hour",value: 'Motorsport 2022 - Bathurst 12 Hour'},
    {text: "Motorsport 2022 - Finke Desert Race",value: 'Motorsport 2022 - Finke Desert Race'},
    {text: "Netball 2023 - World Cup",value: 'Netball 2023 - World Cup'},
    {text: "NFL 2022 - Super Bowl",value: 'NFL 2022 - Super Bowl'},
    {text: "Road Racing 2022 - Classic TT",value: 'Road Racing 2022 - Classic TT'},
    {text: "Road Racing 2022 - Isle of Man TT",value: 'Road Racing 2022 - Isle of Man TT'},
    {text: "Road Racing 2023 - Isle of Man TT",value: 'Road Racing 2023 - Isle of Man TT'},
    {text: "Rugby League 2021 - Magic Round",value: 'Rugby League 2021 - Magic Round'},
    {text: "Rugby League 2021 - NRL Grand Final",value: 'Rugby League 2021 - NRL Grand Final'},
    {text: "Rugby League 2021 - Origin Brisbane",value: 'Rugby League 2021 - Origin Brisbane'},
    {text: "Rugby League 2021 - Origin Melbourne",value: 'Rugby League 2021 - Origin Melbourne'},
    {text: "Rugby League 2021 - Origin Sydney",value: 'Rugby League 2021 - Origin Sydney'},
    {text: "Rugby League 2022 - Origin Perth",value: 'Rugby League 2022 - Origin Perth'},
    {text: "Rugby Union 2023 - World Cup",value: 'Rugby Union 2023 - World Cup'},
    {text: "Soccer 2023 - Women's World Cup",value: "Soccer 2023 - Women's World Cup"},
    {text: "Supercars 2021",value: 'Supercars 2021'},
    {text: "Supercars 2021 - Auckland SuperSprint",value: 'Supercars 2021 - Auckland SuperSprint'},
    {text: "Supercars 2021 - Bathurst 1000",value: 'Supercars 2021 - Bathurst 1000'},
    {text: "Supercars 2021 - Darwin Triple Crown",value: 'Supercars 2021 - Darwin Triple Crown'},
    {text: "Supercars 2021 - Gold Coast 500",value: 'Supercars 2021 - Gold Coast 500'},
    {text: "Supercars 2021 - Perth SuperNight",value: 'Supercars 2021 - Perth SuperNight'},
    {text: "Supercars 2021 - Townsville 500",value: 'Supercars 2021 - Townsville 500'},
    {text: "Supercars 2022 - Bathurst 1000",value: 'Supercars 2022 - Bathurst 1000'},
    {text: "Supercars 2022 - Darwin",value: 'Supercars 2022 - Darwin'},
    {text: "Supercars 2022 - Newcastle",value: 'Supercars 2022 - Newcastle'},
    {text: "Tennis 2022 - Australian Open",value: 'Tennis 2022 - Australian Open'},
    {text: "Tennis 2022 - Wimbledon",value: 'Tennis 2022 - Wimbledon'},
  ]
  
  export const editorSettings = {
    plugins: ['print preview paste importcss searchreplace autoresize autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars emoticons'],
    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl',
  }
  
  export const dynamicRouteMaps = [
    {slug: 'events/rugby/world-cup-france-2023', redirect: 'rwc2023'}
  ]
  export default constants;
  