
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
// import $ from "jquery";
import _ from "lodash";
// import moment from "moment";

import PassengersAdults from "@/Module/Global/Components/Sidebar/Passengers-adults.vue";
import Search from "@/Module/Global/Components/Search/Search.vue";
import { applicationName } from "@/plugins/constants"; 
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import DateRepository from "@/Module/Global/Repository/DateRepository.ts";
// import eMap from "@/Module/Global/js/eroam-map.js";
@Component({
  components: {
    // Datepicker,
    // Search,
    // PassengersAdults,
  },
})
export default class TourSearch extends Vue {
  name:any= "TourSearch";
  applicationName: any = applicationName;
  activity = applicationName=="wolo" ? false : true;
  git = applicationName=="wolo" ? true : false;
  isPreferenceModalVisible: boolean = false;
  disabledValue:any = false;
  
  interestOption: any = [
    { value: 0, text: "Select Interest" },
    { value: 1, text: "Golfing" },
    { value: 2, text: "Spa" },
    { value: 3, text: "Climbing" },
  ];
  interest: any = [];
  

  datePicker = {
    disabledDates: {
      to: new Date(Date.now()),
    },
  };

  searchRequest: any = [];
  checkin_date: any = '';

  searching: boolean = false;
  
  // @Watch ('$store.state.search.loginUserInformation') changeView (newValue,oldValue){
  //   if(_.isEmpty(this.$store.state.search.loginUserInformation)){
  //   $("#autocompleteToursOnly").val(""); 
  //   }
  // }
  
  // mounted() {
  //   this.initialSearch();
  //   this.checkin_date = moment()
  //     .add(1, "days")
  //     .toDate();
  //   if(this.applicationName == 'wolo'){
  //     this.disabledValue = true;
  //   }
  // }

  // search() {
  //   if (this.searchRequest.length) {
  //     this.searching = true;
  //     this.$store.dispatch(
  //       "setCheckinDate",
  //       moment(this.checkin_date).format("YYYY-MM-DD")
  //     );

  //     if (this.activity) {
  //       this.$store.dispatch("SetSearchType", "tours");
  //     } else {
  //       this.$store.dispatch("SetSearchType", "tours-git");
  //     }
  //     ApiRequest.getGooglePlaceCityInfo(this.searchRequest)
  //       .then((res) => {
  //         res = res.data;
  //         if (res.code == 200 && res.data) {
  //           this.$store.dispatch("setSelectedCities", res.data);
  //           let routes: any = _.cloneDeep(res.data);
  //           this.$store.dispatch("SetRoute", routes);
  //           this.$store.dispatch("SetInitalRoute", routes);
  //           if (this.activity) {
  //             this.$store.dispatch("SetLoading", 1);
  //             this.$store.dispatch("searchInitiate");
  //             this.$router.push("/tours");
  //           } else {
  //             const route = routes[0];
  //             let gitCityData = {
  //               fromCityId: route.city_id,
  //               toCityId: route.city_id,
  //               gitTourType: 1, // 2 FOR DIRECT Git Tour and 1 FOR ROUND Git Tour For now we have set default 1
  //               legIndex: 0,
  //               gitIndex: 0,
  //               arrivalDate: new DateRepository().convertFromYToD(this.checkin_date),
  //               source_lat: route.lat,
  //               source_lng: route.lng,
  //               destination_lat: route.lat,
  //               destination_lng: route.lng,
  //               searchType: "googleplace",
  //             };
  //             this.$store.dispatch("SetGitTourICity", gitCityData);
  //             this.$router.push({ name: "git-only-list" });
  //           }
  //         }
  //         this.searching = false;
  //       })
  //       .catch((error) => {
  //         this.searching = false;
  //         this.$toasted.error("Something went wrong", {
  //           theme: "toasted-primary",
  //           position: "top-right",
  //           duration: 2000,
  //         });
  //       });
  //   } else {
  //     this.$toasted.error("Please enter place name", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //   }
  // }

  // initialSearch() {
  //   let vm = this;
  //   let input = document.getElementById("autocompleteToursOnly");
  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"],
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     vm.searchRequest = [];
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });

  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length
  //       ? filtered_city_array[0].long_name
  //       : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.searchRequest.push({
  //       ...place[0],
  //       small_image: imageUrl,
  //     });
  //     $("#autocomplete").val("");
  //   });
  // }
}
