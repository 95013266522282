
import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ApiRequest from "@/Module/Global/api/apiService.js";
import _ from "lodash";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    Swiper,
    SwiperSlide,
    Multiselect,
  },
})
export default class ClientApiSelection extends Vue {
  apiSelection: any = "";
  accomodationCheckbox: any = false;
  transportCheckbox: any = false;
  activityCheckbox: any = false;
  transferCheckbox: any = false;
  midofficeCheckbox: any = false;
  carhireCheckbox: any = false;
  cruiseCheckbox: any = false;
  automatedApi: any = false;
  manualApi: any = false;
  accomodationAPI: any = "";
  transportAPI: any = "";
  transferAPI: any = "";
  activityAPI: any = "";
  midofficeAPI: any = "";
  carhireAPI: any = "";
  cruiseAPI: any = "";
  selectAccomadationOption: any = [];
  selectActivityOption: any = [];
  selectTransportOption: any = [];
  selectTransferOption: any = [];
  selectMidOffficeOption: any = [];
  selectCarhireOption: any = [];
  selectCruiseOption: any = [];
  selectedAccomodationAPI: any = [];
  selectedTransportAPI: any = [];
  selectedTransferAPI: any = [];
  selectedActivityAPI: any = [];
  selectedMidofficeAPI: any = [];
  selectedCarhireAPI: any = [];
  selectedCruiseAPI: any = [];
  HUBSPOTOption: any = [];
  HUBSPOTFields: any = [];
  selectedHUBSPOTAPI: any = [];
  HUBSPOTCheckbox: any = false;
  HUBSPOTApiValues: any = [];
  HUBSPOTAPI: any = "";

  selectLiveBookigStatus: any = "no";
  credentialType: any = "jurni";

  apiKey: any = "pk_test_TYooMQauvdEDq54NiTphI7jx";
  apiSecret: any = "sk_test_4eC39HqLyjWDarjtT1zdp7dc";
  apiUrl: any = "https://jsonplaceholder.typicode.com/todos/1";
  apiNewKey: any = "";
  apiNewSecret: any = "";
  apiNewUrl: any = "";
  liveBooking: any = "No";
  credentialTypeAPI: any = "";

  popoverShow: any = false;
  domain_id: any = "";
  accomodationFields = [];
  activityFields = [];
  transportFields = [];
  transferFields = [];
  midofficeFields = [];
  carHireFields = [];
  cruiseFields = [];
  fieldArray: any = [];
  actionType: any = [];
  apiType: any = "";
  accommodationApiValues: any = [];
  transportApiValues: any = [];
  activityApiValues: any = [];
  transferApiValues: any = [];
  midofficeApiValues: any = [];
  carhireApiValues: any = [];
  cruiseApiValues: any = [];

  removeType = "";
  removeFields = "";
  removeIndex = -1;
  pnrFetchValue = "";
  pnroptions: any = "";
  pnrValue: any = "";
  isModalOpen: boolean = false;
  credentialDetail(value, actionType, index) {
    this.actionType = actionType;
    this.apiType = value;

    let apiValues, fields;

    switch (actionType) {
      case "accomodation":
        apiValues = this.accommodationApiValues;
        fields = this.accomodationFields;
        break;
      case "activity":
        apiValues = this.activityApiValues;
        fields = this.activityFields;
        break;
      case "transport":
        apiValues = this.transportApiValues;
        fields = this.transportFields;
        break;
      case "transfer":
        apiValues = this.transferApiValues;
        fields = this.transferFields;
        break;
      case "midoffice":
        apiValues = this.midofficeApiValues;
        fields = this.midofficeFields;
        break;
      case "carhire":
        apiValues = this.carhireApiValues;
        fields = this.carHireFields;
        break;
      case "cruise":
        apiValues = this.cruiseApiValues;
        fields = this.cruiseFields;
        break;
      case "HUBSPOT":
        apiValues = this.HUBSPOTApiValues;
        fields = this.HUBSPOTFields;
        break;
      default:
        return; // Exit if actionType is not recognized
    }

    if (index < apiValues.length) {
      this.credentialType =
        apiValues[index].is_eroam_credential === 0 ? "own" : "jurni";
    }

    this.fieldArray = fields[value]?.fields || [];

    // Show modal only if it's not already open
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.$bvModal.show("credentialModal");
    }
  }
  closeModal() {
    this.isModalOpen = false;
  }

  addAPI(type: any) {
    switch (type) {
      case "accomodation":
        if (this.selectedAccomodationAPI.includes(this.accomodationAPI)) {
          this.$toasted.show(
            this.accomodationAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.accomodationAPI != "")
            this.selectedAccomodationAPI.push(this.accomodationAPI);
        }
        break;
      case "transport":
        if (this.selectedTransportAPI.includes(this.transportAPI)) {
          this.$toasted.show(
            this.transportAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.transportAPI != "")
            this.selectedTransportAPI.push(this.transportAPI);
        }
        break;
      case "transfer":
        if (this.selectedTransferAPI.includes(this.transferAPI)) {
          this.$toasted.show(
            this.transferAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.transferAPI != "")
            this.selectedTransferAPI.push(this.transferAPI);
        }
        break;
      case "activity":
        if (this.selectedActivityAPI.includes(this.activityAPI)) {
          this.$toasted.show(
            this.activityAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.activityAPI != "")
            this.selectedActivityAPI.push(this.activityAPI);
        }
        break;
      case "midoffice":
        if (this.selectedMidofficeAPI.includes(this.activityAPI)) {
          this.$toasted.show(
            this.midofficeAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.midofficeAPI != "")
            this.selectedMidofficeAPI.push(this.midofficeAPI);
        }
        break;
      case "carhire":
        if (this.selectedCarhireAPI.includes(this.carhireAPI)) {
          this.$toasted.show(
            this.carhireAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.carhireAPI != "")
            this.selectedCarhireAPI.push(this.carhireAPI);
        }
        break;
      case "cruise":
        if (this.selectedCruiseAPI.includes(this.cruiseAPI)) {
          this.$toasted.show(
            this.cruiseAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.cruiseAPI != "") this.selectedCruiseAPI.push(this.cruiseAPI);
        }
        break;
      case "HUBSPOT":
        if (this.selectedHUBSPOTAPI.includes(this.HUBSPOTAPI)) {
          this.$toasted.show(
            this.HUBSPOTAPI + ` is already exists in your list`,
            {
              type: "info",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          if (this.HUBSPOTAPI != "")
            this.selectedHUBSPOTAPI.push(this.HUBSPOTAPI);
        }
        break;
    }
  }

  apiDetailModal() {
    this.$bvModal.show("apiDetailModal");
  }

  appyAccountSuccessModal() {
    this.$bvModal.hide("credentialModal");
    this.$bvModal.show("apply-account-success-dialog");
  }

  swiperSliderOption: any = {
    slidesPerView: 4,
    spaceBetween: 0,
    breakpoints: {
      1199: {
        allowTouchMove: false,
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: ".slide-next",
      prevEl: ".slide-prev",
    },
    pagination: false,
  };

  async mounted() {
    await this.getproductFieldInformation();
    await this.getbusinessInfomation();
  }

  PNRFetchOption: any = [];
  get createPNRFetching() {
    this.PNRFetchOption = [];
    this.PNRFetchOption.push({ text: "AMADEUS A.I.R", value: "amadeus_air" });
    _.forEach(this.selectedTransportAPI, (value) => {
      if (value == "SABRE" || value == "AMADEUS")
        this.PNRFetchOption.push({ text: value, value: value.toLowerCase() });
    });
    return this.PNRFetchOption;
  }

  async getbusinessInfomation() {
    let vm = this;
    try {
      let requestData = {
        user_id: vm.$store.state.loginInformation.id,
        organisation_id: vm.$store.state.loginInformation.organisation_id,
        package_id: vm.$store.state.loginInformation.package_id,
        cob_step: 3,
        domain_id: vm.domain_id == "" ? vm.$store.state.domainId : vm.domain_id,
      };
      vm.$store.dispatch("SetLoading", 1);
      const response = await ApiRequest.getbusinessInfomation(requestData);
      if (response && response.data.code == 200) {
        let responses = response.data;
        const products = responses.data.products;
        //products["key"] from the api endpoint
        this.accomodationCheckbox =
          products["Accommodation"] && products["Accommodation"].length > 0;
        this.transportCheckbox =
          products["Transport"] && products["Transport"].length > 0;
        this.activityCheckbox =
          products["Tours & Tickets"] && products["Tours & Tickets"].length > 0;
        this.transferCheckbox =
          products["Transfer"] && products["Transfer"].length > 0;
        this.midofficeCheckbox =
          products["Midoffice"] && products["Midoffice"].length > 0;
        this.carhireCheckbox =
          products["HireCar"] && products["HireCar"].length > 0;
        this.cruiseCheckbox =
          products["Cruise"] && products["Cruise"].length > 0;
        this.HUBSPOTCheckbox =
          products["HUBSPOT"] && products["HUBSPOT"].length > 0;

        this.accommodationApiValues = this.accomodationCheckbox
          ? products["Accommodation"]
          : [];
        this.transportApiValues = this.transportCheckbox
          ? products["Transport"]
          : [];
        this.activityApiValues = this.activityCheckbox
          ? products["Tours & Tickets"]
          : [];
        this.transferApiValues = this.transferCheckbox
          ? products["Transfer"]
          : [];
        this.midofficeApiValues = this.midofficeCheckbox
          ? products["Midoffice"]
          : [];
        this.carhireApiValues = this.carhireCheckbox ? products["HireCar"] : [];
        this.cruiseApiValues = this.cruiseCheckbox ? products["Cruise"] : [];
        this.HUBSPOTApiValues = this.HUBSPOTCheckbox ? products["HUBSPOT"] : [];

        this.selectedAccomodationAPI = this.accomodationCheckbox
          ? _.uniq(_.map(products["Accommodation"], "api_type"))
          : [];
        this.selectedTransportAPI = this.transportCheckbox
          ? _.uniq(_.map(products["Transport"], "api_type"))
          : [];
        this.selectedActivityAPI = this.activityCheckbox
          ? _.uniq(_.map(products["Tours & Tickets"], "api_type"))
          : [];
        this.selectedTransferAPI = this.transferCheckbox
          ? _.uniq(_.map(products["Transfer"], "api_type"))
          : [];
        this.selectedMidofficeAPI = this.midofficeCheckbox
          ? _.uniq(_.map(products["Midoffice"], "api_type"))
          : [];
        this.selectedCarhireAPI = this.carhireCheckbox
          ? _.uniq(_.map(products["HireCar"], "api_type"))
          : [];
        this.selectedCruiseAPI = this.cruiseCheckbox
          ? _.uniq(_.map(products["Cruise"], "api_type"))
          : [];
        this.selectedHUBSPOTAPI = this.HUBSPOTCheckbox
          ? _.uniq(_.map(products["HUBSPOT"], "api_type"))
          : [];

        _.each(this.selectedAccomodationAPI, (api) => {
          const product: any = _.findLast(products["Accommodation"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.accomodationFields[api]) {
            _.each(this.accomodationFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedTransportAPI, (api) => {
          const product: any = _.findLast(products["Transport"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.transportFields[api]) {
            _.each(this.transportFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedActivityAPI, (api) => {
          const product: any = _.findLast(products["Tours & Tickets"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.activityFields[api]) {
            _.each(this.activityFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedTransferAPI, (api) => {
          const product: any = _.findLast(products["Transfer"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.transferFields[api]) {
            _.each(this.transferFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedMidofficeAPI, (api) => {
          const product: any = _.findLast(products["Midoffice"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.midofficeFields[api]) {
            _.each(this.midofficeFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedCarhireAPI, (api) => {
          const product: any = _.findLast(products["HireCar"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.carHireFields[api]) {
            _.each(this.carHireFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedCruiseAPI, (api) => {
          const product: any = _.findLast(products["Cruise"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.cruiseFields[api]) {
            _.each(this.cruiseFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        _.each(this.selectedHUBSPOTAPI, (api) => {
          const product: any = _.findLast(products["HUBSPOT"], {
            api_type: api,
          });
          const apiCredential = product.credentials.api_credential;
          if (this.HUBSPOTFields[api]) {
            _.each(this.HUBSPOTFields[api].fields, (field) => {
              field.value = apiCredential[field.fieldName];
            });
          }
        });
        vm.domain_id = responses.data.domain_id;
        vm.apiSelection =
          responses.data.launch_type == 1 ? "automated" : "manual";
        vm.apiSelection = "manual"; // Temp
        const pnrEnableDisable = response.data.data.pnr_enable_disable
          ? response.data.data.pnr_enable_disable
          : [];
        const defaultPNR = pnrEnableDisable.find(function (pnr) {
          return pnr.default_pnr === true;
        });
        this.pnrFetchValue = defaultPNR ? defaultPNR.pnr_type : null;
        this.pnroptions = _.map(
          response.data.data.pnr_enable_disable,
          (item) => {
            if (item.pnr_type === "sabre") {
              return { value: "sabre", text: "SABRE" };
            } else if (item.pnr_type === "amadeus") {
              return { value: "amadeus", text: "AMADEUS A.I.R" };
            }
          }
        );
        this.pnrValue = this.pnroptions;
        // if(this.$store.state.loginInformation.package_id== 1){
        //     this.apiSelection = 'manual';
        //     this.automatedApi = false;
        //     this.manualApi = true;
        //     this.nextTab();
        // }
        vm.$store.dispatch("SetLoading", 0);
      }
    } catch (err) {
      vm.$store.dispatch("SetLoading", 0);
    }
  }

  async getproductFieldInformation() {
    let vm = this;
    try {
      const response = await ApiRequest.getproductFieldInformation();
      if (response && response.data.code == 200) {
        let responses = response.data;
        vm.accomodationFields = responses.data[0].ACCOMMODATION.ALL.channels;
        let accomodationFieldsOption = Object.keys(
          responses.data[0].ACCOMMODATION.ALL.channels
        );
        vm.selectAccomadationOption.unshift({ text: "Select API", value: "" });
        _.forEach(accomodationFieldsOption, (val) => {
          vm.selectAccomadationOption.push({ text: val, value: val });
        });

        vm.activityFields = responses.data[0].TOURS.ACTIVITIES.channels;
        let activityFieldsOption = Object.keys(
          responses.data[0].TOURS.ACTIVITIES.channels
        );
        vm.selectActivityOption.unshift({ text: "Select API", value: "" });
        _.forEach(activityFieldsOption, (val) => {
          vm.selectActivityOption.push({ text: val, value: val });
        });

        vm.transportFields = responses.data[0].TRANSPORT.FLIGHTS.channels;
        let transportFieldsOption = Object.keys(
          responses.data[0].TRANSPORT.FLIGHTS.channels
        );
        vm.selectTransportOption.unshift({ text: "Select API", value: "" });
        _.forEach(transportFieldsOption, (val) => {
          vm.selectTransportOption.push({ text: val, value: val });
        });

        vm.transferFields = responses.data[0].TRANSFERS.ALL.channels;
        let transferFieldsOption = Object.keys(
          responses.data[0].TRANSFERS.ALL.channels
        );
        vm.selectTransferOption.unshift({ text: "Select API", value: "" });
        _.forEach(transferFieldsOption, (val) => {
          vm.selectTransferOption.push({ text: val, value: val });
        });

        vm.midofficeFields = responses.data[0].MIDOFFICE.ALL.channels;
        let midofficeFieldsOption = Object.keys(
          responses.data[0].MIDOFFICE.ALL.channels
        );
        vm.selectMidOffficeOption.unshift({ text: "Select API", value: "" });
        _.forEach(midofficeFieldsOption, (val) => {
          vm.selectMidOffficeOption.push({ text: val, value: val });
        });
        // if(vm.midofficeFields['TRAMADA']){
        //     if(_.findIndex(vm.midofficeFields['TRAMADA'], ['type', "SELECT"]) == -1){
        //         vm.midofficeFields['TRAMADA'].fields.push({
        //             type: "SELECT",
        //             label: "Select Payment Type",
        //             fieldName: "paymentType",
        //             mandatory: true,
        //             options:[
        //                 {text:'PRE_PAID_GROSS', value:'PRE_PAID_GROSS'},
        //                 {text:'PRE_PAID', value:'PRE_PAID'}
        //             ],
        //             defaultValue: "PRE_PAID"
        //         })
        //     }
        // }

        vm.carHireFields = responses.data[0].HIRECAR.ALL.channels;
        let carhireFieldsOption = Object.keys(
          responses.data[0].HIRECAR.ALL.channels
        );
        vm.selectCarhireOption.unshift({ text: "Select API", value: "" });
        _.forEach(carhireFieldsOption, (val) => {
          vm.selectCarhireOption.push({ text: val, value: val });
        });
        vm.cruiseFields = responses.data[0].CRUISE.ALL.channels;
        let cruiseFieldsOption = Object.keys(
          responses.data[0].CRUISE.ALL.channels
        );
        vm.selectCruiseOption.unshift({ text: "Select API", value: "" });
        _.forEach(cruiseFieldsOption, (val) => {
          vm.selectCruiseOption.push({ text: val, value: val });
        });
        vm.HUBSPOTFields = responses.data[0].HUBSPOT.ALL.channels;
        let HUBSPOTFieldsOption = Object.keys(
          responses.data[0].HUBSPOT.ALL.channels
        );
        vm.HUBSPOTOption.unshift({ text: "Select API", value: "" });
        _.forEach(HUBSPOTFieldsOption, (val) => {
          vm.HUBSPOTOption.push({ text: val, value: val });
        });
      }
    } catch (err) {}
  }

  checkConstraint(val: any) {
    return val == "required" ? true : false;
  }

  addProductFields() {
    this.$validator.validateAll().then((valid) => {
      if (valid) {
        if (this.actionType == "accomodation") {
          let hotelRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            hotelRecords = {
              segment_sub_type: "Hotel",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            hotelRecords = {
              segment_sub_type: "Hotel",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.accommodationApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.accommodationApiValues.push(hotelRecords);
          } else {
            this.accommodationApiValues.splice(index, 1, hotelRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "transport") {
          let transportRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            transportRecords = {
              segment_sub_type: "Flight",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            transportRecords = {
              segment_sub_type: "Flight",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.transportApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.transportApiValues.push(transportRecords);
          } else {
            this.transportApiValues.splice(index, 1, transportRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "activity") {
          let activityRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            activityRecords = {
              segment_sub_type:
                this.apiType == "TOURRADAR" ? "Tour" : "ACTIVITIES",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            activityRecords = {
              segment_sub_type:
                this.apiType == "TOURRADAR" ? "Tour" : "ACTIVITIES",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.activityApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.activityApiValues.push(activityRecords);
          } else {
            this.activityApiValues.splice(index, 1, activityRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "transfer") {
          let transferRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            transferRecords = {
              segment_sub_type: "BUS",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            transferRecords = {
              segment_sub_type: "BUS",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.transferApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.transferApiValues.push(transferRecords);
          } else {
            this.transferApiValues.splice(index, 1, transferRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "midoffice") {
          let midofficeRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            midofficeRecords = {
              segment_sub_type: "MIDOFFICE",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            midofficeRecords = {
              segment_sub_type: "MIDOFFICE",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.midofficeApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.midofficeApiValues.push(midofficeRecords);
          } else {
            this.midofficeApiValues.splice(index, 1, midofficeRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "carhire") {
          let carhireRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            carhireRecords = {
              segment_sub_type: "HIRECAR",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            carhireRecords = {
              segment_sub_type: "HIRECAR",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.carhireApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.carhireApiValues.push(carhireRecords);
          } else {
            this.carhireApiValues.splice(index, 1, carhireRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "cruise") {
          let CruiseRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            CruiseRecords = {
              segment_sub_type: "CRUISE",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            CruiseRecords = {
              segment_sub_type: "CRUISE",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.cruiseApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.cruiseApiValues.push(CruiseRecords);
          } else {
            this.cruiseApiValues.splice(index, 1, CruiseRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
        if (this.actionType == "HUBSPOT") {
          let HUBSPOTRecords = null;
          if (this.credentialType == "own") {
            let api_credential = {};
            _.forEach(this.fieldArray, (field: any, key) => {
              api_credential[field.fieldName] = field.value;
            });
            HUBSPOTRecords = {
              segment_sub_type: "HUBSPOT",
              api_type: this.apiType,
              is_eroam_credential: 0,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
              credentials: {
                credentials_type: "Sandbox",
                api_credential: api_credential,
                is_default_api: 0,
              },
            };
            this.$toasted.show(`${this.apiType} api credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          } else {
            HUBSPOTRecords = {
              segment_sub_type: "HUBSPOT",
              api_type: this.apiType,
              is_eroam_credential: 1,
              is_live_eroam_credential: 0,
              is_sandbox_eroam_credential: 0,
              is_use_net_price: 0,
              is_use_gross_price: 0,
              gross_margin: 0,
            };
            this.$toasted.show(`${this.apiType} jurni credential added !!!`, {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          }
          const index = _.findIndex(this.HUBSPOTApiValues, {
            api_type: this.apiType,
          });
          if (index == -1) {
            this.HUBSPOTApiValues.push(HUBSPOTRecords);
          } else {
            this.HUBSPOTApiValues.splice(index, 1, HUBSPOTRecords);
          }
          this.$bvModal.hide("credentialModal");
        }
      }
    });
  }

  nextTab() {
    let vm = this;
    let pnrdata = [
      {
        pnr_type: "sabre",
        default_pnr: vm.pnrFetchValue == "sabre",
        enabled: _.some(vm.pnrValue, { value: "sabre" }),
      },
      {
        pnr_type: "amadeus",
        default_pnr: vm.pnrFetchValue == "amadeus",
        enabled: _.some(vm.pnrValue, { value: "amadeus" }),
      },
    ];
    this.$validator.validate().then((valid) => {
      if (valid) {
        let requestData = {};
        if (vm.apiSelection == "manual") {
          requestData = {
            user_id: vm.$store.state.loginInformation.id,
            cob_step: 3,
            launchType: false, //  true for automated, false for manual
            organisation_id: vm.$store.state.loginInformation.organisation_id,
            domain_id:
              vm.domain_id == "" ? vm.$store.state.domainId : vm.domain_id,
            accommodation: vm.accommodationApiValues,
            transport: vm.transportApiValues,
            toursAndTickets: vm.activityApiValues,
            transfer: vm.transferApiValues,
            midoffice: vm.midofficeApiValues,
            hireCar: vm.carhireApiValues,
            //  pnrFetching:this.pnrFetchValue,
            pnr_enable_disable: pnrdata,
            cruise: vm.cruiseApiValues,
            HUBSPOT: vm.HUBSPOTApiValues,
          };
        } else {
          requestData = {
            user_id: vm.$store.state.loginInformation.id,
            cob_step: 3,
            launchType: true, //  true for automated, false for manual
            organisation_id: vm.$store.state.loginInformation.organisation_id,
            domain_id:
              vm.domain_id == "" ? vm.$store.state.domainId : vm.domain_id,
          };
          if (vm.automatedApi == false || vm.manualApi == false) {
            vm.$toasted.show("Please select jurni terms & conditions", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
            return false;
          }
        }
        vm.$store.dispatch("SetLoading", 1);
        ApiRequest.savebusinessInformation(requestData)
          .then(function (res) {
            if (res.data.code == 200) {
              vm.$store.dispatch("SetLoading", 0);
              if (vm.apiSelection == "manual") {
                vm.$emit("nextTabData", 3);
              } else {
                vm.$emit("finishTab");
              }
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            } else {
              vm.$store.dispatch("SetLoading", 0);
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            }
          })
          .catch(function (error) {
            vm.$store.dispatch("SetLoading", 0);
            vm.$toasted.show("Something went wrong.", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          });
      }
    });
  }

  prevTab() {
    this.$emit("prevTabData", 3);
  }

  pnrRemove(removedOption) {
    if (removedOption.value === this.pnrFetchValue) {
      this.pnrFetchValue = this.pnrValue.length ? this.pnrValue[0].value : "";
    }
  }

  pnrSelect(selectedOption) {
    if (this.pnrValue.length === 1) {
      this.pnrFetchValue = selectedOption.value;
    }
  }

  confirmRemoveAPI(type,fieldType, index) {
    this.removeType = type;
    this.removeFields = fieldType;
    this.removeIndex = index;
    this.$bvModal.show("modal-remove");
  }

  async removeAPI(type, fieldTyp, index) {
  try {
    this.$bvModal.hide("modal-remove");
    this.$store.dispatch("SetLoading", true);
    const apiMappings = {
      accomodation: { fields: this.accomodationFields, selectedAPI: this.selectedAccomodationAPI, apiValues: this.accommodationApiValues },
      transport: { fields: this.transportFields, selectedAPI: this.selectedTransportAPI, apiValues: this.transportApiValues },
      activity: { fields: this.activityFields, selectedAPI: this.selectedActivityAPI, apiValues: this.activityApiValues },
      transfer: { fields: this.transferFields, selectedAPI: this.selectedTransferAPI, apiValues: this.transferApiValues },
      midoffice: { fields: this.midofficeFields, selectedAPI: this.selectedMidofficeAPI, apiValues: this.midofficeApiValues },
      carhire: { fields: this.carHireFields, selectedAPI: this.selectedCarhireAPI, apiValues: this.carhireApiValues },
      cruise: { fields: this.cruiseFields, selectedAPI: this.selectedCruiseAPI, apiValues: this.cruiseApiValues },
      HUBSPOT: { fields: this.HUBSPOTFields, selectedAPI: this.selectedHUBSPOTAPI, apiValues: this.HUBSPOTApiValues }
    };
    const { fields, selectedAPI, apiValues } = apiMappings[type] || {};
    if (!fields || !selectedAPI || !apiValues) throw new Error(`Invalid API type: ${type}`);
    fields[fieldTyp].fields.forEach(field => field.value = '');
    this.fieldArray.forEach(field => field.value = '');
    if (index < apiValues.length && apiValues[index]?.id) {
      await ApiRequest.deleteProductApiCredential({ id: apiValues[index].id });
    }
    selectedAPI.splice(index, 1);
    apiValues.splice(index, 1);
    this.resetProductValues(type, index);
  } catch (err) {
    console.error("Error in removeAPI:", err);
  } finally {
    this.$store.dispatch("SetLoading", false);
  }
}

resetProductValues(type, index) {
  const apiValuesMap = {
    accomodation: this.accommodationApiValues,
    transport: this.transportApiValues,
    activity: this.activityApiValues,
    transfer: this.transferApiValues,
    midoffice: this.midofficeApiValues,
    carhire: this.carhireApiValues,
    cruise: this.cruiseApiValues,
    HUBSPOT: this.HUBSPOTApiValues
  };

  if (apiValuesMap[type] && index < apiValuesMap[type].length) {
    apiValuesMap[type].splice(index, 1);
  }
}

  jurniName: any = "Krunal Parikh";
  jurniCompany: any = "Adventure Travel";
  jurniEmail: any = "krunal.parikh@adventuretravel.com";
  jurniNumber: any = "+91 12345 67890";
  jurniAddress: any =
    "TNW Business Center, Old Padra Rd, above McDonald's, Gokul Society, IOC Nagar, Haripura, Vadodara, Gujarat 390015";

  termsPopup() {
    this.$bvModal.show("termsModal");
  }
}
