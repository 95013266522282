
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
// import PassengersAdults from "@/Module/Global/Components/Sidebar/Passengers-adults.vue";
import { applicationName } from '@/plugins/constants';
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
// import Search from "@/Module/Global/Components/Search/Search.vue";
// import eMap from "@/Module/Global/js/eroam-map.js";
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import commonRepo from "@/Module/Global/Repository/CommonRepository";


@Component({ 
    components: {
        // Datepicker, 
        // Search,
        // PassengersAdults
    }   
})
export default class FlightOneway extends Vue{
    // name: 'FlightSearch';
  flightClassselect: any = 0;
  flightClass: any = [
    { value: 0, text: "Economy" },
    { value: 1, text: "Premium Economy" },
    { value: 2, text: "Business" },
    { value: 3, text: "First" }
  ];
  selected_preference: any = {
    accomodation_property_type: "",
    accomodation_property_class: "",
    transport_type: "",
    transport_class: "",
    nationality: "",
    mationalities: "",
    age_groups: "",
    age_group: "",
    activity_category: "",
  };  
  isPreferenceModalVisible: boolean = false;
  applicationName:any=applicationName;

datePicker = {
    disabledDates: {
      to: new Date(Date.now()),
    },
  };

  searchRequest: any = [];
  cityFrom = null;
  cityTo = null;
  checkin_date: any = '';
  searching: boolean = false;

//  mounted() {
//     this.initialSearchfrom();
//     this.initialSearchTo();
//     this.checkin_date = moment()
//       .add(1, "days")
//       .toDate();
//   }
  
// search() {
//     this.searchRequest = [];
//     if($("#autocompleteTo").val() == '') {  this.cityTo = null; }
//     if($("#autocompleteFrom").val() == '') { this.cityFrom = null; }

//     if (!this.cityFrom) {
//       this.$toasted.error("Enter flying from location", {
//         theme: "toasted-primary",
//         position: "top-right",
//         duration: 2000,
//       });
//       return;
//     }

//     if (!this.cityTo) {
//       this.$toasted.error("Enter destination location", {
//         theme: "toasted-primary",
//         position: "top-right",
//         duration: 2000,
//       });
//       return;
//     }
//     this.searchRequest.push(this.cityFrom, this.cityTo);
//     if (this.searchRequest.length) {
//       this.searching = true;
//       this.setPrefrenceForProductOnly();
//       this.$store.dispatch(
//         "setCheckinDate",
//         moment(this.checkin_date).format("YYYY-MM-DD")
//       );
//       this.$store.dispatch("SetSearchType", "transport");
//       ApiRequest.getGooglePlaceCityInfo(this.searchRequest)
//         .then((res) => {
//           res = res.data;
//           if (res.code == 200 && res.data) {
//             this.$store.dispatch("setSelectedCities", res.data);
//             this.$store.dispatch("SetTransportTypeCall", 1);
//             let routes: any = _.cloneDeep(res.data);
//             routes[0].default_nights=0;
//             routes[1].check_in_date=moment(this.checkin_date).format("YYYY-MM-DD");
//             routes[1].check_out_date=moment(this.checkin_date).format("YYYY-MM-DD");
//             this.$store.dispatch("SetRoute", routes);
//             this.$store.dispatch("SetInitalRoute", routes);
//             this.$store.dispatch("SetLoading", 1);
//             this.$store.dispatch("searchInitiate");
//             this.$router.push("/transports");
//           }
//           this.searching = false;
//         }) 
//         .catch((error) => { console.log(error,'error');
//           this.searching = false;
//           this.$toasted.error("Something went wrong", {
//             theme: "toasted-primary",
//             position: "top-right",
//             duration: 2000,
//           });
//         });
//     }
//     else{
//       this.$toasted.error("Please enter place name", {
//         theme: "toasted-primary",
//         position: "top-right",
//         duration: 2000,
//       });
//     }
//   }
  
//   @Watch ('$store.state.search.loginUserInformation') changeView (newValue,oldValue){
//     if(_.isEmpty(this.$store.state.search.loginUserInformation)){
//     $("#autocompleteFrom").val(""); 
//     $("#autocompleteTo").val("");
//     }
//   }

//   initialSearchfrom() {
//     let vm = this;
//     let input = document.getElementById("autocompleteFrom");
//     let google = eMap.googleObj();
//     let autocomplete = new google.maps.places.SearchBox(input, {
//       types: ["(cities)"],
//     });
//     google.maps.event.addListener(autocomplete, "places_changed", function() {
//       let place = autocomplete.getPlaces();
//       if (place.length == 0) {
//         return;
//       }
//       let lat = place[0].geometry.location.lat();
//       let lng = place[0].geometry.location.lng();
//       let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
//       let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
//       let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
//       let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
//       let filtered_array = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("country");
//       });
//       let filtered_city_array = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("locality");
//       });
//       let filtered_city_array_2 = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("administrative_area_level_2");
//       });

//       let filtered_city_array_1 = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("administrative_area_level_1");
//       });

//       let country_name = filtered_array.length
//         ? filtered_array[0].long_name
//         : "";
//       let city_name = place[0].name
//         ? place[0].name
//         : filtered_city_array.length
//         ? filtered_city_array[0].long_name
//         : place[0].name;

//       let imageUrl = place[0].photos
//         ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
//         : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
//       vm.cityFrom ={
//         ...place[0],
//         small_image: imageUrl,
//       };
//       $("#autocomplete").val("");
//     });
//   }

//    initialSearchTo() {
//     let vm = this;
//     let input = document.getElementById("autocompleteTo");
//     let google = eMap.googleObj();
//     let autocomplete = new google.maps.places.SearchBox(input, {
//       types: ["(cities)"],
//     });
//     google.maps.event.addListener(autocomplete, "places_changed", function() {
//       let place = autocomplete.getPlaces();
//       if (place.length == 0) {
//         return;
//       }
//       let lat = place[0].geometry.location.lat();
//       let lng = place[0].geometry.location.lng();
//       let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
//       let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
//       let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
//       let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
//       let filtered_array = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("country");
//       });
//       let filtered_city_array = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("locality");
//       });
//       let filtered_city_array_2 = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("administrative_area_level_2");
//       });

//       let filtered_city_array_1 = place[0].address_components.filter(function(
//         address_component
//       ) {
//         return address_component.types.includes("administrative_area_level_1");
//       });

//       let country_name = filtered_array.length
//         ? filtered_array[0].long_name
//         : "";
//       let city_name = place[0].name
//         ? place[0].name
//         : filtered_city_array.length
//         ? filtered_city_array[0].long_name
//         : place[0].name;

//       let imageUrl = place[0].photos
//         ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
//         : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
//       vm.cityTo = {
//         ...place[0],
//         small_image: imageUrl,
//       };
//       $("#autocomplete").val("");
//     });
//   }
  
//    setPrefrenceForProductOnly() {
//     let commonRepObj=new commonRepo();
//     let vm = this;
//     commonRepObj.setPrefrenceForProductOnly(vm);
//   }

}
