
import { Component, Vue } from "vue-property-decorator";
import ApiRequest from "@/Module/Global/api/apiService.js";
import _ from "lodash";

@Component
export default class ClientPayment extends Vue {
  paymentGateway: any = true;
  manualGateway: any = false;
  midOfficeAPI: any = true;
  apiEndPoint: any = "";
  username: any = "";
  password: any = "";
  apiToken: any = "";
  companyToken: any = "";
  successUrl: any = "";
  failureUrl: any = "";
  apiType: any = "live";
  apiType_penguin: any = "live";
  apiUrl: any = "";
  bypassPayments_mint = false;
  bypassPayments_penguin = false;
  bypassPayments_manual = false;
  bypassPayments_fnb = false;
  paymentCards: any = ["visa", "mastercard"];
  selectedGateways: any = [];
  warningmsg:any = '';
  segmentType: any = "";
  segmentSubType: any = "";
  paymentSupplier: any = "";
  credentialType: any = "";
  apiKey:any = "";
  supplierOption: any = [
    { text: "Select Supplier", value: "" },
    { text: "Travel Operation", value: "Travel Operation" },
    { text: "csv", value: "csv" },
    { text: "Sabre", value: "Sabre" },
  ];
  domain_id: any = "";
  penguinValue:any=[];
  fnbValue:any=[];
  mintValue:any=[];
  manualValue:any=[];
  manualPaymentType:any=[];
  optionvalue:any=[
    { text: 'Shop', value: {'shop':true} },
    { text: 'Office', value: {'office':true} },
  ]
  penguinOffice:boolean= false;
  penguinShop:boolean = false;
  mintOffice:boolean= false;
  mintShop:boolean = false;
  fnbOffice:boolean= false;
  fnbShop:boolean = false;
  manualOffice:boolean= false;
  manualShop:boolean = false;
  manualOptionType:any=[
  { text: 'Credit Offline', value: {'creditOffline' :true} },
  { text: 'Debit Offline', value: {'debitOffline' :true} },
  { text: 'Cash', value: {'cash':true} },
  { text: 'Bank Deposit', value: {'bankDeposite' :true} },
  { text: 'Invoice', value: {'invoice':true} },
  ]
  altapayUsername:any='';
  altapayApiEndPoint:any='';
  altapayPassword:any='';
  altapayApiType_altapay:any="live";
  altapayShop:boolean = false;
  altapayOffice:boolean = false;
  bypassPayments_altaPay = false;
  altapayPaymentCards:any=["visa", "mastercard"];



  penguinUsername:any='';
  penguinPassword:any='';
  penguinApiEndPoint:any='';
  penguinApiType_penguin:any="live";
  penguinPaymentCards:any=["visa", "mastercard"];
  mounted() {
    this.getpaymentInfomation();
  }

  async getpaymentInfomation() {
    let vm = this;
    try {
      let requestData = {
        user_id: vm.$store.state.loginInformation.id,
        organisation_id: vm.$store.state.loginInformation.organisation_id,
        package_id: vm.$store.state.loginInformation.package_id,
        cob_step: 4,
        domain_id: vm.domain_id == "" ? vm.$store.state.domainId : vm.domain_id,
      };
      const response = await ApiRequest.getbusinessInfomation(requestData);
      if (response && response.data.code == 200) {
        let responses = response.data;
        vm.domain_id = responses.data.domain_id;
        vm.selectedGateways = [];
        if (_.has(responses.data.products, "Online")) {
          _.forEach(
            responses.data.products.Online,
            (payment_gateway, index) => {
              if (payment_gateway.payment_gateway_name == "penguin") {
                vm.selectedGateways.push("penguin");
                vm.bypassPayments_penguin =
                  payment_gateway.is_enabled == 1 ? true :false;
                vm.penguinUsername = payment_gateway.api_credential.username;
                vm.penguinPassword = payment_gateway.api_credential.password;
                vm.penguinApiEndPoint = payment_gateway.api_credential.apiEndPoint;
                vm.penguinApiType_penguin = payment_gateway.mode_type;
                vm.successUrl = payment_gateway.api_credential.success_url;
                vm.failureUrl = payment_gateway.api_credential.failure_url;
                vm.paymentCards = payment_gateway.card_type;
                vm.penguinShop = payment_gateway.api_credential.shop;
                vm.penguinOffice = payment_gateway.api_credential.office 
              } if (payment_gateway.payment_gateway_name == "fnb") {
                vm.selectedGateways.push("fnb");
                vm.bypassPayments_fnb =
                payment_gateway.is_enabled == 1 ? true :false;
                vm.apiKey = payment_gateway.api_credential.apiKey;
                vm.apiType_penguin = payment_gateway.mode_type;
                vm.successUrl = payment_gateway.api_credential.success_url;
                vm.failureUrl = payment_gateway.api_credential.failure_url;
                vm.paymentCards = payment_gateway.card_type;
                vm.fnbShop = payment_gateway.api_credential.shop;
                vm.fnbOffice = payment_gateway.api_credential.office
              } if(payment_gateway.payment_gateway_name == "mint") {
                vm.selectedGateways.push("mint");
                vm.bypassPayments_mint =
                  payment_gateway.is_enabled == 1 ? true :false;
                vm.apiType = payment_gateway.mode_type;
                vm.apiToken = payment_gateway.api_credential.apiToken;
                vm.companyToken = payment_gateway.api_credential.companyToken;
                vm.successUrl = payment_gateway.api_credential.success_url;
                vm.failureUrl = payment_gateway.api_credential.failure_url;
                vm.apiUrl = payment_gateway.api_credential.apiUrl;
                vm.paymentCards = payment_gateway.card_type;
                vm.mintShop = payment_gateway.api_credential.shop;
                vm.mintOffice = payment_gateway.api_credential.office             
                }
                if (payment_gateway.payment_gateway_name == "altapay") {
                vm.selectedGateways.push("altapay");
                vm.bypassPayments_altaPay =
                  payment_gateway.is_enabled == 1 ? true :false;
                vm.altapayUsername = payment_gateway.api_credential.username;
                vm.altapayPassword = payment_gateway.api_credential.password;
                vm.altapayApiEndPoint = payment_gateway.api_credential.apiEndPoint;
                vm.altapayApiType_altapay = payment_gateway.mode_type;
                vm.successUrl = payment_gateway.api_credential.success_url;
                vm.failureUrl = payment_gateway.api_credential.failure_url;
                vm.paymentCards = payment_gateway.card_type;
                vm.altapayShop = payment_gateway.api_credential.shop;
                vm.altapayOffice = payment_gateway.api_credential.office 
              }
            }
          );
        }
        if (_.has(responses.data.products, "Manual")) {
          vm.selectedGateways.push("manual");
          vm.manualShop = responses.data.products.Manual[0].api_credential.shop,
          vm.manualOffice = responses.data.products.Manual[0].api_credential.office
          vm.manualPaymentType = responses.data.products.Manual[0].api_credential.manualPaymentType;
          vm.bypassPayments_manual = responses.data.products.Manual[0].api_credential.bypassPayments_manual == 1 ? true :false;
        }
      }
    } catch (err) {}
  }

  finishTab() {
    let vm = this;
    this.$validator.validate().then((valid) => {
      if (valid) {
        const paymentGateway = [];
        const manualPayment = [];
        if (_.includes(vm.selectedGateways, "mint")) {
          paymentGateway.push({
            id: null,
            paymentGatewayName: "mint",
            api_credentials: {
              apiToken: vm.apiToken,
              companyToken: vm.companyToken,
              success_url: vm.successUrl,
              failure_url: vm.failureUrl,
              apiUrl: vm.apiUrl,
              bypassPayments: vm.bypassPayments_mint == true ? 1 : 0,
              shop:vm.mintShop,
              office:vm.mintOffice
            },
            modeType: vm.apiType,
            cardType: vm.paymentCards,
            enabled: vm.bypassPayments_mint == true ? 1 : 0
          });
        }
        if (_.includes(vm.selectedGateways, "penguin")) {
          paymentGateway.push({
            id: null,
            paymentGatewayName: "penguin",
            api_credentials: {
              apiEndPoint: vm.penguinApiEndPoint,
              username: vm.penguinUsername,
              password: vm.penguinPassword,
              success_url: vm.successUrl,
              failure_url: vm.failureUrl,
              bypassPayments: vm.bypassPayments_penguin == true ? 1:0,
              shop:vm.penguinShop,
              office:vm.penguinOffice
            },
            modeType: vm.penguinApiType_penguin,
            cardType: vm.penguinPaymentCards,
            enabled: vm.bypassPayments_penguin == true ? 1 : 0
          });
        }
        if (_.includes(vm.selectedGateways, "altapay")) {
          paymentGateway.push({
            id: null,
            paymentGatewayName: "altapay",
            api_credentials: {
              apiEndPoint: vm.altapayApiEndPoint,
              username: vm.altapayUsername,
              password: vm.altapayPassword,
              success_url: vm.successUrl,
              failure_url: vm.failureUrl,
              bypassPayments: vm.bypassPayments_altaPay == true ? 1:0,
              shop:vm.altapayShop,
              office:vm.altapayOffice
            },
            modeType: vm.altapayApiType_altapay,
            cardType: vm.altapayPaymentCards,
            enabled: vm.bypassPayments_altaPay == true ? 1 : 0
          });
        }
        if (_.includes(vm.selectedGateways, "fnb")) {
          paymentGateway.push({
            id: null,
            paymentGatewayName: "fnb",
            api_credentials: {
              apiKey: vm.apiKey,
              success_url: vm.successUrl,
              failure_url: vm.failureUrl,
              bypassPayments: vm.bypassPayments_fnb === true ? 1 :0,
              shop:vm.fnbShop,
              office:vm.fnbOffice
            },
            modeType: vm.apiType_penguin,
            cardType: vm.paymentCards,
            enabled: vm.bypassPayments_fnb == true ? 1 : 0
          });
        }
        if (_.includes(vm.selectedGateways, "manual")) {
          manualPayment.push({
            payment_type: [
              "credit_card_offline",
              "debit_card_offline",
              "cash",
              "bank_deposit",
              "invoice",
            ],
            api_credentials: {
              shop:vm.manualShop,
              office:vm.manualOffice,
              manualPaymentType :vm.manualPaymentType,
              bypassPayments_manual: vm.bypassPayments_manual == true ? 1 : 0,
            },
            enabled: vm.bypassPayments_manual == true ? 1 : 0
            
          });
        }
        let requestData = {
          cob_step: 4,
          organisation_id: vm.$store.state.loginInformation.organisation_id,
          domain_id:
            vm.domain_id == "" ? vm.$store.state.domainId : vm.domain_id,
          user_id: vm.$store.state.loginInformation.id,
          paymentGateway,
          manualPayment,
        };
        vm.$store.dispatch("SetLoading", 1);
        ApiRequest.savebusinessInformation(requestData)
          .then(function(res) {
            if (res.data.code == 200) {
              vm.$store.dispatch("SetLoading", 0);
              vm.$emit("finishTab");
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            } else {
              vm.$store.dispatch("SetLoading", 0);
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            }
          })
          .catch(function(error) {
            vm.$store.dispatch("SetLoading", 0);
            vm.$toasted.show("Something went wrong.", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          });
      }
    });
  }
  prevTab() {
    this.$emit("prevTabData", 4);
  }
  checkApiKey(){
    if(!_.isEmpty(this.apiKey)){
    ApiRequest.checkApiKey({ "api_key": this.apiKey }).then((res)=>{
      let response = res.data;
      if(response.code == 404){
        this.warningmsg = response.message;
      }else{
        this.$toasted.show("Something went wrong.", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      }
    })
    .catch(function(error) {
            this.$toasted.show("Something went wrong.", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          });
  }
}
}
