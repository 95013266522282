
// import ApiRequest from "@/Module/Global/api/apiService.js";
import { Validator } from "vee-validate";
import { Component, Vue, Emit } from "vue-property-decorator";
import Authanticated from "@/Module/Global/Services/auth.js";
import { EventBus } from "@/Events.js";
import ApiRequest from "@/Module/Global/api/apiService.js";
import {constants, login_logo, clientName} from "@/plugins/constants"; 
import _ from "lodash";

@Component({})
export default class Login extends Vue {
  isSubmited: boolean = false;
  data: any = { username: "", password: "" };

  userError: any = "";
  forgotObj: any = {
    email: ""
  };
  user_from: number = 1; //Login From Consultant
  forgotflag: Boolean = false;
  loginflag: Boolean = true;
  version_no: String = "2.1.0.3";
  domainId:any = '';
  domainList:any = [];
  packageID: any = '';
  organisationID:any = '';
  mounted() {
    this.getdomainList();
  }
  
  logoPath: string = '';
  logo: string = login_logo ? `https://static-graphics.eroam.com/${clientName}/${login_logo}` : this.logoPath;

  login() {
    let vm = this;
    this.$validator.validate().then(valid => {
      setTimeout(function() {
        vm.loginProcess(valid);
      }, 1000);
    });
  }

  getdomainList(){
    let vm = this;
    ApiRequest.getdomainList()
      .then(function(res:any) {
        res = res.data;
        if (res.code == 200) {
          res.data.push({value: '', text: "Please select domain"})
          vm.domainList = res.data;
        } else {
          vm.domainList = [];
        }
      })
      .catch(function(error:any) {
      });
  }

  setPackage(domainID){
    let domainData = _.find(this.domainList, { value : domainID });
    this.packageID = domainData.package_id;
    this.organisationID = domainData.organisation_id;
    this.$set(this.$store.state.loginInformation, "package_id", domainData.package_id);
    this.$set(this.$store.state.loginInformation, "organisation_id", domainData.organisation_id);
  }

  loginProcess(valid:any) {
    let vm = this;
    if (!valid) {
      return false;
    }
    this.isSubmited = true;
    let params: any = {
      username: vm.data.username,
      password: vm.data.password,
      user_from: vm.user_from
    };
    ApiRequest.login(params)
      .then(function(res) {
        res = res.data;
        vm.$store.dispatch("SetLoading", 0);
        if (res.code == 200) {
          Authanticated.logintoken(res.data);
          let changePasswordPage = res.data.is_acvtive;
            if (changePasswordPage == 0) {
            vm.$router.push('/change-password');
          } else {
            vm.loginDetails();
          }
        } else {
          //vm.userError = res.message;
          vm.$toasted.show(res.message, {
            type: "error",
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000
          });
        }
        vm.isSubmited = false;
      })
      .catch(function(error) {
      });
  }
  loginDetails(){
    let vm =this;
       ApiRequest.loginDetail().then(function(res) {
      res= res.data;
      if(res.code == 200){
          Authanticated.login(res.data);
          vm.$store.dispatch("setLoginInfomationValue", res.data);
          if(res.data.user_type == 'superadmin'){
            vm.$set(vm.$store.state.loginInformation, "package_id", vm.packageID);
            vm.$set(vm.$store.state.loginInformation, "organisation_id", vm.organisationID);
          }
          if(vm.$store.state.loginInformation.user_type=='superadmin'){
            vm.$bvModal.show("login-modal");
            vm.$parent.$emit("login:success");
            EventBus.$emit("loginSuccess", res.data);
            vm.$store.dispatch("SetDomainId", vm.domainId);
            if(vm.domainId!=''){
              vm.$router.push('/');
              location.reload();
            }
          }
          else{
            vm.$parent.$emit("login:success");
            EventBus.$emit("loginSuccess", res.data);
            vm.$store.dispatch("SetDomainId", vm.domainId);
            if(vm.$store.state.loginInformation.user_type!='superadmin' ){
              vm.$router.push('/');
              location.reload();
            }
          }
        } 
          else {
          //vm.userError = res.message;
          vm.$toasted.show(res.message, {
            type: "error",
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000
          });
        }
         vm.isSubmited = false;
    }).catch(function(error) {})
  }
  resetModal() {
    this.data = { username: "", password: "" };
    this.isSubmited = false;
    this.userError = "";
  }

  gotoLogin() {
    this.forgotflag = false;
    this.loginflag = true;
  }

  forgot() {
    this.loginflag = false;
    this.forgotflag = true;
  }

  forgotPassword() {
    var vm = this;
    this.$validator.validate().then(valid => {
      vm.forgotPasswordProcess(valid);
    });
  }

  closeModal() {
    var vm = this;
    vm.$bvModal.hide("login-modal");
  }

  forgotPasswordProcess(valid:any) {
    let vm = this;
    if (!valid) {
      return false;
    }
    ApiRequest.forgotPasswordRequest(this.forgotObj)
      .then(function(res:any) {
        res = res.data;
        if (res.code == 200) {
          vm.$toasted.show(res.message, {
            type: "success",
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000
          });
          vm.gotoLogin();
        } else {
          vm.$toasted.show(res.message, {
            type: "error",
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000
          });
        }
        // vm.isSubmited = false;
      })
      .catch(function(error) {});
  }
}
