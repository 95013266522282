
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import bannerSearch from '@/Module/Global/components/website/widgetSearch/bannerSearch.vue';
    @Component({
        components:{
            bannerSearch
        }
    })
    export default class bannerSearchWrapper extends Vue {
        @Prop() selectedProduct:any;
    }
