export const Deutsche: any = {
  creatingFor: "Reiseroute erstellen für",
  creatingNew: "Reiseroute für neue Kunden erstellen",
  searchTitleItinerary: "Reiseroute",
  searchTitleFlights: "Flüge & Züge",
  searchTitleHotel: "Hotels & BnBs",
  searchTitleTours: "Touren & Tickets",
  searchTitlePackages: "Pakete",
  searchTitleRentalcars: "Mietwagen & Wohnmobile",
  searchTitleTransfers: "Transfers",
  searchTitleCruises: "Kreuzfahrten",
  searchTitleEvents: "Events",
  searchTitleInsurance: "Versicherung",
  searchInstructions:
    "Fügen Sie Ihren Abflugort hinzu und wählen Sie 'Suchen'. Fügen Sie die zusätzlichen Standorte im nächsten Schritt während der Route bestätigen hinzu.",
  searchAddPlaces: "Abflugort",
  searcPlaceholder: "Standorte",
  searchError: "* Bitte wählen Sie zwei Standorte",
  searchBlurb:
    "Erstellen Sie Ihre Reiseroute. Fügen Sie jeden der Orte hinzu, die Sie besuchen möchten, indem Sie Ihr Ziel eingeben und auf Eingabe klicken, um sie hinzuzufügen.",
  consultantProfile: "Beraterprofil",
  consultantSearchCust: "Kunden",
  Searchdata: "Suche",
  consultantSearchBlurb: "Kundenbuchungen suchen",
  consultantSearchCustButton: "Kunden suchen",
  consultantSearchCont: "Kontakt suchen",
  consultantQuick: "Schnellzugriff",
  consultantActive: "Buchungen",
  ActiveData: "Aktiv",
  consultantActiveBlurb: "Liste Ihrer aktiven Buchungen anzeigen",
  consultantActiveButton: "Aktive Buchungen anzeigen",
  consultantPending: "Buchungen",
  PendingData: "Ausstehend",
  consultantPendingBlurb: "Liste Ihrer ausstehenden Buchungen anzeigen",
  consultantPendingButton: "Ausstehende Buchungen anzeigen",
  consultantItineraries: "Aktive Reiserouten",
  consultantCustomers: "Kunden",
  consultantAccount: "Kontoübersicht",
  consultantNotices: "Benachrichtigungen und Notizen",
  consultantTaskList: "Aufgabenliste",
  consultantTaskYours: "Ihre Aufgaben",
  consultantTaskNotFound: "Aufgabe nicht gefunden",
  consultantTaskConfirmCompleteMsg:
    "Möchten Sie wirklich die markierte Aufgabe als erledigt abschliessen",
  consultantCustName: "Kundenname",
  consultantActiveAgency: "Aktive Agenturbuchungen anzeigen",
  consultantPendingAgency: "Ausstehende Agenturbuchungen anzeigen",
  consultantTaskCustName: "Kundenname",
  consultantTaskBookingId: "Buchungs-ID",
  consultantTaskNote: "Notizen",
  consultantTaskDate: "Datum auswählen",
  consultantTaskAddButton: "Aufgabe hinzufügen",
  consultantTaskEditButton: "Aufgabe bearbeiten",
  consultantTaskCompleted: "Abgeschlossen",
  consultantTaskactions: "Aktionen",
  consultantTasksnoozed: "Doesen",
  consultantTasktasks: "Aufgaben",
  consultantTasksNone:
    "Sie scheinen auf dem neuesten Stand zu sein, gut erledigt",
  consultantMidOffice: "Mid Office",
  consultantMidOfficeUpload: "hochlade Daten in Mid Office",
  sidebarHeading: "Route bestätigen",
  siderbarInstructions:
    "Fügen Sie alle Orte von Interesse hinzu und stellen Sie sie in der gewünschten Reihenfolge ein. Wählen Sie an jedem Ort ein Abreisedatum und eine Aufenthaltsdauer aus. Wenn Sie sich für ein vorgewähltes Inventar entscheiden, verfeinern Sie die Ergebnisse über die Inventaroptionen für jeden Standort.",
  sidebarOptionDeparture: "Abflug und Reisende",
  sidebarDepDate: "Abreisedatum",
  sidebarDepSelect:
    "Wählen Sie das Datum aus, an dem der erste Standort verlassen werden soll.",
  sidebarDepError: "Fehler, wählen Sie bitte a Abreisedatum.",
  travellersNumber: "Anzahl der Reisenden",
  siderbarnewInstructions:
    "Alle Sehenswürdigkeiten hinzufügen und in der gewünschten Reihenfolge einstellen. Wählen Sie an jedem Ort ein Abreisedatum und eine Aufenthaltsdauer aus. Wenn Sie sich für ein vorgewähltes Inventar entscheiden, verfeinern Sie die Ergebnisse über die Inventaroptionen für jeden Standort.",
  sidebarnewOptionDeparture: "Abflug und Reisende",
  depDate: "Abreisedatum",
  sidebarnewDepSelect:
    "Wählen Sie das Datum aus, an dem der erste Standort verlassen werden soll.",
  sidebarnewDepError: "Fehler, wählen Sie bitte a Abreisedatum.",
  sidebarTravellersNumber: "Anzahl der Reisenden",
  Rooms: "Zimmer | Räume",
  adults: "Erwachsene",
  children: "Kinder",
  childAgeLimits: "Alter (0-17)",
  Infants: "Kleinkinder",
  infantAgesLimit: "Alter (1 & unter)",
  childAges:
    "Wie alt sind die Kinder, mit denen Sie reisen (zum Zeitpunkt der Abreise)?,",
  ageOfChild: "Alter des Kindes",
  infantConfirm: "Ist das Kind unter einem Jahr (zum Zeitpunkt der Abreise)",
  infantLengthPlural: "Sind es Säuglinge",
  infantLengthSingular: "Ist das Kind",
  infantLengthEnd: "Unter einem Jahr (zum Zeitpunkt der Abreise)",
  infantLapStart: "Werden die",
  infantLapPlural: "Säuglinge",
  infantLapSingular: "Kleinkinder",
  infantLapEnd: "auf dem Schoss des Erwachsenen reisen",
  infantsLap: "wird das Kleinkind auf dem Schoss des Erwachsenen reisen",
  sidebarnewInventoryOps: "Inventar und Standortoptionen",
  sidebarViewTours: "Gruppentouren anzeigen",
  sidebarnewRouteOptions:
    "Bitte wählen Sie aus, welche Optionen Sie in diese Route aufnehmen möchten.",
  siderbarnewAutoPopLoc: "Mit Trendstandorten automatisch füllen.",
  siderbarnewAutoPopInv: "Inventar mit Kundeneinstellungen automatisch füllen.",
  arrivalDate: "Ankunftsdatum",
  confirmRouteButton: "Route bestätigen",
  redirectInventory: "Reiserouteninventar",
  sidebarInventoryAlert:
    "Reiseinventar hinzufügen und entfernen zum I. Reiseroute auf der rechten Seite. </ p> Bitte beachten Sie: Durch Hinzufügen einer mehrtägigen Tour werden die vorherigen Standortdaten gesperrt.",
  redirectActivities: "Aktivitäten auswählen",
  sidebarActivitiesAlert:
    "Inventar innerhalb der Reiseroute verwalten, indem Sie das Segment links auswählen. Um Standorte hinzuzufügen, zu entfernen oder neu zu ordnen, klicken Sie auf die Schaltfläche Karte / Route anzeigen.",
  redirectHotelList: "Hotel auswählen",
  sidebarHotelListAlert:
    "Neues Hotel auswählen aus der Liste. </ p> Sie haben oben die aktuell ausgewählten Hotelfunktionen.",
  redirectHotel: "Wählen Sie Ihr Zimmer",
  sidebarHotelAlert:
    "Wählen Sie Ihr Zimmer, um dieses Hotel zur Reiseroute hinzuzufügen",
  redirectToursList: "Wählen Sie eine Tour",
  sidebarToursListAlert:
    "Wählen Sie eine Tour aus der Liste. </ p> Klicken Sie auf eine Tour, um detailliertere Informationen und Preise anzuzeigen und Abflüge anzuzeigen.",
  redirectTour: "Abflug auswählen",
  sidebarTourAlert:
    "Scrollen Sie nach unten, um eine Liste der Abflugdaten mit ihrem Preis zu finden. </ p> Fügen Sie diese Tour entweder hinzu Ihre Reiseroute oder buchen Sie die Tour direkt",
  redirectTransport: "Transport auswählen",
  sidebarTransportAlert: "",
  checkoutAlert: "Passagierdetails eingeben, um die Buchung abzuschließen",
  checkout: "Kasse",
  redirectPreview: "Reiseroutenvorschau",
  redirectPreviewPDF: "PDF-Vorschau",
  sidebarnewInventoryOptions: "Inventaroptionen",
  errorMsg: "Fehleranzeige hier",
  remove: "Entfernen",
  sidebarnewInclusiveTourButton: "Gruppen-Inklusiv-Tour",
  sidebanewNotes: "Diesen Ort zur Route hinzufügen",
  sidebarnewReturnTripOption: ",Rückreiseoption",
  sidebarnewRoundTripForm:
    "Bitte wählen Sie einen Ursprung und eine Rückkehr aus. Wenn Sie Rückflüge einschließen möchten.",
  to: "Bis",
  night: "Nächte",
  tourDuration: "Dauer",
  contact: "Kontakt",
  checkinDate: "Eincheckdatum",
  checkoutDate: "Auscheckdatum",
  supplierReference: "Lieferant",
  propertyReference: "Buchungsreferenz",
  transport: "Transport",
  service: "Service",
  inventoryTravelClass: "Reiseklasse",
  departureLocation: "Abflugort",
  arrivalAirport: "Ankunftsort",
  status: "Status",
  departuretime: "Abfahrtszeit",
  hotels: "Hotels",
  hotelPricePerNight: "Preis pro Nacht",
  hotelPropertyRating: "Immobilienbewertung",
  searchbyPropertyName: "Suche nach Immobilienname",
  search: "Suche",
  refundtype: "Rückerstattungstyp",
  days: "Tage",
  location: "Ort",
  pernight: "pro Nacht",
  pendingItineraries: "Ausstehende Reiserouten",
  hotelownArrangementPropertyName: "Name der Immobilie *",
  hotelownArrangementPropertyNotes: "Notizen zur Immobilie *",
  validationMsg: "Dieses Feld ist erforderlich.",
  emailValidationMsg:
    "Das Feld die E-Mail-Adresse muss eine gültige E-Mail haben.",
  contactMinValidationMsg:
    "Das Feld Kontaktnummer muss mindestens 10 Zeichen lang sein.",
  contactMaxValidationMsg: "Das Feld Kontaktnummer muss 11 Zeichen lang sein.",
  ownArrangementImage: "Bild",
  ownArrangementHotelimage: "Hotelbild",
  ownArrangementImgClick:
    "Hotelbild klicken oder ziehen hier zum Hochladen von",
  ownArrangementFileTypeMsg:
    "(unterstützter Dateityp .jpeg, .jpg, .png) Objektadresse",
  hotelownArrangementPropertyaddress: "*",
  hotelownArrangementPropertyContact: "Immobilienkontakt-Telefonnummer *",
  hotelownArrangementPropertyemail: "Immobilien-E-Mail *",
  hotelownArrangementDurationDetails: "Details zur Dauer",
  hotelownArrangementPropertyCheckindate: "Check-in Datum *",
  hotelownArrangementPropertyCheckintime: "Check-in-Zeit *",
  hotelownArrangementPropertyCheckouttime: "Objektadresse *",
  hotelownArrangementPropertyCheckoutdate: "Check-out-Datum *",
  ownArrangementPricingDetails: "Preisangaben",
  ownArrangementCurrency: "Währung *",
  hotelownArrangementCurrency: "Kaufwährung *",
  hotelownArrangementPropertyPrice: "Verkaufspreis *",
  hotelownArrangementPropertybookingref: "Buchungsreferenz *",
  ownArrangementPurchasingdetails: "Einkaufsdetails",
  ownArrangementPurchasePrice: "Kaufpreis *",
  hotelownArrangementPropertySupplier: "Lieferant *",
  hotelownArrangementPropertyInvoice:
    "Diese Arrangement-Unterkunftspreise in die endgültige Rechnung aufnehmen.",
  hotelownArrangementPropertyRefund:
    "Dieses eigene Arrangement-Accomodation-Produkt ist erstattbar.",
  cancelButton: "Stornieren",
  addtoitinerary: "Zur Reiseroute hinzufügen",
  ownArrangementBasicDetails: "Grundlegende Hoteldetails",
  activityownArrangementActivityname: "Aktivitätsname *",
  activityownArrangementAddress: "Adresse *",
  activityownArrangementPhnno: "Telefonnummer *",
  activityownArrangementEmail: "E-Mail *",
  activityownArrangementWeburl: "Web-URL",
  activityownArrangementImage: "Bild",
  ownArrangementActivityImgClick:
    "Aktivitätsbild klicken oder ziehen um es hier hochzuladen,",
  activityownArrangementActivityImage: "Aktivitätsbild",
  activityownArrangementSellingPrice: "Verkaufspreis *",
  activityownArrangementSupplierReference: "Lieferant",
  activityownArrangementPurchaseCurrency: "Kaufwährung *",
  activityownArrangementPropertyReference: "Buchungsreferenz *",
  ownArrangementComments: "Kommentare",
  activityownArrangementComments: "Kommentare",
  operator: "Betreiber",
  ownArrangementTravelClass: "Klasse",
  ownArrngementArrivalTime: "Ankunftzeit",
  ownArrangementAirlineReference: "Fluglinienreferenz",
  ownArrangementGdsReference: "GDS-Referenz",
  downloadPDF: "PDF herunterladen",
  expandAll: "Alle erweitern",
  collapseAll: "Alle reduzieren",
  showMore: "Mehr anzeigen",
  showLess: "Weniger anzeigen",
  accommodation: "Unterkunft",
  name: "Name",
  checkIn: "Einchecken",
  checkOut: "Auschecken",
  checkOutDash: "Auschecken",
  price: "Preis",
  PerNight: "Pro Nacht",
  roomDetails: "Zimmerbeschreibung",
  roomType: "Zimmertyp",
  pendingBookingNights: "Nächte",
  nonRefundable: "Nicht erstattungsfähig",
  refundable: "erstattungsfähig",
  ownArrangement: "eigenes Arrangement",
  toursActivities: "Touren / Aktivitäten",
  viewitenaryActivityname: "Aktivitätsname",
  viewitenaryAddress: "Adresse",
  viewitenaryPhone: "Telefon",
  viewitenarySellingPice: "Verkaufspreis",
  email: "E-Mail",
  date: "Datum",
  duration: "Dauer",
  code: "Kode",
  startDate: "Startdatum",
  finishDate: "Enddatum",
  endDate: "Enddatum",
  aud: "AUD",
  view: "Ansicht",
  noDataFound: "Kein Datensatz gefunden",
  notProvided: "Nicht bereitgestellt",
  pendingBookingPage: "X",
  pendingBookingCustomerName: "Kundenname",
  pendingBookingDepdate: "Abr. Datum",
  pendingBookingCreatedOn: "Erstellt am",
  travellers: "Reisende",
  pendingBookingTotalPrice: "Gesamtpreis",
  ownArrangementContactDetails: "Kontaktdaten",
  sortby: "Sortieren nach:",
  titleOfActivity: "Titel",
  activityCode: "Aktivitätscode:",
  viator: "Viator",
  from: "Von",
  perPerson: "pro Person",
  category: "Kategorie",
  sidebarActivityName: "Suche nach Aktivitätsname",
  readmore: "Lesen Sie mehr",
  hotelPickupIncluded: "Hotelabholung inklusive:",
  activityDetaildeparturePoint: "Abflugort:",
  overview: "Übersicht",
  importantInfo: "Wichtige Informationen",
  details: "Details",
  activityDetailInclusions: "Einschlüsse",
  activityDetailExclusions: "Ausschlüsse",
  activityDetailAdditionalinfo: "Zusätzliche Informationen",
  activityDetailVoucherinfo: "Gutscheininformationen",
  activityDetailLocalOperatorInformation: "Lokale Betreiberinformationen",
  activityDetailLocalOperatorInformationlope:
    "Vollständige Betreiberinformationen, einschließlich lokaler Telefonnummern an Ihrem Zielort, sind in Ihrem Bestätigungsgutschein enthalten. Unsere Produktmanager wählen nur die erfahrensten und zuverlässigsten Betreiber in jedem Ziel aus, um das Rätselraten für Sie zu beseitigen und Ihre Sicherheit zu gewährleisten.",
  activityDetailCancellationPolicy: "Stornierungsbedingungen",
  activityDetailScheduleAndPricing: "Zeitplan und Preise",
  activityDetailScheduleAndPricingContent:
    "Unsere Preise werden ständig aktualisiert, um sicherzustellen, dass Sie immer den niedrigstmöglichen Preis erhalten - wir garantieren dies zu 100%.",
  activityDetailBookActivity: "Buchungsaktivität",
  activityDetailDeparts: "Abreise",
  activityDetailPickup: "Abholungabgeholt werden",
  activityDetailCheckedPickup: "Ich muss abgeholt werden hinzufügen",
  activityDetailaddActivity: "Activität hinzufügen",
  activityDetailSpecialRequirements: "Besondere Anforderungen",
  activityDetailSelectLocation: "Wählen Sie einen Standort",
  activityDetailEnterPickupLocation: "Abholort eingeben *",
  noRecordFound: "Kein Datensatz gefunden",
  sidebarTransportCustomerPricing: "Kundenpreisegestaltung",
  sidebarTransportCustomerPricingContent:
    "Reiseroute Preisaufschlüsselung einschließlich Kundenkosten insgesamt und nach PAX.",
  sidebarTransportHeading: "AUD 4562",
  sidebarTransportContent: "Gesamtkosten AUD ink. Tax",
  sidebarTransportPricePerPaxAudincTax: "Preis pro PAX AUD ink. Steuern",
  sidebarTransportCostPerDay: "Kosten pro Tag:",
  sidebarTransportCost: "458,00",
  sidebarTransportTotalCost: "Gesamtkosten:",
  sidebarTransportTotal: "AUD 785",
  sidebarTransportAgencyCost: "Agenturkosten",
  sidebarTransportOne: "Eins",
  sidebarTransportTwo: "Zwei",
  sidebarTransportThree: "Drei",
  sidebarTransportFilters: "Transportfilter",
  sidebarTransportType: "Transportart",
  return: "Rückgabe",
  transportOnlyRates:
    "Deaktivieren Sie die Agentenansicht, um die Einzelhandelspreise für die Kunden anzuzeigen.",
  transportOnlySaveQuotation: "Angebot speichern",
  transportOnlyBookItinerary: "Reiseroute buchen",
  transportOnlyCustomerPricing: "Kundenpreise",
  transportOnlyAgencyCost: "Agenturkosten",
  transportOnlyFilters: "Filter",
  transportOnlyDepartureLocation: "Ablugort",
  transportOnlyDestinationLocation: "Bestimmungsort",
  transportOnlyReturnDate: "Rückreisedatum",
  transportOnlyParistoRome: "Paris nach Rom",
  transportOnlyFilterContent: "Montag, 1. April 2019",
  change: "Wechseln",
  transportOnlyvueling: "Vueling",
  nonStop: "Nonstop",
  departs: "Abflug",
  transportOnlyParisOrlyAirport: "Paris Orly Airport (ORY)",
  transportOnlyArrive: "Ankunft",
  transportOnlyFco: "Rom - Bahnhof Roma Tiburtina (FCO)",
  transportOnlyKiwi: "Kiwi.com",
  transportOnlyMystifly: "Mystifly",
  transportOnlySabre: "Sabre",
  transportOnlyEconomy: "Economy",
  transportOnlySelect: "Aussuchen",
  transportOnlyRouteDetails: "Routendetails",
  transportOnlyCalendar: "Montag, 1. April 2019",
  transportOnlyFlight: "Flug",
  transportOnlyShowDetails: "Details anzeigen",
  transportOnlyParis: "Paris - Gare De Lyon",
  transportOnlyMilan: "Mailand - Zentralstation",
  transportOnlyBaggageDetails: "Gepäckdetails",
  transportOnlyPersonalBaggage: "Persönliches Gepäck",
  transportOnlyFree: "Kostenlos",
  transportOnlyCheckedBaggage: "aufgegebenes Gepäck",
  transportOnlyAddCheckedBaggage: "Aufgegebenes Gepäck hinzufügen",
  transportOnlyAddCabinBaggage: "Hinzufügen von Handgepäck,",
  transportOnlyInFlight: "Flug-Add-Ons,",
  transportOnlyPassengerPricingDetails: "Passagier und Preisangaben,",
  transportOnlyPassenger: "Passagier,",
  transportOnlyonexAdult: "1 x Erwachsener,",
  transportOnlyBaseFare: "Grundpreis,",
  transportOnlyBaggage: "Gepäck,",
  transportOnlyFeesAndTaxes: "Sonstige Gebühren / Steuern,",
  transportOnlyTermsConditions: "Geschäftsbedingungen",
  transportOnlyNetRate: "Nettorate",
  transportOnlyCommision: "Provision",
  checkinTime: "Checkin-Zeit",
  checkoutTime: "Checkout-Zeit",
  searchCustomerName: "Suche nach Kundenname",
  confirmroute: "Route bestätigen",
  logout: "Abmeldung",
  searchbypropertyname: "Suche nach Objektname",
  hotelDetailshotelRoom: "(1 Zimmer)",
  hotelDetailRoomsRates: "Zimmer & Preise",
  hotelDetailAmenities: "Hotelausstattung",
  hotelDetailCheckInInstructions: "Check-In Anweisungen",
  hotelDetailsFeesOptionalExtras: "Gebühren / optionale Extras",
  hotelDetailValueAddedServices: "Mehrwertdienste",
  hotelDetailCheckin: "Einchecken",
  hotelDetailhotelCheckout: "Auschecken:",
  hotelDetailBedPreferencese: "Betten Preferenzen:",
  hotelDetailViewCancellationPolicy: "Stornierungsbedingungen anzeigen",
  hotelDetailRoomDetailAmenities: "Zimmerdetails,",
  hotelDetailRoomAmenities: "Zimmerausstattung",
  hotelDetailGuests: "Gäste:",
  hotelDetailSubtotal: "Zwischensumme:",
  hotelTotal: "Gesamt",
  hotelDetailTaxRecoveryServiceFee: "Steuerrückerstattung & Servicegebühr:",
  hotelDetailSpecialCheckInInstructions: "Spezielle Anweisungen zum Einchecken",
  hotelDetailadditionalInformation: "Zusätzliche Informationen",
  headerLogin: "Anmelden",
  headerLogout: "Abmelden",
  hide: "Ausblenden",
  depart: "Abreise",
  arrive: "Ankunft",
  flightNumber: "Flugnummer",
  routeDetails: "Reiseroutendetails",
  wifi: "WIFI",
  included: "INKLUSIVE",
  airCondition: "Klimaanlage",
  passengerPricingDetails: "Passagier- und Preisdetails",
  supplier: "Lieferant",
  passenger: "Passagier",
  passengers: "Passagiere",
  flightMystifly: "Geheimnisvoll",
  flight2Adult: "2 x Erwachsene",
  baggage: "Gepäck",
  otherFeesTaxes: "Sonstige Gebühren / Steuern",
  free: "KOSTENLOS",
  netRate: "Nettotarif",
  select: "Auswählen",
  floatZero: "0,00",
  doubleZerofloat: "0,00,00",
  tv: "Fernsehen",
  flightEconomy: "Wirtschaft",
  viewMore: "Mehr anzeigen",
  flightOnBoardAddOns: "On-Board-hinzufügen",
  loginSignIn: "Anmelden",
  customerNameOrEmail: "Kundenname oder E-Mail",
  searchBy: "Suchen nach",
  firstName: "Vorname",
  lastName: "Nachname",
  gender: "Geschlecht",
  phone: "Telefon",
  submitButton: "Senden",
  your: "Ihr",
  createdOn: "Erstellt am",
  nights: "Nächte",
  totalPrice: "Gesamtpreis",
  emailUserName: "Eoder Benutzername",
  userName: "Benutzername",
  password: "Passwort",
  enterPassword: "Passwort eingeben",
  enterCustomerName: "Kundenname eingeben",
  taskEnterBookingId: "Buchungs-ID eingeben",
  commission: "Provision",
  costPerDay: "Kosten pro Tag",
  pricePerPAX: "Preis pro PAX",
  totalCost: "Gesamtkosten",
  netCost: "Nettokosten",
  includingTax: "ink. Steuern",
  priceSummary: "Preisübersicht",
  ConsultantSidebarItineraryCustomerCostByPAXMsg:
    "Reiserkosten einschließlich Kundenkosten insgesamt und nach PAX.",
  sidebarHeadingPreviewItinerary: "Vorschau der Reiseroute",
  sidebarHeadingSaveQuotation: "Angebot speichern",
  sidebarHeadingBookItinerary: "Reiseroute buchen",
  sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg:
    "Agentenansicht deaktivieren, um die Einzelhandelspreise für den Kunden anzuzeigen.",
  agentView: "Agentenansicht",
  customerView: "Kundenansicht",
  save: "Speichern",
  travelInsurance: "Reiseversicherung",
  no: "Nein",
  addTravel: "Reise hinzufügen",
  plus: "Plus",
  basic: "Grundlegend",
  inventoryMedicalExpenseTripCancellation: "Krankheitskosten, Reisestornierung",
  inventoryAssistanceServices: "Unterstützung Services",
  inventoryLostBaggageMore: "Verlorenes Gepaeck + Mehr",
  audWithFloating: "AUD 00.00",
  inventoryComparsionAndTerms: "Vergleich und Bedingungen",
  customerCare: "Kundenpflege",
  premium: "Premium",
  inventoryExploreBenefitsOfPayingPremiumMsg:
    "Entdecken Sie die Vorteile der Premiumzahlung",
  previous: "zuvor",
  continue: "fortsetzen",
  saveAndContinue: "Sichern und Fortsetzen",
  BacktoInventory: "Zurück zum Inventar",
  AcceptandContinue: "Akzeptieren und fortfahren",
  busBud: "BusBud",
  edit: "Bearbeiten",
  delete: "Löschen",
  checked: "Aktiviert",
  checkoutBusListINBusAddonMsg: "In Bus-hinzufügen",
  BaseFarePerPax: "Grundpreis (pro Person)",
  onexAdult: "1 x Erwachsene",
  oneRoom: "Zimmer 1",
  CancellationPolicy: "Stornierungsbedingungen",
  expedia: "Expedia",
  roomSleep: "Zimmer schläft",
  checkOutActivityListviator: "Viator",
  checkOutActivityDetails: "Aktivitätsdetail",
  option: "Optio",
  groupInclusiveTourOptions: "Gruppen-Inklusiv-Tour-Optionen",
  matchCurrentRouteMsg:
    "Derzeit gibt es 219 Gruppen Inklusive Touren, die Ihrer aktuellen Route entsprechen.",
  errorDisplayHere: "Fehleranzeige hier",
  tourFilters: "Tourfilter",
  tailorMadeTours: "Maßgeschneiderte Touren",
  tailorMadeOptionsMsg:
    "Bitte wählen Sie die gewünschten Optionen aus mit der die maßgeschneiderten Optionen angezeigt werden.",
  tailorMadeToursItineryMsg:
    "Nur maßgeschneiderte Touren für Reiserouten anzeigen",
  MonthOfDeparture: "Monat der Abreise",
  selectDate: "Datum auswählen",
  destination: "Ziel",
  tourExperience: "Tourerfahrung",
  preferredSupplier: "Bevorzugter Lieferant",
  style: "Stil",
  size: "Größe",
  start: "Start",
  notAvailbale: "N / A",
  inclusions: "Einschlüsse",
  day: "Tag",
  meals: "Mahlzeiten",
  breakfast: "Frühstück",
  lunch: "Mittagessen",
  dinner: "Abendessen",
  tourDetailsNoMealMsg: "An diesem Tag sind keine Mahlzeiten enthalten.",
  locationsToVisit: "Zu besuchende Orte",
  additionalInformation: "Zusätzliche Informationen",
  departureNotFound: "Abflüge nicht gefunden",
  additionalInformationNotFound: "Keine weiteren Informationen verfügbar",
  departureAndPricing: "Abflüge & Preise",
  departing: "Abflug",
  finishing: "Endbearbeitung",
  availability: "Verfügbarkeit",
  totalFrom: "Gesamt ab",
  perAdult: "pro Erwachsenem",
  perChild: "pro Kind",
  departureGuaranteed: "Abreise garantiert",
  onSale: "zum Verkauf",
  onRequest: "auf Anfrage",
  addToRoute: "Zur Route hinzufügen",
  termsConditions: "Allgemeine Geschäftsbedingungen",
  available: "Verfügbar",
  finish: "Fertigstellen",
  stars: "Sterne",
  reviews: "Rezensionen",
  rating: "Bewertung",
  default: "Standard",
  title: "Titel",
  lengthOfTour: "Länge der Tour",
  optionsSelected: "Ausgewählte Optionen",
  tourNotFound: "Tour nicht gefunden",
  validTo: "Gültig bis",
  lodging: "Unterkunft",
  validFrom: "Gültig ab",
  perNight: "Pro Nacht",
  sortBy: "Sortieren nach",
  viewItineraryGITtours: "GIT-Touren",
  inventoryAddNewAccommodation: "Ein anderes Hotel auswählen",
  inventoryAddNewTransport: "Neuen Transport hinzufügen",
  passengersAvoidBoardingComplicationMsg:
    "Alle Namen (Vor- und Nachname) genau so verwenden, wie sie im Reisepass / Ausweis angegeben sind, um Komplikationen beim Einsteigen zu vermeiden.",
  passengersRequiredFlightSegmentMsg:
    "Erforderlich für das internationale Flugsegment.",
  dateOfBirth: "Geburtsdatum",
  passengersTourSpecificField: "Reisespezifisches Feld",
  passengersTourSegmentMsg: "Erforderlich für das Tour 'X' -Segment",
  visaRequriements: "Visabestimmungen",
  passengersreferencePurposeMsg:
    "Ein Visum oder ein Flughafentransitvisum kann für Reisen erforderlich sein, auch wenn man nicht im Zwischenland anhaelte. Diese Anforderungen dienen nur zu Referenzzwecken. Wenden Sie sich an die Botschaft oder das Außenministerium, um weitere Informationen zu erhalten.",
  passengersDeniedDestinationIssueMsg:
    "ERoam haftet nicht für Passagiere, denen das Einsteigen oder die Einreise in ein Ziel aufgrund von Visa-Problemen verweigert wurde.",
  givenNames: "Vornamen",
  passengerNames: "Vornamen *",
  surname: "Nachname",
  passengersurname: "Nachname *",
  familyname: "Familienname",
  PassengerGender: "Geschlecht *",
  paymenttotalprice: "Gesamtpreis",
  paymentnewprice: "Neuer Preis",
  emailAddress: "E-Mail-Adresse",
  passengeremailAddress: "E-Mail-Adresse *",
  contactNumber: "Kontaktnummer",
  passengercontactNumber: "Kontaktnummer *",
  passengersIndividualInventorySpecificationMsg:
    "Die folgenden Felder sind erforderlich aufgrund individueller Inventarspezifikationen wie interne Flüge, Touranforderungen usw.",
  Nationality: "Nationalität",
  PassengerNationality: "Nationalität *",
  confirmBooking: "Buchungsbestätigung",
  receiptNumber: "Quittungsnummer",
  paymentAddReceiptNumberMsg:
    "Quittungsnummer der verarbeiteten Zahlung hinzufügen.",
  PaymentDeniedDestinationIssueMsg:
    "Für die Reise kann ein Visum oder ein Flughafentransitvisum erforderlich sein. Die hier aufgeführten Anforderungen dienen nur zu Auffrischungszwecken. Wenden Sie sich an die Botschaft oder Ihr Außenministerium, um weitere Informationen zu erhalten. eRoam haftet nicht für Passagiere, denen das Einsteigen oder das Betreten eines Ziels aufgrund von Visa-Problemen verweigert wird.",
  amount: "Betrag *",
  paymentEnterAmountMsg:
    "Geben Sie einen Betrag ein, der gerade getätigt wird.",
  paymentEnterTypeMsg: "Geben Sie die Art der Zahlung ein",
  paymentType: "Zahlungsart *",
  paymentOnceSubmittedMsg:
    "Sobald die Zahlungsdetails übermittelt wurden, wird die Buchung bestätigt.",
  payment: "Zahlung",
  printPDF: "PDF drucken",
  custom: "Benutzerdefiniert",
  additionalNotes: "Zusätzliche Hinweise",
  list: "Liste",
  checkinDash: "Check-In",
  detailedSummary: "Detaillierte Zusammenfassung",
  dailySummary: "Tägliche Zusammenfassung",
  tripSummary: "Reisezusammenfassung",
  transportName: "Transportname",
  activities: "Aktivitäten",
  basicTransportDetails: "Grundlegende Transportdetails",
  transportOwnArrangementRefundMsg:
    "Dieses eigene Anordnungstransportprodukt ist erstattungsfähig.",
  transportOwnArrangementPricingMsg:
    "Diese eigene Anornungstransportpreisgestaltung in die endgültige Rechnung aufnehmen .",
  salePrice: "Verkaufspreis",
  transportOwnArrangementAddLegtoArrangement:
    "Teil zur Vereinbarung hinzufügen",
  arrivalLocation: "Ankunftsort",
  transportOwnArrangementAddNewLeg: "Neues Teil hinzufügen",
  arrival: "Ankunft",
  departure: "Abreise",
  fromTo: "Von - Bis",
  selectThePlaceToReturn: "Ort für die Rückkehr auswählen",
  selectAReturn: "Wählen Sie eine Rückkehr aus",
  returnToAnotherCity: "Rückkehr in eine andere Stadt",
  selectPlaceOrigin: "Wählen Sie den Herkunftsort.",
  selectOrigin: "Wählen Sie eine Herkunft.",
  accomodationSummary: "Unterkunftsübersicht.",
  transportSummary: "Transportübersicht",
  activitySummary: "Aktivitätsübersicht",
  taskListConformDeleteMsg:
    "Bitte bestätigen Sie, dass Sie die Aufgabe löschen möchten?",
  ConfirmActionTitle: "Aktion bestätigen",
  passengertitle: "Titel *",
  hotelListTotalCost: "Gesamtkosten",
  nonstop: "Durchgehend",
  selected: "Ausgewählt",
  inventoryAddNewHotels: "Neue Hotels hinzufügen",
  inventoryAddOwnArrangement: "Eigenes Anordnung hinzufügen",
  propertylocation: "Standort der Immobilie",
  inventoryAddNewToursTickets: "Andere Tour & Tickets auswählen",
  inventoryContactPhone: "Kontakt-Telefon",
  inventoryContactEmail: "Kontakt-E-Mail",
  flight: "Flug",
  time: "Zeit",
  bookingId: "Buchungs-ID",
  bookingNote: "Buchungsnotiz",
  stops: "Halte",
  layOverDuration: "Zwischenhaltedauer",
  itineraryPdfImageText:
    "Reiseroutenbild zum Hochladen hier klicken oder hochladen",
  confirm: "Bestätigen",
  changePassword: "Passwort ändern",
  oldPasswordErrorMsg: "Altes Passwort eingeben",
  newPasswordErrorMsg: "Neues Passwort eingeben",
  confirmPasswordErrorMsg: "Passwortbestätigung stimmt nicht überein",
  oldPassword: "Altes Passwort",
  confirmPassword: "Passwort bestätigen",
  newPassword: "Neues Passwort",
  visitedLocations: "Orte zu besuche",
  checkoutFlightListINFlightAddonMsg: "In Flight Add-ons",
  checkoutmystifly: "Mystifly",
  tourDetailstitle: "Tourdetails",
  update_total_cost: "Gesamtkosten aktualisieren",
  updateCostConfirmCompleteMsg:
    "Sind Sie sicher, dass Sie mit der negativen Provision fortfahren möchten?",
  confirmButton: "Bestätigen",
  norating: "Keine Bewertung",
  adult: "Erwachsener",
  child: "Kind",
  PrimaryPassenger: "Hauptpassagier (Hauptperson)",
  hotelbed: "Hotelbett",
  activityownemail: "Lieferanten-E-Mail",
  activityownweb: "Website",
  activityownphone: "Telefon",
  activityownsupplier: "Lieferant",
  hotelDetails: "Hoteldetails",
  room: "Zimmer",
  ownArrangementActivityBasicDetails: "Grundlegende Aktivitätsdetails",
  booking: "Buchung",
  tour: "Tour",
  oopsTheActivity: "Die Aktivität",
  oopsTheTour: "Die Tour",
  oopsTheFlight: "Der Flug",
  notAvailable: "es ist derzeit nicht verfügbar.",
  inventoryAddHotelOwnArrangement: "Eigene Anordnung (Unterkunft)",
  inventoryAddToursOwnArrangement: "Eigene Anordnung (Tour & Ticket)",
  inventoryAddReplaceTransport: "Wählen Sie einen anderen Transport",
  inventoryAddTransportOwnArrangement: "Eigene Anordnung (Transport)",
  noProductSelected: "Kein Produkt ausgewählt",
  taskSelectDate: "Datum auswählen",
  taskSelecgender: "Geschlecht auswählen",
  age: "Alter",
  relation: "Beziehung",
  activityTime: "Uhrzeit *",
  activityDate: "Datum *",
  accountId: "Konto-ID",
  nationality: "Nationalität",
  city: "Stadt",
  address: "Adresse",
  postcode: "Postleitzahl",
  region: "Region",
  ontarioResident: "Einwohner von Ontario",
  prepaidGratuities: "Vorausbezahlte Trinkgelder",
  mobilePhone: "Kontakt",
  titleBooking: "Titel",
  genderBooking: "Geschlecht",
  emailBooking: "E-Mail-Adresse",
  paymentAmount: "Zahlungsbetrag",
  checkoutRceiptNumber: "Quittungsnummer",
  loadPnr: "PNR laden",
  activityownArrangementPropertyInvoice:
    ",Preise in die endgültige Rechnung aufnehmen.",
  activityownArrangementPropertyRefund: "Dies ist erstattungsfähig.",
  bookingVoucherfileType: "(Unterstützte Dateitypen .jpeg, .jpg, .png, .pdf)",
  bookingVouchermsg: "Klicken Sie hier, um den Gutschein hochzuladen.",
  carrierName: "Name des Beförderers",
  departureDateandTime: "Datum und Uhrzeit der Abreise",
  arrivalDateandTime: "Datum & Uhrzeit der Ankunft",
  airlineReference: "Referenz der Fluggesellschaft",
  pnrNumber: "PNR eingeben",
  pnrInstructions:
    "Bitte gültige PNR-Nummer eingeben, um die Reiseroute zu erhalten",
  termsandconditions: "Allgemeine Geschäftsbedingungen",
  total: "Gesamt",
  baggageAdult: "Erwachsenen- / Kindergepäck",
  baggageChild: "Kindergepäck",
  gitWarningMessage: "Sie haben eine ungültige Tour für diese Stadt ausgewählt",
  summeryConfirmCompleteMsg:
    "Sind Sie sicher, dass Sie mit einer ungültigen Tourauswahl fortfahren möchten?",
  tourType: "Tourtyp",
  SearchPlace: "Søg sted | Søg steder",
  SearchCity: "Søg By | Søg i byer",
  manager: "Manager",
  product: "Produkt",
  finance: "Finansiering",
  admin: "Admin",
  viewProductManager: "Vis produktchef",
  viewfinanceManager: "Vis Økonomichef",
  viewAdminManager: "Se Administrator",
  viewsupplierManager: "Vis leverandørchef",
  viewmarketingManager: "Vis marketingchef",
  DaterangeFrom: "Datointerval fra",
  DaterangeTo: "Datointerval til",
  DataType: "Datatype",
  FileType: "Filtype",
  downloadNow: "Hent nu",
  ConsultantName: "Konsulentnavn",
  SupplierName: "Leverandørnavn",
  ClientFile: "Klientfil",
  pay: "Betale",
  TypeofPayment: "Betalingstype",
  Login: "Log på",
  exportData: "Eksportér data",
  roomNumber: "Værelse",
  storeName: "Navn på store",
  manageUsers: "Administrer brugere",
  manageBrands: "Administrer brands",
  viewReports: "Vis rapporter",
  brand: "Mærke",
  manageLicensee: "Administrer licenstager",
  ManageLicensee: "Søg licenstager",
  OnboardNewLicensee: "Indbygget ny licenstager",
  CreateNewPackage: "Opret en ny pakke",
  consultantArchived: "Bookinger",
  ArchivedData: "Arkiveret",
  consultantArchivedBlurb: "Se liste over dig arkiverede bookinger",
  consultantArchivedButton: "Se arkiverede reservationer",
  consultantArchivedAgency: "Se arkiverede bureaureservationer",
  archivedItineraries: "Arkiverede rejseplaner",
  taskDay: "Vælg dag",
  action: "Handling",
  mobile: "Mobile",
  taskSelectDay: "Vælg dag",
  DateandTime: "Dato og klokkeslæt",
  addtransport: "Tilføj transport",
  addhotels: "Tilføj hoteller",
  addactivities: "Tilføj aktiviteter",
  manageclientpreferences: "Administrere klientindstillinger",
  Return: "Vende tilbage",
  oneway: "Én vej",
  multycity: "Byen Multy",
  flyingfrom: "Flyver fra",
  selectclass: "Vælg klasse",
  returndate: "Returdato",
  comingsoon: "Kommer snart",
  thingstodo: "Aktiviteter, du skal gøre",
  extendedtouring: "Udvidet tur",
  selectinterest: "Vælg rente",
  searchpnr: "Søg i PNR",
  googlesearch: "Google-søgning",
  package: "Pakke",
  addcustomer: "Tilføj kunde",
  geoLocationManager: "GEO Lokalitetschef",
  supplieroperatorManager: "Leverandør / Operatørchef",
  yieldManager: "Udbytte manager",
  packageBuilder: "Pakkebygger",
  plotmaps: "Plot kort",
  reporting: "Rapportering",
  CSVDownload: "CSV Download",
  makePayments: "Foretag betalinger",
  packageName: "Pakkenavn",
  uploadImages: "Overfør billeder",
  toursandtickets: "Ture og billetter",
  hotel: "Hotel",
  packageOverview: "Oversigt over pakke",
  packageItinerary: "Pakkerejse",
  Optional: "Valgfri",
  createpackagefromtour: "Opret pakke fra rundvisning",
  Currency: "Valuta",
  companyName: "Firmanavn",
  Phone: "Telefon (herunder landekode)",
  url: "Url",
  uploadLogo: "Overfør logo",
  selectProduct: "Vælg produkt",
  hotelSupplier: "Hotel leverandør",
  tourSupplier: "Rejseleverandør",
  transportSupplier: "Transportleverandør",
  otherSupplier: "Anden leverandør",
  selectSettings: "Vælg indstillinger",
  defaultLanguage: "Standardsprog",
  defaultCurrency: "Standardvaluta",
  chooseTemplate: "Vælg skabelon",
  paymentGateway: "Betalingsgateway",
  selectSubscription: "Vælg abonnement",
  licenceType: "Licenstype",
  howmanylicences: "Hvor mange licenser?",
  addons: "Tilføjelser",
  paymentTerms: "Betalingsbetingelser",
  licenseenameoremail: "Licenstagerens navn eller e-mail",
  createProfilesandClientDetails:
    "Opret profiler, og administrer kundeoplysninger",
  passportNumber: "Pasnummer",
  passportissuedate: "Dato for udstedelse af pas",
  passportExpiryDate: "Udløbsdato for pas",
  passportissuingcountry: "Pasudstedende land",
  clientContact: "Klientkontakt",
  Phonehome: "Telefon (hjem)",
  Phonework: "Telefon (arbejde)",
  phonemobile: "Telefon (mobil)",
  AddressLinOne: "Adresse (linje 1)",
  AddressLineTwo: "Adresse (linje to)",
  StateProvinceRegion: "Stat / Provins / Region",
  zipPostalCode: "Postnummeret",
  country: "Land",
  newsletter: "Nyhedsbrev",
  companyABN: "Virksomhed ABN",
  paName: "PA-navn",
  clientLoyaltyPrograms: "Klient loyalitetsprogrammer",
  srno: "Hr. Nr.",
  loyaltyType: "Fordelskundetype",
  providerName: "Udbyderens navn",
  membershipNumber: "Medlemsnummer",
  clientPreferences: "Klientindstillinger",
  airline: "Flyselskab",
  airlines: "Flyselskab(s)",
  travelClass: "Rejseklasse",
  preferredSeating: "Foretrukne siddepladser",
  mealRequest: "Anmodning om måltid",
  Hotels: "Hotel(r)",
  hotelChain: "Hotelkæde",
  Cars: "Bil(er)",
  carRental: "Biludlejning",
  CarType: "Biltype",
  interests: "Renter",
  linkedprofiles: "Sammenkædede profiler",
  Preferreddestinations: "Foretrukne destinationer",
  businessPreferences: "Forretningspræferencer",
  otherPreferences: "Andre indstillinger",
  pendingBookings: "Afventer bookinger",
  archiveBookings: "Arkiv Bookinger",
  activeBookings: "Aktive bookinger",
  viewdetails: "Vis detaljer",
  update: "Opdatering",
  bookingmanagement: "Booking administration",
  bookingDashboard: "Booking Dashboard",
  bookingoverview: "oversigt over booking",
  Generalbookingdetails: "Generelle booking detaljer.",
  type: "Type",
  created: "Lavet",
  segmentoverview: "Oversigt over segment",
  totalnumberofsegment:
    "Det samlede antal produkter/segmenter og de udestående bekræftede segmenter.",
  segmentsconfirmedvstotalsegment:
    "Segmenter Bekræftet i forhold til samlede segmenter, der er inkluderet i bookingen.",
  amountremainingforclient:
    "Det resterende beløb, som kunden skal betale før restforfaldsdatoen.",
  outstandingAmount: "Udestående beløb",
  outstanding: "Udestående",
  balanceDue: "Forfalden saldo",
  bookingDetails: "Oplysninger om booking",
  user: "Bruger",
  consultantNotes: "Konsulent noter",
  historynotfound: "Historikken blev ikke fundet",
  leadpaxdetails: "Oplysninger om kundeemne Pax",
  additionalpaxdetails: "Yderligere Pax-oplysninger",
  locationdetails: "Oplysninger om placering",
  selectedHotels: "Udvalgte hoteller",
  confirmed: "Bekræftet",
  snooze: "Snooze",
  upload: "Uploade",
  novoucherfound: "Der blev ikke fundet et bilag",
  Nopaymentsfound: "Der blev ikke fundet nogen betalinger",
  newvoucher: "Upload en ny kupon til denne reservation",
  allocation: "Tildeling",
  selectedtoursandtickets: "Udvalgte ture og billetter",
  offeredIn: "Tilbydes i",
  tofrom: "TIL/ FRA",
  bookingRef: "Booking Ref",
  selectedTransport: "Valgt transport",
  segmentmessage:
    "Ved at bekræfte dette segment, anerkender du, at dette produkt er blevet booket hos leverandøren, Uden for denne ansøgning. For at fuldføre bekræftelsen skal du udfylde feltet nedenfor.",
  bookingReference: "Booking-reference",
  suppliersbookingreference: "Angiv leverandørens reservationsreference",
  confirmsegment: "Bekræft segment",
  pickupTime: "Afhentningstid",
  totalDuration: "Samlet varighed",
  layoverTime: "Læg dig over tid",
  ticketType: "Billettype",
  class: "Klasse",
  provider: "Udbyder",
  viewcustomer: "Vis kunde",
  costofitinerary: "De samlede udgifter til rejseplanen",
  paid: "Betalt",
  receiptofallpayments: "Kvitteringer for alle betalinger til dato",
  balanceduetocustomer: "Saldo, der skal inddrives fra kunden",
  recordcustomerpayment: "Registrer en debitorbetaling nedenfor.",
  completePayment: "Komplet betaling",
  cancel: "Annullere",
  accommodationORtransportation:
    "* Vælg mindst én indkvartering eller transport.",
  preferredPropertyClass: "Klasse for foretrukket egenskab",
  transportation: "Transport",
  preferredtransporttype: "Foretrukken transporttype",
  preferredtransportclass: "Foretrukken transportklasse",
  agegroup: "Aldersgruppe",
  interest: "Interesse",
  activityinterest: "Aktivitetsinteresse",
  applytoalllocations: "Anvend på alle lokationer",
  applythislocation: "Anvend denne placering",
  apply: "Anvende",
  hotelDescription: "Hotel Beskrivelse",
  activityDescription: "Beskrivelse af aktivitet",
  checkoutcancelationdescription:
    "Det er en veletableret kendsgerning, at en læser vil blive distraheret af læsbart indhold af en side, når man ser på dens layout. Pointen med at bruge Lorem Ipsum er, at det har en mere eller mindre normal fordeling af bogstaver, i modsætning til at bruge 'Indhold her, indhold her', hvilket gør det til at ligne læsbar engelsk. Mange desktop publishing pakker og webside redaktører nu bruge Lorem Ipsum som deres standard model tekst, og en søgning efter 'lorem ipsum' vil afdække mange websteder stadig i deres vorden. Forskellige versioner har udviklet sig gennem årene, nogle gange ved et uheld, nogle gange med vilje (injiceret humor og lignende).",
  OverrideQuotation: "Gem/tilsidesæt tilbud",
  DuplicateQuotation: "Dublet tilbud",
  OriginCity: "Oprindelse By",
  ReturnCity: "Returby",
  AddTransport: "Tilføj transport",
  AddHotels: "Tilføj hoteller",
  AddActivities: "Tilføj aktiviteter",
  ManagePreferences: "Administrere klientindstillinger",
  searchPNR: "Søg i PNR",
  oneWay: "Én vej",
  multiCity: "Flere byer",
};
