
    import { Component, Vue } from 'vue-property-decorator';
    import $ from 'jquery';

    import VerifyInformation from "@/Module/Global/components/wizard2/VerifyInformation.vue";
    import ClientConfiguration from "@/Module/Global/components/wizard2/ClientConfiguration.vue";
    import ClientApiSelection from "@/Module/Global/components/wizard2/ClientApiSelection.vue";
    import ClientPayment from "@/Module/Global/components/wizard2/ClientPayment.vue";
    import Authanticated from "@/Module/Global/Services/auth.js";
    import Ls from "@/Module/Global/Services/ls.js";

    @Component({
        components:{
            VerifyInformation,
            ClientConfiguration,
            ClientApiSelection,
            ClientPayment
        }
    })
    export default class ClientOnBoarding extends Vue {
        // progressbar computed property
        get progressValue(){
            return 100 * this.currentTab.id / this.steps.length;
        }

        toggleValue:any=false;
        steps: any = [
        {
            id: 1,
            name: "Verify Info",
            component: "VerifyInformation",            
        },
        {
            id: 2,
            name: "Configuration",
            component: "ClientConfiguration",            
        },
        {
            id: 3,
            name: "Lets Get Started",
            component: "ClientApiSelection",            
        },
        {
            id: 4,
            name: "Payment Gateway",
            component: "ClientPayment",            
        }]
        currentTab = this.steps[0];

        sidebarPanels:any=[
            {
                id:'1',
                panelHeading:'Verify Info',
                panelContent:['Verify or Edit information']
            },
            {
                id:'2',
                panelHeading:'Configuration',
                panelContent:[
                    'Check eroam URL',
                    'Style your website/shop',
                    'Select Color Scheme',
                    'Change to Global Settings'
                ]
            },
            {
                id:'3',
                panelHeading:'Lets Get Started',
                panelContent:['Select Products and add API with Credential']
            },
            {
                id:'4',
                panelHeading:'Payment Gateway',
                panelContent:[
                    'Select payment product',
                    'Select payment gateway and enter the details of selected payment gateway',
                    'Finish wizard'
                ]
            },
        ]

        mounted(){            
            this.calculateMainWrapperHeight();
            // toggle chnage Icon
            this.$root.$on('bv::collapse::state', (collapseId:any, isJustShown:any) => {
                if(collapseId == "get-started"){
                    if(isJustShown){
                        this.toggleValue = true;
                    }
                    else{
                        this.toggleValue = false;
                    }
                }
            })
        }

        calculateMainWrapperHeight(){
            var navHeight:any =  $(".headerTop").outerHeight();
            $(".main-content-wrapper").css("padding-top", navHeight);
            var winHeight:any = $(window).height();            
            if(screen.width > 1024){            
                $(".main-content-wrapper").height(winHeight - navHeight);
            }
        }

        nextTab(id:any){
            let newTabId = id + 1
            this.currentTab = this.steps[newTabId - 1];            
        }

        prevTab(id:any){
            this.currentTab = this.steps[id - 2];            
        }

        successOnboarding(){
            this.$router.push({ name: "congratulation" });
           // this.$bvModal.show('success-dialog')
        }

        redirectTo(){
            let token_id = Ls.get("auth.token_id");
            let URL = this.$store.state.step2.domainName;
            Authanticated.logout(token_id)
                .then(() => {
                    this.$store.dispatch("setLoginInfomationValue", {});
                    this.$store.dispatch("setStep1Value", {});
                    this.$store.dispatch("setStep2Value", {});
                    this.$store.dispatch("setStep3Value", {});
                    Authanticated.clearLocalStorage();
                    window.location.href = `http://${URL}`;
                })
                .catch(function () {});
            
            
        }
    }
