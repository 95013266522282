
    import { Component, Vue } from 'vue-property-decorator';
    // import PassengersCustomer from "@/Module/Global/Components/Sidebar/PassengersCustomer.vue";  
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    import DatePicker from 'vue2-datepicker';
    import moment from "moment";
    @Component({
        components:{
            // PassengersCustomer,
            CitySearch,
            DatePicker
        }
    })
    export default class widgetItinerary5 extends Vue {
        fromCity:any="";
        checkin_date:any="";
        metaKeyWords:any=[
            {name:'London,UK'},
            {name:'Paris'},
            {name:'Rome'},
            {name:'Sydney'},
        ]
        preferenceSelect:any="No Origin or Return (Default)";
        PreferOptions = [
            { value: 0, text: "No Origin or Return (Default)" },
            { value: 1, text: "Add 0 nights to Origin & Return"},
            { value: 2, text: "Add 0 nights to Origin (Only)" },
            { value: 3, text: "Add 0 nights to Return (Only)" }
        ];
        is_hotel_active:any=true;
        is_activity_active:any=true;
        is_transport_active:any=true;
        is_transfer_active:any=true;
    }
