
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { applicationName } from '@/plugins/constants';

import FlightOneway from "@/Module/Global/components/tabs/FlightOneway.vue";
import FlightReturn from "@/Module/Global/components/tabs/FlightReturn.vue";
import FlightMultiCity from "@/Module/Global/components/tabs/FlightMultiCity.vue";

@Component({ 
    components: {
        FlightMultiCity,
        FlightReturn,
        FlightOneway
    }   
}) 

export default class FlightSearch extends Vue{
    // name: 'FlightSearch';
    selectedComponent: any = "FlightOneway";
    flightClass : any =[{ text: 'Select One', value: null }, 'Fisrt Class', 'Economy Class', 'Business Class'];
    isPreferenceModalVisible: boolean = false;
    applicationName:any=applicationName; 

    components:any=[
        { id: 1, name: "One Way", component: "FlightOneway" },
        { id: 2, name: "Return", component: "FlightReturn" },
        { id: 3, name: "Multi City", component: "FlightMultiCity" },
    ];

    mounted() {
    this.selectedComponent = "FlightOneway";

    }

    selectComponent(componentName: any) {
    this.selectedComponent = componentName;
  }
}
