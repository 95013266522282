
    import { Component, Vue } from 'vue-property-decorator';
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    @Component({
        components:{
            CitySearch
        }
    })
    export default class widgetPackage5 extends Vue {
        fromCity:any="";
    }
