
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class packageCard extends Vue {
        @Prop({
            default:{
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            }
        }) content;
    }
