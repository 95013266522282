
    import { Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import widgetFlight from '@/Module/Global/components/website/widgetSearch/widgetFlight.vue';
    import widgetHotel from '@/Module/Global/components/website/widgetSearch/widgetHotel.vue';
    import widgetTour from '@/Module/Global/components/website/widgetSearch/widgetTour.vue';
    import widgetPackage from '@/Module/Global/components/website/widgetSearch/widgetPackage.vue';
    import widgetItinerary from '@/Module/Global/components/website/widgetSearch/widgetItinerary.vue';
    @Component({
        components:{
            widgetFlight,
            widgetHotel,
            widgetTour,
            widgetPackage,
            widgetItinerary
        }
    })
    export default class bannerSearch extends Vue {  
        @Prop() productSelected:any;            
        products:any=[]
        currentTab = {id:4, heading:'Create Itinerary',productName:'Create Itinerary', componentName:'widgetItinerary', icon:'fas fa-route'};
        searchWidth:any=null;
        mounted(){
            if(this.productSelected.includes('flights')){  
                this.products.push({id:1, heading:'Flight Search',productName:'Flight', componentName:'widgetFlight', icon:'icon-plane'});
            }
            if(this.productSelected.includes('hotels')){
                this.products.push({id:2, heading:'Hotel Search',productName:'Hotel', componentName:'widgetHotel', icon:'icon-hotel'});
            }
            if(this.productSelected.includes('activities') || this.productSelected.includes('tours')){
                this.products.push({id:3, heading:'Activity Search',productName:'Tour & Tickets', componentName:'widgetTour', icon:'icon-ticket'});
            }
            this.products.push({id:4, heading:'Create Itinerary',productName:'Create Itinerary', componentName:'widgetItinerary', icon:'fas fa-route'});
            this.products.push({id:5, heading:'Package Search',productName:'Packages', componentName:'widgetPackage', icon:'fas fa-box-open'});
            this.currentTab = this.products[0];
        }

        currentValue(value){
           this.searchWidth = value
        }

        get checkSearchWidth(){
            var widthValue;
            if(this.currentTab.productName == 'Create Itinerary' || (this.currentTab.productName == 'Flight' && this.searchWidth=='multicity')){
                widthValue = 'w-100'
            }
            else{
                widthValue = 'half-width'
            }
            return widthValue;
        }
    }
