
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    // import PassengersCustomer from "@/Module/Global/Components/Sidebar/PassengersCustomer.vue";
    import DatePicker from 'vue2-datepicker';
    @Component({
        components:{
            CitySearch,
            DatePicker,
            // PassengersCustomer
        }
    })
    export default class widgetFlightMultiCity5 extends Vue {
        multiFlight:any=[];
        classSelect:any="Select class";   
        flightClass:any=[
            {text:'Economy',value:'Economy'},
            {text:'Premium Economy', value:'Premium Economy'},
            {text:'Business Class', value:'Business Class'},            
        ]
        getClassValue(e){
            this.classSelect = e.target.innerHTML;
        }
        addFlight(){
            let flightMulticity={
                fromCity:"",
                toCity:"",
                checkin_date:"",
                checkout_date:""
            }
            this.multiFlight.push(flightMulticity);
        }
        mounted(){
            this.multiFlight=[
                {
                    fromCity:"",
                    toCity:"",
                    checkin_date:"",
                    checkout_date:""
                },
                {
                    fromCity:"",
                    toCity:"",
                    checkin_date:"",
                    checkout_date:""
                },
            ]
        }
    }
