// import { Customers } from "../faker";

export const English: any = {
    creatingFor: 'Creating itinerary for',
    creatingNew: 'Creating new customer itinerary',
    searchTitleItinerary: 'Itinerary',
    searchTitleFlights: 'Flights & Trains',
    searchTitleHotel: 'Hotels & BnB\'s',
    searchTitleTours: 'Tours & Tickets',
    searchTitlePackages: 'Packages',
    searchTitleRentalcars: 'Rental Cars & Motorhomes',
    searchTitleTransfers: 'Transfers',
    searchTitleCruises: 'Cruises',
    searchTitleEvents: 'Events',
    searchTitleInsurance: 'Insurance',
    searchInstructions: 'Add as many locations that you would like to include in the itinerary.',
    searchAddPlaces: 'Create Itinerary',
    searcPlaceholder: 'Locations',
    searchError: '* Please Select Two Locations',
    searchBlurb: 'Create your itinerary. Add each of the locations you wish to visit by typing your destination and click Enter to add them.',
    consultantProfile: 'Consultant Profile',
    consultantSearchCust: 'Customers',
    Searchdata: 'Search',
    consultantSearchBlurb: 'Find a customer\'s bookings',
    consultantSearchCustButton: 'Search Customers',
    consultantSearchCont: 'Search Contact',
    consultantQuick: 'Quick Access',
    consultantActive: 'Bookings',
    ActiveData: 'Active',
    consultantActiveBlurb: 'View list of your active bookings',
    consultantActiveButton: 'View Active Bookings',
    consultantPending: 'Bookings',
    PendingData: 'Pending',
    consultantPendingBlurb: 'View list of your pending bookings',
    consultantPendingButton: 'View Pending Bookings',
    consultantItineraries: 'Active Itineraries',
    consultantCustomers: 'Customers',
    consultantAccount: 'Account Summary',
    consultantNotices: 'Notifications & Notices',
    consultantTaskList: 'Task List',
    consultantTaskYours: 'Your Tasks',
    consultantTaskNotFound: 'Task not found',
    consultantTaskConfirmCompleteMsg: 'Are you sure you want to complete marked tasks as complete',
    consultantArchived: 'Bookings',
    ArchivedData: 'Archived',
    consultantArchivedBlurb: 'View list of your archived bookings',
    consultantArchivedButton: 'View Archived Bookings',
    consultantArchivedAgency: 'View Archived Agency Bookings',
    archivedItineraries: 'Archived Itineraries',
    consultantCustName: 'Customer Name',
    consultantActiveAgency: 'View Active Agency Bookings',
    consultantPendingAgency: 'View Pending Agency Bookings',
    consultantTaskCustName: 'Customer Name',
    consultantTaskBookingId: 'Booking ID',
    consultantTaskNote: 'Notes',
    consultantTaskDate: 'Select Date',
    taskDay: 'Select Day',
    consultantTaskAddButton: 'Add Task',
    consultantTaskEditButton: 'Edit Task',
    consultantTaskCompleted: 'Completed',
    consultantTaskactions: 'Actions',
    consultantTasksnoozed: 'Snoozed',
    consultantTasktasks: 'Tasks',
    consultantTasksNone: 'Seems you\'re up to date, well done',
    consultantMidOffice: 'Mid Office',
    consultantMidOfficeUpload: 'Upload Data to Mid Office',
    sidebarHeading: 'Confirm Route',
    siderbarInstructions: 'Add all the Places of interest and set them in the desired order. Select a depature date and length of stay in each location. If opting into pre- selected inventory, refine results via the inventory options for each location.',
    sidebarOptionDeparture: 'Departure and Travellers',
    sidebarDepDate: 'Departure Date',
    sidebarDepSelect: 'Select the date to depart from the first location.',
    sidebarDepError: 'Error, please select a departure date.',
    travellersNumber: 'Number of Travellers',
    siderbarnewInstructions: 'Add all the Places of interest and set them in the desired order. Select a depature date and length of stay in each location. If opting into pre- selected inventory, refine results via the inventory options for each location.',
    sidebarnewOptionDeparture: 'Departure and Travellers',
    depDate: 'Departure Date',
    origincity:'Origin City',
    returncity:'Return City',
    sidebarnewDepSelect: 'Select the date to depart from the first location.',
    sidebarnewDepError: 'Error, please select a departure date.',
    sidebarTravellersNumber: 'Number of Travellers',
    Rooms: 'Room | Rooms',
    adults: 'Adult | Adults',
    children: 'Child | Children',
    childAgeLimits: 'Ages (0-17)',
    Infants: 'Infant |Infants',
    infantAgesLimit: 'Ages (1 & Under)',
    childAges: 'How old are the children you\'re travelling with (at a time of departure)',
    ageOfChild: 'Age of child',
    infantConfirm: 'Is infant under the age of one(at time of Departure)',
    infantLengthPlural: 'Are the infants',
    infantLengthSingular: 'Is the infant',
    infantLengthEnd: 'under the age of one(at time of Departure)',
    infantLapStart: 'Will the',
    infantLapPlural: 'infants',
    infantLapSingular: 'infant',
    infantLapEnd: 'travel on the Adults lap?',
    infantsLap: 'will Infants travel on the Adults lap?',
    sidebarnewInventoryOps: 'Inventory and Location options',
    sidebarViewTours: 'View Group Tours',
    sidebarnewRouteOptions: 'Please select which options you would like to include within this route.',
    siderbarnewAutoPopLoc: 'Auto-populate with trending locations.',
    siderbarnewAutoPopInv: 'Auto populate inventory using client preferences.',
    arrivalDate: 'Arrival Date',
    actDate:'Activity Date',
    confirmRouteButton: 'Confirm Route',
    redirectInventory: 'Itinerary Inventory',
    sidebarInventoryAlert: 'Add and remove Trip Inventory to the Itinerary on the right. </p> Please note: Adding a multi day tour will lock the previous locations dates in place.',
    redirectActivities: 'Choose Activities',
    redirectActivitiesDetail:'Activity Details',
    sidebarActivitiesAlert: 'Manage the inventory within the Itinerary by Selecting the segment to the left. To add, remove or re-order locations, select the View Map / Route button.',
    redirectHotelList: 'Choose Hotel',
    redirectHotelListDetail:'Hotel Details',
    sidebarHotelListAlert: 'Choose a new hotel from the list. </p>You\'re current selected hotel features on top.',
    redirectHotel: 'Choose your room',
    sidebarHotelAlert: 'Choose your room to add this hotel to the itinerary',
    redirectToursList: 'Choose a Tour',
    redirectTourDetail: 'Tour Detail',
    sidebarToursListAlert: 'Choose a tour from the list. </p>Click on a tour to see more detailed information, pricing and to view departures',
    redirectTour: 'Choose a Departure',
    sidebarTourAlert: 'Scroll down to find a list of departure dates with their price.</p>Either add this tour to your itinerary or book the tour directly',
    redirectTransport: 'Choose Transport',
    sidebarTransportAlert: '',
    checkoutAlert: 'Enter Passenger details to complete the booking',
    checkout: 'Checkout',
    redirectPreview: 'Itinerary Preview',
    redirectPreviewPDF: 'Preview PDF',
    sidebarnewInventoryOptions: 'Inventory Options',
    inventoryOptions:'Inventory Options',
    errorMsg: 'Error display here',
    remove: 'Remove',
    sidebarnewInclusiveTourButton: 'Group Inclusive Tour',
    sidebanewNotes: 'Add this place to the route',
    sidebarnewReturnTripOption: 'Return Trip Option',
    sidebarnewRoundTripForm: 'Please select an Origin and Return. If you wish to include return flights.',
    to: 'To',
    night: 'Nights',
    tourDuration: 'Duration',
    contact: 'Contact',
    checkinDate: 'Checkin Date',
    checkoutDate: 'Checkout Date',
    supplierReference: 'Supplier',
    propertyReference: 'Booking Reference',
    transport: 'Transport',
    service: 'Service',
    inventoryTravelClass: 'Travel Class',
    departureLocation: 'Departure Location',
    arrivalAirport: 'Arrival Location',
    status: 'Status',
    action: 'Action',
    departuretime: 'Departure Time',
    hotels: 'Hotels',
    hotelPricePerNight: 'Price Per Night',
    hotelPropertyRating: 'Property Rating',
    searchbyPropertyName: 'Search by Property Name',
    search: 'Search',
    refundtype: 'Refund Type',
    days: 'Days',
    location: 'Location',
    pernight: 'per night',
    pendingItineraries: 'Pending Itineraries',
    hotelownArrangementPropertyName: 'Property Name *',
    hotelownArrangementPropertyNotes: 'Property Notes *',
    validationMsg: 'This field is required.',
    emailValidationMsg: 'The Email Address field must be a valid email.',
    contactMinValidationMsg: 'The Contact Number field must be at least 10 characters.',
    contactMaxValidationMsg: 'The Contact Number field must be 11 characters.',
    ownArrangementImage: 'Image',
    ownArrangementHotelimage: 'Hotel Image',
    ownArrangementImgClick: 'Click or Drag hotel image here to upload',
    ownArrangementFileTypeMsg: '(Supported file type .jpeg, .jpg, .png)',
    hotelownArrangementPropertyaddress: 'Property Address *',
    hotelownArrangementPropertyContact: 'Property Contact Phone Number *',
    hotelownArrangementPropertyemail: 'Property Email *',
    hotelownArrangementDurationDetails: 'Duration Details',
    hotelownArrangementPropertyCheckindate: 'Check-in Date *',
    hotelownArrangementPropertyCheckintime: 'Check-in Time *',
    hotelownArrangementPropertyCheckouttime: 'Check-out Time *',
    hotelownArrangementPropertyCheckoutdate: 'Check-out Date *',
    ownArrangementPricingDetails: 'Pricing Details',
    ownArrangementCurrency: 'Currency *',
    hotelownArrangementCurrency: 'Purchase Currency *',
    hotelownArrangementPropertyPrice: 'Selling Price *',
    hotelownArrangementPropertybookingref: 'Booking Reference *',
    ownArrangementPurchasingdetails: 'Purchasing Details',
    ownArrangementPurchasePrice: 'Purchase Price *',
    hotelownArrangementPropertySupplier: 'Supplier *',
    hotelownArrangementPropertyInvoice: 'Include this pricing in final Invoice.',
    hotelownArrangementPropertyRefund: 'This is Refundable.',
    cancelButton: 'Cancel',
    addtoitinerary: 'Add To Itinerary',
    ownArrangementBasicDetails: 'Basic Hotel Details',
    activityownArrangementActivityname: 'Activity Name *',
    hotelownArrangementPropertyDescription:'Description',
    activityownArrangementAddress: 'Address *',
    activityownArrangementPhnno: 'Phone Number *',
    activityownArrangementEmail: 'Email *',
    activityownArrangementWeburl: 'Web URL',
    activityownArrangementImage: 'Image',
    ownArrangementActivityImgClick: 'Click or Drag activity image here to upload',
    activityownArrangementActivityImage: 'Activity Image',
    activityownArrangementSellingPrice: 'Selling Price *',
    activityownArrangementSupplierReference: 'Supplier',
    activityownArrangementPurchaseCurrency: 'Purchase Currency *',
    activityownArrangementPropertyReference: 'Booking Reference *',
    ownArrangementComments: 'Comments',
    activityownArrangementComments: 'Comments',
    operator: 'Operator',
    ownArrangementTravelClass: 'Class',
    ownArrngementArrivalTime: 'Arrival Time',
    ownArrangementAirlineReference: 'Airline Reference',
    ownArrangementGdsReference: 'GDS Reference',
    downloadPDF: 'Download PDF',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    showMore: 'Show More',
    showLess: 'Show Less',
    accommodation: 'Accommodation',
    name: 'Name',
    checkIn: 'Check In',
    checkOut: 'Check Out',
    checkOutDash: 'Check-out',
    price: 'Price',
    PerNight: 'Per Night',
    roomDetails: 'Room Details',
    roomType: 'Room Type',
    pendingBookingNights: 'Nights',
    non:'Non',
    nonRefundable: 'Non-Refundable',
    refundable: 'Refundable',
    ownArrangement: 'Own Arrangement',
    toursActivities: 'Tours / Activities',
    viewitenaryActivityname: 'Activity Name',
    viewitenaryAddress: 'Address',
    viewitenaryPhone: 'Phone',
    viewitenarySellingPice: 'Selling Price',
    email: 'Email',
    date: 'Date',
    duration: 'Duration',
    code: 'Code',
    startDate: 'Start Date',
    finishDate: 'Finish Date',
    endDate: 'End Date',
    aud: 'AUD',
    view: 'View',
    noDataFound: 'No Record Found',
    notProvided: 'Not Provided',
    pendingBookingPage: 'X',
    pendingBookingCustomerName: 'Customer Name',
    pendingBookingDepdate: 'Dep. Date',
    pendingBookingCreatedOn: 'Created On',
    travellers: 'Travellers',
    pendingBookingTotalPrice: 'Total Price',
    ownArrangementContactDetails: 'Contact Details',
    sortby: 'Sort By',
    titleOfActivity: 'Title',
    activityCode: 'Activity Code',
    viator: 'viator',
    from: 'From',
    perPerson: 'per person',
    category: 'Category',
    sidebarActivityName: 'Search by Activity Name',
    readmore: 'Read More',
    hotelPickupIncluded: 'Hotel Pickup Included:',
    activityDetaildeparturePoint: 'Departure Point:',
    overview: 'Overview',
    importantInfo: 'Important Info',
    details: 'Details',
    activityDetailInclusions: 'Inclusions',
    activityDetailExclusions: 'Exclusions',
    activityDetailAdditionalinfo: 'Additional info',
    activityDetailVoucherinfo: 'Voucher info',
    activityDetailLocalOperatorInformation: 'Local operator information',
    activityDetailLocalOperatorInformationlope: 'Complete Operator information, including local telephone numbers at your destination, are included on your Confirmation Voucher. Our Product Managers select only the most experienced and reliable operators in each destination, removing the guesswork for you, and ensuring your peace of mind.',
    activityDetailCancellationPolicy: 'Cancellation Policy',
    activityDetailScheduleAndPricing: 'Schedule and Pricing',
    activityDetailScheduleAndPricingContent: 'Our pricing is constantly updated to ensure you always receive the lowest price possible - we 100% guarantee it.',
    activityDetailBookActivity: 'Book Activity',
    activityDetailDeparts: 'Departs',
    activityDetailPickup: 'Pick Up:',
    activityDetailCheckedPickup: 'I need to be Picked up',
    activityDetailaddActivity: 'Add Activity',
    activityDetailSpecialRequirements: 'Special Requirements',
    activityDetailSelectLocation: 'Select Location',
    activityDetailEnterPickupLocation: 'Enter Pickup Location *',
    noRecordFound: 'No Record Found',
    sidebarTransportCustomerPricing: 'Customer Pricing',
    sidebarTransportCustomerPricingContent: 'Itinerary Pricing breakdown including customer cost total and by PAX.',
    sidebarTransportHeading: 'AUD 4562',
    sidebarTransportContent: 'Total Cost',
    sidebarTransportContentNew:'inc. Tax',
    sidebarTransportPricePerPaxAudincTax: 'Price Per PAX AUD inc. Tax',
    sidebarTransportCostPerDay: 'Cost Per Day:',
    sidebarTransportCost: '458.00',
    sidebarTransportTotalCost: 'Total Cost:',
    sidebarTransportTotal: 'AUD 785',
    sidebarTransportAgencyCost: 'Agency Cost',
    sidebarTransportOne: 'One',
    sidebarTransportTwo: 'Two',
    sidebarTransportThree: 'Three',
    sidebarTransportFilters: 'Transport Filters',
    sidebarTransportType: 'Transport Type',
    return: 'Return',
    transportOnlyRates: 'Turn the Agent View off to display the retail rates for the Customers.', 
    transportOnlySaveQuotation: 'Save Quotation',
    transportOnlyBookItinerary: 'Book Itinerary',
    transportOnlyCustomerPricing: 'Customer Pricing',
    transportOnlyAgencyCost: 'Agency Cost',
    transportOnlyFilters: 'Filters',
    transportOnlyDepartureLocation: 'Departure Location',
    transportOnlyDestinationLocation: 'Destination Location',
    transportOnlyReturnDate: 'Return Date',
    transportOnlyParistoRome: 'Paris to Rome',
    transportOnlyFilterContent: 'Monday, 1st April 2019',
    change: 'Change',
    transportOnlyvueling: 'Vueling',
    nonStop: 'Nonstop',
    departs: 'Depart',
    transportOnlyParisOrlyAirport: 'Paris Orly Airport (ORY)',
    transportOnlyArrive: 'Arrive',
    transportOnlyFco: 'Rome - Roma Tiburtina Railway Station (FCO)',
    transportOnlyKiwi: 'Kiwi.com',
    transportOnlyMystifly: 'Mystifly',
    transportOnlySabre: 'Sabre',
    transportOnlyEconomy: 'Economy',
    transportOnlySelect: 'Select',
    transportOnlyRouteDetails: 'Route Details',
    transportOnlyCalendar: 'Monday 1st April, 2019',
    transportOnlyFlight: 'Flight',
    transportOnlyShowDetails: 'Show Details',
    transportOnlyParis: 'Paris - Gare De Lyon',
    transportOnlyMilan: 'Milan - CentralStation',
    transportOnlyBaggageDetails: 'Baggage Details',
    transportOnlyPersonalBaggage: 'Personal Baggage',
    transportOnlyFree: 'Free',
    transportOnlyCheckedBaggage: ' Checked Baggage',
    transportOnlyAddCheckedBaggage: 'Add Checked Baggage',
    transportOnlyAddCabinBaggage: 'Add Cabin Baggage',
    transportOnlyInFlight: 'In-Flight Add-Ons',
    transportOnlyPassengerPricingDetails: 'passenger & pricing details',
    transportOnlyPassenger: 'Passenger',
    transportOnlyonexAdult: '1 x Adult',
    transportOnlyBaseFare: 'Base Fare',
    transportOnlyBaggage: 'Baggage',
    transportOnlyFeesAndTaxes: 'Other Fees / Taxes',
    transportOnlyTermsConditions: 'Terms & Conditions',
    transportOnlyNetRate: 'Net Rate',
    transportOnlyCommision: 'Commision',
    checkinTime: 'Checkin Time',
    checkoutTime: 'Checkout Time',
    searchCustomerName: 'Search by Customer Name',
    confirmroute: 'Confirm Route',
    logout: 'Logout',
    searchbypropertyname: 'Search by Property Name',
    hotelDetailshotelRoom: '(1 Room)',
    hotelDetailRoomsRates: 'Rooms & Rates',
    hotelDetailAmenities: 'Hotel Amenities',
    hotelDetailCheckInInstructions: 'Check In Instructions',
    hotelDetailsFeesOptionalExtras: 'Fees / Optional Extras',
    hotelDetailValueAddedServices: 'Value added Services',
    hotelDetailCheckin: 'Check In',
    hotelDetailhotelCheckout: 'Check Out:',
    hotelDetailBedPreferencese: 'Bed Preferencese:',
    hotelDetailViewCancellationPolicy: 'View Cancellation Policy',
    hotelDetailRoomDetailAmenities: ' Room Detail Amenities',
    hotelDetailRoomAmenities: 'Room Amenities',
    hotelDetailGuests: 'Guests:',
    hotelDetailSubtotal: 'SubTotal:',
    hotelTotal: 'Total',
    hotelDetailTaxRecoveryServiceFee: 'Tax Recovery & Service Fee:',
    hotelDetailSpecialCheckInInstructions: 'Special Check In Instructions',
    hotelDetailadditionalInformation: 'Additional Information',
    headerLogin: 'Login',
    headerLogout: 'Logout',
    hide: 'Hide',
    depart: 'Depart',
    arrive: 'Arrive',
    flightNumber: 'Flight Number',
    routeDetails: 'Route Details',
    wifi: 'WIFI',
    included: 'INCLUDED',
    airCondition: 'Air Condition',
    passengerPricingDetails: 'Passenger & pricing details',
    supplier: 'Supplier',
    passenger: 'Passenger',
    passengers: 'Passengers',
    flightMystifly: 'Mystifly',
    flight2Adult: '2 x Adult',
    baggage: 'Baggage',
    otherFeesTaxes: 'Other Fees / Taxes',
    free: 'FREE',
    netRate: 'Net Rate',
    select: 'Select',
    floatZero: '0.00',
    doubleZerofloat: '00.00',
    tv: 'TV',
    flightEconomy: 'Economy',
    viewMore: 'View More',
    flightOnBoardAddOns: 'On board Add-Ons',
    loginSignIn: 'Sign In',
    customerNameOrEmail: 'Customer Name or Email',
    searchBy: 'Search by',
    firstName: 'First Name',
    middleName:'Middle Name',
    lastName: 'Last Name',
    clientName:'Client Name',
    gender: 'Gender',
    phone: 'Phone',
    mobile: 'Mobile',
    submitButton: 'Submit',
    your: 'Your',
    createdOn: 'Created On',
    nights: 'Nights',
    totalPrice: 'Total Price',
    emailUserName: 'Email Or User Name',
    Username: 'Username',
    Password: 'Password',
    enterPassword: 'Enter Password',
    enterCustomerName: 'Enter Customer Name',
    taskEnterBookingId: 'Enter Booking Id',
    commission: 'Commission',
    costPerDay: 'Cost Per Day',
    pricePerPAX: 'Price Per PAX',
    totalCost: 'Total Cost',
    netCost: 'Net Cost',
    includingTax: 'inc. Tax',
    priceSummary: 'Price Summary',
    inventorySummary: 'Inventory Summary',
    ConsultantSidebarItineraryCustomerCostByPAXMsg: 'Itinerary Pricing breakdown including customer cost total and by PAX.',
    sidebarHeadingPreviewItinerary: 'Preview Itinerary',
    sidebarHeadingSaveQuotation: 'Save Quotation',
    sidebarHeadingBookItinerary: 'Book Itinerary',
    sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: 'Turn the Agent View off to display the retail rates for the Customer.',
    agentView: 'Agent View',
    customerView: 'Customer View',
    save: 'Save',
    travelInsurance: 'Travel Insurance',
    no: 'No',
    addTravel: 'Add Travel',
    plus: 'Plus',
    basic: 'Basic',
    inventoryMedicalExpenseTripCancellation: 'Medical Expenses, Trip Cancellation',
    inventoryAssistanceServices: 'Assistance Services',
    inventoryLostBaggageMore: 'Lost Baggage + More',
    audWithFloating: 'AUD 00.00',
    inventoryComparsionAndTerms: 'Comparsion and Terms',
    customerCare: 'Customer Care',
    premium: 'Premium',
    inventoryExploreBenefitsOfPayingPremiumMsg: 'Explore the benefits of paying for Premium',
    previous: 'Previous',
    continue: 'Continue',
    saveAndContinue: 'Save & Continue',
    BacktoInventory: 'Back to Inventory',
    AcceptandContinue: 'Accept and Continue',
    busBud: 'BusBud',
    edit: 'Edit',
    delete: 'Delete',
    checked: 'Checked',
    checkoutBusListINBusAddonMsg: 'In Bus Add-ons',
    BaseFarePerPax: 'Base Fare(Per Pax)',
    onexAdult: '1 x Adult',
    oneRoom: 'Room 1',
    CancellationPolicy: 'Cancellation Policy',
    expedia: 'Expedia',
    roomSleep: 'Room sleep',
    checkOutActivityListviator: 'Viator',
    checkOutActivityDetails: 'Activity Details',
    option: 'Option',
    groupInclusiveTourOptions: 'Group Inclusive Tour Options',
    matchCurrentRouteMsg: 'There are currently 219 group inclusive tours that match your current route.',
    errorDisplayHere: 'Error display here',
    tourFilters: 'Tour Filters',
    tailorMadeTours: 'Tailor-made Tours',
    tailorMadeOptionsMsg: 'Please select which options you would to display just the tailor-made options',
    tailorMadeToursItineryMsg: 'Display only Tailor-made Tours for Itineraries',
    MonthOfDeparture: 'Month of Departure',
    selectDate: 'Select Date',
    destination: 'Destination',
    tourExperience: 'Tour Experience',
    preferredSupplier: 'Preferred Supplier',
    style: 'Style',
    size: 'Size',
    start: 'Start',
    notAvailbale: 'N/A',
    inclusions: 'Inclusions',
    day: 'Day',
    meals: 'Meals',
    breakfast: 'Breakfast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    tourDetailsNoMealMsg: 'There are no meals included on this day.',
    locationsToVisit: 'Locations to visit',
    additionalInformation: 'Additional Information',
    departureNotFound: 'Departures not found',
    additionalInformationNotFound: 'No more Information available',
    departureAndPricing: 'Departures & Pricing',
    departing: 'Departing',
    finishing: 'Finishing',
    availability: 'Availability',
    totalFrom: 'Total From',
    perAdult: 'Per Adult',
    perChild: 'Per Child',
    departureGuaranteed: 'departure guaranteed',
    onSale: 'on sale',
    onRequest: 'on request',
    addToRoute: 'Add to Route',
    addTour:'Add Tour',
    termsConditions: 'Terms & Conditions',
    available: 'Available',
    finish: 'Finish',
    stars: 'Stars',
    reviews: 'Reviews',
    rating: 'Rating',
    default: 'Default',
    title: 'Title',
    lengthOfTour: 'Length of Tour',
    optionsSelected: 'options selected',
    tourNotFound: 'Tour not found',
    validTo: 'Valid to',
    lodging: 'Lodging',
    validFrom: 'Valid From',
    perNight: 'Per Night',
    sortBy: 'Sort By',
    viewItineraryGITtours: 'GIT tours',
    inventoryAddNewAccommodation: 'Choose Another Hotel',
    inventoryAddNewTransport: 'Add New Transport',
    passengersAvoidBoardingComplicationMsg: 'Use all names (Given and Surname) exactly as they appear in the Passport/ID to avoid any boarding complications.',
    passengersRequiredFlightSegmentMsg: 'Required for International Flight Segment.',
    dateOfBirth: 'Date of Birth',
    passengersTourSpecificField: 'Tour Specific Field.',
    passengersTourSegmentMsg: 'Required for Tour `X` Segment',
    visaRequriements: 'Visa Requirement',
    passengersreferencePurposeMsg: 'A visa or airport transit visa may be necessary for travel even when not stopping in the layover country. These requirements are for reference purposes only. Contact the embassy or foreign ministry for more information.',
    passengersDeniedDestinationIssueMsg: 'eRoam is not liable for any passengers denied boarding or entry to any destination due to visa issues.',
    givenNames: 'Given Names',
    passengerNames: 'Given Names *',
    surname: 'Surname',
    passengersurname: 'Surname *',
    familyname: 'Familyname',
    PassengerGender: 'Gender *',
    paymenttotalprice: 'Total Price',
    paymentnewprice: 'New Price',
    emailAddress: 'Email Address',
    passengeremailAddress: 'Email Address *',
    contactNumber: 'Contact Number',
    passengercontactNumber: 'Contact Number *',
    passengersIndividualInventorySpecificationMsg: 'Below fields are required due to individual Inventory Specifications, such as internal flights, tour requirements etc.',
    Nationality: 'Nationality',
    PassengerNationality: 'Nationality *',
    confirmBooking: 'Confirm Booking',
    receiptNumber: 'Receipt Number',
    paymentAddReceiptNumberMsg: 'Add the receipt number of the processed payment.',
    PaymentDeniedDestinationIssueMsg: 'A visa or airport transit visa may be necessary for travel. Requriements found here are for refrence purposes only. Contact the embassy or your foreign ministry for more information. eRoam is no liable for any passenger who is denied from boarding or from entering any destination due to visa issues.',
    amount: 'Amount *',
    Amount:'Amount',
    paymentEnterAmountMsg: 'Enter an amount that is being made.',
    paymentEnterTypeMsg: 'Enter the type of Payment',
    paymentType: 'Payment Type *',
    paymentOnceSubmittedMsg: 'Once the payment details have been submitted, the booking will be confirmed.',
    payment: 'Payment',
    printPDF: 'Print PDF',
    custom: 'Custom',
    additionalNotes: 'Additional Notes',
    list: 'List',
    checkinDash: 'Check-In',
    detailedSummary: 'Detailed Summary',
    dailySummary: 'Daily Summary',
    tripSummary: 'Trip Summary',
    transportName: 'Transport Name',
    activities: 'Activities',
    basicTransportDetails: 'Basic Transport Details',
    transportOwnArrangementRefundMsg: 'This is Refundable.',
    transportOwnArrangementPricingMsg: 'Include this pricing in final Invoice.',
    salePrice: 'Sale Price',
    transportOwnArrangementAddLegtoArrangement: 'Add Leg to Arrangement',
    arrivalLocation: 'Arrival Location',
    transportOwnArrangementAddNewLeg: 'Add a new Leg',
    arrival: 'Arrival',
    departure: 'Departure',
    fromTo: 'From - To',
    selectThePlaceToReturn: 'Select the place to Return',
    selectAReturn: 'Select a Return',
    returnToAnotherCity: 'Return to another city',
    selectPlaceOrigin: 'Select the place of origin.',
    selectOrigin: 'Select an Origin.',
    accomodationSummary: 'Accomodation Summary.',
    transportSummary: 'Transport Summary',
    activitySummary: 'Activity Summary',
    taskListConformDeleteMsg: 'Please confirm that you want to delete Task?',
    ConfirmActionTitle: 'Confirm action',
    passengertitle: 'Title *',
    hotelListTotalCost: 'Total Cost',
    nonstop: 'Non-Stop',
    selected: 'Selected',
    inventoryAddNewHotels: 'Add New Hotels',
    inventoryAddOwnArrangement: 'Add Own Arrangement',
    propertylocation: 'Property Location',
    inventoryAddNewToursTickets: 'Choose Another Tour & Tickets',
    inventoryContactPhone: 'Contact Phone',
    inventoryContactEmail: 'Contact Email',
    flight: 'Flight',
    time: 'Time',
    bookingId: 'Booking id',
    bookingNote: 'note',
    stops: 'Stops',
    layOverDuration: 'Layover Duration',
    itineraryPdfImageText: 'Click or Drag itinerary image here to upload',
    confirm: 'Confirm',
    changePassword: 'Change Password',
    oldPasswordErrorMsg: 'Enter Old Password',
    newPasswordErrorMsg: 'Enter New Password',
    confirmPasswordErrorMsg: 'Password Confirmation Doesn\'t Matched',
    oldPassword: 'Old Password',
    confirmPassword: 'Confirm Password',
    newPassword: 'New Password',
    visitedLocations: 'Locations to Visit',
    checkoutFlightListINFlightAddonMsg: 'In Flight Add-ons',
    checkoutmystifly: 'Mystifly',
    tourDetailstitle: 'Tour  Details',
    update_total_cost: 'Update total cost',
    updateCostConfirmCompleteMsg: 'Are You Sure, Want To Continue With Negative Commission?',
    confirmButton: 'Confirm',
    norating: 'No Rating',
    adult: 'Adult',
    child: 'Child',
    PrimaryPassenger: 'Primary Passenger (Lead Pax)',
    hotelbed: 'Hotelbed',
    activityownemail: 'Supplier Email',
    activityownweb: 'Website',
    activityownphone: 'Phone',
    activityownsupplier: 'Supplier',
    hotelDetails: 'Hotel Details',
    Room: 'Room',
    ownArrangementActivityBasicDetails: 'Basic Activity Details',
    booking: 'Booking',
    tour: 'Tour',
    oopsTheActivity: 'The Activity ',
    oopsTheTour: 'The Tour',
    oopsTheFlight: 'The Flight',
    notAvailable: 'is not available right now.',
    inventoryAddHotelOwnArrangement: 'Own Arrangement (Accomodation)',
    inventoryAddToursOwnArrangement: 'Own Arrangement (Tour & Ticket)',
    inventoryAddReplaceTransport: 'Choose Another Transport',
    inventoryAddTransportOwnArrangement: 'Own Arrangement (Transport)',
    noProductSelected: 'No Product Selected',
    taskSelectDay: 'Select Day',
    taskSelecgender: 'Select Gender',
    age: 'Age',
    relation: 'Relation',
    activityTime: 'Time *',
    activityDate: 'Date *',
    accountId: 'Account Id',
    nationality: 'Nationality',
    city: 'City',
    address: 'Address',
    postcode: 'Post Code',
    region: 'Region',
    ontarioResident: 'Ontario Resident',
    prepaidGratuities: 'Prepaid Gratuities',
    mobilePhone: 'Contact',
    titleBooking: 'Title',
    genderBooking: 'Gender',
    emailBooking: 'Email Address',
    paymentAmount: 'Payment Amount',
    checkoutRceiptNumber: 'Receipt Number',
    loadPnr: 'Load PNR',
    activityownArrangementPropertyInvoice: 'Include this pricing in final Invoice.',
    activityownArrangementPropertyRefund: 'This is Refundable.',
    bookingVoucherfileType: '(Supported file types .jpeg, .jpg, .png, .pdf)',
    bookingVouchermsg: 'Click here to upload voucher',
    carrierName: 'Carrier Name',
    departureDateandTime: 'Departure Date & Time',
    arrivalDateandTime: 'Arrival Date & Time',
    DateandTime: 'Date & Time',
    airlineReference: 'Airline Reference',
    pnrNumber: 'Enter PNR',
    pnrInstructions: 'Please enter valid PNR number to get itinerary',
    termsandconditions: 'Terms and conditions',
    total: 'Total',
    baggageAdult: 'Adult / Child Baggage',
    baggageChild: 'Child Baggage',
    gitWarningMessage: 'You have selected invalid Tour for this city',
    summeryConfirmCompleteMsg: 'Are You Sure, Want To Continue With Invalid Tour Selection?',
    tourType: 'Tour Type',
    SearchPlace: 'Search Place | Search Places',
    SearchCity: 'Search City | Search Cities',
    manager:'Manager',
    product:'Product',
    finance:'Finance',
    admin:'Admin',    
    viewProductManager:'View Product Manager',
    viewfinanceManager:'View Finance Manager',
    viewAdminManager:'View Admin Manager',
    viewsupplierManager:'View Supplier Manager',
    viewmarketingManager:'View Marketing Manager',
    DaterangeFrom:'Date Range From',
    DaterangeTo:'Date Range To',
    DataType:'Data Type',
    FileType:'File Type',
    downloadNow:'Download Now',
    ConsultantName:'Consultant Name',
    SupplierName:'Supplier Name',
    ClientFile:'Client File',
    pay:'Pay',
    TypeofPayment:'Type of Payment',
    Login:'Log in',
    exportData:'Export Data',
    roomNumber: 'Room',
    storeName:'Store Name',
    store:'Store',
    manageUsers:'Manage Users',
    manageBrands:'Manage Brands',
    viewReports:'View Reports',
    brand:'Brand',
    manageLicensee:'Manage Licensee',
    ManageLicensee:'Search Licensee',
    OnboardNewLicensee:'Onboard New Licensee',
    CreateNewPackage:'Create a New Package',
    flyingFrom: 'Flying From',
    selectClass: 'Select Class',
    addtransport:'Add Transport',
    addhotels:'Add Hotels',
    addactivities:'Add Activities',
    manageclientpreferences:'Manage Client Preferences',
    Return:'Return',
    oneway:'One Way',
    multycity:'Multy City',
    flyingfrom:'Flying From',
    selectclass:'Select Class',
    returndate:'Return Date',
    comingsoon:'Coming soon',
    thingstodo:'Things to do',
    extendedtouring:'Extended Touring',
    selectinterest:'Select Interest',
    searchpnr:'Search PNR',
    googlesearch:'Google Search',
    package:'Package',
    addcustomer:'Add Customer',
    geoLocationManager:'GEO Location Manager',
    supplieroperatorManager:'Supplier / Operator Manager',
    yieldManager:'Yield Manager',
    manageProperty:'Manage Property',
    packageBuilder:'Package Builder',
    plotmaps:'Plot maps',
    reporting:'Reporting',
    CSVDownload:'CSV Download',
    makePayments:'Make Payments',
    packageName:'Package Name',
    uploadImages:'Upload Images',
    toursandtickets:'Tours and Tickets',
    hotel:'Hotel',
    packageOverview:'Package Overview',
    packageItinerary:'Package Itinerary',
    Optional:'Optional',
    createpackagefromtour:'Create Package from Tour',
    Currency:'Currency',
    companyName:'Company Name',
    Phone:'Phone (Including country code)',
    url:'URL',
    uploadLogo:'Upload Logo',
    selectProduct:'Select Product',
    hotelSupplier:'Hotel Supplier',
    tourSupplier:'Tour Supplier',
    transportSupplier:'Transport Supplier',
    otherSupplier:'Other Supplier',
    selectSettings:'Select Settings',
    defaultLanguage:'Default Language',
    defaultCurrency:'Default Currency',
    chooseTemplate:'Choose Template',
    paymentGateway:'Payment Gateway',
    selectSubscription:'Select Subscription',
    licenceType:'Licence Type',
    howmanylicences:'How many licences?',
    addons:'Add-ons',
    paymentTerms:'Payment Terms',
    licenseenameoremail:'Licensee name or email',
    createProfilesandClientDetails:'Create Profiles & Manage Client Details',
    passportNumber:'Passport Number',
    passportissuedate:'Passport Issue Date',
    passportExpiryDate:'Passport Expiry Date',
    passportissuingcountry:'Passport Issuing Country',
    clientContact:'Client Contact',
    Phonehome:'Phone (home)',
    Phonework:'Phone (work)',
    phonemobile:'Phone (mobile)',
    AddressLinOne:'Address (Line One)',
    AddressLineTwo:'Address (Line Two)',
    StateProvinceRegion:'State / Province / Region',
    zipPostalCode:'ZIP / Postal Code',
    country:'Country',
    newsletter:'Newsletter',
    companyABN:'Company ABN',
    paName:'PA name',
    clientLoyaltyPrograms:'Client Loyalty Programs',
    srno:'Sr No',
    loyaltyType:'Loyalty Type',
    providerName:'Provider Name',
    membershipNumber:'Membership Number',
    clientPreferences:'Client Preferences',
    airline:'Airline',
    airlines:'Airline(S)',
    travelClass:'Travel Class',
    preferredSeating:'Preferred Seating',
    mealRequest:'Meal Request',
    Hotels:'Hotel(s)',
    hotelChain:'Hotel Chain',
    Cars:'Car(s)',
    carRental:'Car Rental',
    CarType:'Car Type',
    interests:'Interest(s)',
    linkedprofiles:'Linked profiles',
    Preferreddestinations:'Preferred destinations',
    businessPreferences:'Business Preferences',
    otherPreferences:'Other Preferences',
    pendingBookings:'Pending Bookings',
    archiveBookings:'Archive Bookings',
    activeBookings:'Active Bookings',
    viewdetails:'View Details',
    update:'Update',
    bookingmanagement:'Booking Management',
    bookingDashboard:'Booking Dashboard',
    bookingoverview:'booking overview',
    Generalbookingdetails:'General booking details.',
    type:'Type',
    created:'Created',
    segmentoverview:'Segment overview',
    totalnumberofsegment:'The total number of products/ segments, and the outstanding Confirmed segments.',
    segmentsconfirmedvstotalsegment:'Segments Confirmed vs. Total Segments Included in booking.',
    amountremainingforclient:'The amount remaining for the client to pay before the balance due date.',
    outstandingAmount:'Outstanding Amount',
    outstanding:'Outstanding',
    balanceDue:'Balance Due',
    bookingDetails:'Booking Details',
    user:'User',
    consultantNotes:'Consultant Notes',
    historynotfound:'History Not Found',
    leadpaxdetails:'Lead Pax Details',
    additionalpaxdetails:'Additional Pax Details',
    locationdetails:'Location Details',
    selectedHotels:'Selected Hotels',
    confirmed:'Confirmed',
    snooze:'Snooze',
    upload:'Upload',
    novoucherfound:'No Voucher Found',
    Nopaymentsfound:'No Payments Found',
    newvoucher:'Upload a new voucher for this booking',
    allocation:'Allocation',
    selectedtoursandtickets:'Selected Tours & Tickets',
    offeredIn:'Offered In',
    tofrom:'TO / FROM',
    bookingRef:'Booking Ref',
    selectedTransport:'Selected Transport',
    segmentmessage:'By confirming this segment, you are acknowledging that this product has been booked with the supplier, Outside of this application. In order to complete the confirmation, please complete the field below.',
    bookingReference:'Booking Reference',
    suppliersbookingreference:'Enter the supplier\'s booking reference',
    confirmsegment:'Confirm Segment',
    pickupTime:'Pick-Up Time',
    totalDuration:'Total Duration',
    layoverTime:'Lay over Time',
    ticketType:'Ticket Type',
    class:'Class',
    provider:'Provider',
    viewcustomer:'View Customer',
    costofitinerary:'Total cost of the itinerary',
    paid:'paid',
    receiptofallpayments:'Receipts of all payments to date',
    balanceduetocustomer:'Balance due to collect from the customer',
    recordcustomerpayment:'Record a customer payment below.',
    completePayment:'Complete Payment',
    cancel:'Cancel',
    accommodationORtransportation:'* Please select at least one Accommodation or Transportation.',
    preferredPropertyClass:'Preferred Property Class',
    transportation:'Transportation',
    preferredtransporttype:'Preferred Transport Type',
    preferredtransportclass:'Preferred Transport Class',
    agegroup:'Age Group',
    interest:'Interest',
    activityinterest:'Activity Interest',
    applytoalllocations:'Apply To All Locations',
    applythislocation:'Apply This Location',
    apply:'Apply',
    hotelDescription:'Hotel Description',
    activityDescription:'Activity Description',
    checkoutcancelationdescription :'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for \'lorem ipsum\' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    OverrideQuotation:'Save/Override Quotation',
    DuplicateQuotation:'Duplicate Quotation',
    OriginCity: 'Origin City',
    ReturnCity: 'Return City',
    AddTransport: 'Add Transport',
    AddHotels: 'Add Hotels',
    AddActivities: 'Add Activities',
    ManagePreferences: 'Manage Client Preferences',
    searchPNR: 'Search PNR',
    oneWay: 'One Way',
    multiCity: 'Multi City',
    // Last confirmed Table entry
    // Put All New elements below this line.
    Preferences:'Preferences',
    SelectTransportType:'Select Transport Type (Optional)',
    SelectActivityInterest:'Select Activity Interest (Optional)',
    SelectNationality:'Select Nationality (Optional)',
    SelectPropertyClass:'Select Property Class (Optional)',
    SelectTransportClass:'Select Transport Class (Optional)',
    SelectAgeGroup:'Select Age Group (Optional)',
    CustomerDetail:'Customer Details',
    UploadPhoto:'Upload Photo',
    ContactMethod:'Contact Method',
    LoyaltyType:'Loyalty type',
    ProviderName:'Provider name',
    MembershipNumber:'Membership number',
    LeisurePreference:'Leisure Preference',
    Add:'Add',
    AddSeatNumber:'Add Seat Number',
    Other:'Other',
    HotelChain:'Hotel Chain(s)',
    DuplicatetoBusinessPreferences:'Duplicate to Business Preferences',
    DuplicatetoOtherPreferences:'Duplicate to Other Preferences (eg. family)',
    CarRental:'Car Rental(s)',
    OtherPreferences:'Other Preferences (e.g family)',
    EditCustomer:'Edit Customer',
    SaveCustomer:'Save Customer',
    ItineraryInclusions:'Itinerary/Inclusions',
    NoofRoom:'No. of Room',
    TotalRoomPrice:'Total Room Price',
    QuotationHistory:'Quotation History',
    QuoteID:'Quote ID',
    DuplicateQuote:'Would you like to override the previous quote or make a new duplicate quote?',
    TotalInventory:'Total Inventory',
    Activity:'Activity',
    PassengersToAllocate:'Passengers to Allocate',
    PaymentMethods:'Payment Methods',
    CreditCard:'Credit Card',
    NameonCard:'Name on Card',
    CreditCardNumber:'Credit Card Number',
    SelectMonth:'Select Month',
    SelectYear:'Select Year',
    SecurityCode:'Security Code',
    DebitCard:'Debit Card',
    DebitCardNumber:'Debit Card Number',
    PaymentSummary:'Payment Summary',
    ItineraryPricing:'Itinerary Pricing breakdown including customer cost total and by PAX.',
    TotalCost:'Total Cost including Tax',
    Dublicatequotemessage:'Would you like to override the previous quote or make a new duplicate quote?',
    Quotationhistory:'Quotation History',
    RemoveImage:'Remove Image',
    About:'About',
    Languages:'Language',
    Religion:'Religion',
    Weather:'Weather',
    ETD:'ETD',
    Layover:'Layover',
    ETA:'ETA',
    CheckIn:'Check-in',
    CheckOut:'Check-out',
    Notes:'Notes',
    manual: 'Manual',
    addLocation : 'Add Location',
    manageOriginReturn: 'Manage Origin & Return',
    Itineraryfor:'Itinerary for',
    Name:'Name',
    PickUplocation:'Pick-up location',
    ActivityCode:'Activity#',
    ViewMap:'View Map',
    TourName:'Tour Name',
    TourOperator:'Tour Operator',
    OverView:'OverView',
    Propertyreference:'Property Reference',
    Supplierreference:'supplier Reference',
    OwnArrangementTransport:'Own Arrangement Transport',
    OwnArrangementHotel:'Own Arrangement Hotel',
    OwnArrangementActivity:'Own Arrangement Activity',
    SendEmail:'Send Email',
    EndoftheItinerary:'End of the Itinerary',
    OtherPaymentMode:'Other Payment Mode',
    gdprCharge:'ATOL Fee',
    setupAccount:'Setup an Account',
    register:'Register',
    NET: 'NET',
    Marketing: 'Marketing',
    activityBookingNotAvailable:"We're afraid, the tours or tour option you are trying to book is sold out and no longer available. Please try other tour",
    conditions:'Condition|Conditions',
    condition:'Condition',
    noActivityFound:'No Activity Found', 
    CategoryName:'Category',
    percentage: 'Percentage',
    //client on boarding
    signUpWithFacebook : 'Sign Up with Facebook',
    signUpWithGoogle : 'Sign Up with Google',
    business : 'Business',
    legalNameofBusiness : 'Legal Name of Business',
    licenseHolder : 'License Holder',
    clientHolder: 'Client Holder',
    apartmentSuiteEtc : 'Apartment, Suite, etc',
    street : 'Street',
    state : 'State',
    timeZone : 'TimeZone',
    selectInstance:'Select Instance',
    domain: 'Domain',
    template : 'Template',
    uploadFavicon: 'Upload Favicon',
    dragLogo:'Drag and drop theme logo here to upload',
    dragFavicon:'Drag and drop theme favicon here to upload',
    imageFileType:'Supported file types, .jpeg, .jpg, .png',
    dimension:'Dimensions',
    browsePageTitle:'Browse Page Title',
    font:'Font',
    scheme:'Scheme',
    color:'Colour',
    primary:'Primary',
    secondary:'Secondary',
    disable:'Disable',
    themeColorPrimary:'Header, Search Tabs, Buttons, Map Location Pointer',
    themeColorSeconday:'Description, Titles',
    themeColorDisable:'Disable buttons, Text',
    tourTicket:'Tours & Tickets',
    accommodationType:'Hotel, Restaurant, Resort',
    transportType:'Flight, Bus, Car, Train',
    tourTicketType:'Activity, Tour',
    transferType:'Car, Bus, Train',
    credentials:'Credentials',
    key:'key',
    secret:'Secret',
    connect:'Connect',
    use:'Use',
    Success:'success',
    faliure:'Failure',
    sidebarHeadingSavepackageBuilder: 'Create Package',
    packagesummery: 'Package Summary',
    packageImage: 'Package Image',
    book:'Book',
    agencypricing:'Agency Pricing',
    sidebarHeadingupdatepackageBuilder: 'Update Package',
    clonepackageBuilder: 'Clone Package',
    pickUp:'Pick Up',
    dropOff:'Drop Off',
    carHire:'Car Hire',
    passengerDetails: 'Passenger Details',
    baseRateperPerson: 'Base Rate Per Person',
    transfer: 'Transfer',
    addtransfer: 'Add Transfer',
    retail: "RETAIL",
    Retail:"Retail",
    image: 'Image',
    map: 'Map',
    bookpackage:'Book Package',
    trasportType:'Transport Type',
    redirectTransportList: 'Choose Transport',
    redirectTransfers:'Choose Transfer',
    countryOfResidency: 'Country of Residency',
    viewOnimage:'View on Image',
    viewOnmap:'View on Map',
    pricePerDay: 'Price Per Day',
    itinerary:'Itinerary',
    customerId:'Customer Id',
    cancellationSupplierName:'Supplier Name',
    bookingstatus:'Status',
    cancellationAmount:'Amount',
    cancellationCharge:'Cancellation Charge',
    event:'Event',
    series:'Series',
    venue:'Venue',
    end:'End',
    allotment:'Allotment',
    description:'Description',
    minimum:'min | minimum',
    maximum:'max | maximum',
    sale:'sale | sales',
    seatClassification:'Seat Classification',
    Net:'Net',
    optRrp:'Opt RRP',
    rrp:'RRP',
    ticketing:'Ticketing',
    bookingOption:'Disable live bookings',
    merchandise:"Merchandise",    
    ticket:'Ticket',
    activity:'Activity | Activites',
    redirectMerchandiseDetail:'Merchandise Details',
    redirectMerchandiseList: 'Choose Merchandise',
    redirectEventDetail:'Event Details',
    redirectEventList: 'Choose Event',
    eventId:'Event Id',
    eventType:'Event Type',
    eventStartDate:'Start Date & Time',
    eventEndDate:'End Date & Time',
    eventName:'Event Name',
    merchandiseId:'Merchandise Id',
    reasonForCancellation:'Reason',
    searchTitleCarhire:'Car Hire'
};
