export const Japanese: any = {
   welcomeMsg: '歓迎',
   searchTitleItinerary: 'ダイナミックな旅程',
   searchTitleFlights: 'フライト',
   searchTitleHotel: 'ホテル',
   searchTitleTours: 'ツアー',
   searchTitlePackages: 'パッケージ',
   searchTitleRentalcars: 'レンタカー',
   searchTitleTransfers: '送金',
   searchTitleCruises: 'クルーズ',
   searchTitleEvents: 'イベント',
   searchTitleInsurance: '保険',
   searchInstructions: '興味のある場所を追加',
   searchAddPlaces: '場所を追加',
   searchBlurb: '旅程を作成します。目的地を入力して訪問したい場所をそれぞれ追加し、Enterをクリックしてそれらを追加します。',
   consultantProfile: 'コンサルタントプロフィール',
   consultantSearchCust: '顧客を検索',
   consultantSearchBlurb: '顧客の予約を探す',
   consultantSearchCustButton: '顧客を検索',
   consultantSearchCont: '連絡先を検索',
   consultantQuick: '迅速なアクセス',
   consultantActive: 'アクティブ予約',
   consultantActiveBlurb: 'あなたのアクティブな予約のリストを見る',
   consultantActiveButton: 'アクティブな予約を見る',
   consultantPending: '保留中の予約',
   consultantPendingBlurb: '保留中の予約のあなたのリストを見る',
   consultantPendingButton: '保留中の予約を見る',
   consultantItineraries: '旅程',
   consultantAccount: 'アカウントの概要',
   consultantNotices: '通知と通知',
   consultantTaskList: 'タスクリスト',
   consultantTaskYours: 'あなたの仕事',
   consultantCustName: '顧客名',
   consultantActiveAgency: 'アクティブエージェンシーの予約を見る',
   consultantPendingAgency: '保留中の代理店の予約を見る',
   Completed: '完了しました',
   Actions: '行動',
   Snoozed: 'スヌーズ',
   Tasks: 'タスク',
   consultantTasksNone: 'すべて完了したようです',
}