
    import { Component, Vue } from 'vue-property-decorator';
    import CitySearch from '@/Module/Global/components/common/CitySearch.vue';
    // import PassengersCustomer from "@/Module/Global/Components/Sidebar/PassengersCustomer.vue";
    import DatePicker from 'vue2-datepicker';
    import moment from "moment";
    @Component({
        components:{            
            CitySearch,
            DatePicker,
            // PassengersCustomer
        }
    })
    export default class widgetFlightReturn5 extends Vue {
        fromCity:any="";
        toCity:any="";
        checkin_date:any="";        
        classSelect:any="Select class";   
        flightClass:any=[
            {text:'Economy',value:'Economy'},
            {text:'Premium Economy', value:'Premium Economy'},
            {text:'Business Class', value:'Business Class'},            
        ]
        getClassValue(e){
            this.classSelect = e.target.innerHTML;
        }
    }
