
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
// import PassengersAdults from "@/Module/Global/Components/Sidebar/Passengers-adults.vue";
import { applicationName } from "@/plugins/constants";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
// import Search from "@/Module/Global/Components/Search/Search.vue";
// import eMap from "@/Module/Global/js/eroam-map.js";
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import Itinerary from "@/Module/Global/Models/Itinerary";
// import IntializeRepository from "@/Module/Global/Repository/IntializeRepository";
// import commonRepo from "@/Module/Global/Repository/CommonRepository";

@Component({
  components: {
    // Datepicker,
    // Search,
    // PassengersAdults,
  },
})
export default class FlightReturn extends Vue {
  flightClassselect: any = 0;
  flightClass: any = [
    { value: 0, text: "Economy" },
    { value: 1, text: "Premium Economy" },
    { value: 2, text: "Business" },
    { value: 3, text: "First" },
  ];
  selected_preference: any = {
    accomodation_property_type: "",
    accomodation_property_class: "",
    transport_type: "",
    transport_class: "",
    nationality: "",
    mationalities: "",
    age_groups: "",
    age_group: "",
    activity_category: "",
  };  
  isPreferenceModalVisible: boolean = false;
  applicationName: any = applicationName;
  searching: boolean = false;

  datePicker = {
    disabledDates: {
      to: new Date(Date.now()),
    },
  };

  checkoutDisabledDates = {
    to: new Date(Date.now()),
  };

  // @Watch("checkin_date") checkinDateChanged(newValue, oldValue) {
  //   if (applicationName == "wolo") {
  //     this.checkout_date = moment(newValue)
  //       .add(7, "days")
  //       .toDate();
  //   } else {
  //     this.checkout_date = moment(newValue)
  //       .add(3, "days")
  //       .toDate();
  //   }
  //   this.checkoutDisabledDates = {
  //     to: new Date(newValue),
  //   };
  // }

  searchRequest: any = [];
  cityFrom = null;
  cityTo = null;
  checkin_date: any = '';
  checkout_date: any = '';

  // mounted() {
  //   this.initialSearchfrom();
  //   this.initialSearchTo();
  //   this.checkin_date = moment()
  //     .add(1, "days")
  //     .toDate();
  //   this.checkout_date = moment()
  //     .add(3, "days")
  //     .toDate();
  // }

  // search() {
  //   this.searchRequest = [];
  //   if (!this.cityFrom) {
  //     this.$toasted.error("Enter flying from location", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //     return;
  //   }
  //   if (!this.cityTo) {
  //     this.$toasted.error("Enter destination location", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //     return;
  //   }
  //   this.searchRequest.push(this.cityFrom, this.cityTo);
  //   if (this.searchRequest.length) {
  //     let default_nights = moment(this.checkout_date).diff(
  //       moment(this.checkin_date),
  //       "days",
  //       true
  //     );
  //     if (default_nights >= 0) {
  //       this.searching = true;
  //       this.setPrefrenceForProductOnly();
  //       this.$store.dispatch(
  //         "setCheckinDate",
  //         moment(this.checkin_date).format("YYYY-MM-DD")
  //       );
  //       this.$store.dispatch(
  //         "setCheckoutDate",
  //         moment(this.checkout_date).format("YYYY-MM-DD")
  //       );
  //       this.$store.dispatch("SetSearchType", "transport");
  //       ApiRequest.getGooglePlaceCityInfo(this.searchRequest)
  //         .then((res) => {
  //           res = res.data;
  //           if (res.code == 200 && res.data) {
  //             this.$store.dispatch("setSelectedCities", res.data);
  //             let routes: any = _.cloneDeep(res.data);
  //             routes[0]["check_in_date"] = moment(this.checkin_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[0]["check_out_date"] = moment(this.checkin_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[0]["isMultiCity"] = true;
  //             routes[1]["check_in_date"] = moment(this.checkout_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[1]["check_out_date"] = moment(this.checkout_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[1]["isMultiCity"] = true;
  //             routes.push(JSON.parse(JSON.stringify(routes[0])));
  //             routes[2]["check_in_date"] = moment(this.checkout_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[2]["check_out_date"] = moment(this.checkout_date).format(
  //               "YYYY-MM-DD"
  //             );
  //             routes[2]["isMultiCity"] = true;
  //             let itinerary = [];
  //             for (let i = 0; i < routes.length; i++) {
  //               let iti = new Itinerary({}, {}, {}, {}, {});
  //               let initialize = new IntializeRepository();
  //               iti.activity = initialize.activityInitialize();
  //               iti.transport = initialize.transportInitialize();
  //               iti.transport.default = {
  //                 own_arrangement: 1,
  //                 isMultiCity: true,
  //               };
  //               iti.hotel = initialize.hotelInitialize();
  //               itinerary.push(iti);
  //             }
  //             this.$store.dispatch("SetItinerary", itinerary);
  //             this.$store.dispatch("SetTransportTypeCall", 2);
  //             this.$store.dispatch("SetRoute", routes);
  //             this.$store.dispatch("SetInitalRoute", routes);
  //             this.$store.dispatch("SetLoading", 1);
  //             this.$router.push("/transports");
  //           }
  //           this.searching = false;
  //         })
  //         .catch((error) => {
  //           this.searching = false;
  //           this.$toasted.error("Something went wrong", {
  //             theme: "toasted-primary",
  //             position: "top-right",
  //             duration: 2000,
  //           });
  //         });
  //     } else {
  //       this.$toasted.error("Please enter place name", {
  //         theme: "toasted-primary",
  //         position: "top-right",
  //         duration: 2000,
  //       });
  //     }
  //   }
  // }

  // initialSearchfrom() {
  //   let vm = this;
  //   let input = document.getElementById("autocompleteFrom");
  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"],
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });

  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length
  //       ? filtered_city_array[0].long_name
  //       : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.cityFrom = {
  //       ...place[0],
  //       small_image: imageUrl,
  //     };
  //     $("#autocomplete").val("");
  //   });
  // }

  // initialSearchTo() {
  //   let vm = this;
  //   let input = document.getElementById("autocompleteTo");
  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"],
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });

  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length
  //       ? filtered_city_array[0].long_name
  //       : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.cityTo = {
  //       ...place[0],
  //       small_image: imageUrl,
  //     };
  //     $("#autocomplete").val("");
  //   });
  // }
  // setPrefrenceForProductOnly() {
  //   let commonRepObj=new commonRepo();
  //   let vm = this;
  //   commonRepObj.setPrefrenceForProductOnly(vm);
  // }
}
