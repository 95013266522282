
import { Component, Prop, Vue, Emit ,Watch} from "vue-property-decorator";
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import eMap from "@/Module/Global/js/eroam-map.js";
import _ from "lodash";
// import $ from "jquery";
// import moment from "moment";
import { applicationName } from "@/plugins/constants";
// import Passengers from "@/Module/Global/Components/Sidebar/Passengers.vue";
// import Datepicker from "vuejs-datepicker";
// import PreferenceModal from "@/Module/Global/Components/Preference/PreferenceModal.vue";
// import CommonRepository from "@/Module/Global/Repository/CommonRepository.ts";
@Component({
  components: {
    // Datepicker,
    // Passengers,
    // PreferenceModal
  }
})
export default class GoogleSearch extends Vue {

  applicationName: any = applicationName;
  searchValue: any = []; // For Slected City Google Response Array
  searchValueRecord: any = []; // For Selected City Name ARRAY
  searchRequest: any = [];
  removeFlag = "yes";
  searchData: any = {
    cities: [],
    selected_cities: [],
    destination_city: {
      label: "",
      value: ""
    },
    route_lists: [],
    selected_route: {},
    check_in_date: "",
    accomodatin_rating: [],
    activity_category_id: "",
    initiate_search: 0
  };
  datePicker = {
    disabledDates: {
      to: new Date(Date.now())
    }
  };
  checkin_date: any = '';
  preferences: any = [];
  isPreferenceModalVisible: boolean = false;
  allActiveProduct: any = {};
  is_transport_active: boolean = true;
  is_hotel_active: boolean = true;
  is_activity_active: boolean = true;
   is_transfer_active: boolean = true;
  is_auto_trading_location_active: boolean = false;
  cityOptions = [
    { value: 0, text: "No Origin or Return (Default) " },
    { value: 1, text: "Add 0 nights to Origin & Return"},
    { value: 2, text: "Add 0 nights to Origin (Only)" },
    { value: 3, text: "Add 0 nights to Return (Only)" }
  ];
  selectedCityOption = 0;

  // @Watch ('$store.state.search.loginUserInformation') changeView (newValue,oldValue){
  //   if(_.isEmpty(this.$store.state.search.loginUserInformation)){
  //   this.searchValueRecord = [];
  //   }
  // }

  // mounted() {
  //   let vm = this;
  //   vm.initialSearch();
  //   if (vm.checkin_date) {
  //     vm.checkin_date = vm.checkin_date.setDate(
  //       vm.checkin_date.getDate() + 122
  //     );
  //     vm.$store.dispatch("setCheckinDate", vm.checkin_date);
  //   }
  //   vm.getPreferences();
  //   let allActiveProduct = {
  //     is_transport_active: vm.is_transport_active,
  //     is_hotel_active: vm.is_hotel_active,
  //     is_activity_active: vm.is_activity_active,
  //     is_transfer_active: vm.is_transfer_active,
  //     is_auto_trading_location_active: vm.is_auto_trading_location_active,
  //     set_preference: 0
  //   };
  //   vm.$set(vm.allActiveProduct, "allActiveProduct", allActiveProduct);
  //   vm.$store.dispatch("SetallActiveProducty", allActiveProduct);
  //    if(applicationName=='wolo'){
  //     vm.selectedCityOption=1;
  //   }
  // }
  // changeCheckInDate() {
  //   let vm = this;
  //   setTimeout(function() {
  //     vm.checkin_date = moment(vm.checkin_date).format("YYYY-MM-DD");
  //     vm.$store.dispatch(
  //       "setCheckinDate",
  //       moment(vm.checkin_date).format("YYYY-MM-DD")
  //     );
  //   }, 1000);
  // }
  // initialSearch = () => {
  //   let vm = this;
  //   let input = document.getElementById("autocomplete");
  //       console.log(input,'input');

  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"]
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });
      
  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     // let city_name1 = filtered_city_array.length
  //     //   ? filtered_city_array[0].long_name
  //     //   : filtered_city_array_1.length ? filtered_city_array_1[0].long_name : place[0].name;
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length ? filtered_city_array[0].long_name : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.searchValueRecord.push({
  //       index: vm.searchValueRecord.length,
  //       name: city_name,
  //       country_name: country_name,
  //       status: true
  //     });
  //     vm.searchValue.push(place);
  //     vm.searchRequest.push({
  //       ...place[0],
  //       small_image: imageUrl
  //     });
  //     $("#autocomplete").val(""); 
  //   });
  // };
  // loadGooglePlaceCityInfo() {
  //   let input = document.getElementById("autocomplete");
  //   let vm = this;
  //   vm.$store.dispatch("SetSearchType", "googleplace");
  //  if(vm.searchRequest.length>0){
  //    vm.searchData.initiate_search = 1;
  //    ApiRequest.getGooglePlaceCityInfo(vm.searchRequest)
  //     .then(function(res) {
  //       res = res.data;
  //       if (res.code == 200 && res.data) {
  //         vm.searchData.cities = res.data;
  //         vm.searchData.selected_cities = res.data;
  //         vm.$store.dispatch("setSelectedCities", res.data);
  //         vm.setRoutesData(res.data);
  //       }
  //     })
  //     .catch(function(error) {
  //       vm.searchData.initiate_search = "";
  //     });
  //  }
  //  else{
  //      this.$toasted.error("Please enter place name", { 
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 1000,
  //     });
  //  }
  // }
  // setRoutesData(data: any) {
  //   var routes: any = _.cloneDeep(data);
  //   if (this.selectedCityOption === 1) {
  //     routes[0].default_nights = 0;
  //     routes[0].origin = true;
  //     routes[routes.length - 1].default_nights = 0;
  //     routes[routes.length - 1].return = true;
  //   } else if (this.selectedCityOption === 2) {
  //     routes[0].default_nights = 0;
  //     routes[0].origin = true;
  //   } else if (this.selectedCityOption === 3) {
  //     routes[routes.length - 1].default_nights = 0;
  //     routes[routes.length - 1].return = true;
  //   }
  //   this.$store.dispatch("SetRoute", this.addInventoryRequests(routes));
  //   this.$store.dispatch("SetInitalRoute", routes);
  //   this.redirectToMap();
  // }
  // addInventoryRequests(routes: any) {
  //   return _.forEach(routes, route => {
  //     route.inventory = this.$store.state.search.allActiveProduct;
  //   });
  // }
  // redirectToMap() {
  //   this.$router.push("/confirm-route");
  // }
  // removeTag = (tag: any) => {
  //   let vm = this;
  //   let tagIndex:any = 0;
  //   let updatedCities = _.forEach(vm.searchValueRecord, (cityR,index) => {
  //     if (cityR == tag) {
  //       cityR.status = false; 
  //       vm.searchValueRecord.splice(index, 1);
  //       tagIndex = index;
  //     }
  //   });

  //   let requestData = _.forEach(vm.searchRequest, (search,index) => {
  //     if (search != undefined && search.name == tag.name && tagIndex == index) {
  //       vm.searchRequest.splice(index, 1); 
  //     }
  //   });
    
  //   let updatedCitiesArray = _.forEach(vm.searchValue, (city,index) => {
  //     if (city != undefined && city[0].name == tag.name && tagIndex == index) {
  //       vm.searchValue.splice(index, 1);
  //     }
  //   });
  //   vm.removeFlag = "no";
  //   setTimeout(() => {
  //     vm.removeFlag = "yes";
  //   }, 500);
  // };
  // getPreferences() {
  //   var vm = this;
  //   ApiRequest.getPreferences()
  //     .then(function(res) {
  //       var response = res.data.data;
  //       if (response) {
  //         response.transportTypes.unshift({ code: 0, name: "Select All" });
  //         vm.preferences = response;
  //         vm.$store.dispatch("setPreferences", response);
  //       }
  //     })
  //     .catch(function(error) {});
  // }
  // showPreferenceModal() {
  //   this.isPreferenceModalVisible = true;
  // }
  // activeProduct(e, productType) {
  //   let vm = this;
  //   vm.commonRepository.activeProduct(vm, e, productType); // Set all active and Inactive product
  // }
}
