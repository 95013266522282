export const ChineseSimplified: any = {
   creatingFor: '为...创建路线',
   creatingNew: '为新客户创建路线',
   searchTitleItinerary: '路线',
   searchTitleFlights: '航班和火车',
   searchTitleHotel: '酒店和BnBs',
   searchTitleTours: '旅游和门票',
   searchTitlePackages: '套餐',
   searchTitleRentalcars: '租车和房车',
   searchTitleTransfers: '转机',
   searchTitleCruises: '巡游',
   searchTitleEvents: '事件',
   searchTitleInsurance: '保险',
   searchInstructions: '添加出发地点，然后选择搜索。在“确认路线”中，在下一步中添加其他位置。',
   searchAddPlaces: '出发位置',
   searcPlaceholder: '位置',
   searchError: '*请选择两个位置',
   searchBlurb: '创建行程”。通过键入目的地来添加您希望访问的每个位置，然后单击Enter以添加它们。',
   consultantProfile: '顾问资料',
   consultantSearchCust: '客户',
   Searchdata: '搜索',
   consultantSearchBlurb: '查找客户的预订',
   consultantSearchCustButton: '搜索客户',
   consultantSearchCont: '搜索联系人',
   consultantQuick: '快速访问',
   consultantActive: '预订',
   ActiveData: '有效',
   consultantActiveBlurb: '查看您的有效预订列表',
   consultantActiveButton: '查看当前的预订',
   consultantPending: '预订',
   PendingData: '待处理',
   consultantPendingBlurb: '查看您待处理的预订列表',
   consultantPendingButton: '查看待处理的预订',
   consultantItineraries: '活动路线',
   consultantCustomers: '客户',
   consultantAccount: '帐户摘要',
   consultantNotices: '通知和通知',
   consultantTaskList: '任务列表',
   consultantTaskYours: '您的任务”，任务',
   consultantTaskNotFound: '未找到',
   consultantTaskConfirmCompleteMsg: '确定要完成”标记为完成的任务',
   consultantCustName: '客户名称',
   consultantActiveAgency: '查看有效的代理商预订',
   consultantPendingAgency: '查看待处理的代理商预订',
   consultantTaskCustName: '客户名称',
   consultantTaskBookingId: '预订ID',
   consultantTaskNote: '注释',
   consultantTaskDate: '选择日期',
   consultantTaskAddButton: '添加任务',
   consultantTaskEditButton: '编辑任务',
   consultantTaskCompleted: '完成',
   consultantTaskactions: '操作',
   consultantTasksnoozed: '延后',
   consultantTasktasks: '任务',
   consultantTasksNone: '好像您是最新的，做得很好',
   consultantMidOffice: '办公室中部',
   consultantMidOfficeUpload: '将数据上传到办公室中部',
   sidebarHeading: '确认路线”',
   siderbarInstructions: '添加所有名胜古迹，并将其设置为所需的顺序。选择每个位置的出发日期和停留时间。如果选择预先选择的库存，则通过每个位置的库存选项优化结果。',
   sidebarOptionDeparture: '出发和旅行的人',
   sidebarDepDate: '出发日期',
   sidebarDepSelect: '选择要离开第一个地点的日期',
   sidebarDepError: '错误，请选择一个',
   travellersNumber: '旅客人数',
   siderbarnewInstructions: '添加所有名胜古迹并按所需顺序设置。选择每个位置的出发日期和停留时间。如果选择预先选择的库存，则通过每个位置的库存选项优化结果。',
   sidebarnewOptionDeparture: '出发和旅行的人',
   depDate: '出发日期',
   sidebarnewDepSelect: '选择要离开第一个地点的日期',
   sidebarnewDepError: '错误，请选择一个',
   sidebarTravellersNumber: '旅客人数',
   Rooms: '房间',
   adults: '成人',
   children: '儿童',
   childAgeLimits: '年龄（0-17）',
   Infants: '婴儿',
   infantAgesLimit: '年龄（1岁及以下）',
   childAges: '年龄”您要与之（启程时），',
   ageOfChild: '同行的孩子“孩子的年龄',
   infantConfirm: '一岁以下的婴儿（启程时）',
   infantLengthPlural: '是婴儿',
   infantLengthSingular: '是婴儿',
   infantLengthEnd: '（未满一个人的年龄（出发时）',
   infantLapStart: '该',
   infantLapPlural: '婴儿',
   infantLapSingular: '婴儿”，婴儿',
   infantLapEnd: '在成人腿上旅行吗？',
   infantsLap: '婴儿将在成人腿上旅行吗？',
   sidebarnewInventoryOps: '库存和位置选项',
   sidebarViewTours: '查看团体旅游',
   sidebarnewRouteOptions: '请选择要包含在此路线中的选项。',
   siderbarnewAutoPopLoc: '自动填充趋势位置。',
   siderbarnewAutoPopInv: '使用客户偏好自动填充广告资源',
   arrivalDate: '到达日期” ，',
   confirmRouteButton: '确认路线',
   redirectInventory: '行程清单',
   sidebarInventoryAlert: '向I添加和删除行程清单”在右边。 </ p>请注意：添加多日游会锁定以前的位置日期。',
   redirectActivities: '选择活动',
   sidebarActivitiesAlert: '通过选择左侧的细分来管理行程中的库存”。要添加，删除或重新排序位置，请选择“查看地图/路线”按钮。',
   redirectHotelList: '选择酒店',
   sidebarHotelListAlert: '从列表中选择新酒店”。 </ p>您当前位于顶部的酒店特色。',
   redirectHotel: '选择您的房间',
   sidebarHotelAlert: '选择您的房间以将该酒店添加到行程中”，行程”，行程',
   redirectToursList: '选择“选择',
   sidebarToursListAlert: '从列表中。 </ p>单击游览以查看更多详细信息，价格并查看出发地',
   redirectTour: '选择出发地',
   sidebarTourAlert: '向下滚动以找到出发日期及其价格的列表。” </ p>可以将此游览添加到您的行程或直接预订行程',
   redirectTransport: '选择运输',
   sidebarTransportAlert: '，',
   checkoutAlert: '”输入乘客详细信息以完成预订',
   checkout: '结帐',
   redirectPreview: '行程预览',
   redirectPreviewPDF: '预览PDF',
   sidebarnewInventoryOptions: '库存选项',
   errorMsg: '此处显示错误”',
   remove: '删除',
   sidebarnewInclusiveTourButton: '团体旅游',
   sidebanewNotes: '将此地点添加到路线',
   sidebarnewReturnTripOption: '回程选项',
   sidebarnewRoundTripForm: '请选择起点和回程”。如果您希望包括回程航班。',
   to: '至',
   night: '夜',
   tourDuration: '时长',
   contact: '联系',
   checkinDate: '入住日期',
   checkoutDate: '退房日期',
   supplierReference: '供应商',
   propertyReference: '预订参考',
   transport: '运输',
   service: '服务',
   inventoryTravelClass: '旅行舱位',
   departureLocation: '出发地点',
   arrivalAirport: '到达地点',
   status: '状态',
   departuretime: '出发时间',
   hotels: '酒店',
   hotelPricePerNight: '每晚价格',
   hotelPropertyRating: '物业评分',
   searchbyPropertyName: '按属性名称搜索',
   search: '搜索',
   refundtype: '退款类型',
   days: '天数',
   location: '位置',
   pernight: '每晚',
   pendingItineraries: '待定路线',
   hotelownArrangementPropertyName: '属性名称*',
   hotelownArrangementPropertyNotes: '属性注释*',
   validationMsg: '此字段为必填项。',
   emailValidationMsg: '电子邮件地址”字段必须是有效的电子邮件。',
   contactMinValidationMsg: '联系电话字段必须至少包含10个字符。',
   contactMaxValidationMsg: '联系电话字段必须至少包含11个字符。',
   ownArrangementImage: '图片',
   ownArrangementHotelimage: '酒店图片',
   ownArrangementImgClick: '点击或拖动酒店图片”在这里上传',
   ownArrangementFileTypeMsg: '（支持的文件类型.jpeg，.jpg，.png）',
   hotelownArrangementPropertyaddress: '属性地址*',
   hotelownArrangementPropertyContact: '属性联系人电话号码*',
   hotelownArrangementPropertyemail: '属性电子邮件*',
   hotelownArrangementDurationDetails: '持续时间详细信息',
   hotelownArrangementPropertyCheckindate: '签到日期*',
   hotelownArrangementPropertyCheckintime: '入住时间*',
   hotelownArrangementPropertyCheckouttime: '物业地址*',
   hotelownArrangementPropertyCheckoutdate: '退房日期*',
   ownArrangementPricingDetails: '价格明细',
   ownArrangementCurrency: '货币*',
   hotelownArrangementCurrency: '购买货币*',
   hotelownArrangementPropertyPrice: '售价*',
   hotelownArrangementPropertybookingref: '预订参考*',
   ownArrangementPurchasingdetails: '购买明细',
   ownArrangementPurchasePrice: '购买价格*',
   hotelownArrangementPropertySupplier: '供应商*',
   hotelownArrangementPropertyInvoice: '在最终发票中包含此安排住宿定价。',
   hotelownArrangementPropertyRefund: '此安排住宿产品可退款',
   cancelButton: '取消”。 ，',
   addtoitinerary: '添加到行程',
   ownArrangementBasicDetails: '基本酒店详细信息',
   activityownArrangementActivityname: '活动名称*',
   activityownArrangementAddress: '地址*',
   activityownArrangementPhnno: '电话号码*',
   activityownArrangementEmail: '电子邮件*',
   activityownArrangementWeburl: 'Web URL',
   activityownArrangementImage: '图像',
   ownArrangementActivityImgClick: '单击或拖动”活动图像在这里上传',
   activityownArrangementActivityImage: '活动图片',
   activityownArrangementSellingPrice: '售价*',
   activityownArrangementSupplierReference: '供应商',
   activityownArrangementPurchaseCurrency: '购买币种*',
   activityownArrangementPropertyReference: '预订参考*',
   ownArrangementComments: '评论',
   activityownArrangementComments: '评论',
   operator: '运营商',
   ownArrangementTravelClass: '班级',
   ownArrngementArrivalTime: '到达”时间',
   ownArrangementAirlineReference: '航空公司参考',
   ownArrangementGdsReference: 'GDS参考',
   downloadPDF: '下载PDF',
   expandAll: '全部展开',
   collapseAll: '全部折叠',
   showMore: '显示更多',
   showLess: '显示较少',
   accommodation: '住宿',
   name: '姓名',
   checkIn: '签到” ，',
   checkOut: '退房',
   checkOutDash: '退房',
   price: '价格',
   PerNight: '每晚',
   roomDetails: '房间详细信息',
   roomType: '房间类型',
   pendingBookingNights: '夜',
   nonRefundable: '不可退款',
   refundable: '可退款',
   ownArrangement: '自己的安排',
   toursActivities: '旅行/活动',
   viewitenaryActivityname: '活动名称',
   viewitenaryAddress: '地址',
   viewitenaryPhone: '电话',
   viewitenarySellingPice: '销售价格',
   email: '电子邮件',
   date: '日期',
   duration: '持续时间',
   code: '代码',
   startDate: '开始日期',
   finishDate: '完成日期',
   endDate: '结束日期',
   aud: '澳元',
   view: '查看',
   noDataFound: '找到记录',
   notProvided: '未“未提供',
   pendingBookingPage: 'X',
   pendingBookingCustomerName: '客户名称',
   pendingBookingDepdate: '部门”。日期',
   pendingBookingCreatedOn: '创建于',
   travellers: '旅行者',
   pendingBookingTotalPrice: '总价',
   ownArrangementContactDetails: '联系方式',
   sortby: '排序方式：',
   titleOfActivity: '标题',
   activityCode: '活动代码：',
   viator: '访客',
   from: '发件人',
   perPerson: '每人” ，',
   category: '类别',
   sidebarActivityName: '按活动名称搜索',
   readmore: '阅读更多',
   hotelPickupIncluded: '包含酒店接客服务',
   activityDetaildeparturePoint: '出发地点：',
   overview: '概述',
   importantInfo: '重要信息',
   details: '详细信息',
   activityDetailInclusions: '包含',
   activityDetailExclusions: '排除” ，',
   activityDetailAdditionalinfo: '其他信息',
   activityDetailVoucherinfo: '优惠券信息',
   activityDetailLocalOperatorInformation: '本地运营商信息',
   activityDetailLocalOperatorInformationlope: '完整的运营商信息，包括目的地的本地电话号码”都包含在您的确认凭证中。我们的产品经理只选择每个目的地中经验最丰富，最可靠的运营商，从而为您消除了猜测，并确保您放心。',
   activityDetailCancellationPolicy: '取消政策',
   activityDetailScheduleAndPricing: '计划和定价',
   activityDetailScheduleAndPricingContent: '我们的定价会不断更新，以确保您总是获得最低的价格-我们100％保证。',
   activityDetailBookActivity: '预订活动',
   activityDetailDeparts: '出发',
   activityDetailPickup: '提货：”，提货',
   activityDetailCheckedPickup: '我需要',
   activityDetailaddActivity: '添加活动',
   activityDetailSpecialRequirements: '特殊要求',
   activityDetailSelectLocation: '选择位置',
   activityDetailEnterPickupLocation: '输入提货地点*',
   noRecordFound: '未找到记录',
   sidebarTransportCustomerPricing: '客户定价',
   sidebarTransportCustomerPricingContent: '行程定价明细，包括客户费用总额和按PAX。',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'Total Cost AUD inc。”。税',
   sidebarTransportPricePerPaxAudincTax: '每PAX AUD的价格”。税',
   sidebarTransportCostPerDay: '每日成本：',
   sidebarTransportCost: '458.00',
   sidebarTransportTotalCost: '总成本：',
   sidebarTransportTotal: '785澳元',
   sidebarTransportAgencyCost: '代理商成本',
   sidebarTransportOne: '一个',
   sidebarTransportTwo: '两个',
   sidebarTransportThree: '三个',
   sidebarTransportFilters: '运输过滤器',
   sidebarTransportType: '运输类型”',
   return: '退货',
   transportOnlyRates: '关闭代理商视图以显示客户的零售价。',
   transportOnlySaveQuotation: '保存报价',
   transportOnlyBookItinerary: '预订行程',
   transportOnlyCustomerPricing: '客户定价',
   transportOnlyAgencyCost: '代理商费用',
   transportOnlyFilters: '过滤器',
   transportOnlyDepartureLocation: '出发地点',
   transportOnlyDestinationLocation: '目的地位置',
   transportOnlyReturnDate: '返回日期',
   transportOnlyParistoRome: '巴黎到罗马”，，',
   transportOnlyFilterContent: '2019年4月1日星期一',
   change: '更改',
   transportOnlyvueling: '伏林',
   nonStop: '直航',
   departs: '出发',
   transportOnlyParisOrlyAirport: '巴黎奥利机场（ORY）” ，',
   transportOnlyArrive: '到达',
   transportOnlyFco: '罗马-火车站（FCO）',
   transportOnlyKiwi: '罗马蒂布蒂纳``Kiwi.com',
   transportOnlyMystifly: '米斯蒂夫',
   transportOnlySabre: '佩刀',
   transportOnlyEconomy: '经济',
   transportOnlySelect: '精选',
   transportOnlyRouteDetails: '路线详细信息',
   transportOnlyCalendar: '``2019年4月1日，星期一，',
   transportOnlyFlight: '航班',
   transportOnlyShowDetails: '显示详细信息',
   transportOnlyParis: '巴黎-里昂火车站”，车站',
   transportOnlyMilan: '米兰-中央',
   transportOnlyBaggageDetails: '行李详细信息',
   transportOnlyPersonalBaggage: '个人行李',
   transportOnlyFree: '免费',
   transportOnlyCheckedBaggage: '托运行李，',
   transportOnlyAddCheckedBaggage: '添加托运行李',
   transportOnlyAddCabinBaggage: '添加随身行李',
   transportOnlyInFlight: '机上附加组件',
   transportOnlyPassengerPricingDetails: '乘客和价格明细',
   transportOnlyPassenger: '乘客',
   transportOnlyonexAdult: '1 x成人',
   transportOnlyBaseFare: '基本票价',
   transportOnlyBaggage: '行李',
   transportOnlyFeesAndTaxes: '其他费用/税金',
   transportOnlyTermsConditions: '条款和条件”条件',
   transportOnlyNetRate: '净费率',
   transportOnlyCommision: '佣金',
   checkinTime: '签到时间” ，',
   checkoutTime: '结帐时间',
   searchCustomerName: '按客户名称搜索',
   confirmroute: '确认路线',
   logout: '登出',
   searchbypropertyname: '按物业名称搜索',
   hotelDetailshotelRoom: '（1间客房）',
   hotelDetailRoomsRates: '客房和房价',
   hotelDetailAmenities: '酒店便利设施',
   hotelDetailCheckInInstructions: '入住”说明',
   hotelDetailsFeesOptionalExtras: '费用/可选附加项',
   hotelDetailValueAddedServices: '增值服务',
   hotelDetailCheckin: '签到',
   hotelDetailhotelCheckout: '签出：',
   hotelDetailBedPreferencese: '床偏好设置：',
   hotelDetailViewCancellationPolicy: '查看取消政策',
   hotelDetailRoomDetailAmenities: '房间详细设施，',
   hotelDetailRoomAmenities: '房间设施',
   hotelDetailGuests: '客人：',
   hotelDetailSubtotal: '小计：',
   hotelTotal: '总计',
   hotelDetailTaxRecoveryServiceFee: '退税和服务费：',
   hotelDetailSpecialCheckInInstructions: '特殊入住说明',
   hotelDetailadditionalInformation: '其他信息',
   headerLogin: '登录',
   headerLogout: '注销',
   hide: '隐藏',
   depart: '出发',
   arrive: '到达',
   flightNumber: '航班号',
   routeDetails: '路线详细信息',
   wifi: 'WIFI',
   included: '已包含',
   airCondition: '空调',
   passengerPricingDetails: '乘客和价格详细信息',
   supplier: '供应商',
   passenger: '乘客',
   passengers: '乘客',
   flightMystifly: '我的飞行',
   flight2Adult: '2 x成人',
   baggage: '行李',
   otherFeesTaxes: '其他费用/税项',
   free: '免费',
   netRate: '净费率',
   select: '选择',
   floatZero: '0.00',
   doubleZerofloat: '00.00',
   tv: '电视',
   flightEconomy: '经济',
   viewMore: '查看更多” ，',
   flightOnBoardAddOns: '机载插件',
   loginSignIn: '登录',
   customerNameOrEmail: '客户名称或电子邮件',
   searchBy: '搜索依据',
   firstName: '名字',
   lastName: '姓氏',
   gender: '性别',
   phone: '电话',
   submitButton: '提交',
   your: '您的”',
   createdOn: '创建时间',
   nights: '晚上',
   totalPrice: '总价',
   emailUserName: '电子邮件或用户名',
   userName: '用户名',
   password: '密码 ord',
   enterPassword: '输入密码',
   enterCustomerName: '输入客户名',
   taskEnterBookingId: '输入预订ID',
   commission: '佣金',
   costPerDay: '每日费用',
   pricePerPAX: '每份PAX的价格”，含税',
   totalCost: '总费用',
   netCost: '净费用',
   includingTax: '”。税',
   priceSummary: '价格摘要',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: '包含客户总费用和按PAX的行程定价明细。',
   sidebarHeadingPreviewItinerary: '预览行程',
   sidebarHeadingSaveQuotation: '保存报价',
   sidebarHeadingBookItinerary: '预订行程',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: '关闭代理商视图以显示零售价”客户。',
   agentView: '代理商视图',
   customerView: '客户视图',
   save: '保存',
   travelInsurance: '旅行保险',
   no: '否',
   addTravel: '添加旅行',
   plus: '加',
   basic: '基本',
   inventoryMedicalExpenseTripCancellation: '医疗费用，行程取消',
   inventoryAssistanceServices: '协助”服务',
   inventoryLostBaggageMore: '超重行李',
   audWithFloating: '00.00澳元',
   inventoryComparsionAndTerms: '比较和条款',
   customerCare: '客户服务',
   premium: '高级',
   inventoryExploreBenefitsOfPayingPremiumMsg: '探索支付保费的好处',
   previous: '上一个',
   continue: '继续',
   saveAndContinue: '保存” ＆继续',
   BacktoInventory: '返回库存',
   AcceptandContinue: '接受并继续',
   busBud: 'BusBud',
   edit: '编辑',
   delete: '删除',
   checked: '已检查',
   checkoutBusListINBusAddonMsg: '公交附加件',
   BaseFarePerPax: '基本票价（每人）',
   onexAdult: '1 x Adult',
   oneRoom: 'Room 1',
   CancellationPolicy: 'Cancellation Policy',
   expedia: 'Expedia',
   roomSleep: 'Room sleep',
   checkOutActivityListviator: 'Viator',
   checkOutActivityDetails: 'Activity Details',
   option: 'Option',
   groupInclusiveTourOptions: 'Group Inclusive Tour Options',
   matchCurrentRouteMsg: '目前有219组” ，与您当前路线相匹配的包容性旅行。',
   errorDisplayHere: '此处显示错误',
   tourFilters: '旅行过滤器',
   tailorMadeTours: '量身定制的旅行',
   tailorMadeOptionsMsg: '请选择您要选择的选项只能显示量身定制的选项',
   tailorMadeToursItineryMsg: '仅显示量身定制的行程',
   MonthOfDeparture: '出发月份',
   selectDate: '选择日期',
   destination: '目的地',
   tourExperience: '游览体验',
   preferredSupplier: '首选供应商',
   style: '风格”',
   size: '尺寸',
   start: '开始',
   notAvailbale: '不适用',
   inclusions: '包含物',
   day: '日',
   meals: '餐食',
   breakfast: '早餐',
   lunch: '午餐',
   dinner: '晚餐',
   tourDetailsNoMealMsg: '这一天不包括餐点”。 ，',
   locationsToVisit: '要参观的地点',
   additionalInformation: '其他信息',
   departureNotFound: '找不到出发地',
   additionalInformationNotFound: '没有更多可用信息”，可用情况',
   departureAndPricing: '出发地和价格',
   departing: '出发地',
   finishing: '完成情况',
   availability: '',
   totalFrom: '总计',
   perAdult: '每次”成人',
   perChild: '每个孩子',
   departureGuaranteed: '可保证出发',
   onSale: '出售',
   onRequest: '应要求',
   addToRoute: '添加到路线',
   termsConditions: '条款和条件',
   available: '可用',
   finish: '完成',
   stars: '星级',
   reviews: '评论” ，',
   rating: '评分',
   default: '默认',
   title: '标题',
   lengthOfTour: '游览时间',
   optionsSelected: '选择的选项',
   tourNotFound: '未找到游览',
   validTo: '有效期',
   lodging: '住宿',
   validFrom: '有效期自',
   perNight: '每晚',
   sortBy: '排序方式',
   viewItineraryGITtours: 'GIT游览',
   inventoryAddNewAccommodation: '选择另一家酒店',
   inventoryAddNewTransport: '添加新交通工具',
   passengersAvoidBoardingComplicationMsg: '使用与护照/身份证上显示的名称完全相同的名称（Given和姓氏），以避免登机。',
   passengersRequiredFlightSegmentMsg: '必填”国际航班航段。',
   dateOfBirth: '出生日期',
   passengersTourSpecificField: '旅游特定领域',
   passengersTourSegmentMsg: 'X航段所需',
   visaRequriements: '签证安排',
   passengersreferencePurposeMsg: '旅行时可能需要签证或机场过境签证，即使不是停在中转国家。这些要求仅供参考。请与使馆或外交部联系以获取更多信息。',
   passengersDeniedDestinationIssueMsg: 'eRoam对因签证问题而被拒绝登机或进入任何目的地的任何乘客不承担任何责任。',
   givenNames: '给定姓名',
   passengerNames: '给定姓名*',
   surname: '姓氏',
   passengersurname: '姓*',
   familyname: '姓氏',
   PassengerGender: '性别*',
   paymenttotalprice: '总价',
   paymentnewprice: '新价格',
   emailAddress: '电子邮件地址',
   passengeremailAddress: '电子邮件地址*',
   contactNumber: '联系电话',
   passengercontactNumber: '联系电话*',
   passengersIndividualInventorySpecificationMsg: '以下字段为必填项取决于个别的库存规范，例如内部航班，旅行要求等。',
   Nationality: '国籍',
   PassengerNationality: '国籍*',
   confirmBooking: '确认预订',
   receiptNumber: '收据编号',
   paymentAddReceiptNumberMsg: '添加已处理付款的收据编号。',
   PaymentDeniedDestinationIssueMsg: 'A”旅行可能需要签证或机场过境签证。在此处找到的资产仅供参考。请联系使馆或外交部以获取更多信息。对于因签证问题而被拒绝登机或进入任何目的地的任何乘客，eRoam概不负责。',
   amount: '金额*',
   paymentEnterAmountMsg: '输入正在的金额',
   paymentEnterTypeMsg: '付款“输入付款方式',
   paymentType: '付款”键入*',
   paymentOnceSubmittedMsg: '一旦提交了付款详细信息，便会确认预订。',
   payment: '付款',
   printPDF: '打印PDF',
   custom: '自定义',
   additionalNotes: '其他说明',
   list: '列表',
   checkinDash: '签到',
   detailedSummary: '详细摘要',
   dailySummary: '每日摘要',
   tripSummary: '旅行摘要',
   transportName: '运输名称',
   activities: '活动',
   basicTransportDetails: '基本运输明细',
   transportOwnArrangementRefundMsg: '此自家安排运输产品可退款。',
   transportOwnArrangementPricingMsg: '在最终发票中包含此自有安排运输价格”。',
   salePrice: '售价',
   transportOwnArrangementAddLegtoArrangement: '添加腿到安排',
   arrivalLocation: '到达位置',
   transportOwnArrangementAddNewLeg: '添加新腿',
   arrival: '到达',
   departure: '出发',
   fromTo: '从-到',
   selectThePlaceToReturn: '选择返回的地方',
   selectAReturn: '选择回程',
   returnToAnotherCity: '返回其他城市',
   selectPlaceOrigin: '选择原点。',
   selectOrigin: '选择原点',
   accomodationSummary: '住宿摘要',
   transportSummary: '运输摘要',
   activitySummary: '活动摘要',
   taskListConformDeleteMsg: '请确认您要删除任务？',
   ConfirmActionTitle: '确认操作',
   passengertitle: '标题*',
   hotelListTotalCost: '总计”费用',
   nonstop: '不间断',
   selected: '选定',
   inventoryAddNewHotels: '添加新酒店',
   inventoryAddOwnArrangement: '添加自己的安排',
   propertylocation: '属性位置',
   inventoryAddNewToursTickets: '选择其他游览和门票',
   inventoryContactPhone: '联系电话',
   inventoryContactEmail: '联系电子邮件',
   flight: '航班”',
   time: '时间',
   bookingId: '预订ID',
   bookingNote: '预订注释',
   stops: '停止',
   layOverDuration: '停留时间',
   itineraryPdfImageText: '单击或拖动此处的行程图像以上传',
   confirm: '确认',
   changePassword: '更改密码',
   oldPasswordErrorMsg: '输入旧密码',
   newPasswordErrorMsg: '输入新密码',
   confirmPasswordErrorMsg: '密码确认不匹配',
   oldPassword: '旧密码',
   confirmPassword: '确认密码',
   newPassword: '新密码',
   visitedLocations: '访问位置',
   checkoutFlightListINFlightAddonMsg: '飞行中的附件',
   checkoutmystifly: 'Mystifly',
   tourDetailstitle: '游览详细信息',
   update_total_cost: '更新总费用',
   updateCostConfirmCompleteMsg: '您确定要继续负佣金吗？',
   confirmButton: '确认',
   norating: '无评分',
   adult: '成人',
   child: '孩子',
   PrimaryPassenger: '主要乘客（领导人数）” ，',
   hotelbed: '酒店床',
   activityownemail: '供应商电子邮件',
   activityownweb: '网站',
   activityownphone: '电话',
   activityownsupplier: '供应商',
   hotelDetails: '酒店详细信息',
   room: '房间',
   ownArrangementActivityBasicDetails: '基本活动详细信息',
   booking: '预订',
   tour: '游览',
   oopsTheActivity: '活动',
   oopsTheTour: '旅行',
   oopsTheFlight: '航班',
   notAvailable: '目前不可用。',
   inventoryAddHotelOwnArrangement: '自己的安排（住宿）',
   inventoryAddToursOwnArrangement: '自己的安排（旅游和票务）',
   inventoryAddReplaceTransport: '选择其他交通工具',
   inventoryAddTransportOwnArrangement: '自己的安排（运输）',
   noProductSelected: '未产品',
   taskSelectDate: '选择“选择日期',
   taskSelecgender: '选择性别',
   age: '年龄',
   relation: '关系',
   activityTime: '时间*',
   activityDate: '日期*',
   accountId: '帐户ID',
   nationality: '国籍',
   city: '城市”',
   address: '地址',
   postcode: '邮政编码',
   region: '地区',
   ontarioResident: '安大略省居民',
   prepaidGratuities: '预付小费',
   mobilePhone: '联系方式',
   titleBooking: '标题',
   genderBooking: '性别',
   emailBooking: '电子邮件地址',
   paymentAmount: '付款金额',
   checkoutRceiptNumber: '收据编号”',
   loadPnr: '加载PNR',
   activityownArrangementPropertyInvoice: '在最终发票中包含此定价。',
   activityownArrangementPropertyRefund: '这是可退款的。',
   bookingVoucherfileType: '（（支持的文件类型.jpeg，.jpg，.png，.pdf）',
   bookingVouchermsg: '点击此处上传优惠券',
   carrierName: '承运人姓名',
   departureDateandTime: '出发日期和时间',
   arrivalDateandTime: '到达日期和时间',
   airlineReference: '航空公司参考',
   pnrNumber: '输入PNR',
   pnrInstructions: '请输入有效的PNR编号以获取行程',
   termsandconditions: '条款和条件',
   total: '总计” ，',
   baggageAdult: '成人/儿童行李',
   baggageChild: '儿童行李',
   gitWarningMessage: '您为该城市选择了无效的旅行团',
   summeryConfirmCompleteMsg: '您确定要继续使用无效的旅行选择吗？',
   tourType: '旅行类型',
   SearchPlace: '搜索地点 |搜索地点',
   SearchCity: '搜索城市 |搜索城市',
   manager: '经理',
   product: '产品',
   finance: '金融',
   admin: '管理',
   viewProductManager: '查看产品管理器',
   viewfinanceManager: '查看财务经理',
   viewAdminManager: '查看管理员管理器',
   viewsupplierManager: '查看供应商经理',
   viewmarketingManager: '查看营销经理',
   DaterangeFrom: '日期范围',
   DaterangeTo: '日期范围到',
   DataType: '数据类型',
   FileType: '文件类型',
   downloadNow: '立即下载',
   ConsultantName: '顾问姓名',
   SupplierName: '供应商名称',
   ClientFile: '客户端文件',
   pay: '支付',
   TypeofPayment: '付款类型',
   Login: '登录',
   exportData: '导出数据',
   roomNumber: '房间',
   storeName: '商店名称',
   manageUsers: '管理用户',
   manageBrands: '管理品牌',
   viewReports: '查看报告',
   brand: '品牌',
   manageLicensee: '管理被许可方',
   ManageLicensee: '搜索被许可人',
   OnboardNewLicensee: '船上新持牌人',
   CreateNewPackage: '创建新包',
   consultantArchived: '预订',
   ArchivedData: '存档',
   consultantArchivedBlurb: '查看已存档预订的列表',
   consultantArchivedButton: '查看已存档的预订',
   consultantArchivedAgency: '查看已存档的代理预订',
   archivedItineraries: '已存档的行程',
   taskDay: '选择"天',
   action: '行动',
   mobile: '移动',
   taskSelectDay: '选择"天',
   DateandTime: '日期和时间',
   addtransport: '添加传输',
   addhotels: '添加酒店',
   addactivities: '添加活动',
   manageclientpreferences: '管理客户端首选项',
   Return: '返回',
   oneway: '单向',
   multycity: '马尔蒂市',
   flyingfrom: '飞行从',
   selectclass: '选择类',
   returndate: '退货日期',
   comingsoon: '即将推出',
   thingstodo: '需要做的事情',
   extendedtouring: '扩展旅游',
   selectinterest: '选择兴趣',
   searchpnr: '搜索 PNR',
   googlesearch: '谷歌搜索',
   package: '包',
   addcustomer: '添加客户',
   geoLocationManager: 'GEO 位置管理器',
   supplieroperatorManager: '供应商/操作员经理',
   yieldManager: '产量经理',
   packageBuilder: '包生成器',
   plotmaps: '绘制地图',
   reporting: '报告',
   CSVDownload: 'CSV 下载',
   makePayments: '付款',
   packageName: '包名称',
   uploadImages: '上传图片',
   toursandtickets: '旅游和门票',
   hotel: '酒店',
   packageOverview: '包概述',
   packageItinerary: '套餐行程',
   Optional: '选',
   createpackagefromtour: '从旅游创建包',
   Currency: '货币',
   companyName: '公司名称',
   Phone: '电话（包括国家/地区代码）',
   url: 'Url',
   uploadLogo: '上传徽标',
   selectProduct: '选择产品',
   hotelSupplier: '酒店供应商',
   tourSupplier: '旅游供应商',
   transportSupplier: '运输供应商',
   otherSupplier: '其他供应商',
   selectSettings: '选择设置',
   defaultLanguage: '默认语言',
   defaultCurrency: '默认货币',
   chooseTemplate: '选择模板',
   paymentGateway: '支付网关',
   selectSubscription: '选择订阅',
   licenceType: '许可证类型',
   howmanylicences: '有多少牌照？',
   addons: '加载项',
   paymentTerms: '付款条款',
   licenseenameoremail: '被许可人姓名或电子邮件',
   createProfilesandClientDetails: '创建配置文件和管理客户详细信息',
   passportNumber: '护照号码',
   passportissuedate: '护照签发日期',
   passportExpiryDate: '护照有效期',
   passportissuingcountry: '护照签发国',
   clientContact: '客户联系人',
   Phonehome: '电话（家庭）',
   Phonework: '电话（工作）',
   phonemobile: '电话（手机）',
   AddressLinOne: '地址（第一行）',
   AddressLineTwo: '地址（第二行）',
   StateProvinceRegion: '州 /省 /地区',
   zipPostalCode: '邮政编码',
   country: '国家',
   newsletter: '通讯',
   companyABN: '公司 ABN',
   paName: 'PA 名称',
   clientLoyaltyPrograms: '客户忠诚度计划',
   srno: '不先生',
   loyaltyType: '忠诚度类型',
   providerName: '提供程序名称',
   membershipNumber: '会员编号',
   clientPreferences: '客户端首选项',
   airline: '航空公司',
   airlines: '航空公司（S）',
   travelClass: '旅行舱位',
   preferredSeating: '首选座位',
   mealRequest: '膳食要求',
   Hotels: '酒店',
   hotelChain: '连锁酒店',
   Cars: '汽车',
   carRental: '汽车租赁',
   CarType: '汽车类型',
   interests: '利息',
   linkedprofiles: '链接的配置文件',
   Preferreddestinations: '首选目的地',
   businessPreferences: '业务偏好',
   otherPreferences: '其他首选项',
   pendingBookings: '待定预订',
   archiveBookings: '存档预订',
   activeBookings: '活动预订',
   viewdetails: '查看详细信息',
   update: '更新',
   bookingmanagement: '预订管理',
   bookingDashboard: '预订仪表板',
   bookingoverview: '预订概述',
   Generalbookingdetails: '一般预订详情。',
   type: '类型',
   created: '创建',
   segmentoverview: '细分市场概述',
   totalnumberofsegment: '产品/细分市场的总数和未确认的细分市场。',
   segmentsconfirmedvstotalsegment: '已确认的细分与预订中包含的总细分。',
   amountremainingforclient: '客户在余额到期日之前支付的剩余金额。',
   outstandingAmount: '未缴金额',
   outstanding: '优秀',
   balanceDue: '到期余额',
   bookingDetails: '预订详情',
   user: '用户',
   consultantNotes: '顾问说明',
   historynotfound: '未找到历史记录',
   leadpaxdetails: '潜在顾客详细信息',
   additionalpaxdetails: '其他 Pax 详细信息',
   locationdetails: '位置详细信息',
   selectedHotels: '精选酒店',
   confirmed: '证实',
   snooze: '打盹',
   upload: '上传',
   novoucherfound: '未找到凭证',
   Nopaymentsfound: '未找到付款',
   newvoucher: '上传此预订的新凭证',
   allocation: '分配',
   selectedtoursandtickets: '精选旅游和门票',
   offeredIn: '提供于',
   tofrom: '到 / 从',
   bookingRef: '预订参考',
   selectedTransport: '选定的传输',
   segmentmessage: '通过确认此细分市场，您确认此产品已在此应用程序之外向供应商预订。为了完成确认，请填写下面的字段。',
   bookingReference: '预订参考',
   suppliersbookingreference: '输入供应商的预订参考',
   confirmsegment: '确认段',
   pickupTime: '接机时间',
   totalDuration: '总持续时间',
   layoverTime: '随时间进行铺设',
   ticketType: '票证类型',
   class: '类',
   provider: '供应商',
   viewcustomer: '查看客户',
   costofitinerary: '行程总费用',
   paid: '支付',
   receiptofallpayments: '迄今所有付款的收据',
   balanceduetocustomer: '应付从客户那里收取的余额',
   recordcustomerpayment: '在下面记录客户付款。',
   completePayment: '完成付款',
   cancel: '取消',
   accommodationORtransportation: '• 请选择至少一个住宿或交通服务。',
   preferredPropertyClass: '首选属性类',
   transportation: '运输',
   preferredtransporttype: '首选传输类型',
   preferredtransportclass: '首选传输类',
   agegroup: '年龄组',
   interest: '兴趣',
   activityinterest: '活动兴趣',
   applytoalllocations: '适用于所有地点',
   applythislocation: '应用此位置',
   apply: '应用',
   hotelDescription: '酒店描述',
   activityDescription: '活动描述',
   checkoutcancelationdescription: '长期以来，读者在查看页面布局时，会因页面的可读内容而分心，这是一个长期确立的事实。使用 Lorem Ipsum 的要点是，它或多或少地按字母分布，而不是使用"此处的内容，此处的内容"，使其看起来像可读的英语。许多桌面发布包和网页编辑现在使用Lorem Ipsum作为他们的默认模型文本，搜索"lorem ipsum"将发现许多网站仍处于起步阶段。多年来，各种版本都发生了变化，有时是偶然的，有时是故意的（注入幽默等）。',
   OverrideQuotation: '保存/覆盖报价',
   DuplicateQuotation: '重复报价',
   OriginCity: '起源城市',
   ReturnCity: '返回城市',
   AddTransport: '添加传输',
   AddHotels: '添加酒店',
   AddActivities: '添加活动',
   ManagePreferences: '管理客户端首选项',
   searchPNR: '搜索 PNR',
   oneWay: '单向',
   multiCity: '多城市',
};
