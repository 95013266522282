export const Danish: any = {
   creatingFor: 'Oprettelse af rejseplan til',
   creatingNew: 'Oprettelse af ny kundeplan',
   searchTitleItinerary: 'Rejseplan',
   searchTitleFlights: 'Flyvning og tog',
   searchTitleHotel: 'Hoteller & BnB\'s',
   searchTitleTours: 'Turer & billetter',
   searchTitlePackages: 'Pakker',
   searchTitleRentalcars: 'Udlejningsbiler & Bobiler',
   searchTitleTransfers: 'Overførsler',
   searchTitleCruises: 'Krydstogter',
   searchTitleEvents: 'Begivenheder',
   searchTitleInsurance: 'Forsikring',
   searchInstructions: 'Tilføj din afgangsplacering, og vælg \'Søg\'. Tilføj de yderligere placeringer på det næste trin under bekræftelsesruten.',
   searchAddPlaces: 'Afgangsplacering',
   searcPlaceholder: 'Lokationer',
   searchError: '* Vælg venligst to placeringer',
   searchBlurb: 'Opret din rejseplan. Tilføj hver af de placeringer, du ønsker at besøge, ved at indtaste din destination, og klik på Enter for at tilføje dem.',
   consultantProfile: 'Konsulentprofil',
   consultantSearchCust: 'Kunder',
   Searchdata: 'Søg',
   consultantSearchBlurb: 'Find en kundes bookinger',
   consultantSearchCustButton: 'Søg efter kunder',
   consultantSearchCont: 'Søgekontakt',
   consultantQuick: 'Hurtig adgang',
   consultantActive: 'Bookinger',
   ActiveData: 'Aktiv',
   consultantActiveBlurb: 'Vis liste over dine aktive bookinger',
   consultantActiveButton: 'Se aktive bookinger',
   consultantPending: 'Bookinger',
   PendingData: 'Ventende',
   consultantPendingBlurb: 'Vis liste over dine ventende bookinger',
   consultantPendingButton: 'Se ventende bookinger',
   consultantItineraries: 'Aktive rejseplaner',
   consultantCustomers: 'Kunder',
   consultantAccount: 'Kontosammendrag',
   consultantNotices: 'Meddelelser og meddelelser',
   consultantTaskList: 'Opgaveliste',
   consultantTaskYours: 'Dine opgaver',
   consultantTaskNotFound: 'Opgave ikke fundet',
   consultantTaskConfirmCompleteMsg: 'Er du sikker på, at du vil udfylde markerede opgaver som komplette',
   consultantCustName: 'Kundenavn',
   consultantActiveAgency: 'Se Active Agent-bookinger',
   consultantPendingAgency: 'Se ventende agenturbestillinger',
   consultantTaskCustName: 'Kundenavn',
   consultantTaskBookingId: 'Booking-ID',
   consultantTaskNote: 'Noter',
   consultantTaskDate: 'Vælg dato',
   consultantTaskAddButton: 'Tilføj opgave',
   consultantTaskEditButton: 'Rediger opgave',
   consultantTaskCompleted: 'Fuldført',
   consultantTaskactions: 'Handlinger',
   consultantTasksnoozed: 'Snoozed',
   consultantTasktasks: 'Opgaver',
   consultantTasksNone: 'Synes du er opdateret, godt klaret',
   consultantMidOffice: 'Midtkontor',
   consultantMidOfficeUpload: 'Upload data til Midtkontor',
   sidebarHeading: 'Bekræft rute',
   siderbarInstructions: 'Tilføj alle seværdigheder, og indstil dem i den ønskede rækkefølge. Vælg en dateringsdato og opholdets længde på hvert sted. Hvis du vælger det forud valgte lager, skal du finjustere resultaterne via lagerindstillingerne for hvert sted.',
   sidebarOptionDeparture: 'Afgang og rejsende',
   sidebarDepDate: 'Afrejsedato',
   sidebarDepSelect: 'Vælg den dato, der skal afgå fra den første placering.',
   sidebarDepError: 'Fejl, vælg venligst en afrejsedato.',
   travellersNumber: 'Antal rejsende',
   siderbarnewInstructions: 'Tilføj alle seværdigheder, og indstil dem i den ønskede rækkefølge. Vælg en dateringsdato og opholdets længde på hvert sted. Hvis du vælger det forud valgte lager, skal du finjustere resultaterne via lagerindstillingerne for hvert sted.',
   sidebarnewOptionDeparture: 'Afgang og rejsende',
   depDate: 'Afrejsedato',
   sidebarnewDepSelect: 'Vælg den dato, der skal afgå fra den første placering.',
   sidebarnewDepError: 'Fejl, vælg venligst en afrejsedato.',
   sidebarTravellersNumber: 'Antal rejsende',
   Rooms: 'Værelser',
   adults: 'Voksne',
   children: 'Børn',
   childAgeLimits: 'Alder (0-17)',
   Infants: 'Spædbørn',
   infantAgesLimit: 'Alder (1 & under)',
   childAges: 'Hvor gamle er de børn, du er på rejse med (på afgangstidspunktet)',
   ageOfChild: 'Alder på barnet',
   infantConfirm: 'Er spædbarn under en alder (på tidspunktet for afgang)',
   infantLengthPlural: 'Er spædbørn',
   infantLengthSingular: 'Er spædbarnet',
   infantLengthEnd: 'under en alder (på tidspunktet for afrejse)',
   infantLapStart: 'Vil',
   infantLapPlural: 'spædbørn',
   infantLapSingular: 'spædbarn',
   infantLapEnd: 'rejse på skødet Voksne?',
   infantsLap: 'vil spædbørn rejse på skødet Voksne?',
   sidebarnewInventoryOps: 'Beholdning og Placeringsmuligheder',
   sidebarViewTours: 'Vis grupperejser',
   sidebarnewRouteOptions: 'Vælg de indstillinger, du vil medtage inden for denne rute.',
   siderbarnewAutoPopLoc: 'Auto-udfyld med trendeplaceringer.',
   siderbarnewAutoPopInv: 'Auto udfyld inventar ved hjælp af klientpræferencer.',
   arrivalDate: 'Ankomstdato',
   confirmRouteButton: 'Bekræft rute',
   redirectInventory: 'Rejselinje',
   sidebarInventoryAlert: 'Tilføj og fjern Trip Inventory til I rute til højre. </p> Bemærk: Hvis du tilføjer en flerdages tur, låses de tidligere lokationsdatoer på plads.',
   redirectActivities: 'Vælg aktiviteter',
   sidebarActivitiesAlert: 'Administrer opgørelsen inden for rejseplanen ved at vælge segmentet til venstre. For at tilføje, fjerne eller ombestille placeringer skal du vælge knappen Vis kort / rute.',
   redirectHotelList: 'Vælg hotel',
   sidebarHotelListAlert: 'Vælg et nyt hotel fra listen. </p> Du er de nuværende valgte hotelfunktioner øverst.',
   redirectHotel: 'Vælg dit værelse',
   sidebarHotelAlert: 'Vælg dit værelse for at tilføje dette hotel til rejseplanen',
   redirectToursList: 'Vælg en tur',
   sidebarToursListAlert: 'Vælg en tur fra listen. </p> Klik på en tur for at se mere detaljeret information, priser og for at se afganger',
   redirectTour: 'Vælg en afgang',
   sidebarTourAlert: 'Rul ned for at finde en liste over afgangsdatoer med deres pris. </p> Tilføj enten denne tur til din rejseplan eller booke turen direkte',
   redirectTransport: 'Vælg transport',
   sidebarTransportAlert: '',
   checkoutAlert: 'Indtast passageroplysninger for at gennemføre bookingen',
   checkout: 'Checkout',
   redirectPreview: 'Rejseprøvevisning',
   redirectPreviewPDF: 'Eksempelvisning af PDF',
   sidebarnewInventoryOptions: 'Indstillinger for lagerbeholdning',
   errorMsg: 'Fejlvisning her',
   remove: 'Fjern',
   sidebarnewInclusiveTourButton: 'Group Inclusive Tour',
   sidebanewNotes: 'Føj dette sted til ruten',
   sidebarnewReturnTripOption: 'Valg af returrejse',
   sidebarnewRoundTripForm: 'Vælg et origin og return. Hvis du ønsker at medtage returflyvninger.',
   to: 'Til',
   night: 'Nætter',
   tourDuration: 'Varighed',
   contact: 'Kontakt',
   checkinDate: 'Indtjekningsdato',
   checkoutDate: 'Checkout-dato',
   supplierReference: 'Leverandør',
   propertyReference: 'Bookingreference',
   transport: 'Transport',
   service: 'Service',
   inventoryTravelClass: 'Rejseklasse',
   departureLocation: 'Afgangssted',
   arrivalAirport: 'Ankomstplacering',
   status: 'Status',
   departuretime: 'Afgangstid',
   hotels: 'Hoteller',
   hotelPricePerNight: 'Pris pr. Nat',
   hotelPropertyRating: 'Ejendomsvurdering',
   searchbyPropertyName: 'Søg efter ejendomsnavn',
   search: 'Søg',
   refundtype: 'Restitutionstype',
   days: 'Dage',
   location: 'Placering',
   pernight: 'pr. Nat',
   pendingItineraries: 'Venter på rejseplaner',
   hotelownArrangementPropertyName: 'Ejendomsnavn *',
   hotelownArrangementPropertyNotes: 'Egenskabsnotater *',
   validationMsg: 'Dette felt er påkrævet.',
   emailValidationMsg: 'E-mail-adressen -feltet skal være en gyldig e-mail.',
   contactMinValidationMsg: 'Feltet Kontaktnummer skal være mindst 10 tegn.',
   contactMaxValidationMsg: 'Feltet Kontaktnummer skal være 11 tegn.',
   ownArrangementImage: 'Billede',
   ownArrangementHotelimage: 'Hotelbillede',
   ownArrangementImgClick: 'Klik eller træk hotelbillede her for at uploade',
   ownArrangementFileTypeMsg: '(understøttet filtype .jpeg, .jpg, .png)',
   hotelownArrangementPropertyaddress: 'Ejendomsadresse * \',adresse',
   hotelownArrangementPropertyContact: 'Ejendomskontakt telefonnummer *',
   hotelownArrangementPropertyemail: 'E-mail-*',
   hotelownArrangementDurationDetails: 'Varighed detaljer',
   hotelownArrangementPropertyCheckindate: 'Indtjekning Dato *',
   hotelownArrangementPropertyCheckintime: 'Indtjekningstid *',
   hotelownArrangementPropertyCheckouttime: 'Ejendomsadresse *',
   hotelownArrangementPropertyCheckoutdate: 'Udtjekningsdato *',
   ownArrangementPricingDetails: 'Prisoplysninger',
   ownArrangementCurrency: 'Valuta *',
   hotelownArrangementCurrency: 'Køb af valuta *',
   hotelownArrangementPropertyPrice: 'Salgspris *',
   hotelownArrangementPropertybookingref: 'Bookingreference *',
   ownArrangementPurchasingdetails: 'Indkøbsdetaljer',
   ownArrangementPurchasePrice: 'Købspris *',
   hotelownArrangementPropertySupplier: 'Leverandør *',
   hotelownArrangementPropertyInvoice: 'Inkluder denne egen Arrangementprisen for indkvartering i den endelige faktura.',
   hotelownArrangementPropertyRefund: 'Dette produkt for eget arrangement indkvartering kan refunderes.',
   cancelButton: 'Annuller',
   addtoitinerary: 'Føj til rejseplan',
   ownArrangementBasicDetails: 'Grundlæggende hoteloplysninger',
   activityownArrangementActivityname: 'Aktivitetsnavn *',
   activityownArrangementAddress: 'Adresse *',
   activityownArrangementPhnno: 'Telefonnummer *',
   activityownArrangementEmail: 'E-mail *',
   activityownArrangementWeburl: 'Web-URL',
   activityownArrangementImage: 'Billede',
   ownArrangementActivityImgClick: 'Klik eller træk aktivitetsbillede her for at uploade',
   activityownArrangementActivityImage: 'Aktivitetsbillede',
   activityownArrangementSellingPrice: 'Salgspris *',
   activityownArrangementSupplierReference: 'Leverandør',
   activityownArrangementPurchaseCurrency: 'Købsvaluta *',
   activityownArrangementPropertyReference: 'Bookingreference *',
   ownArrangementComments: 'Kommentarer',
   activityownArrangementComments: 'Kommentarer',
   operator: 'Operatør',
   ownArrangementTravelClass: 'Klasse',
   ownArrngementArrivalTime: 'Ankomst Tid',
   ownArrangementAirlineReference: 'Airline Reference',
   ownArrangementGdsReference: 'GDS Reference',
   downloadPDF: 'Download PDF',
   expandAll: 'Expand All',
   collapseAll: 'Collapse All',
   showMore: 'Show More',
   showLess: 'Show Less',
   accommodation: 'Accommodation',
   name: 'Name',
   checkIn: 'Check in',
   checkOut: 'Udtjekning',
   checkOutDash: 'Udtjekning',
   price: 'Pris',
   PerNight: 'Per nat',
   roomDetails: 'Værelsesoplysninger',
   roomType: 'Værelsestype',
   pendingBookingNights: 'Nætter',
   nonRefundable: 'Ikke-refunderbar',
   refundable: 'Refunderbar',
   ownArrangement: 'Egen arrangement',
   toursActivities: 'Ture / Aktiviteter',
   viewitenaryActivityname: 'Aktivitetsnavn',
   viewitenaryAddress: 'Adresse',
   viewitenaryPhone: 'Telefon',
   viewitenarySellingPice: 'Salgspris',
   email: 'E-mail',
   date: 'Dato',
   duration: 'Varighed',
   code: 'Kode',
   startDate: 'Startdato',
   finishDate: 'Udførelsesdato',
   endDate: 'Slutdato',
   aud: 'AUD',
   view: 'Visning',
   noDataFound: 'Ingen registrering fundet',
   notProvided: 'Ikke leveret',
   pendingBookingPage: 'X',
   pendingBookingCustomerName: 'Kundenavn',
   pendingBookingDepdate: 'Dep. Dato',
   pendingBookingCreatedOn: 'Oprettet',
   travellers: 'Rejsende',
   pendingBookingTotalPrice: 'Samlet pris',
   ownArrangementContactDetails: 'Kontaktoplysninger',
   sortby: 'Sorter efter:',
   titleOfActivity: 'Titel',
   activityCode: 'Aktivitetskode:',
   viator: 'Viator',
   from: 'Fra',
   perPerson: 'pr. Person',
   category: 'Kategori',
   sidebarActivityName: 'Søgning efter aktivitetsnavn',
   readmore: 'Læs mere',
   hotelPickupIncluded: 'Hotelopfang inkluderet:',
   activityDetaildeparturePoint: 'Afgangspunkt:',
   overview: 'Oversigt',
   importantInfo: 'Vigtig information',
   details: 'Detaljer',
   activityDetailInclusions: 'Inkluderinger',
   activityDetailExclusions: 'Udelukkelser',
   activityDetailAdditionalinfo: 'Yderligere info',
   activityDetailVoucherinfo: 'Voucher-information',
   activityDetailLocalOperatorInformation: 'Lokal operatørinformation',
   activityDetailLocalOperatorInformationlope: 'Komplet operatørinformation, inklusive lokale telefonnumre på din destination, er inkluderet i din bekræftelsesbevis. Vores produktchefer vælger kun de mest erfarne og pålidelige operatører i hver destination, fjerner gætteriet for dig og sikrer din ro i sindet.',
   activityDetailCancellationPolicy: 'Annulleringspolitik',
   activityDetailScheduleAndPricing: 'Planlæg og prisfastsættelse',
   activityDetailScheduleAndPricingContent: 'Vores priser opdateres konstant for at sikre dig modtag altid den lavest mulige pris - vi garanterer 100% det.',
   activityDetailBookActivity: 'Bogaktivitet',
   activityDetailDeparts: 'Afgår',
   activityDetailPickup: 'Afhentning',
   activityDetailCheckedPickup: 'Jeg skal afhentes',
   activityDetailaddActivity: 'Tilføj aktivitet',
   activityDetailSpecialRequirements: 'Særlige krav',
   activityDetailSelectLocation: 'Vælg placering',
   activityDetailEnterPickupLocation: 'Indtast afhentningsplacering *',
   noRecordFound: 'Ingen registrering blev fundet',
   sidebarTransportCustomerPricing: 'Kundepriser',
   sidebarTransportCustomerPricingContent: 'Opgave om rejsepriser inklusive kundepriser i alt og efter PAX.',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'Samlede omkostninger AUD inkl. Skat',
   sidebarTransportPricePerPaxAudincTax: 'Pris pr. PAX AUD inkl. Skat',
   sidebarTransportCostPerDay: 'Omkostning pr. Dag:',
   sidebarTransportCost: '458,00',
   sidebarTransportTotalCost: 'Samlede omkostninger:',
   sidebarTransportTotal: 'AUD 785',
   sidebarTransportAgencyCost: 'Agenturets omkostninger',
   sidebarTransportOne: 'En',
   sidebarTransportTwo: 'To',
   sidebarTransportThree: 'Tre',
   sidebarTransportFilters: 'Transportfiltre',
   sidebarTransportType: 'Transporttype',
   return: 'return',
   transportOnlyRates: 'Slå agentvisningen fra for at vise detailpriserne for kunderne.',
   transportOnlySaveQuotation: 'Gem tilbud',
   transportOnlyBookItinerary: 'Book rejseplan',
   transportOnlyCustomerPricing: 'Kundepris',
   transportOnlyAgencyCost: 'Agenturets omkostninger',
   transportOnlyFilters: 'Filtre',
   transportOnlyDepartureLocation: 'Afgangssted',
   transportOnlyDestinationLocation: 'Destination Location',
   transportOnlyReturnDate: 'Return Date',
   transportOnlyParistoRome: 'Paris to Rome',
   transportOnlyFilterContent: 'Mandag 1. april 2019',
   change: 'Change',
   transportOnlyvueling: 'Vueling',
   nonStop: 'Nonstop',
   departs: 'Depart',
   transportOnlyParisOrlyAirport: 'Paris Orly Airport (ORY)',
   transportOnlyArrive: 'Ankom',
   transportOnlyFco: 'Rom - Roma Tiburtina Railway Station (FCO)',
   transportOnlyKiwi: 'Kiwi.com',
   transportOnlyMystifly: 'Mystifly',
   transportOnlySabre: 'Sabre',
   transportOnlyEconomy: 'Economy',
   transportOnlySelect: 'Select',
   transportOnlyRouteDetails: 'Route details',
   transportOnlyCalendar: 'Mandag 1. april, 2019',
   transportOnlyFlight: 'Fly',
   transportOnlyShowDetails: 'Vis detaljer',
   transportOnlyParis: 'Paris - Gare De Lyon',
   transportOnlyMilan: 'Milan - CentralStation',
   transportOnlyBaggageDetails: 'Detaljer om bagage',
   transportOnlyPersonalBaggage: 'Personlig bagage',
   transportOnlyFree: 'Gratis',
   transportOnlyCheckedBaggage: 'Kontrolleret bagage',
   transportOnlyAddCheckedBaggage: 'Tilføj kontrolleret bagage',
   transportOnlyAddCabinBaggage: 'Tilføj kabinebagage',
   transportOnlyInFlight: 'In-flight-tilføjelser',
   transportOnlyPassengerPricingDetails: 'passager- og prisoplysninger',
   transportOnlyPassenger: 'Passager',
   transportOnlyonexAdult: '1 x voksen',
   transportOnlyBaseFare: 'Basepris',
   transportOnlyBaggage: 'Bagage',
   transportOnlyFeesAndTaxes: 'Andre gebyrer / afgifter',
   transportOnlyTermsConditions: 'Vilkår & Betingelser',
   transportOnlyNetRate: 'Nettosats',
   transportOnlyCommision: 'Kommission',
   checkinTime: 'Checkin-tid',
   checkoutTime: 'Udtjekningstid',
   searchCustomerName: 'Søgning efter kundenavn',
   confirmroute: 'Bekræft rute',
   logout: 'Logout',
   searchbypropertyname: 'Søgning efter ejendomsnavn',
   hotelDetailshotelRoom: '(1 værelse)',
   hotelDetailRoomsRates: 'Værelser og priser',
   hotelDetailAmenities: 'Hotelfaciliteter',
   hotelDetailCheckInInstructions: 'Indtjekning Instruktioner',
   hotelDetailsFeesOptionalExtras: 'Gebyrer / Valgfri ekstraudstyr',
   hotelDetailValueAddedServices: 'Værdiskabende tjenester',
   hotelDetailCheckin: 'Indtjekning',
   hotelDetailhotelCheckout: 'Udtjekning:',
   hotelDetailBedPreferencese: 'Sengeprioritet:',
   hotelDetailViewCancellationPolicy: 'Vis afbestillingsregler',
   hotelDetailRoomDetailAmenities: 'Værelsesdetaljer Faciliteter',
   hotelDetailRoomAmenities: 'Værelsesfaciliteter',
   hotelDetailGuests: 'Gæster:',
   hotelDetailSubtotal: 'SubTotal:',
   hotelTotal: 'Total',
   hotelDetailTaxRecoveryServiceFee: 'Skatteopkrævning & servicegebyr:',
   hotelDetailSpecialCheckInInstructions: 'Speciel indtjekningsinstruktioner',
   hotelDetailadditionalInformation: 'Yderligere oplysninger',
   headerLogin: 'Login',
   headerLogout: 'Logout',
   hide: 'Skjul',
   depart: 'Afrejse',
   arrive: 'Ankom',
   flightNumber: 'Flynummer',
   routeDetails: 'Ruteoplysninger',
   wifi: 'WIFI',
   included: 'INKLUDERET',
   airCondition: 'Aircondition',
   passengerPricingDetails: 'Detaljer om passagerer og priser',
   supplier: 'Leverandør',
   passenger: 'Passager',
   passengers: 'Passagerer',
   flightMystifly: 'Mystifly',
   flight2Adult: '2 x voksen',
   baggage: 'Bagage',
   otherFeesTaxes: 'Andre gebyrer / afgifter',
   free: 'GRATIS',
   netRate: 'Nettosats',
   select: 'Vælg',
   floatZero: '0,00',
   doubleZerofloat: '0000',
   tv: 'TV',
   flightEconomy: 'Økonomi',
   viewMore: 'Se mere',
   flightOnBoardAddOns: 'Ombord-tilføjelser',
   loginSignIn: 'Log ind',
   customerNameOrEmail: 'Kundenavn eller e-mail',
   searchBy: 'Søg efter',
   firstName: 'Fornavn',
   lastName: 'Efternavn',
   gender: 'Køn',
   phone: 'Telefon',
   submitButton: 'Send',
   your: 'Dit',
   createdOn: 'Oprettet',
   nights: 'Nætter',
   totalPrice: 'Samlet pris',
   emailUserName: 'E-mail eller brugernavn',
   userName: 'Brugernavn',
   password: 'Passw ord',
   enterPassword: 'Indtast adgangskode',
   enterCustomerName: 'Indtast kundenavn',
   taskEnterBookingId: 'Indtast reservations-id',
   commission: 'Kommission',
   costPerDay: 'Omkostning pr. dag',
   pricePerPAX: 'Pris pr. PAX',
   totalCost: 'Samlede omkostninger',
   netCost: 'Nettoomkostninger',
   includingTax: 'inkl. Skat',
   priceSummary: 'Prisoversigt',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: 'Opdeling af rejsepriser inklusive kundepriser i alt og efter PAX.',
   sidebarHeadingPreviewItinerary: 'Forhåndsvisning af rejseplan',
   sidebarHeadingSaveQuotation: 'Gem tilbud',
   sidebarHeadingBookItinerary: 'Book rejseplan',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: 'Sluk agentvisningen for at vise detailpriserne for kunde.',
   agentView: 'Agentvisning',
   customerView: 'Kundevisning',
   save: 'Gem',
   travelInsurance: 'Rejseforsikring',
   no: 'Nej',
   addTravel: 'Tilføj rejse',
   plus: 'Plus',
   basic: 'Grundlæggende',
   inventoryMedicalExpenseTripCancellation: 'Medicinske udgifter, rejse-aflysning',
   inventoryAssistanceServices: 'Hjælp Tjenester',
   inventoryLostBaggageMore: 'Mistet bagage + Mere',
   audWithFloating: 'AUD',
   inventoryComparsionAndTerms: '00,00" Sammenligning og vilkår" Kundebehandling',
   customerCare: '',
   premium: 'Premium',
   inventoryExploreBenefitsOfPayingPremiumMsg: 'Udforsk fordelene ved at betale for Premium',
   previous: 'Forrige',
   continue: 'Fortsæt',
   saveAndContinue: 'Gem & Fortsæt',
   BacktoInventory: 'Tilbage til inventar',
   AcceptandContinue: 'Accepter og fortsæt',
   busBud: 'BusBud',
   edit: 'Rediger',
   delete: 'Slet',
   checked: 'Kontrolleret',
   checkoutBusListINBusAddonMsg: 'I bus-tilføjelser',
   BaseFarePerPax: 'Base Fare (Per Pax)',
   onexAdult: '1 x Voksen',
   oneRoom: 'Værelse 1',
   CancellationPolicy: 'Afbestillingsregler',
   expedia: 'Expedia',
   roomSleep: 'Værelsessøvn',
   checkOutActivityListviator: 'Viator',
   checkOutActivityDetails: 'Aktivitetsoplysninger',
   option: 'Option',
   groupInclusiveTourOptions: 'Group Inclusive Tour Options',
   matchCurrentRouteMsg: 'Der er i øjeblikket 219 gruppe inklusive ture, der matcher din aktuelle rute.',
   errorDisplayHere: 'Fejlvisning her',
   tourFilters: 'Tourfiltre',
   tailorMadeTours: 'Skræddersyede ture',
   tailorMadeOptionsMsg: 'Vælg hvilke indstillinger du vil skal kun vise de skræddersyede muligheder',
   tailorMadeToursItineryMsg: 'Vis kun skræddersyede ture til rejseplaner',
   MonthOfDeparture: 'Afgangsmåned',
   selectDate: 'Vælg dato',
   destination: 'Destination',
   tourExperience: 'Turoplevelse',
   preferredSupplier: 'Foretrukket leverandør',
   style: 'Stil',
   size: 'Størrelse',
   start: 'Start',
   notAvailbale: 'N / A',
   inclusions: 'Inkluderinger',
   day: 'Dag',
   meals: 'Måltider',
   breakfast: 'Morgenmad',
   lunch: 'Frokost',
   dinner: 'Middag',
   tourDetailsNoMealMsg: 'Der er ingen måltider inkluderet denne dag.',
   locationsToVisit: 'Steder at besøge',
   additionalInformation: 'Yderligere oplysninger',
   departureNotFound: 'Afgang ikke fundet',
   additionalInformationNotFound: 'Ingen flere oplysninger tilgængelige',
   departureAndPricing: 'Afgang og pris',
   departing: 'Afgang',
   finishing: 'Efterbehandling',
   availability: 'Tilgængelighed',
   totalFrom: 'I alt fra',
   perAdult: 'pr. Voksen',
   perChild: 'pr. Barn',
   departureGuaranteed: 'garanteret afrejse',
   onSale: 'i salg',
   onRequest: 'på anmodning',
   addToRoute: 'Føj til rute',
   termsConditions: 'Vilkår og betingelser',
   available: 'Tilgængelig',
   finish: 'Udfør',
   stars: 'Stjerner',
   reviews: 'Anmeldelser',
   rating: 'Bedømmelse',
   default: 'Standard',
   title: 'Titel',
   lengthOfTour: 'Længde på turné',
   optionsSelected: 'valgmuligheder valgt',
   tourNotFound: 'Tour ikke fundet',
   validTo: 'Gyldig til',
   lodging: 'Logi',
   validFrom: 'Gyldig fra',
   perNight: 'Per nat',
   sortBy: 'Sorter efter',
   viewItineraryGITtours: 'GIT-ture',
   inventoryAddNewAccommodation: 'Vælg et andet hotel',
   inventoryAddNewTransport: 'Tilføj ny transport',
   passengersAvoidBoardingComplicationMsg: 'Brug alle navn (givet og efternavn) nøjagtigt som de vises i pas / ID for at undgå boardingkomplikationer.',
   passengersRequiredFlightSegmentMsg: 'Krævet for internationalt flysegment.',
   dateOfBirth: 'Fødselsdato',
   passengersTourSpecificField: 'Tour-specifikt felt.',
   passengersTourSegmentMsg: 'Påkrævet for tur-X \'-segment',
   visaRequriements: 'Visakrav',
   passengersreferencePurposeMsg: 'Et visum eller lufthavnstransitvisum kan være nødvendigt for rejser, selvom ikke stopper i layover-landet. Disse krav er kun til referenceformål. Kontakt ambassaden eller udenrigsministerium for mere information.',
   passengersDeniedDestinationIssueMsg: 'ERoam er ikke ansvarlig for eventuelle passagerer, der afvises eller adgang til enhver destination på grund af visum problemer.',
   givenNames: 'Fornavn',
   passengerNames: 'Fornavn *',
   surname: 'Efternavn',
   passengersurname: 'Efternavn *',
   familyname: 'Familienavn',
   PassengerGender: 'Køn *',
   paymenttotalprice: 'Samlet pris',
   paymentnewprice: 'Ny pris',
   emailAddress: 'E-mail-adresse',
   passengeremailAddress: 'E-mail-adresse *',
   contactNumber: 'Kontaktnummer',
   passengercontactNumber: 'Kontaktnummer *',
   passengersIndividualInventorySpecificationMsg: 'Nedenstående felter er påkrævet på grund af individuelle inventarspecifikationer, såsom interne flyvninger, rejsekrav osv.',
   Nationality: 'Nationalitet',
   PassengerNationality: 'Nationalitet *',
   confirmBooking: 'Bekræft reservation',
   receiptNumber: 'Kvitteringsnummer',
   paymentAddReceiptNumberMsg: 'Tilføj kvitteringsnummer for den behandlede betaling.',
   PaymentDeniedDestinationIssueMsg: 'A visum eller lufthavnstransitvisum kan være nødvendigt for rejser. Krav, der findes her, er kun beregnet til opfriskning. Kontakt ambassaden eller dit udenrigsministerium for mere information. eRoam hæfter ikke for nogen passagerer, der nægtes at gå om bord eller indtaste en destination på grund af visumproblemer.',
   amount: 'Beløb *',
   paymentEnterAmountMsg: 'Indtast et beløb, der bliver foretaget.',
   paymentEnterTypeMsg: 'Indtast betalingstypen',
   paymentType: 'Betaling Skriv *',
   paymentOnceSubmittedMsg: 'Når betalingsoplysningerne er blevet sendt, bekræftes reservationen.',
   payment: 'Betaling',
   printPDF: 'Udskriv PDF',
   custom: 'Brugerdefineret',
   additionalNotes: 'Yderligere noter',
   list: 'Liste',
   checkinDash: 'Indtjekning',
   detailedSummary: 'Detaljeret oversigt',
   dailySummary: 'Dagligt resume',
   tripSummary: 'Rejseoversigt',
   transportName: 'Transportnavn',
   activities: 'Aktiviteter',
   basicTransportDetails: 'Grundlæggende transportoplysninger',
   transportOwnArrangementRefundMsg: 'Dette eget arrangement Transportprodukt kan refunderes.',
   transportOwnArrangementPricingMsg: 'Medtag denne egen ordning Transportpriser i den endelige faktura .',
   salePrice: 'Salgspris',
   transportOwnArrangementAddLegtoArrangement: 'Tilføj ben til arrangement',
   arrivalLocation: 'ankomstplads',
   transportOwnArrangementAddNewLeg: 'tilføj et nyt ben',
   arrival: 'ankomst',
   departure: 'afgang',
   fromTo: 'fra - til',
   selectThePlaceToReturn: 'vælg det sted, der skal returneres',
   selectAReturn: 'Vælg en tilbagevenden',
   returnToAnotherCity: 'Vend tilbage til en anden by',
   selectPlaceOrigin: 'Vælg oprindelsessted.',
   selectOrigin: 'Vælg en oprindelse',
   accomodationSummary: 'Resumé af indkvartering',
   transportSummary: 'Transportoversigt',
   activitySummary: 'Aktivitetssammendrag',
   taskListConformDeleteMsg: 'Bekræft, at du ønsker at slette opgave?',
   ConfirmActionTitle: 'Bekræft handling',
   passengertitle: 'Titel *',
   hotelListTotalCost: 'I alt Omkostninger',
   nonstop: 'Non-stop',
   selected: 'Selected',
   inventoryAddNewHotels: 'Tilføj nye hoteller',
   inventoryAddOwnArrangement: 'Tilføj eget arrangement',
   propertylocation: 'Property location',
   inventoryAddNewToursTickets: 'Vælg Another Tour & Tickets',
   inventoryContactPhone: 'Contact phone',
   inventoryContactEmail: 'Contact email',
   flight: 'Flight',
   time: 'Tid',
   bookingId: 'Booking-id',
   bookingNote: 'Booking-note',
   stops: 'Stopper',
   layOverDuration: 'Layover varighed',
   itineraryPdfImageText: 'Klik eller træk rejseplan her for at uploade',
   confirm: 'Bekræft',
   changePassword: 'Skift adgangskode',
   oldPasswordErrorMsg: 'Indtast gammel adgangskode',
   newPasswordErrorMsg: 'Indtast nyt kodeord',
   confirmPasswordErrorMsg: 'Bekræftelse af adgangskode stemmer ikke overens',
   oldPassword: 'Gammelt kodeord',
   confirmPassword: 'Bekræft adgangskode',
   newPassword: 'Ny adgangskode',
   visitedLocations: 'Steder, der skal',
   checkoutFlightListINFlightAddonMsg: 'I tilføjelser til fly',
   checkoutmystifly: 'besøges\',\'Mystifly',
   tourDetailstitle: 'Tourdetaljer',
   update_total_cost: 'Opdater de samlede omkostninger',
   updateCostConfirmCompleteMsg: 'Er du sikker, vil du fortsætte med en negativ kommission?',
   confirmButton: 'Bekræft',
   norating: 'Ingen vurdering',
   adult: 'Voksen',
   child: 'Barn',
   PrimaryPassenger: 'Primær passager (Lead Pax)',
   hotelbed: 'Hotelbed',
   activityownemail: 'Leverandør-e-mail',
   activityownweb: 'Websted',
   activityownphone: 'Telefon',
   activityownsupplier: 'Leverandør',
   hotelDetails: 'Hoteloplysninger',
   room: 'Værelse',
   ownArrangementActivityBasicDetails: 'Grundlæggende aktivitetsdetaljer',
   booking: 'Booking',
   tour: 'Tour',
   oopsTheActivity: 'Aktiviteten',
   oopsTheTour: 'Turen',
   oopsTheFlight: 'Flyet',
   notAvailable: 'er ikke tilgængelig lige nu.',
   inventoryAddHotelOwnArrangement: 'Eget arrangement (indkvartering)',
   inventoryAddToursOwnArrangement: 'Eget arrangement (tur og billet)',
   inventoryAddReplaceTransport: 'Vælg en anden transport',
   inventoryAddTransportOwnArrangement: 'Eget arrangement (Transport)',
   noProductSelected: 'Ingen produkter er valgt',
   taskSelectDate: 'Vælg dato',
   taskSelecgender: 'Vælg køn',
   age: 'Alder',
   relation: 'Forhold',
   activityTime: 'Tid *',
   activityDate: 'Dato *',
   accountId: 'Konto-id',
   nationality: 'Nationalitet',
   city: 'By',
   address: 'Adresse',
   postcode: 'Postnummer',
   region: 'Region',
   ontarioResident: 'Ontario bosiddende',
   prepaidGratuities: 'Forudbetalte takknemligheder',
   mobilePhone: 'Kontakt',
   titleBooking: 'Titel',
   genderBooking: 'Køn',
   emailBooking: 'E-mail-adresse',
   paymentAmount: 'Betalingsbeløb',
   checkoutRceiptNumber: 'Kvitteringsnummer',
   loadPnr: 'Indlæs PNR',
   activityownArrangementPropertyInvoice: 'Inkluder denne pris i den endelige faktura.',
   activityownArrangementPropertyRefund: 'Dette kan refunderes.',
   bookingVoucherfileType: '(Understøttede filtyper .jpeg, .jpg, .png, .pdf)',
   bookingVouchermsg: 'Klik her for at uploade voucher',
   carrierName: 'Luftfartsnavn',
   departureDateandTime: 'Afrejsedato og tid',
   arrivalDateandTime: 'Ankomstdato og klokkeslæt',
   airlineReference: 'Luftfartsreference',
   pnrNumber: 'Indtast PNR',
   pnrInstructions: 'Indtast et gyldigt PNR-nummer for at få rejseplan',
   termsandconditions: 'Vilkår og betingelser',
   total: 'I alt',
   baggageAdult: 'Voksen- / børnbagage',
   baggageChild: 'Børnebagage',
   gitWarningMessage: 'Du har valgt ugyldig tur til denne by',
   summeryConfirmCompleteMsg: 'Er du sikker, vil du fortsætte med ugyldigt valg af turné?',
   tourType: 'Tourtype',
   SearchPlace: 'Lieu de recherche | Rechercher des lieux',
   SearchCity: 'Rechercher City | Rechercher des villes',
   manager: 'Gestionnaire',
   product: 'Produit',
   finance: 'Finances',
   admin: 'Admin',
   viewProductManager: 'Afficher le gestionnaire de produit',
   viewfinanceManager: 'Afficher le gestionnaire financier',
   viewAdminManager: 'Afficher le Gestionnaire d’administrateurs',
   viewsupplierManager: 'Afficher le gestionnaire de fournisseurs',
   viewmarketingManager: 'Afficher Le Directeur Marketing',
   DaterangeFrom: 'Plage de dates à partir de',
   DaterangeTo: 'Plage de dates à',
   DataType: 'Type de données',
   FileType: 'Type de fichier',
   downloadNow: 'Télécharger maintenant',
   ConsultantName: 'Nom du consultant',
   SupplierName: 'Nom du fournisseur',
   ClientFile: 'Fichier client',
   pay: 'Payer',
   TypeofPayment: 'Type de paiement',
   Login: 'Connectez-vous',
   exportData: 'Données d’exportation',
   roomNumber: 'Chambre',
   storeName: 'Nom du magasin',
   manageUsers: 'Gérer les utilisateurs',
   manageBrands: 'Gérer les marques',
   viewReports: 'Afficher les rapports',
   brand: 'Marque',
   manageLicensee: 'Gérer la licenciée',
   ManageLicensee: 'Licencié de recherche',
   OnboardNewLicensee: 'À bord d’une nouvelle licenciée',
   CreateNewPackage: 'Créer un nouveau package',
   consultantArchived: 'Réservations',
   ArchivedData: 'Archivés',
   consultantArchivedBlurb: 'Afficher la liste de vous Réservations archivées',
   consultantArchivedButton: 'Afficher les réservations archivées',
   consultantArchivedAgency: 'Afficher les réservations d’agences archivées',
   archivedItineraries: 'Itinéraires archivés',
   taskDay: 'Sélectionner le jour',
   action: 'Action',
   mobile: 'Mobile',
   taskSelectDay: 'Sélectionner le jour',
   DateandTime: 'Date et heure',
   addtransport: 'Ajouter le transport',
   addhotels: 'Ajouter des hôtels',
   addactivities: 'Ajouter des activités',
   manageclientpreferences: 'Gérer les préférences des clients',
   Return: 'Retour',
   oneway: 'One Way',
   multycity: 'Ville de Multy',
   flyingfrom: 'Voler à partir de',
   selectclass: 'Sélectionner la classe',
   returndate: 'Date de retour',
   comingsoon: 'À venir',
   thingstodo: 'Choses à faire',
   extendedtouring: 'Tournée prolongée',
   selectinterest: 'Sélectionner l’intérêt',
   searchpnr: 'Rechercher PNR',
   googlesearch: 'Recherche Google',
   package: 'Paquet',
   addcustomer: 'Ajouter un client',
   geoLocationManager: 'Gestionnaire de localisation GEO',
   supplieroperatorManager: 'Fournisseur / Gestionnaire d’opérateur',
   yieldManager: 'Gestionnaire de rendement',
   packageBuilder: 'Générateur de packages',
   plotmaps: 'Cartes de parcelle',
   reporting: 'Rapports',
   CSVDownload: 'CSV Télécharger',
   makePayments: 'Effectuer des paiements',
   packageName: 'Nom du package',
   uploadImages: 'Télécharger des images',
   toursandtickets: 'Tours et billets',
   hotel: 'Hôtel',
   packageOverview: 'Vue d’ensemble du package',
   packageItinerary: 'Itinéraire du forfait',
   Optional: 'Optionnel',
   createpackagefromtour: 'Créer un package à partir de Tour',
   Currency: 'Monnaie',
   companyName: 'Nom de l’entreprise',
   Phone: 'Téléphone (y compris le code de pays)',
   url: 'Url',
   uploadLogo: 'Logo de téléchargement',
   selectProduct: 'Sélectionner le produit',
   hotelSupplier: 'Fournisseur d’hôtel',
   tourSupplier: 'Fournisseur de tour',
   transportSupplier: 'Fournisseur de transport',
   otherSupplier: 'Autre fournisseur',
   selectSettings: 'Sélectionner les paramètres',
   defaultLanguage: 'Langue par défaut',
   defaultCurrency: 'Devise par défaut',
   chooseTemplate: 'Choisir le modèle',
   paymentGateway: 'Passerelle de paiement',
   selectSubscription: 'Sélectionner l’abonnement',
   licenceType: 'Licence Type',
   howmanylicences: 'Combien de permis?',
   addons: 'Modules complémentaires',
   paymentTerms: 'Conditions de paiement',
   licenseenameoremail: 'Nom ou courriel du titulaire de licence',
   createProfilesandClientDetails: 'Créer des profils et gérer les détails du client',
   passportNumber: 'Numéro de passeport',
   passportissuedate: 'Date de délivrance du passeport',
   passportExpiryDate: 'Date d’expiration du passeport',
   passportissuingcountry: 'Pays émetteur de passeports',
   clientContact: 'Client Contact',
   Phonehome: 'Téléphone (maison)',
   Phonework: 'Téléphone (travail)',
   phonemobile: 'Téléphone (mobile)',
   AddressLinOne: 'Adresse (Ligne 1)',
   AddressLineTwo: 'Adresse (ligne deux)',
   StateProvinceRegion: 'État / Province / Région',
   zipPostalCode: 'ZIP / Postal Code',
   country: 'Pays',
   newsletter: 'Newsletter',
   companyABN: 'Société ABN',
   paName: 'Nom pa',
   clientLoyaltyPrograms: 'Programmes de fidélisation des clients',
   srno: 'M. No',
   loyaltyType: 'Type de fidélité',
   providerName: 'Nom du fournisseur',
   membershipNumber: 'Numéro d’adhésion',
   clientPreferences: 'Préférences du client',
   airline: 'Compagnie aérienne',
   airlines: 'Compagnie aérienne(S)',
   travelClass: 'Classe de voyage',
   preferredSeating: 'Sièges préférés',
   mealRequest: 'Demande de repas',
   Hotels: 'Hôtel(s)',
   hotelChain: 'Chaîne d’hôtel',
   Cars: 'Voiture(s)',
   carRental: 'Location de voiture',
   CarType: 'Type de voiture',
   interests: 'Intérêts',
   linkedprofiles: 'Profils liés',
   Preferreddestinations: 'Destinations privilégiées',
   businessPreferences: 'Préférences d’entreprise',
   otherPreferences: 'Autres préférences',
   pendingBookings: 'Réservations en attente',
   archiveBookings: 'Réservations d’archives',
   activeBookings: 'Réservations actives',
   viewdetails: 'Afficher les détails',
   update: 'mettre à jour',
   bookingmanagement: 'Gestion des réservations',
   bookingDashboard: 'Tableau de bord de réservation',
   bookingoverview: 'vue d’ensemble de la réservation',
   Generalbookingdetails: 'Détails généraux de réservation.',
   type: 'Type',
   created: 'Créé',
   segmentoverview: 'Vue d’ensemble du segment',
   totalnumberofsegment: 'Le nombre total de produits/segments, et les segments confirmés exceptionnels.',
   segmentsconfirmedvstotalsegment: 'Segments confirmés par rapport aux segments totaux inclus dans la réservation.',
   amountremainingforclient: 'Montant restant à payer pour le client avant la date d’échéance du solde.',
   outstandingAmount: 'Montant impayé',
   outstanding: 'Exceptionnelle',
   balanceDue: 'Solde dû',
   bookingDetails: 'Détails de la réservation',
   user: 'Utilisateur',
   consultantNotes: 'Consultant Notes',
   historynotfound: 'Histoire introuvable',
   leadpaxdetails: 'Lead Pax Détails',
   additionalpaxdetails: 'Détails supplémentaires de Pax',
   locationdetails: 'Détails de l’emplacement',
   selectedHotels: 'Hôtels sélectionnés',
   confirmed: 'Confirmé',
   snooze: 'Snooze',
   upload: 'Télécharger',
   novoucherfound: 'Aucun bon trouvé',
   Nopaymentsfound: 'Aucun paiement trouvé',
   newvoucher: 'Téléchargez un nouveau bon pour cette réservation',
   allocation: 'Allocation',
   selectedtoursandtickets: 'Tours et billets sélectionnés',
   offeredIn: 'Offert en',
   tofrom: 'À / DE',
   bookingRef: 'Réservation Ref',
   selectedTransport: 'Transport sélectionné',
   segmentmessage: 'En confirmant ce segment, vous reconnaissez que ce produit a été réservé au fournisseur, en dehors de cette application. Pour compléter la confirmation, veuillez compléter le champ ci-dessous.',
   bookingReference: 'Référence de réservation',
   suppliersbookingreference: 'Entrez la référence de réservation du fournisseur',
   confirmsegment: 'Confirmer le segment',
   pickupTime: 'Heure de ramassage',
   totalDuration: 'Durée totale',
   layoverTime: 'Lay over Time',
   ticketType: 'Type de billet',
   class: 'Classe',
   provider: 'Fournisseur',
   viewcustomer: 'Afficher le client',
   costofitinerary: 'Coût total de l’itinéraire',
   paid: 'Payé',
   receiptofallpayments: 'Reçus de tous les paiements à ce jour',
   balanceduetocustomer: 'Solde dû à la collecte auprès du client',
   recordcustomerpayment: 'Enregistrez un paiement client ci-dessous.',
   completePayment: 'Paiement complet',
   cancel: 'Annuler',
   accommodationORtransportation: '* Veuillez sélectionner au moins un hébergement ou transport.',
   preferredPropertyClass: 'Classe de propriété préférée',
   transportation: 'Transport',
   preferredtransporttype: 'Type de transport préféré',
   preferredtransportclass: 'Classe de transport privilégiée',
   agegroup: 'Groupe d’âge',
   interest: 'Intérêt',
   activityinterest: 'Intérêt d’activité',
   applytoalllocations: 'Appliquer à tous les emplacements',
   applythislocation: 'Appliquer cet emplacement',
   apply: 'Appliquer',
   hotelDescription: 'Description de l’hôtel',
   activityDescription: 'Description de l’activité',
   checkoutcancelationdescription: 'Il est un fait établi depuis longtemps qu’un lecteur sera distrait par le contenu lisible d’une page en regardant sa mise en page. Le point d’utiliser Lorem Ipsum est qu’il a une distribution plus ou moins normale des lettres, par opposition à l’utilisation de « a contenu ici, le contenu ic », ce qui en fait ressembler à l’anglais lisible. De nombreux paquets de publication de bureau et éditeurs de pages Web utilisent maintenant Lorem Ipsum comme texte modèle par défaut, et une recherche de « erem ipsu » permettra de découvrir de nombreux sites Web encore à leurs balbutiements. Diverses versions ont évolué au fil des ans, parfois par accident, parfois exprès (humour injecté et autres).',
   OverrideQuotation: 'Enregistrer/Remplacer la citation',
   DuplicateQuotation: 'Citation en double',
   OriginCity: 'Origin City',
   ReturnCity: 'Ville de retour',
   AddTransport: 'Ajouter le transport',
   AddHotels: 'Ajouter des hôtels',
   AddActivities: 'Ajouter des activités',
   ManagePreferences: 'Gérer les préférences des clients',
   searchPNR: 'Rechercher PNR',
   oneWay: 'One Way',
   multiCity: 'Multi City',
};
