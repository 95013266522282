
import { Component, Vue } from 'vue-property-decorator';
import ApiRequest from "@/Module/Global/api/apiService.js";
import _ from 'lodash';

@Component
export default class VerifyInformation extends Vue {
    toggleEditMode:any=true; 
    admin_type:any = "";
    username:any =  "";
    first_name:any = "";
    last_name:any =  "";
    email:any = "";
    contact_number:any = "";
    address:any = "";
    organisation_name:any = "";
    business_address:any = "";
    street:any = "";
    city:any = "";
    state:any = "";
    country:any = "";
    website_url:any = "";
    payment_plan:any = "Quarterly";
    sub_total_price:any = "855.00";
    additional_user_price:any = "59.97";
    total_price:any = "914.97";
    payment_method:any = "Credit Card";
    number_of_store:any = 3;
    completed_onboarding_step:any = "2";
    next_onboarding_step:any = "2";
    store: any = [];
    currency = 'USD';//this.$store.state.loginInformation.currency;
    countryOption:any = [];
    toggelStoreInput:boolean[]=[];
    zipcode:any="";
    company_no :any ="";
    registered_travelAgent:any=false;
    agent_no:any="";
    addStore(){
        this.store.push({
            user_count:0
        });
        this.toggelStoreInput.push(true);
    }
    editStore(index:any){
        this.$set(this.toggelStoreInput, index, !this.toggelStoreInput[index])
    }

    paymentNotificationPopup(){
        this.$bvModal.show("paymentNotification-dialog")
    }
    mounted(){
        let vm = this;
        vm.$store.dispatch("SetLoading", 0);
        vm.getbusinessInfomation();
        vm.getCountries();
    }
    getCountries() {
        let vm = this;
        ApiRequest.getCountries()
        .then(function (res) {
            let response = res.data.data;
            if (response) {
            _.each(response, (val) => {
                let countries: Object = { value: `${val.code}`, text: val.text };
                vm.countryOption.push(countries);
            });
            }
        })
        .catch(function (error:any) {});
   }
    async getbusinessInfomation() {
    let vm=this;
    try {
        let requestData = {user_id: vm.$store.state.loginInformation.id, 
            organisation_id: vm.$store.state.loginInformation.organisation_id,
            package_id:vm.$store.state.loginInformation.package_id,
            cob_step: 1,
            domain_id:vm.$store.state.domainId
        }
        const response = await ApiRequest.getbusinessInfomation(requestData);
        if (response && response.data.code == 200) {
            let responses = response.data;
            vm.username = responses.data.user_name;
            vm.first_name = responses.data.first_name;
            vm.last_name =  responses.data.last_name;
            vm.email = responses.data.email;
            vm.contact_number = responses.data.phone_number;
            vm.address = responses.data.address;
            vm.organisation_name = responses.data.business_name;
            vm.business_address = responses.data.business_address;
            vm.street = responses.data.street;
            vm.city = responses.data.city;
            vm.state = responses.data.state;
            vm.country = responses.data.country;
            vm.website_url = responses.data.website; 
            vm.store = responses.data.stores;
            vm.total_price = responses.data.total_price;
            vm.payment_method = responses.data.form_of_payment;
            vm.number_of_store = responses.data.number_of_store;
            vm.zipcode=responses.data.zipcode;
            vm.company_no =responses.data.company_no;
            vm.registered_travelAgent=responses.data.registered_travelAgent;
            vm.agent_no=responses.data.agent_no;

        }
    } catch (err) {}
}
nextTab() {
    let vm = this;
    this.$validator.validate().then(valid => {
        if(valid){
            let requestData = {
                cob_step: 1,
                user_id: vm.$store.state.loginInformation.id,
                organisation_id: vm.$store.state.loginInformation.organisation_id,
                package_id:3,
                title:'Mr',
                username: vm.username,
                first_name: vm.first_name,
                last_name: vm.last_name,
                email: vm.email,
                phone: vm.contact_number,
                address: vm.address,
                organisation_name: vm.organisation_name,
                business_address: vm.business_address,
                street: vm.street,
                city: vm.city,
                state: vm.state,
                country: vm.country,
                website_url: vm.website_url,
                zipcode: vm.zipcode,
                store:vm.store,
                company_no :vm.company_no,
                registered_travelAgent:vm.registered_travelAgent,
                agent_no:vm.agent_no,
                domain_id:vm.$store.state.domainId
                // card_details: {
                //     card_name: "John Do",
                //     card_number: "4242424242424242",
                //     expiration_month: "02",
                //     expiration_year: "24",
                //     security_code: "123",
                //     amount: "0.01"
                // }
            }
            vm.$store.dispatch("SetLoading", 1);
            ApiRequest.savebusinessInformation(requestData)
            .then(function (res) {
                if(res.data.code==200){
                    vm.$store.dispatch("SetLoading", 0);
                    vm.$emit("nextTabData",1);
                    vm.$toasted.show(res.data.message, {
                        theme: "bubble",
                        position: "top-right",
                        duration: 2000
                    });
                }
                else{
                    vm.$store.dispatch("SetLoading", 0);
                    vm.$toasted.show(res.data.message, {
                        theme: "bubble",
                        position: "top-right",
                        duration: 2000
                    });  
                }
                
            })
            .catch(function (error) {
                vm.$store.dispatch("SetLoading", 0);
                vm.$toasted.show("Something went wrong.", {
                        theme: "bubble",
                        position: "top-right",
                        duration: 2000
                });
            });
            
        }
    });
    
}

}
