import axios from "axios";
import router from "@/router";
import LS from "@/Module/Global/Services/ls.js";
import CryptoJS from "crypto-js";
import Authanticated from "@/Module/Global/Services/auth.js";

const qs = require("qs");
// import NProgress from 'nprogress'
// var NProgress = require("nprogress")
// var PulseLoader = require('vue-spinner/src/PulseLoader.vue');
// EndPoint API
// var CryptoJSAesJson = {
//   stringify: function(cipherParams) {
//     var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
//     if (cipherParams.iv) j.iv = cipherParams.iv.toString();
//     if (cipherParams.salt) j.s = cipherParams.salt.toString();
//     return JSON.stringify(j);
//   },
//   parse: function(jsonStr) {
//     var j = JSON.parse(jsonStr);
//     var cipherParams = CryptoJS.lib.CipherParams.create({
//       ciphertext: CryptoJS.enc.Base64.parse(j.ct)
//     });
//     if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
//     if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
//     return cipherParams;
//   }
// };
var instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URI
    ? process.env.VUE_APP_BACKEND_URI
    : "https://market.cms.eroam.com/api",
  // baseURL: "https://e2.cms.eroam.com/api",
  // baseURL: `${process.env.VUE_APP_BACKEND_URI}`,
  timeout: 120000
  
});
instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const AUTH_TOKEN = LS.get("auth.token");
    if (AUTH_TOKEN) {
      config.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    }
    config.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    // if (config.data != null) {
    //   let encrypted = CryptoJS.AES.encrypt(
    //     JSON.stringify(config.data),
    //     "AES_EROAM",
    //     { format: CryptoJSAesJson }
    //   ).toString();
    //   config.data = qs.stringify(JSON.parse(encrypted));
    // }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.data.EPC) {
      response.data = JSON.parse(
        CryptoJS.AES.decrypt(JSON.stringify(response.data), "AES_EROAM", {
          format: CryptoJSAesJson,
        }).toString(CryptoJS.enc.Utf8)
      );
    }
    return response;
  },
  function(error) {
    if (error.response.data.statusCode == 401) {
      Authanticated.clearLocalStorage();
      window.location.href == window.location.origin+'/' ? window.location.href = '/' : router.push('/') ;
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
export default instance;
