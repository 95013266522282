import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/store";
import { i18n } from "@/plugins/i18n";
import { BootstrapVue } from "bootstrap-vue";
import "./assets/scss/app.scss";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import Loading from "vue-loading-overlay";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios, Loading);

Vue.use(BootstrapVue);

import VueScreen from 'vue-screen';
Vue.use(VueScreen,'bootstrap');

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper);

import Toasted from "vue-toasted";
Vue.use(Toasted);

declare module "vee-validate";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });

import Multiselect from "vue-multiselect";
import "../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

import 'vue2-datepicker/index.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
