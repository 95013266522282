
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";
    import _ from "lodash";
    @Component({
        components:{
            Swiper, 
            SwiperSlide
        }
    })
    export default class websiteHeroBanner extends Vue {
        @Prop() bannerImages:any;
        fullSlider:any=[
            {
                slideImg:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                placeName:'place name'
            }
        ];
       
        swiperOption: any;
        created(){
            let bannerpalceImgnew=this.bannerImages;
            let images = _.map(
                bannerpalceImgnew,
                "place_name"
            );
            this.swiperOption = {
                //autoplay:true,
                pagination:{
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet(index, className) {                                                                             
                        return `<div class="${className} swiper-pagination-bullet-custom"><span>${images[index]}</span></div>`
                    }
                }            
            }        
        }   
        
        beforeCreated(){
            let bannerpalceImgnew=this.bannerImages;
            let images = _.map(
                bannerpalceImgnew,
                "place_name"
            );
            this.swiperOption = {
                //autoplay:true,
                pagination:{
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet(index, className) {                                                                             
                        return `<div class="${className} swiper-pagination-bullet-custom"><span>${images[index]}</span></div>`
                    }
                }            
            }
        }
    }
