import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import { English } from "./Translations/English";
import { French } from "./Translations/French";
import { Japanese } from "./Translations/Japanese";
import { Spanish } from "./Translations/Spanish";
import { Italian } from "./Translations/Italian";
import { Deutsche } from "./Translations/Deutsche";
import { Gujarati } from "./Translations/Gujarati";
// import { Greek } from "./Translations/Greek";
import { ChineseSimplified } from "./Translations/ChineseSimplified";
import { ChineseTraditional } from "./Translations/ChineseTraditional";
import { Danish } from "./Translations/Danish";
import { Hindi } from "./Translations/Hindi";

const messages: any = {
   'gb': English,
   'fr': French,
   'es': Spanish,
   'jp': Japanese,
   'de': Deutsche,
   'it': Italian,
   'gu': Gujarati,
  // 'gr': Greek,
   'cs': ChineseSimplified,
   'ct': ChineseTraditional,
   'da': Danish,
   'hi': Hindi,
};

export const i18n = new VueI18n({
   locale: 'gb', // set locale  
   fallbackLocale: 'gb', // set fallback locale
   messages, // set locale messages
});
