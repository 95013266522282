
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import Passengers from "@/Module/Global/Components/Sidebar/Passengers.vue";
import { applicationName } from "@/plugins/constants";
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import eMap from "@/Module/Global/js/eroam-map.js";
@Component({
  components: {
    // Datepicker,
    // Passengers,
  },
})
export default class HotelSearch extends Vue {
  name:any = "HotelSearch";
  applicationName: any = applicationName;
  hotelRating: any = "";
  RatingOptions: any = [
    { text: "Select Star Rating", value: "" },
    { text: "1 star", value: "1" },
    { text: "2 star", value: "2" },
    { text: "3 star", value: "3" },
    { text: "4 star", value: "4" },
    { text: "5 star", value: "5" },
  ];

  // datePicker = {
  //   disabledDates: {
  //     to: new Date(Date.now()),
  //   },
  // };

  // checkoutDisabledDates = {
  //   to: new Date(Date.now()),
  // };

  searchRequest: any = [];
  // checkin_date: any = new Date();
  // checkout_date: any = new Date();
  checkin_date: any = '';
  checkout_date: any = '';
  searching: boolean = false;
  
  // @Watch ('$store.state.search.loginUserInformation') changeView (newValue,oldValue){
  //   if(_.isEmpty(this.$store.state.search.loginUserInformation)){
  //   $("#autocompleteHotelOnly").val(""); 
  //   }
  // }

  // @Watch("checkin_date") checkinDateChanged(newValue, oldValue) {
  //   if(applicationName=='wolo'){
  //     this.checkout_date = moment(newValue)
  //       .add(7, "days")
  //       .toDate();
  //     }
  //   else{
  //       this.checkout_date = moment(newValue)
  //       .add(3, "days")
  //       .toDate();
  //   }
  //   this.checkoutDisabledDates = {
  //     to: new Date(newValue),
  //   };
  // }

  // mounted() {
  //   this.initialSearch();
  //   this.checkin_date = moment()
  //     .add(1, "days")
  //     .toDate();
  //   this.checkout_date = moment()
  //     .add(3, "days")
  //     .toDate();
  // }

  // search() {
  //   if (this.searchRequest.length) {
  //     let default_nights = moment(this.checkout_date).diff(
  //       moment(this.checkin_date),
  //       "days",
  //       true
  //     );
  //     if (default_nights >= 0) {
  //       this.searching = true;
  //       this.$store.dispatch(
  //         "setCheckinDate",
  //         moment(this.checkin_date).format("YYYY-MM-DD")
  //       );
  //       this.$store.dispatch(
  //         "setCheckoutDate",
  //         moment(this.checkout_date).format("YYYY-MM-DD")
  //       );

  //       this.$store.dispatch("SetSearchType", "accommodation");
  //       ApiRequest.getGooglePlaceCityInfo(this.searchRequest)
  //         .then((res) => {
  //           res = res.data;
  //           if (res.code == 200 && res.data) {
  //             this.$store.dispatch("setSelectedCities", res.data);
  //             let routes: any = _.cloneDeep(res.data);
  //             if (routes.length) {
  //               routes[0].default_nights = _.round(default_nights);
  //             }
  //             this.$store.dispatch("SetRoute", routes);
  //             this.$store.dispatch("SetInitalRoute", routes);
  //             this.$store.dispatch("SetLoading", 1);
  //             this.$store.dispatch("searchInitiate");
  //             this.$router.push("/hotels");
  //           }
  //           this.searching = false;
  //         })
  //         .catch((error) => {
  //           this.searching = false;
  //           this.$toasted.error("Something went wrong", {
  //             theme: "toasted-primary",
  //             position: "top-right",
  //             duration: 2000,
  //           });
  //         });
  //     } else {
  //       this.$toasted.error("Check Out date should be after Check In date", {
  //         theme: "toasted-primary",
  //         position: "top-right",
  //         duration: 2000,
  //       });
  //     }
  //   } else {
  //     this.$toasted.error("Please enter place name", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //   }
  // }

  // initialSearch() {
  //   let vm = this;
  //   let input = document.getElementById("autocompleteHotelOnly");
  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"],
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     vm.searchRequest = [];
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });

  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length
  //       ? filtered_city_array[0].long_name
  //       : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.searchRequest.push({
  //       ...place[0],
  //       small_image: imageUrl,
  //     });
  //   });
  // }
}
