

import { Component, Vue } from "vue-property-decorator";
import GoogleSearch from "@/Module/Global/components/Search/GoogleSearch.vue";

@Component({ 
    components: {
        GoogleSearch
    }   
})

export default class GoogleSearchTab extends Vue{
  
}
