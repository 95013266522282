
    import { Component, Vue } from 'vue-property-decorator';
    import Languages from '@/Module/Global/components/header/Languages.vue';
    import Currency from '@/Module/Global/components/header/Currency.vue';
    import Login from '@/Module/Global/components/header/Login.vue';
    import { EventBus } from "@/Events.js";
    import Ls from "@/Module/Global/Services/ls.js";
    import ApiRequest from "@/Module/Global/api/apiService.js";
    import Authanticated from "@/Module/Global/Services/auth.js";

    @Component({
        components:{
            Languages,
            Currency,
            Login
        }
    })
export default class Header extends Vue {
isLoggedIn:any=false;
logoPath:any = "https://static-graphics.eroam.com/eRoam/logo.svg";
user: any = {
    name: "",
    role: "",
};
passwordError: any = "";
changePasswordObj: any = {
    oldPass: "",
    newPass: "",
    confirmPass: "",
};
mounted(){
    this.checkIfUserLoggedIn();
    EventBus.$on("loginSuccess", function (data) {
        this.checkIfUserLoggedIn();
    });
}
checkIfUserLoggedIn() {
    if (Ls.get("auth.token") != null) {
    this.isLoggedIn = true;
    this.user.name = Ls.get("auth.name");
    this.user.role = Ls.get("auth.role");
    } else {
    this.isLoggedIn = false;
    this.$router.push("/");
    this.$bvModal.show("login-modal");
    this.user = {
        name: "",
        role: "",
    };
    }
}
changePassword() {
    this.passwordError = "";
    var vm = this;
    this.$validator.validate().then((valid) => {
        vm.changePasswordProcess(valid);
    });
}
changePasswordProcess(valid) {
var vm = this;
if (!valid) {
    return false;
}
ApiRequest.changePassword(vm.changePasswordObj)
    .then(function (res:any) {
    res = res.data;
    if (res.code == 200) {
        vm.changePasswordObj = {
            oldPass: "",
            newPass: "",
            confirmPass: "",
        };
        vm.$bvModal.hide("modal-changePassword");
        vm.$toasted.show(res.message, {
        type: "success",
        theme: "toasted-primary",
        position: "top-right",
        duration: 5000,
        });
        vm.logout();
    } else {
        vm.passwordError = res.message;
    }
    // vm.isSubmited = false;
    })
    .catch(function (error) {});
}
logout() {
    var vm = this;
    let token_id = Ls.get("auth.token_id");
    Authanticated.logout(token_id)
        .then(function () {
        // here we have to reload the page
        vm.$store.dispatch("incrementDashboardKey");
        vm.$store.dispatch("SetloginUserInformation", {});
        vm.$store.dispatch("SetUserRole", "");
        vm.$store.dispatch("userRoleInformation", []);
        vm.$store.dispatch("SetselectedStoreInfo", {});
        vm.$store.dispatch("setLoginInfomationValue", {});
        Authanticated.clearLocalStorage();
        vm.checkIfUserLoggedIn();
        vm.user = {
            name: "",
            role: "",
        };
        })
        .catch(function () {});
}
}
