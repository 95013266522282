export const Spanish: any = {
  creatingFor: "Creando itinerario para",
  creatingNew: "Creando nuevo itinerario para cliente",
  searchTitleItinerary: "Itinerario",
  searchTitleFlights: "Vuelos & Trenes",
  searchTitleHotel: "Hoteles & BnB's",
  searchTitleTours: "Tours & Entradas",
  searchTitlePackages: "Paquetes",
  searchTitleRentalcars: "Alquiler de Autos & Autocaravanas",
  searchTitleTransfers: "Transferencias",
  searchTitleCruises: "Cruceros",
  searchTitleEvents: "Eventos",
  searchTitleInsurance: "Seguros",
  searchInstructions:
    "Agregar su lugar de salida, y seleccionar 'Buscar'.Agregar los lugares adicionales en el siguiente paso, durante la Ruta Confirmada.",
  searchAddPlaces: "Lugar de salida",
  searcPlaceholder: "Lugares",
  searchError: "* Por Favor Seleccionar Dos Lugares",
  searchBlurb:
    "Crear su itinerario. Agregar cada uno de los lugares que desea visitar escribiendo su destino y haga clic en Enter para agregarlos.",
  consultantProfile: "Perfil Consultor",
  consultantSearchCust: "Cliente",
  Searchdata: "Buscar",
  consultantSearchBlurb: "Encontrar cliente'es reservas",
  consultantSearchCustButton: "Buscar Clientes",
  consultantSearchCont: "Buscar Contacto",
  consultantQuick: "Acceso Rápido",
  consultantActive: "Reservas",
  ActiveData: "Activo",
  consultantActiveBlurb: "Ver lista de reservas activas",
  consultantActiveButton: "Ver Reservas Activas",
  consultantPending: "Reservas",
  PendingData: "Pendiente",
  consultantPendingBlurb: "Ver lista de reservas activas",
  consultantPendingButton: "Ver Reservas Pendientes",
  consultantItineraries: "Itinerarios Activos",
  consultantCustomers: "Clientes",
  consultantAccount: "Resumen Cuenta",
  consultantNotices: "Notificaciones & Noticias",
  consultantTaskList: "Lista de Tares",
  consultantTaskYours: "Sus Tareas",
  consultantTaskNotFound: "Tarea no encontrada",
  consultantTaskConfirmCompleteMsg:
    "Esta seguro que desea marcar su tarea como completada",
  consultantCustName: "Nombre cliente",
  consultantActiveAgency: "Ver Reservas Activas de la Agencia",
  consultantPendingAgency: "Ver Reservas Pendientes de la Agencia",
  consultantTaskCustName: "Nombre Cliente",
  consultantTaskBookingId: "ID Reserva",
  consultantTaskNote: "Notas",
  consultantTaskDate: "Seleccionar Fecha",
  consultantTaskAddButton: "Agregar Tarea",
  consultantTaskEditButton: "Editar Tarea",
  consultantTaskCompleted: "Completo",
  consultantTaskactions: "Acciones",
  consultantTasksnoozed: "Posponer",
  consultantTasktasks: "Tareas",
  consultantTasksNone: "Parece que esta a la fecha, bien hecho",
  consultantMidOffice: "Oficina Central",
  consultantMidOfficeUpload: "Subir Datos a la Oficina Central",
  sidebarHeading: "Confirmar Ruta",
  siderbarInstructions:
    "Agregue todos los lugares de interés y póngalos en el order deseado. Seleccione una fecha y duración de estadía en cada lugar. Si opta por un inventario preseleccionado, refine sus resultados a través de las opciones de inventario para cada lugar.",
  sidebarOptionDeparture: "Salidas y Viajeros",
  sidebarDepDate: "Fecha de Salida",
  sidebarDepSelect: "Seleccionar la fecha de salida desde la primer ubicación.",
  sidebarDepError: "Error, por favor seleccionar fecha de salida.",
  travellersNumber: "Númmero de Viajeros",
  siderbarnewInstructions:
    "Agregue todos los lugares de interés y póngalos en el order deseado. Seleccione una fecha y duración de estadía en cada lugar. Si opta por un inventario preseleccionado, refine sus resultados a través de las opciones de inventario para cada lugar.",
  sidebarnewOptionDeparture: "Salidas y Viajeros",
  depDate: "Fecha de Salida",
  sidebarnewDepSelect:
    "Seleccionar la fecha de salida desde la primer ubicación.",
  sidebarnewDepError: "Error, por favor seleccionar fecha de salida.",
  sidebarTravellersNumber: "Númmero de Viajeros",
  Rooms: "Habitación | Habitaciones",
  adults: "Adultos",
  children: "Niños",
  childAgeLimits: "Edades (0-17)",
  Infants: "Infantes",
  infantAgesLimit: "Edad (1 y Menos)",
  childAges:
    "Cuantos años tienen los niños con los que viaja(a la hora de salida)",
  ageOfChild: "Edad del niño",
  infantConfirm: "Es infante debajo de un año de edad(a la hora de salida)",
  infantLengthPlural: "Son los infantes",
  infantLengthSingular: "Es el infante",
  infantLengthEnd: "debajo de un año de edad(a la hora de salida)",
  infantLapStart: "El/Los",
  infantLapPlural: "infantes",
  infantLapSingular: "infante",
  infantLapEnd: "viajara en el regazo de un Adulto?",
  infantsLap: "viajaran en el regazo de un Adulto?",
  sidebarnewInventoryOps: "Inventario y opciones de Lugares",
  sidebarViewTours: "Ver Grupos Turísticos",
  sidebarnewRouteOptions:
    "Por Favor seleccionar qué opciones quiere incluir en esta ruta.",
  siderbarnewAutoPopLoc: "Rellenar automáticamente con lugares de tendencia.",
  siderbarnewAutoPopInv:
    "Rellenar automáticamente usando preferencias del cliente.",
  arrivalDate: "Fecha de Llegada",
  confirmRouteButton: "Confirmar Ruta",
  redirectInventory: "Inventario de Itinerario",
  sidebarInventoryAlert:
    "Agregar and remover Inventario de Viaje a el Itinerario en la derecha. </p> Tenga en cuenta: Agregar un tour de varios días bloqueará las fechas de lugares anteriores.",
  redirectActivities: "Elegir Actividades",
  sidebarActivitiesAlert:
    "Administre el inventario dentro del Itinerario seleccionando el segmento a la izquierda. Para agregar, remover o reordenar lugares, seleccionar Ver Mapa / Botón de ruta.",
  redirectHotelList: "Elegir Hotel",
  sidebarHotelListAlert:
    "Elegir un nuevo hotel de la lista. </p>Su hotel seleccionado actualmente aparece en la parte superior.",
  redirectHotel: "Elegir su habitación",
  sidebarHotelAlert:
    "Elegir su habitación para agregar este hotel al itinerario",
  redirectToursList: "Elegir un tour",
  sidebarToursListAlert:
    "Elegir un tour de la lista. </p>Clic en un tour para ver  información mas detallada, precios y salidas",
  redirectTour: "Elegir una Salida",
  sidebarTourAlert:
    "Desplácese hacia abajo para encontrar una liste de fechas de salida con sus precios.</p>Agregue este tour a su itinerario o reserve el tour directamente",
  redirectTransport: "Elegir Transporte",
  sidebarTransportAlert: "",
  checkoutAlert:
    "Agregue los detalles del Pasajero para completar la reservación",
  checkout: "Revisar",
  redirectPreview: "Vista Itinerario",
  redirectPreviewPDF: "Vista PDF",
  sidebarnewInventoryOptions: "Opciones Inventario",
  errorMsg: "Error al mostrar aquí",
  remove: "Remover",
  sidebarnewInclusiveTourButton: "Tour Grupal incluido",
  sidebanewNotes: "Agregar este lugar a la ruta",
  sidebarnewReturnTripOption: "Opción de Viaje con Retorno",
  sidebarnewRoundTripForm:
    "Por Favor seleccionar un Origen y Retorno. Si desea incluir vuelos de regreso.",
  to: "A",
  night: "Noches",
  tourDuration: "Duración",
  contact: "Contacto",
  checkinDate: "Fecha Checkin",
  checkoutDate: "Fecha Checkout",
  supplierReference: "Proveedor",
  propertyReference: "Referencia de Reserva",
  transport: "Transporte",
  service: "Servicio",
  inventoryTravelClass: "Clase de Viajero",
  departureLocation: "Lugar de Salida",
  arrivalAirport: "Lugar de Llegada",
  status: "Estado",
  departuretime: "Tiempo de Salida",
  hotels: "Hoteles",
  hotelPricePerNight: "Precio Por Noche",
  hotelPropertyRating: "Valoración de la propiedad",
  searchbyPropertyName: "Buscar por Nombre de Propiedad",
  search: "Buscar",
  refundtype: "Tipo de Reembolso",
  days: "Días",
  location: "Locación",
  pernight: "por noche",
  pendingItineraries: "Itinerarios Pendientes",
  hotelownArrangementPropertyName: "Nombre Propiedad *",
  hotelownArrangementPropertyNotes: "Notas Propiedad *",
  validationMsg: "Este campo es requerido.",
  emailValidationMsg:
    "El Campo Dirección correo electrónico debe ser un correo electrónico válido.",
  contactMinValidationMsg:
    "El campo de Número de Contacto debe tener al menos 10 caracteres.",
  contactMaxValidationMsg:
    "El campo de Número de Contacto debe tener al menos 11 caracteres.",
  ownArrangementImage: "Imagen",
  ownArrangementHotelimage: "Imagen Hotel",
  ownArrangementImgClick: "Clic o Arrastre imagen del hotel aqui para subirla",
  ownArrangementFileTypeMsg: "(Tipo de archivo admitido .jpeg, .jpg, .png)",
  hotelownArrangementPropertyaddress: "Dirección Propiedad *",
  hotelownArrangementPropertyContact: "Numero de Contacto de la Propiedad *",
  hotelownArrangementPropertyemail: "Correo Electrónico de la Propiedad *",
  hotelownArrangementDurationDetails: "Detalles Duración",
  hotelownArrangementPropertyCheckindate: "Fecha Check-in *",
  hotelownArrangementPropertyCheckintime: "Hora Check-in *",
  hotelownArrangementPropertyCheckouttime: "Hora Check-out *",
  hotelownArrangementPropertyCheckoutdate: "Fecha Check-out *",
  ownArrangementPricingDetails: "Detalles Precios",
  ownArrangementCurrency: "Moneda *",
  hotelownArrangementCurrency: "Moneda de Compra *",
  hotelownArrangementPropertyPrice: "Precio Venta *",
  hotelownArrangementPropertybookingref: "Referencia Reservación *",
  ownArrangementPurchasingdetails: "Detalles de Compra",
  ownArrangementPurchasePrice: "Precio de Compra *",
  hotelownArrangementPropertySupplier: "Proveedor *",
  hotelownArrangementPropertyInvoice:
    "Incluir este precio de Alojamiento de Arreglo Propio en la Factura Final.",
  hotelownArrangementPropertyRefund:
    "Este Producto de Alojamiento de Arreglo Propio es Reembolsable",
  cancelButton: "Cancelar",
  addtoitinerary: "Agregar A Itinerario",
  ownArrangementBasicDetails: "Detalles Básicos del Hotel",
  activityownArrangementActivityname: "Nombre de Actividad *",
  activityownArrangementAddress: "Dirección *",
  activityownArrangementPhnno: "Número de Telefono *",
  activityownArrangementEmail: "Correo Electrónico *",
  activityownArrangementWeburl: "Web URL",
  activityownArrangementImage: "Imagen",
  ownArrangementActivityImgClick:
    "Clic o Arrastre la imagen de la actividad aquí para subirla",
  activityownArrangementActivityImage: "Imagen Actividad",
  activityownArrangementSellingPrice: "Precio Venta *",
  activityownArrangementSupplierReference: "Proveedor",
  activityownArrangementPurchaseCurrency: "Moneda Compra *",
  activityownArrangementPropertyReference: "Referencia Reservación *",
  ownArrangementComments: "Comentarios",
  activityownArrangementComments: "Comentarios",
  operator: "Operador",
  ownArrangementTravelClass: "Clase",
  ownArrngementArrivalTime: "Hora Llegada",
  ownArrangementAirlineReference: "Referencia Aerolinea",
  ownArrangementGdsReference: "Referencia GDS",
  downloadPDF: "Descargar PDF",
  expandAll: "Expandir TODO",
  collapseAll: "Colapsar TODO",
  showMore: "Mostrar Mas",
  showLess: "Mostrar Menos",
  accommodation: "Acomodación",
  name: "Nombre",
  checkIn: "Check In",
  checkOut: "Check Out",
  checkOutDash: "Check-out",
  price: "Precio",
  PerNight: "Por Noche",
  roomDetails: "Detalles Habitación",
  roomType: "Tipo Habitación",
  pendingBookingNights: "Noches",
  nonRefundable: "No-Reembolsable",
  refundable: "Reembolsable",
  ownArrangement: "Arreglo Propio",
  toursActivities: "Tours / Actividades",
  viewitenaryActivityname: "Nombre Actividad",
  viewitenaryAddress: "Dirección",
  viewitenaryPhone: "Telefono",
  viewitenarySellingPice: "Precio Venta",
  email: "Correo Electrónico",
  date: "Fecha",
  duration: "Duración",
  code: "Código",
  startDate: "Fecha de Inicio",
  finishDate: "Fecha de Finalización",
  endDate: "Fecha Final",
  aud: "AUD",
  view: "Vista",
  noDataFound: "No Record Encontrado",
  notProvided: "No Provisto",
  pendingBookingPage: "X",
  pendingBookingCustomerName: "Nombre Cliente",
  pendingBookingDepdate: "Fecha Salida",
  pendingBookingCreatedOn: "Creado En",
  travellers: "Viajeros",
  pendingBookingTotalPrice: "Precio Total",
  ownArrangementContactDetails: "Detalles de Contacto",
  sortby: "Ordear Por:",
  titleOfActivity: "Titulo",
  activityCode: "Código Actividad:",
  viator: "Viator",
  from: "Desde",
  perPerson: "por persona",
  category: "Categoria",
  sidebarActivityName: "Buscar por Nombre de Actividad",
  readmore: "Leer Mas",
  hotelPickupIncluded: "Recogida del Hotel Incluida:",
  activityDetaildeparturePoint: "Punto de Salida:",
  overview: "Vista Previa General",
  importantInfo: "Info Importante",
  details: "Detalles",
  activityDetailInclusions: "Inclusiones",
  activityDetailExclusions: "Exclusiones",
  activityDetailAdditionalinfo: "Info Adicional",
  activityDetailVoucherinfo: "Info Comprobante",
  activityDetailLocalOperatorInformation: "Información de operador local",
  activityDetailLocalOperatorInformationlope:
    "Información Completa del Operador, incluyendo números de teléfono en su destino están incluidos en su Comprobante de Confirmación. Nuestros Gerentes de Producto seleccionan solo a los operadores más experimentados y confiables en cada destino, eliminando las conjeturas por usted y garantizando su tranquilidad.",
  activityDetailCancellationPolicy: "Póliza de Cancelación",
  activityDetailScheduleAndPricing: "Horario y Precio",
  activityDetailScheduleAndPricingContent:
    "Nuestros precios se actualizan constantemente para garantizar que siempre reciba el precio más bajo posible - lo garantizamos al 100%.",
  activityDetailBookActivity: "Reservar Actividad",
  activityDetailDeparts: "Salidas",
  activityDetailPickup: "Recogidas:",
  activityDetailCheckedPickup: "Necesito que me recojan",
  activityDetailaddActivity: "Agregar Actividad",
  activityDetailSpecialRequirements: "Requerimientos Especiales",
  activityDetailSelectLocation: "Selecionar Lugar",
  activityDetailEnterPickupLocation: "Agregue Lugar de Recogida *",
  noRecordFound: "No se Encontro Record ",
  sidebarTransportCustomerPricing: "Precio Cliente",
  sidebarTransportCustomerPricingContent:
    "Desglose de precios del itinerario, incluido el costo total del cliente y por PAX.",
  sidebarTransportHeading: "AUD 4562",
  sidebarTransportContent: "Precio Total en AUD inc. Impuesto",
  sidebarTransportPricePerPaxAudincTax: "Precio Por PAX AUD inc. Impuesto",
  sidebarTransportCostPerDay: "Costo Por Día:",
  sidebarTransportCost: "458.00",
  sidebarTransportTotalCost: "Costo Total:",
  sidebarTransportTotal: "AUD 785",
  sidebarTransportAgencyCost: "Costo Agencia",
  sidebarTransportOne: "Uno",
  sidebarTransportTwo: "Dos",
  sidebarTransportThree: "Tres",
  sidebarTransportFilters: "Filtros Transporte",
  sidebarTransportType: "Tipo de Transporte",
  return: "regreso",
  transportOnlyRates:
    "apague la vista del agente para mostrar las tarifas minoristas para los Clientes.",
  transportOnlySaveQuotation: "Guardar Cotización",
  transportOnlyBookItinerary: "Reservar Itinerario",
  transportOnlyCustomerPricing: "Precios Cliente",
  transportOnlyAgencyCost: "Costo Agencia",
  transportOnlyFilters: "Filtros",
  transportOnlyDepartureLocation: "Lugares de Salida",
  transportOnlyDestinationLocation: "Lugares de Destino",
  transportOnlyReturnDate: "Fecha de Regreso",
  transportOnlyParistoRome: "Paris a Rome",
  transportOnlyFilterContent: "Lunes, 1ro de Abril 2019",
  change: "Cambiar",
  transportOnlyvueling: "Vueltas",
  nonStop: "Sin escalas",
  departs: "Salir",
  transportOnlyParisOrlyAirport: "Aeropuerto de Paris Orly (ORY)",
  transportOnlyArrive: "Llegada",
  transportOnlyFco: "Roma - Roma Estación de Tren Tiburtina (FCO)",
  transportOnlyKiwi: "Kiwi.com",
  transportOnlyMystifly: "Mystifly",
  transportOnlySabre: "Sabre",
  transportOnlyEconomy: "Economica",
  transportOnlySelect: "Seleccionar",
  transportOnlyRouteDetails: "Detalles de Ruta",
  transportOnlyCalendar: "Lunes 1ro de Abril, 2019",
  transportOnlyFlight: "Vuelo",
  transportOnlyShowDetails: "Mostrar Detalles",
  transportOnlyParis: "Paris - Gare De Lyon",
  transportOnlyMilan: "Milan - CentralStation",
  transportOnlyBaggageDetails: "Detalles de Equipaje",
  transportOnlyPersonalBaggage: "Personal de Equipaje",
  transportOnlyFree: "Gratis",
  transportOnlyCheckedBaggage: " Equipaje Facturado",
  transportOnlyAddCheckedBaggage: "Agregar Equipaje Facturado",
  transportOnlyAddCabinBaggage: "Agregar Equipaje de Cabina",
  transportOnlyInFlight: "Complementos en Vuelo",
  transportOnlyPassengerPricingDetails: "Detalles Pasajero y Precio",
  transportOnlyPassenger: "Pasajero",
  transportOnlyonexAdult: "1 x Adulto",
  transportOnlyBaseFare: "Tarifa Base",
  transportOnlyBaggage: "Equipaje",
  transportOnlyFeesAndTaxes: "Otros Cargos / Impuestos",
  transportOnlyTermsConditions: "Términos & Condiciones",
  transportOnlyNetRate: "Tasa Neta",
  transportOnlyCommision: "Comisión",
  checkinTime: "Hora Checkin",
  checkoutTime: "Hora Checkout",
  searchCustomerName: "Buscar por Nombre de Cliente",
  confirmroute: "Confirmar Ruta",
  logout: "Cerrar sesión",
  searchbypropertyname: "Buscar por Nombre de Propiedad",
  hotelDetailshotelRoom: "(1 Habitación)",
  hotelDetailRoomsRates: "Habitación & Tarifas",
  hotelDetailAmenities: "Servicios del Hotel",
  hotelDetailCheckInInstructions: "Instrucciones de Registro",
  hotelDetailsFeesOptionalExtras: "Cargos / Extras Opcionales",
  hotelDetailValueAddedServices: "Valor de Servicios Adicionados",
  hotelDetailCheckin: "Check In",
  hotelDetailhotelCheckout: "Check Out:",
  hotelDetailBedPreferencese: "Preferencias de Cama:",
  hotelDetailViewCancellationPolicy: "Ver Póliza de Cancelación",
  hotelDetailRoomDetailAmenities: " Comodidades de la Habitación",
  hotelDetailRoomAmenities: "Comodidades de la Habitación",
  hotelDetailGuests: "Huésped:",
  hotelDetailSubtotal: "SubTotal:",
  hotelTotal: "Total",
  hotelDetailTaxRecoveryServiceFee:
    "Recuperación de Impuestos y Cuota de Servicio:",
  hotelDetailSpecialCheckInInstructions: "Instrucciones Especiales de Check in",
  hotelDetailadditionalInformation: "Información Adicional",
  headerLogin: "Iniciar Sesión",
  headerLogout: "Cerrar Sesión",
  hide: "Esconder",
  depart: "Salir",
  arrive: "Llegar",
  flightNumber: "Número de Vuelo",
  routeDetails: "Detalles de Ruta",
  wifi: "WIFI",
  included: "INCLUIDO",
  airCondition: "Aire Acondicionado",
  passengerPricingDetails: "Detalles Pasajero y Precio",
  supplier: "Proveedor",
  passenger: "Pasajero",
  passengers: "Pasajeros",
  flightMystifly: "Mystifly",
  flight2Adult: "2 x Adultos",
  baggage: "Equipaje",
  otherFeesTaxes: "Otros Cargos / Impuestos",
  free: "GRATIS",
  netRate: "Tasa Neta",
  select: "Seleccionar",
  floatZero: "0.00",
  doubleZerofloat: "00.00",
  tv: "TV",
  flightEconomy: "Economica",
  viewMore: "Ver Mas",
  flightOnBoardAddOns: "Complementos Abordo",
  loginSignIn: "Iniciar Sesión",
  customerNameOrEmail: "Nombre de Cliente o Correo Electrónico",
  searchBy: "Buscar Por",
  firstName: "Primer Nombre",
  lastName: "Apellido",
  gender: "Género",
  phone: "Telefono",
  submitButton: "Enviar",
  your: "Tu",
  createdOn: "Creado En",
  nights: "Noches",
  totalPrice: "Pecio Total",
  emailUserName: "Correo Electrónico o Nombre de Usuario",
  userName: "Nombre de Usuario",
  password: "Contraseña",
  enterPassword: "Ingrese Contraseña",
  enterCustomerName: "Ingrese Nombre de Cliente",
  taskEnterBookingId: "Ingrese ID de la Reserva",
  commission: "Comisión",
  costPerDay: "Costo Por Día",
  pricePerPAX: "Precio Por Persona",
  totalCost: "Costo Total",
  netCost: "Costo Neto",
  includingTax: "Cía. Impuesto",
  priceSummary: "Resumen Precio",
  ConsultantSidebarItineraryCustomerCostByPAXMsg:
    "Desglose de precios del itinerario, incluido el costo total del cliente y por Persona.",
  sidebarHeadingPreviewItinerary: "Vista Previa Itinerario",
  sidebarHeadingSaveQuotation: "Guardar Cotización",
  sidebarHeadingBookItinerary: "Reservar Itinerario",
  sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg:
    "Tapague la vista del agente para mostrar las tarifas minoristas para los Clientes.",
  agentView: "Vista Agente",
  customerView: "Vista Cliente",
  save: "Guardar",
  travelInsurance: "Segura de Viaje",
  no: "No",
  addTravel: "Agregar Viaje",
  plus: "Plus",
  basic: "Básico",
  inventoryMedicalExpenseTripCancellation:
    "Gastos Medicos, Cancelación de Viaje",
  inventoryAssistanceServices: "Servicios de Asistencia",
  inventoryLostBaggageMore: "Perdida de Equipaje y Mas",
  audWithFloating: "AUD 00.00",
  inventoryComparsionAndTerms: "Comparación and Términos",
  customerCare: "Servicio al Cliente",
  premium: "Premium",
  inventoryExploreBenefitsOfPayingPremiumMsg:
    "Explorar los beneficios de pagar con Premium",
  previous: "Anterior",
  continue: "Continuar",
  saveAndContinue: "Guardar y Continuar",
  BacktoInventory: "Volver a Inventario",
  AcceptandContinue: "Aceptar y Continuar",
  busBud: "BusBud",
  edit: "Editar",
  delete: "Eliminar",
  checked: "Revisado",
  checkoutBusListINBusAddonMsg: "Complementos en el Bus",
  BaseFarePerPax: "Tarifa Base(Por Persona)",
  onexAdult: "1 x Adulto",
  oneRoom: "1 Habitación",
  CancellationPolicy: "Póliza de Cancelación",
  expedia: "Expedia",
  roomSleep: "Room sleep",
  checkOutActivityListviator: "Viator",
  checkOutActivityDetails: "Detalles Actividad",
  option: "Opción",
  groupInclusiveTourOptions: "Opciones de Excursion Grupal Incluida",
  matchCurrentRouteMsg:
    "Actualmente hay 219 Excursiones Grupales que coinciden con su ruta actual.",
  errorDisplayHere: "Error al mostrar aquí",
  tourFilters: "Filtros Tour",
  tailorMadeTours: "Tours a Medida",
  tailorMadeOptionsMsg:
    "Seleccione qué opciones desea mostrar solo opciones a medida",
  tailorMadeToursItineryMsg:
    "Mostrar solo Tours a Medida para los itinerarios ",
  MonthOfDeparture: "Mes de Salida",
  selectDate: "Seleccione Fecha",
  destination: "Destino",
  tourExperience: "Experiencia de Tour",
  preferredSupplier: "Proveedor Preferido",
  style: "Estilo",
  size: "Tamaño",
  start: "Comienzo",
  notAvailbale: "N/A",
  inclusions: "Inclusiones",
  day: "Día",
  meals: "Comidas",
  breakfast: "Desayuno",
  lunch: "Almuerzo",
  dinner: "Cena",
  tourDetailsNoMealMsg: "No hay comida incluidas este día.",
  locationsToVisit: "Lugares para visitar",
  additionalInformation: "Información Adicional",
  departureNotFound: "Salida No Encontrada",
  additionalInformationNotFound: "No mas Información disponible",
  departureAndPricing: "Salidas y Precios",
  departing: "Saliendo",
  finishing: "Terminando",
  availability: "Disponibilidad",
  totalFrom: "Total Desde",
  perAdult: "por Adulto",
  perChild: "por Niño",
  departureGuaranteed: "salida garantizada",
  onSale: "en descuento",
  onRequest: "bajo pedido",
  addToRoute: "Agregar a la Ruta",
  termsConditions: "Términos & Condiciones",
  available: "Disponibilidad",
  finish: "Terminar",
  stars: "Estrellas",
  reviews: "Comentarios",
  rating: "Clasificación",
  default: "Defecto",
  title: "Titulo",
  lengthOfTour: "Duración del Tour",
  optionsSelected: "opciones seleccionadas",
  tourNotFound: "Tour no encontrado",
  validTo: "Valido hasta",
  lodging: "Alojamiento",
  validFrom: "Valido Desde",
  perNight: "Por Noche",
  sortBy: "Ordenado Por",
  viewItineraryGITtours: "Tours GIT",
  inventoryAddNewAccommodation: "Elegir Otro Hotel",
  inventoryAddNewTransport: "Agregar Nuevo Transporte",
  passengersAvoidBoardingComplicationMsg:
    "Use todos los nombres (Nombre y Apellido) exactamente como aparece en el Pasaporte/ID para evitar complicaciones de embarque.",
  passengersRequiredFlightSegmentMsg:
    "Requerido para segmento de Viaje Internacional.",
  dateOfBirth: "Fecha de Nacimiento",
  passengersTourSpecificField: "Campo Especifico de Tour.",
  passengersTourSegmentMsg: 'Requerido para Segmento de Tour "X"',
  visaRequriements: "Requerimientos Visa",
  passengersreferencePurposeMsg:
    "Una Visa o Visa de transito aeroportuaria puede ser necesaria para viajar hasta cuando nose para en el Pais de escala. Estos requisites son solo para fines de referencia. Contacte la Embajada o el ministerio de relaciones exteriores para más información.",
  passengersDeniedDestinationIssueMsg:
    "eRoam no se hace responsable de los pasajeros a los que se haya denegado el embarque o la entrada a cualquier destino debido a problemas de visa.",
  givenNames: "Nombre",
  passengerNames: "Nombres *",
  surname: "Apellido",
  passengersurname: "Apellido *",
  familyname: "Apellido",
  PassengerGender: "Género *",
  paymenttotalprice: "Precio Total",
  paymentnewprice: "Precio Nuevo",
  emailAddress: "Correo Electrónico",
  passengeremailAddress: "Correo Electrónico *",
  contactNumber: "Número de Contacto",
  passengercontactNumber: "Número de Contacto *",
  passengersIndividualInventorySpecificationMsg:
    "Los campos a continuación son obligatorios debido a las Especificaciones de inventario individuales, como vuelos internos, requisitos de tours, etc.",
  Nationality: "Nacionalidad",
  PassengerNationality: "Nacionalidad *",
  confirmBooking: "Reservación Confirmada",
  receiptNumber: "Número de Recibo",
  paymentAddReceiptNumberMsg: "Agregue el número de recibo del pago procesado.",
  PaymentDeniedDestinationIssueMsg:
    "Una Visa o Visa de transito aeroportuaria puede ser necesaria para viajar hasta cuando nose para en el Pais de escala. Contacte la Embajada o el ministerio de relaciones exteriores para más información. eRoam no se hace responsable de los pasajeros a los que se haya denegado el embarque o la entrada a cualquier destino debido a problemas de visa.",
  amount: "Cantidad *",
  paymentEnterAmountMsg: "Ingrese una cantidad que esta haciendo.",
  paymentEnterTypeMsg: "Ingrese el tipo de Pago",
  paymentType: "Tipo de Pago *",
  paymentOnceSubmittedMsg:
    "Una vez que se hayan enviado los detalles del pago, se confirmará la reserva.",
  payment: "Pago",
  printPDF: "Imprimir PDF",
  custom: "Personalizar",
  additionalNotes: "Anotaciones Adicionales",
  list: "Lista",
  checkinDash: "Check-In",
  detailedSummary: "Resumen Detallado",
  dailySummary: "Resumen Diario",
  tripSummary: "Resumen de Viaje",
  transportName: "Nombre de Transporte",
  activities: "Actividades",
  basicTransportDetails: "Detalles Básicos de Transporte",
  transportOwnArrangementRefundMsg:
    "Este producto de transporte de arreglo propio es reembolsable.",
  transportOwnArrangementPricingMsg:
    "Incluya este precio de transporte de arreglo propio en la factura final.",
  salePrice: "Precio de Venta",
  transportOwnArrangementAddLegtoArrangement: "Agregar Segmento al Arreglo",
  arrivalLocation: "Lugar de Llegada",
  transportOwnArrangementAddNewLeg: "Agregar un Nuevo Segmento",
  arrival: "Llegada",
  departure: "Salida",
  fromTo: "Desde - Hacia",
  selectThePlaceToReturn: "Seleccionar el lugar de regreso",
  selectAReturn: "Seleccionar un Regreso",
  returnToAnotherCity: "Regresar a otra ciudad",
  selectPlaceOrigin: "Seleccionar el lugar de origen.",
  selectOrigin: "Seleccionar un origen.",
  accomodationSummary: "Resumen Alojamiento.",
  transportSummary: "Resumen Transporte",
  activitySummary: "Resumen Actividad",
  taskListConformDeleteMsg: "Por Favor confirme que desea borrar Tarea?",
  ConfirmActionTitle: "Acción Confirmada",
  passengertitle: "Titulo *",
  hotelListTotalCost: "Coste Total",
  nonstop: "Sin Escala",
  selected: "Seleccionado",
  inventoryAddNewHotels: "Agregar Nuevos Hoteles",
  inventoryAddOwnArrangement: "Agregar Propio Acomodamiento",
  propertylocation: "Ubicación Propiedad",
  inventoryAddNewToursTickets: "Elige Otro Tour y Entradas",
  inventoryContactPhone: "Número de Contacto",
  inventoryContactEmail: "Correo Electrónico de Contacto",
  flight: "Vuelo",
  time: "Hora",
  bookingId: "ID reserva",
  bookingNote: "Nota de Reserva",
  stops: "Paradas",
  layOverDuration: "Duración de la escala",
  itineraryPdfImageText:
    "Clic o Arrastre la imagen del itinerario aquí para subirla",
  confirm: "Confirmar",
  changePassword: "Cambiar Contraseña",
  oldPasswordErrorMsg: "Ingrese Contraseña Vieja",
  newPasswordErrorMsg: "Ingrese Contraseña Nueva",
  confirmPasswordErrorMsg: "La confirmación de contraseña no coincide",
  oldPassword: "Old Password",
  confirmPassword: "Confirmar Contraseña",
  newPassword: "Nueva Contraseña",
  visitedLocations: "Lugares para Visitar",
  checkoutFlightListINFlightAddonMsg: "Complementos en vuelo",
  checkoutmystifly: "Mystifly",
  tourDetailstitle: "Detalles del Tour",
  update_total_cost: "Actualizar costo total",
  updateCostConfirmCompleteMsg:
    "Esta seguro, quiere continuar con una comisión negativa?",
  confirmButton: "Confirmar",
  norating: "Sin Evaluar",
  adult: "Adulto",
  child: "Niño",
  PrimaryPassenger: "Pasajero Principal (Pax principal)",
  hotelbed: "Hotelbed",
  activityownemail: "Correo Electrónico Proveedor",
  activityownweb: "Sitio Web",
  activityownphone: "Telefono",
  activityownsupplier: "Proveedor",
  hotelDetails: "Detalles de Hotel",
  room: "Habitación",
  ownArrangementActivityBasicDetails: "Detalles básicos de la actividad",
  booking: "Reserva",
  tour: "Tour",
  oopsTheActivity: "La Actividad ",
  oopsTheTour: "El Tour",
  oopsTheFlight: "El Vuelo",
  notAvailable: "no esta disponible en este momento.",
  inventoryAddHotelOwnArrangement: "Arreglo Propio (Alojamiento)",
  inventoryAddToursOwnArrangement: "Arreglo Propio (Tour y Entradas)",
  inventoryAddReplaceTransport: "Elige otro Transporte",
  inventoryAddTransportOwnArrangement: "Arreglo Propio (Transporte)",
  noProductSelected: "Producto no seleccionado",
  taskSelectDate: "Seleccionar Fecha",
  taskSelecgender: "Seleccionar genero",
  age: "Edad",
  relation: "Relación",
  activityTime: "Hora *",
  activityDate: "Fecha *",
  accountId: "Id de Cuenta",
  nationality: "Nacionalidad",
  city: "Ciudad",
  address: "Dirección",
  postcode: "Código Postal",
  region: "Region",
  ontarioResident: "Residente de Ontario",
  prepaidGratuities: "Propinas prepagas",
  mobilePhone: "Contacto",
  titleBooking: "Titulo",
  genderBooking: "Genero",
  emailBooking: "Correo Electrónico",
  paymentAmount: "Monto del pago",
  checkoutRceiptNumber: "Número de Recibo",
  loadPnr: "Carga PNR",
  activityownArrangementPropertyInvoice:
    "Incluir este precio en la factura final.",
  activityownArrangementPropertyRefund: "Esto es Reembolsable.",
  bookingVoucherfileType:
    "(Tipos de archivos compatibles.jpeg, .jpg, .png, .pdf)",
  bookingVouchermsg: "Clic aquí para subir el comprobante",
  carrierName: "Nombre de la compañía",
  departureDateandTime: "Salida Fecha y Hora",
  arrivalDateandTime: "Llegada Fecha y Hora",
  airlineReference: "Referencia de Aerolínea",
  pnrNumber: "Ingresar PNR",
  pnrInstructions:
    "Por Favor ingresar un numero PNR valido para obtener itinerario",
  termsandconditions: "Términos y condiciones",
  total: "Total",
  baggageAdult: "Equipaje Adulto / Niño",
  baggageChild: "Equipaje Niño",
  gitWarningMessage: "Ha seleccionado un Tour invalido para esta ciudad",
  summeryConfirmCompleteMsg:
    "Esta seguro, Quiere Continuar Con Una Selección De Tour Invalida?",
  tourType: "Tipo de Tour",
  SearchPlace: "Lugar de búsqueda ? Buscar lugares",
  SearchCity: "Ciudad de búsqueda ? Buscar ciudades",
  manager: "director",
  product: "Producto",
  finance: "Finanzas",
  admin: "Admin",
  viewProductManager: "Ver Product Manager",
  viewfinanceManager: "Ver Gerente de Finanzas",
  viewAdminManager: "Ver Administrador de administradores",
  viewsupplierManager: "Ver Gerente de Proveedores",
  viewmarketingManager: "Ver Gerente de Marketing",
  DaterangeFrom: "Intervalo de fechas desde",
  DaterangeTo: "Intervalo de fechas a",
  DataType: "Tipo de datos",
  FileType: "Tipo de archivo",
  downloadNow: "Descargar ahora",
  ConsultantName: "Nombre del consultor",
  SupplierName: "Nombre del proveedor",
  ClientFile: "Archivo de cliente",
  pay: "Pagar",
  TypeofPayment: "Tipo de pago",
  Login: "Inicia sesión",
  exportData: "Exportar datos",
  roomNumber: "Habitación",
  storeName: "Nombre de la tienda",
  manageUsers: "Administrar usuarios",
  manageBrands: "Gestionar marcas",
  viewReports: "Ver informes",
  brand: "Marca",
  manageLicensee: "Administrar licenciatario",
  ManageLicensee: "Licenciatario de búsqueda",
  OnboardNewLicensee: "Nuevo licenciatario incorporado",
  CreateNewPackage: "Crear un nuevo paquete",
  consultantArchived: "Reservas",
  ArchivedData: "Archivados",
  consultantArchivedBlurb: "Ver lista de reservas archivadas",
  consultantArchivedButton: "Ver reservas archivadas",
  consultantArchivedAgency: "Ver reservas de agencias archivadas",
  archivedItineraries: "Itinerarios archivados",
  taskDay: "Seleccionar día",
  action: "Acción",
  mobile: "Móvil",
  taskSelectDay: "Seleccionar día",
  DateandTime: "Fecha y hora",
  addtransport: "Añadir transporte",
  addhotels: "Añadir hoteles",
  addactivities: "Añadir actividades",
  manageclientpreferences: "Administrar las preferencias del cliente",
  Return: "devolución",
  oneway: "One Way",
  multycity: "Ciudad de Multy",
  flyingfrom: "Volando desde",
  selectclass: "Seleccionar clase",
  returndate: "Fecha de devolución",
  comingsoon: "Próximamente",
  thingstodo: "Cosas que hacer",
  extendedtouring: "Touring extendido",
  selectinterest: "Seleccione Interés",
  searchpnr: "Buscar PNR",
  googlesearch: "Búsqueda de Google",
  package: "Paquete",
  addcustomer: "Añadir cliente",
  geoLocationManager: "Geo Gerente de Ubicación",
  supplieroperatorManager: "Gerente de Proveedor / Operador",
  yieldManager: "Gerente de Rendimiento",
  packageBuilder: "Generador de paquetes",
  plotmaps: "Mapas de trazado",
  reporting: "Informes",
  CSVDownload: "CSV Descargar",
  makePayments: "Realizar pagos",
  packageName: "Nombre del paquete",
  uploadImages: "Subir imágenes",
  toursandtickets: "Tours y Entradas",
  hotel: "Hotel",
  packageOverview: "Descripción general del paquete",
  packageItinerary: "Itinerario del paquete",
  Optional: "Opcional",
  createpackagefromtour: "Crear paquete a partir del tour",
  Currency: "Moneda",
  companyName: "Nombre de la empresa",
  Phone: "Teléfono (incluido el código de país)",
  url: "Url",
  uploadLogo: "Cargar logotipo",
  selectProduct: "Seleccionar producto",
  hotelSupplier: "Proveedor hotelero",
  tourSupplier: "Proveedor turístico",
  transportSupplier: "Proveedor de transporte",
  otherSupplier: "Otro proveedor",
  selectSettings: "Seleccione Configuración",
  defaultLanguage: "Idioma predeterminado",
  defaultCurrency: "Moneda predeterminada",
  chooseTemplate: "Elija Plantilla",
  paymentGateway: "Pasarela de pagos",
  selectSubscription: "Seleccione Suscripción",
  licenceType: "Tipo de licencia",
  howmanylicences: "¿Cuántas licencias?",
  addons: "Complementos",
  paymentTerms: "Términos de pago",
  licenseenameoremail: "Nombre del licenciatario o correo electrónico",
  createProfilesandClientDetails:
    "Crear perfiles y administrar detalles del cliente",
  passportNumber: "Número de pasaporte",
  passportissuedate: "Fecha de emisión del pasaporte",
  passportExpiryDate: "Fecha de vencimiento del pasaporte",
  passportissuingcountry: "País emisor de pasaportes",
  clientContact: "Contacto con el cliente",
  Phonehome: "Teléfono (casa)",
  Phonework: "Teléfono (trabajo)",
  phonemobile: "Teléfono (móvil)",
  AddressLinOne: "Dirección (línea uno)",
  AddressLineTwo: "Dirección (línea dos)",
  StateProvinceRegion: "Estado / Provincia / Región",
  zipPostalCode: "Código Postal",
  country: "País",
  newsletter: "Boletín",
  companyABN: "Empresa ABN",
  paName: "Nombre PA",
  clientLoyaltyPrograms: "Programas de fidelización de clientes",
  srno: "Mr No",
  loyaltyType: "Tipo de lealtad",
  providerName: "Nombre del proveedor",
  membershipNumber: "Número de membresía",
  clientPreferences: "Preferencias del cliente",
  airline: "Aerolínea",
  airlines: "Aerolínea(s)",
  travelClass: "Clase de viaje",
  preferredSeating: "Asientos preferidos",
  mealRequest: "Solicitud de comida",
  Hotels: "Hotel(es)",
  hotelChain: "Cadena hotelera",
  Cars: "Coche(s)",
  carRental: "Alquiler de coches",
  CarType: "Tipo de coche",
  interests: "Interés(es)",
  linkedprofiles: "Perfiles vinculados",
  Preferreddestinations: "Destinos preferidos",
  businessPreferences: "Preferencias comerciales",
  otherPreferences: "Otras preferencias",
  pendingBookings: "Reservas pendientes",
  archiveBookings: "Archivar reservas",
  activeBookings: "Reservas activas",
  viewdetails: "Ver detalles",
  update: "actualizar",
  bookingmanagement: "Gestión de reservas",
  bookingDashboard: "Panel de control de reservas",
  bookingoverview: "resumen de reservas",
  Generalbookingdetails: "Detalles generales de la reserva.",
  type: "Tipo",
  created: "Creado",
  segmentoverview: "Visión general del segmento",
  totalnumberofsegment:
    "El número total de productos/segmentos y los segmentos confirmados pendientes.",
  segmentsconfirmedvstotalsegment:
    "Segmentos confirmados frente a segmentos totales incluidos en la reserva.",
  amountremainingforclient:
    "El importe restante para que el cliente pague antes de la fecha de vencimiento del saldo.",
  outstandingAmount: "Importe pendiente",
  outstanding: "Excepcional",
  balanceDue: "Saldo adeudado",
  bookingDetails: "Detalles de la reserva",
  user: "Usuario",
  consultantNotes: "Notas de Consultores",
  historynotfound: "Historia no encontrada",
  leadpaxdetails: "Detalles de Lead Pax",
  additionalpaxdetails: "Detalles adicionales de Pax",
  locationdetails: "Detalles de la ubicación",
  selectedHotels: "Hoteles seleccionados",
  confirmed: "Confirmado",
  snooze: "Snooze",
  upload: "Subir",
  novoucherfound: "No se ha encontrado ningún cupón",
  Nopaymentsfound: "No se han encontrado pagos",
  newvoucher: "Sube un nuevo cupón para esta reserva",
  allocation: "Asignación",
  selectedtoursandtickets: "Tours y Entradas Seleccionados",
  offeredIn: "Ofrecido en",
  tofrom: "A / DESDE",
  bookingRef: "Referencia de reserva",
  selectedTransport: "Transporte seleccionado",
  segmentmessage:
    "Al confirmar este segmento, usted está reconociendo que este producto ha sido reservado con el proveedor, Fuera de esta aplicación. Para completar la confirmación, complete el siguiente campo.",
  bookingReference: "Referencia de reserva",
  suppliersbookingreference:
    "Introduzca la referencia de reserva del proveedor",
  confirmsegment: "Confirmar segmento",
  pickupTime: "Hora de recogida",
  totalDuration: "Duración total",
  layoverTime: "Lay over Time",
  ticketType: "Tipo de entrada",
  class: "Clase",
  provider: "Proveedor",
  viewcustomer: "Ver cliente",
  costofitinerary: "Coste total del itinerario",
  paid: "Pagado",
  receiptofallpayments: "Recibos de todos los pagos hasta la fecha",
  balanceduetocustomer: "Saldo debido a la recogida del cliente",
  recordcustomerpayment: "Registre un pago de cliente a continuación.",
  completePayment: "Pago completo",
  cancel: "Cancelar",
  accommodationORtransportation:
    "* Seleccione al menos un Alojamiento o Transporte.",
  preferredPropertyClass: "Clase de propiedad preferida",
  transportation: "Transporte",
  preferredtransporttype: "Tipo de transporte preferido",
  preferredtransportclass: "Clase de transporte preferido",
  agegroup: "Grupo de edad",
  interest: "Interés",
  activityinterest: "Interés de la actividad",
  applytoalllocations: "Aplicar a todas las ubicaciones",
  applythislocation: "Aplicar esta ubicación",
  apply: "Aplicar",
  hotelDescription: "Descripción del hotel",
  activityDescription: "Descripción de la actividad",
  checkoutcancelationdescription:
    "Es un hecho establecido desde hace mucho tiempo que un lector se distraerá por el contenido legible de una página cuando se mira su diseño. El punto de usar Lorem Ipsum es que tiene una distribución más o menos normal de letras, en lugar de usar 'Contenido aquí, contenido aquí', haciendo que parezca un inglés legible. Muchos paquetes de publicación de escritorio y editores de páginas web ahora utilizan Lorem Ipsum como su texto de modelo predeterminado, y una búsqueda de 'lorem ipsum' descubrirá muchos sitios web todavía en su infancia. Varias versiones han evolucionado a lo largo de los años, a veces por accidente, a veces a propósito (humor inyectado y similares).",
  OverrideQuotation: "Guardar/Reemplazar presupuesto",
  DuplicateQuotation: "Cotización duplicada",
  OriginCity: "Ciudad de Origen",
  ReturnCity: "Ciudad de regreso",
  AddTransport: "Añadir transporte",
  AddHotels: "Añadir hoteles",
  AddActivities: "Añadir actividades",
  ManagePreferences: "Administrar las preferencias del cliente",
  searchPNR: "Buscar PNR",
  oneWay: "One Way",
  multiCity: "Multi City",
};
