import api from "./siteconfig.js";

export default {
  login(data) {
    return api.post("/login", data);
  },
  changePassword(data) {
    return api.post("/change-password", data);
  },
  setMailPasswordRequest(data) {
    return api.post("/mail-password-request", data);
  },
  forgotPasswordRequest(data) {
    return api.post("/forgot-password-request", data);
  },
  logout(token_id) {
    return api.delete(`oauth/personal-access-tokens/${token_id}`);
  },
  getCountries(data){
    return api.post("/countries",data);
  },
  getbusinessInfomation(data){
    return api.post("cob-wizard-detail",data);
  },
  savebusinessInformation(data){
    return api.post("cob-wizard-save",data);
  },
  getproductFieldInformation(data){
    return api.post("product-api-field-property",data);
  },
  getSupportedCurrencies(data) {
    return api.post("/supported-currencies",data);
  },
  deleteProductApiCredential(data){
    return api.post("delete-product-api-credential",data);
  },
  getCOBTemplateList(data){
    return api.post("cob-template-list",data);
  },
  deleteOrganisation(data){
    return api.post("delete-organisation-image",data)
  },
  getdomainApi(data){
    return api.post("is-eroam-domian-exist",data)
  },
  getdomainList(data){
    return api.post("get-domain-list",data)
  },
  loginDetail(data){
    return api.post("login-detail",data)
  },
  getFxcurrencyList(data){
    return api.post("get-fx-currency-exchange-rate",data)
  },
  addFxInfomation(data){
    return api.post("save-fx-currency-exchange-rate",data)
  },
  getDynamicrate(data){
    return api.post("get-currency-exchange-rate",data)
  },
  getInverseFxrate(data){
    return api.post("inverse-currency-exchange-rate",data)
  },
  checkApiKey(data){
    return api.post("check-api-key",data)

  }
  

};