export const Gujarati: any = {
  creatingFor: "તેના માટે માર્ગ નિર્માણ",
  creatingNew: "બનાવવું નવા ગ્રાહકબનાવવું",
  searchTitleItinerary: "ઇટિનરરીઇટિનરરી ઇટિનરરી",
  searchTitleFlights: "ફ્લાઇટ્સ અને ટ્રેન",
  searchTitleHotel: "હોટેલ્સ અને બી.એન.બી.",
  searchTitleTours: "ટૂર્સ અને ટિકિટ",
  searchTitlePackages: "પેકેજ",
  searchTitleRentalcars: "ભાડાની કાર અને મોટરહોમ",
  searchTitleTransfers: "સ્થાનાંતરણ",
  searchTitleCruises: "ક્રૂઝ",
  searchTitleEvents: "ઇવેન્ટ્સ",
  searchTitleInsurance: "વીમા",
  searchInstructions:
    "તમારું પ્રસ્થાન સ્થાન ઉમેરો અને શોધ પસંદ કરો. પુષ્ટિ રૂટ દરમિયાન, આગળના પગલા પર વધારાના સ્થાનો ઉમેરો.",
  searchAddPlaces: "પ્રસ્થાન સ્થાન",
  searcPlaceholder: "સ્થાનો",
  searchError: "* કૃપા કરીને બે સ્થાનો પસંદ કરો",
  searchBlurb:
    "તમારું માર્ગ નિર્દેશિકા બનાવો. તમે ઇચ્છો છો તે દરેક સ્થાનો તમારા લક્ષ્યસ્થાનને ટાઇપ કરીને ઉમેરો અને તેમને ઉમેરવા માટે દાખલ કરો પર ક્લિક કરો.",
  consultantProfile: "સલાહકાર પ્રોફાઇલ",
  consultantSearchCust: "ગ્રાહકો",
  Searchdata: "શોધ",
  consultantSearchBlurb: "ગ્રાહકનું બુકિંગ",
  consultantSearchCustButton: "શોધો શોધ ગ્રાહકો",
  consultantSearchCont: "શોધ સંપર્ક",
  consultantQuick: "ઝડપી પ્રવેશ",
  consultantActive: "બુકિંગ",
  ActiveData: "સક્રિય",
  consultantActiveBlurb: "તમારી સક્રિય બુકિંગની સૂચિ જુઓ",
  consultantActiveButton: "સક્રિય બુકિંગ જુઓ",
  consultantPending: "બુકિંગ",
  PendingData: "બાકી",
  consultantPendingBlurb: "તમારી બાકી બુકિંગની સૂચિ જુઓ",
  consultantPendingButton: "બાકી બુકિંગ જુઓ",
  consultantItineraries: "એક્ટીવ ઇટિનરેરીઝ",
  consultantCustomers: "ગ્રાહકો",
  consultantAccount: "એકાઉન્ટ સારાંશ",
  consultantNotices: "સૂચનાઓ અને સૂચનાઓ",
  consultantTaskList: "ટાસ્ક સૂચિ",
  consultantTaskYours: "તમારી કાર્યો",
  consultantTaskNotFound: "કાર્ય મળી નથી",
  consultantTaskConfirmCompleteMsg:
    "શું તમને ખાતરી છે કે તમે પૂર્ણ કરવા માંગો છો? કાર્યોને સંપૂર્ણ તરીકે ચિહ્નિત કર્યા",
  consultantCustName: "ગ્રાહકનું નામ",
  consultantActiveAgency: "સક્રિય એજન્સી બુકિંગ જુઓ",
  consultantPendingAgency: "બાકી એજન્સી બુકિંગ જુઓ",
  consultantTaskCustName: "ગ્રાહકનું નામ",
  consultantTaskBookingId: "બુકિંગ આઈડી",
  consultantTaskNote: "નોંધો",
  consultantTaskDate: "તારીખ પસંદ કરો",
  consultantTaskAddButton: "કાર્ય ઉમેરો",
  consultantTaskEditButton: "કાર્ય સંપાદિત કરો",
  consultantTaskCompleted: "પૂર્ણ",
  consultantTaskactions: "ક્રિયાઓ",
  consultantTasksnoozed: "સ્નૂઝ્ડ",
  consultantTasktasks: "ક્રિયાઓ",
  consultantTasksNone: "તમને અદ્યતન લાગે છે, સારું થયું",
  consultantMidOffice: "મિડ ઓફિસ",
  consultantMidOfficeUpload: "મિડ Officeફિસ પર ડેટા અપલોડ કરો",
  sidebarHeading: "રૂટની પુષ્ટિ કરો",
  siderbarInstructions:
    "તમામ રૂચિનાં સ્થાનો ઉમેરો અને તેમને ઇચ્છિત ક્રમમાં સેટ કરો. દરેક સ્થાન પર નિરૂપણની તારીખ અને રહેવાની લંબાઈ પસંદ કરો. જો પૂર્વ-પસંદ કરેલી ઇન્વેન્ટરીને પસંદ કરી રહ્યા હોય, તો દરેક સ્થાન માટેના ઇન્વેન્ટરી વિકલ્પો દ્વારા પરિણામોને શુદ્ધ કરો.",
  sidebarOptionDeparture: "પ્રસ્થાન અને મુસાફરો",
  sidebarDepDate: "પ્રસ્થાન તારીખ સ્થાનથી પ્રસ્થાનની તારીખ",
  idebarDepSelect: "પ્રથમપસંદ કરો.",
  sidebarDepError: "ભૂલ, કૃપા કરીને એક પસંદ કરો પ્રસ્થાનની તારીખ.",
  travellersNumber: "મુસાફરોની સંખ્યા",
  siderbarnewInstructions:
    "બધા રસપ્રદ સ્થળો ઉમેરો અને તેમને ઇચ્છિત ક્રમમાં સેટ કરો. દરેક સ્થાન પર નિરૂપણની તારીખ અને રહેવાની લંબાઈ પસંદ કરો. જો પૂર્વ-પસંદ કરેલી ઇન્વેન્ટરીને પસંદ કરી રહ્યા હોય, તો દરેક સ્થાન માટેના ઇન્વેન્ટરી વિકલ્પો દ્વારા પરિણામોને શુદ્ધ કરો.",
  sidebarnewOptionDeparture: "પ્રસ્થાન અને મુસાફરો",
  depDate: "પ્રસ્થાન તારીખ  સ્થાનથી પ્રસ્થાનની તારીખ",
  sidebarnewDepSelect: "પ્રથમપસંદ કરો.",
  sidebarnewDepError: "ભૂલ, કૃપા કરીને એક પસંદ કરો પ્રસ્થાન તારીખ.",
  sidebarTravellersNumber: "મુસાફરોની સંખ્યા",
  Rooms: "ઓરડાઓ",
  adults: "પુખ્ત વયના લોકો",
  children: "બાળકો",
  childAgeLimits: "યુગ (0-17)",
  Infants: "શિશુ",
  infantAgesLimit: "યુગ (1 અને નીચેની)",
  childAges:
    "કેટલી વયની છે 'બાળકો સાથે તમે મુસાફરી કરી રહ્યાં છો' (પ્રસ્થાન સમયે)",
  ageOfChild: "બાળકની ઉંમર",
  infantConfirm: "એક વર્ષની નીચે શિશુ છે (વિદાય સમયે)",
  infantLengthPlural: "શિશુ છે",
  infantLengthSingular: "શિશુ છે",
  infantLengthEnd: "એક વર્ષની નીચે (પ્રસ્થાન સમયે) વય",
  infantLapStart: "શું",
  infantLapPlural: "શિશુ",
  infantLapSingular: "શિશુ",
  infantLapEnd: "પુખ્ત વયના ગોદમાં?",
  infantsLap: "મુસાફરી કરશે શિશુઓ વયસ્કોની ખોળામાં મુસાફરી કરશે?",
  sidebarnewInventoryOps: "ઈન્વેન્ટરી અને સ્થાન વિકલ્પો",
  sidebarViewTours: "જૂથ પ્રવાસો જુઓ",
  sidebarnewRouteOptions:
    "કૃપા કરીને આ માર્ગની અંદર તમે કયા વિકલ્પોને શામેલ કરવા માંગો છો તે પસંદ કરો.",
  siderbarnewAutoPopLoc: "ટ્રેન્ડિંગ સ્થાનો સાથે સ્વત pop-પulateપ્યુલેટ કરો.",
  siderbarnewAutoPopInv:
    "ક્લાયંટ પસંદગીઓનો ઉપયોગ કરીને સ્વત pop પ Autoપ્યુલેટ ઇન્વેન્ટરી.",
  arrivalDate: "આગમન તારીખ",
  confirmRouteButton: "રૂટની પુષ્ટિ કરો",
  redirectInventory: "ઇટિનરરી ઇન્વેન્ટરી",
  sidebarInventoryAlert:
    "આઇમાં ટ્રીપ ઇન્વેન્ટરી ઉમેરો અને દૂર કરો જમણી બાજુએ ટેનીરી. </p> મહેરબાની કરીને નોંધ કરો: મલ્ટિ-ડે ટૂર ઉમેરવાથી પાછલા સ્થાનોની તારીખો લ placeક થઈ જશે.",
  redirectActivities: "પ્રવૃત્તિઓ પસંદ કરો",
  sidebarActivitiesAlert:
    "ડાબા ભાગને પસંદ કરીને ઇટિનરરીમાં ઇન્વેન્ટરી મેનેજ કરો. સ્થાનોને ઉમેરવા, દૂર કરવા અથવા ફરીથી ઓર્ડર કરવા માટે, નકશો / રૂટ જુઓ બટનનેકરો.",
  redirectHotelList: "પસંદ હોટેલ પસંદ કરો",
  sidebarHotelListAlert:
    "સૂચિમાંથી નવી હોટેલ પસંદ કરો. </p> તમે હાલની પસંદ કરેલી હોટેલ સુવિધાઓ ટોચ પર છો.",
  redirectHotel: "તમારો ઓરડો પસંદ કરો",
  sidebarHotelAlert:
    "આ હોટેલને પ્રવાસના માર્ગમાં ઉમેરવા માટે તમારા ઓરડાને પસંદ કરો",
  redirectToursList: "ટૂર પસંદ કરો",
  sidebarToursListAlert:
    "સૂચિમાંથી ટૂર પસંદ કરો. < /p> વધુ વિગતવાર માહિતી, ભાવો અને પ્રસ્થાનોને જોવા માટે પ્રવાસ પર ક્લિક કરો પ્રસ્થાન",
  redirectTour: "પસંદ કરો",
  sidebarTourAlert:
    "તેમની કિંમત સાથે પ્રસ્થાનની તારીખની સૂચિ શોધવા માટે નીચે સ્ક્રોલ કરો. </ p> ક્યાં તો આ ટૂરને આમાં ઉમેરો તમારા માર્ગદર્શન અથવા પ્રવાસની સીધી બુક",
  redirectTransport: "કરો પરિવહન પસંદ કરો",
  sidebarTransportAlert: "",
  checkoutAlert: "બુકિંગ પૂર્ણ કરવા માટે પેસેન્જર વિગતો દાખલ કરો",
  checkout: "ચેકઆઉટ",
  redirectPreview: "માર્ગદર્શન પૂર્વાવલોકન",
  redirectPreviewPDF: "પૂર્વદર્શન પીડીએફ",
  sidebarnewInventoryOptions: "ઈન્વેન્ટરી વિકલ્પો",
  errorMsg: "ભૂલ અહીં પ્રદર્શિત કરો",
  remove: "દૂર કરો",
  sidebarnewInclusiveTourButton: "જૂથ સમાવિષ્ટ ટૂર",
  sidebanewNotes: "આ સ્થાનને રૂટમાં ઉમેરો",
  sidebarnewReturnTripOption: "રીટર્ન ટ્રીપ વિકલ્પ",
  sidebarnewRoundTripForm:
    "કૃપા કરીને એક મૂળ અને વળતર પસંદ કરો. જો તમે વળતર ફ્લાઇટ્સ શામેલ કરવા માંગો છો.",
  to: "ટૂ",
  night: "નાઇટ્સ",
  tourDuration: "અવધિ",
  contact: "સંપર્ક",
  checkinDate: "ચેકઇન ડેટ",
  checkoutDate: "ચેકઆઉટ ડેટ",
  supplierReference: "સપ્લાયર",
  propertyReference: "બુકિંગ સંદર્ભ",
  transport: "ટ્રાન્સપોર્ટ",
  service: "સેવા",
  inventoryTravelClass: "યાત્રા વર્ગ",
  departureLocation: "પ્રસ્થાન સ્થાન",
  arrivalAirport: "આગમન સ્થાન",
  status: "સ્થિતિ",
  departuretime: "પ્રસ્થાન સમય",
  hotels: "હોટેલ્સ",
  hotelPricePerNight: "ભાવ દીઠ રાત",
  hotelPropertyRating: "સંપત્તિ રેટિંગ",
  searchbyPropertyName: "સંપત્તિના નામ દ્વારા શોધ",
  search: "શોધ કરો",
  refundtype: "રિફંડ પ્રકાર",
  days: "દિવસો",
  location: "સ્થાન",
  pernight: "રાત્રિ દીઠ",
  pendingItineraries: "બાકી ઇટિનરેરીઝ",
  hotelownArrangementPropertyName: "સંપત્તિનું નામ *",
  hotelownArrangementPropertyNotes: "સંપત્તિ નોંધો",
  validationMsg: "આ ક્ષેત્ર આવશ્યક છે.",
  emailValidationMsg: "ઇમેઇલ સરનામું ફીલ્ડ માન્ય ઇમેઇલ હોવું આવશ્યક છે.",
  contactMinValidationMsg:
    "સંપર્ક નંબર ક્ષેત્ર ઓછામાં ઓછું 10 અક્ષરોનું હોવું જોઈએ.",
  contactMaxValidationMsg: "સંપર્ક નંબર ક્ષેત્ર 11 અક્ષરોનું હોવું આવશ્યક છે.",
  ownArrangementImage: "છબી",
  ownArrangementHotelimage: "હોટેલ છબી",
  ownArrangementImgClick:
    "હોટેલની છબીને ક્લિક કરો અથવા ખેંચો. અપલોડ કરવા માટે અહીં",
  ownArrangementFileTypeMsg: "(સપોર્ટેડ ફાઇલ પ્રકાર .jpeg, .jpg, .png)",
  hotelownArrangementPropertyaddress: "સંપત્તિ સરનામું *",
  hotelownArrangementPropertyContact: "સંપત્તિ સંપર્ક ફોન નંબર *",
  hotelownArrangementPropertyemail: "સંપત્તિ ઇમેઇલ *",
  hotelownArrangementDurationDetails: "અવધિ વિગતો",
  hotelownArrangementPropertyCheckindate: "ચેક-ઇન તારીખ *",
  hotelownArrangementPropertyCheckintime: "ચેક-ઇન ટાઇમ *",
  hotelownArrangementPropertyCheckouttime: "સંપત્તિ સરનામું",
  hotelownArrangementPropertyCheckoutdate: "ચેક-આઉટ તારીખ *",
  ownArrangementPricingDetails: "પ્રાઇસીંગ વિગતો",
  ownArrangementCurrency: "કરન્સી",
  hotelownArrangementCurrency: "ખરીદી કરન્સી *",
  hotelownArrangementPropertyPrice: "વેચવાના ભાવ *",
  hotelownArrangementPropertybookingref: "બુકિંગ સંદર્ભ *",
  ownArrangementPurchasingdetails: "ખરીદીની વિગતો",
  ownArrangementPurchasePrice: "ખરીદી કિંમત *",
  hotelownArrangementPropertySupplier: "સપ્લાયર *",
  hotelownArrangementPropertyInvoice:
    "અંતિમ ઇન્વoiceઇસમાં આ પોતાની એરેન્જમેન્ટ એકોમોડેશન પ્રાઇસિંગ શામેલ કરો.",
  hotelownArrangementPropertyRefund:
    "આ પોતાની એરેન્જમેન્ટ એકોમોડેશન પ્રોડક્ટ રિફંડ છે.",
  cancelButton: "રદ કરો",
  addtoitinerary: "ઇટિનરીમાં ઉમેરો",
  ownArrangementBasicDetails: "મૂળભૂત હોટેલ વિગતો",
  activityownArrangementActivityname: "પ્રવૃત્તિ નામ *",
  activityownArrangementAddress: "સરનામું",
  activityownArrangementPhnno: "ફોન નંબર *",
  activityownArrangementEmail: "ઇમેઇલ",
  activityownArrangementWeburl: "વેબ યુઆરએલ",
  activityownArrangementImage: "છબી",
  ownArrangementActivityImgClick:
    "ક્લિક કરો અથવા ખેંચો પ્રવૃત્તિ છબી અહીં અપલોડ કરવા માટે",
  activityownArrangementActivityImage: "પ્રવૃત્તિ છબી",
  activityownArrangementSellingPrice: "વેચાણ કિંમત *",
  activityownArrangementSupplierReference: "સપ્લાયર",
  activityownArrangementPurchaseCurrency: "ખરીદી ચલણ *",
  activityownArrangementPropertyReference: "બુકિંગ સંદર્ભ *",
  ownArrangementComments: "ટિપ્પણીઓ",
  activityownArrangementComments: "ટિપ્પણીઓ",
  operator: "ratorપરેટર",
  ownArrangementTravelClass: "વર્ગ",
  ownArrngementArrivalTime: "આગમન સમય",
  ownArrangementAirlineReference: "એરલાઇન સંદર્ભ",
  ownArrangementGdsReference: "જીડીએસ સંદર્ભ",
  downloadPDF: "પીડીએફ ડાઉનલોડ કરો",
  expandAll: "બધા વિસ્તૃત કરો",
  collapseAll: "બધાને સંકુચિત કરો",
  showMore: "વધુ બતાવો",
  showLess: "ઓછું બતાવો",
  accommodation: "નિવાસ",
  name: "નામ",
  checkIn: "ચેક ઇન",
  checkOut: "ચેક આઉટ",
  checkOutDash: "ચેક-આઉટ",
  price: "ભાવ",
  PerNight: "દીઠ નાઇટ",
  roomDetails: "ઓરડાઓની વિગતો",
  roomType: "ઓરડાના પ્રકાર",
  pendingBookingNights: "નાઇટ્સ",
  nonRefundable: "નોન-રિફંડબલ",
  refundable: "રિફંડબલ",
  ownArrangement: "પોતાની ગોઠવણ",
  toursActivities: "પ્રવાસો / પ્રવૃત્તિઓ",
  viewitenaryActivityname: "પ્રવૃત્તિ નામ",
  viewitenaryAddress: "સરનામું",
  viewitenaryPhone: "ફોન",
  viewitenarySellingPice: "વેચાણ કિંમત",
  email: "ઇમેઇલ",
  date: "તારીખ",
  duration: "અવધિ",
  code: "કોડ",
  startDate: "પ્રારંભ તારીખ",
  finishDate: "સમાપ્ત તારીખ",
  endDate: "સમાપ્તિ તારીખ",
  aud: "એયુડી",
  view: "જુઓ",
  noDataFound: "નો રેકોર્ડ મળ્યો નથી",
  notProvided: "પ્રદાન નથી",
  pendingBookingPage: "એક્સ",
  pendingBookingCustomerName: "ગ્રાહકનું નામ",
  pendingBookingDepdate: "ડેપ. તારીખ",
  pendingBookingCreatedOn: "બનાવટ પર",
  travellers: "મુસાફરો",
  pendingBookingTotalPrice: "કુલ ભાવ",
  ownArrangementContactDetails: "સંપર્ક વિગતો",
  sortby: "આના દ્વારા સકરો:",
  titleOfActivity: "શીર્ષક",
  activityCode: "પ્રવૃત્તિ કોડ:",
  viator: "ortર્ટ વાયેટર",
  from: "પ્રતિ પ્રતિ",
  perPerson: "વ્યક્તિ",
  category: "કેટેગરી",
  sidebarActivityName: "પ્રવૃત્તિ દ્વારા નામ દ્વારા શોધ",
  readmore: "વધુ વાંચો",
  hotelPickupIncluded: "હોટેલ પિકઅપ સમાયેલ:",
  activityDetaildeparturePoint: "પ્રસ્થાન બિંદુ:",
  overview: "ઝાંખી",
  importantInfo: "મહત્વપૂર્ણ માહિતી",
  details: "વિગતો",
  activityDetailInclusions: "સમાવેશ",
  activityDetailExclusions: "બાકાત",
  activityDetailAdditionalinfo: "અતિરિક્ત માહિતી",
  activityDetailVoucherinfo: "વાઉચર માહિતી",
  activityDetailLocalOperatorInformation: "સ્થાનિક ઓપરેટર માહિતી",
  activityDetailLocalOperatorInformationlope:
    "તમારા ગંતવ્ય પરના સ્થાનિક ટેલિફોન નંબરો સહિત સંપૂર્ણ Opeપરેટર માહિતી, તમારા પુષ્ટિ વાઉચર પર શામેલ છે. અમારા પ્રોડક્ટ મેનેજરો દરેક લક્ષ્યસ્થાનના ફક્ત સૌથી અનુભવી અને વિશ્વસનીય operaપરેટર્સને પસંદ કરે છે, તમારા માટેનું અનુમાન દૂર કરે છે અને તમારી માનસિક શાંતિને સુનિશ્ચિત કરે છે.",
  activityDetailCancellationPolicy: "રદ કરવાની નીતિ",
  activityDetailScheduleAndPricing: "સમયપત્રક અને પ્રાઇસીંગ",
  activityDetailScheduleAndPricingContent:
    "તમને ખાતરી કરવા માટે અમારી કિંમતો સતત અપડેટ કરવામાં આવે છે. હંમેશા શક્ય સૌથી નીચો ભાવ પ્રાપ્ત થાય છે - અમે તેની 100% બાંયધરી આપીએ છીએ.",
  activityDetailBookActivity: "બુક પ્રવૃત્તિ",
  activityDetailDeparts: "પ્રસ્થાન",
  activityDetailPickup: "ચૂંટો:",
  activityDetailCheckedPickup: "મને પસંદ કરવાની જરૂર છે",
  activityDetailaddActivity: "પ્રવૃત્તિ ઉમેરો",
  activityDetailSpecialRequirements: "વિશેષ આવશ્યકતાઓ",
  activityDetailSelectLocation: "સ્થાન પસંદ કરો",
  activityDetailEnterPickupLocation: "પીકઅપ લોકેશન દાખલ કરો",
  noRecordFound: "કોઈ રેકોર્ડ મળ્યું નથી",
  sidebarTransportCustomerPricing: "ગ્રાહક પ્રાઇસીંગ",
  sidebarTransportCustomerPricingContent:
    "ગ્રાહકની કિંમત અને પેક્સ દ્વારા ઇટિનરરી પ્રાઇસીંગ બ્રેકડાઉન.",
  sidebarTransportHeading: "એયુડી 4562",
  sidebarTransportContent: "કુલ કિંમત એડીડી ઇન્ક. કર",
  sidebarTransportPricePerPaxAudincTax: "ભાવ દીઠ પેક્સ એયુડી ઇંક. ટેક્સ",
  sidebarTransportCostPerDay: "ખર્ચ દીઠ દિવસ:",
  sidebarTransportCost: "458.00",
  sidebarTransportTotalCost: "કુલ કિંમત:",
  sidebarTransportTotal: "એયુડી 785",
  sidebarTransportAgencyCost: "એજન્સી કિંમત",
  sidebarTransportOne: "એક",
  sidebarTransportTwo: "બે",
  sidebarTransportThree: "ત્રણ",
  sidebarTransportFilters: "પરિવહન ફિલ્ટર્સ",
  sidebarTransportType: "પરિવહન પ્રકાર",
  return: "વળતર",
  transportOnlyRates:
    "ગ્રાહકો માટે છૂટક દરો પ્રદર્શિત કરવા માટે એજન્ટ વ્યૂ બંધ કરો.",
  transportOnlySaveQuotation: "સેવ ક્વોટેશન",
  transportOnlyBookItinerary: "બુક ઇટિનરરી",
  transportOnlyCustomerPricing: "ગ્રાહક પ્રાઇસીંગ",
  transportOnlyAgencyCost: "એજન્સી કોસ્ટ",
  transportOnlyFilters: "ફિલ્ટર્સ",
  transportOnlyDepartureLocation: "પ્રસ્થાન સ્થાન",
  transportOnlyDestinationLocation: "ડેસ્ટિનેશન લોકેશન",
  transportOnlyReturnDate: "રીટર્ન ડેટ",
  transportOnlyParistoRome: "પોરિસથી રોમ",
  transportOnlyFilterContent: "સોમવાર, 1 લી એપ્રિલ 2019",
  change: "ચેન્જ",
  transportOnlyvueling: "વ્યુઅલિંગ",
  nonStop: "નોનસ્ટોપ",
  departs: "ડિપાર્ટ",
  transportOnlyParisOrlyAirport: "પેરિસ ઓર્લી એરપોર્ટ (ઓઆરવાય)",
  transportOnlyArrive: "આગમન",
  transportOnlyFco: "રોમ - રોમા તિબુર્ટીના રેલ્વે સ્ટેશન (FCO)",
  transportOnlyKiwi: "કિવિ ડોટ કોમ",
  transportOnlyMystifly: "રહસ્યમય",
  transportOnlySabre: "સાબર",
  transportOnlyEconomy: "અર્થવ્યવસ્થા",
  transportOnlySelect: "પસંદ કરો",
  transportOnlyRouteDetails: "રૂટ વિગતો",
  transportOnlyCalendar: "સોમવાર 1 એપ્રિલ, 2019",
  transportOnlyFlight: "ફ્લાઇટ",
  transportOnlyShowDetails: "વિગતો બતાવો",
  transportOnlyParis: "પેરિસ - ગેરે દે લિયોન",
  transportOnlyMilan: "મિલન - સેન્ટ્રલ સ્ટેશન",
  transportOnlyBaggageDetails: "સામાનની વિગતો",
  transportOnlyPersonalBaggage: "પર્સનલ બેગેજ",
  transportOnlyFree: "ફ્રી",
  transportOnlyCheckedBaggage: "ચેકડેડ બેગેજ",
  transportOnlyAddCheckedBaggage: "ચેકડેડ બેગેજ ઉમેરો",
  transportOnlyAddCabinBaggage: "કેબીન બેગેજ",
  transportOnlyInFlight: "ઇન-ફ્લાઇટ -ડ-sન્સ",
  transportOnlyPassengerPricingDetails: "પેસેન્જર અને ભાવોની વિગતો",
  transportOnlyPassenger: "પેસેન્જર",
  transportOnlyonexAdult: "1 એક્સ એડલ્ટ",
  transportOnlyBaseFare: "બેઝ ફેર",
  transportOnlyBaggage: "બેગેજ",
  transportOnlyFeesAndTaxes: "અન્ય ફીઝ / ટેક્સ",
  transportOnlyTermsConditions: "શરતો અને શરતો",
  transportOnlyNetRate: "નેટ રેટ",
  transportOnlyCommision: "કમિશન",
  checkinTime: "ચેકઇન ટાઇમ",
  checkoutTime: "ચેકઆઉટ સમય",
  searchCustomerName: "ગ્રાહકના નામ દ્વારા શોધ",
  confirmroute: "પુષ્ટિ રૂટ",
  logout: "લ Logગઆઉટ",
  searchbypropertyname: "સંપત્તિના નામ દ્વારા શોધ કરો",
  hotelDetailshotelRoom: "(1 ઓરડો)",
  hotelDetailRoomsRates: "રૂમ અને દરો",
  hotelDetailAmenities: "હોટેલ સુવિધાઓ",
  hotelDetailCheckInInstructions: "ચેક ઇન સૂચનાઓ",
  hotelDetailsFeesOptionalExtras: "ફી / વૈકલ્પિક એક્સ્ટ્રાઝ",
  hotelDetailValueAddedServices: "મૂલ્ય વર્ધિત સેવાઓ",
  hotelDetailCheckin: "ચેક ઇન",
  hotelDetailhotelCheckout: "તપાસો:",
  hotelDetailBedPreferencese: "પલંગની પ્રાધાન્યતા:",
  hotelDetailViewCancellationPolicy: "રદ કરવાની નીતિ જુઓ",
  hotelDetailRoomDetailAmenities: "ઓરડાની વિગતવાર સુવિધાઓ",
  hotelDetailRoomAmenities: "ઓરડાની સુવિધાઓ",
  hotelDetailGuests: "અતિથિઓ:",
  hotelDetailSubtotal: "પેટાસરવાળો:",
  hotelTotal: "કુલ",
  hotelDetailTaxRecoveryServiceFee: "ટેક્સ પુનઃપ્રાપ્તિ અને સેવા ફી:",
  hotelDetailSpecialCheckInInstructions: "સૂચનો ખાસ તપાસ",
  hotelDetailadditionalInformation: "વધારાની માહિતી",
  headerLogin: "લૉગિન",
  headerLogout: "લૉગઆઉટ",
  hide: "છુપાવો",
  depart: "થી રવાના",
  arrive: "આગમન",
  flightNumber: "ફ્લાઇટ નંબર",
  routeDetails: "રૂટની વિગતો",
  wifi: "વાઇફાઇ",
  included: "શામેલ",
  airCondition: "એર કંડિશન",
  passengerPricingDetails: "પેસેન્જર અને ભાવોની વિગતો",
  supplier: "સપ્લાયર",
  passenger: "પેસેન્જર",
  passengers: "પેસેન્જર",
  flightMystifly: "મિસ્ટીફાયલી",
  flight2Adult: "2 x એડલ્ટ",
  baggage: "બેગેજ",
  otherFeesTaxes: "અન્ય ફીઝ / ટેક્સ",
  free: "ફ્રી",
  netRate: "નેટ રેટ",
  select: "સિલેક્ટ",
  floatZero: "0.00",
  doubleZerofloat: "00 .00",
  tv: "ટીવી",
  flightEconomy: "ઇકોનોમી",
  viewMore: "વધુ જુઓ",
  flightOnBoardAddOns: "બોર્ડ Addડ-sન્સ",
  loginSignIn: "સાઇન ઇન",
  customerNameOrEmail: "ગ્રાહકનું નામ અથવા ઇમેઇલ",
  searchBy: "દ્વારા શોધો",
  firstName: "પ્રથમ નામ",
  lastName: "છેલ્લું નામ",
  gender: "લિંગ",
  phone: "ફોન",
  submitButton: "સબમિટ કરો",
  your: "તમારું",
  createdOn: "બનાવટ ચાલુ",
  nights: "નાઇટ્સ",
  totalPrice: "કુલ ભાવ",
  emailUserName: "ઇમેઇલ અથવા વપરાશકર્તા નામ",
  userName: "વપરાશકર્તા નામ",
  password: "પાસવ ઓર્ડર",
  enterPassword: "પાસવર્ડ દાખલ કરો",
  enterCustomerName: "ગ્રાહકનું નામ દાખલ કરો",
  taskEnterBookingId: "બુકિંગ આઈડ દાખલ કરો",
  commission: "કમિશન",
  costPerDay: "ખર્ચ દીઠ દિવસ",
  pricePerPAX: "ભાવ દીઠ પેક્સ",
  totalCost: "કુલ કિંમત",
  netCost: "નેટ કોસ્ટ",
  includingTax: "ઇંક. ટેક્સ",
  priceSummary: "ભાવનો સારાંશ",
  ConsultantSidebarItineraryCustomerCostByPAXMsg:
    "ગ્રાહકના ખર્ચનો કુલ અને પીએક્સ દ્વારા ઇટિનરી પ્રાઇસીંગ બ્રેકડાઉન.",
  sidebarHeadingPreviewItinerary: "પૂર્વાવલોકન ઇટિનરરી",
  sidebarHeadingSaveQuotation: "સેવ ક્વોટેશન",
  sidebarHeadingBookItinerary: "બુક ઇટિનરરી",
  sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg:
    "એજન્ટ વ્યૂ ચાલુ કરો ગ્રાહક.",
  agentView: "એજન્ટ વ્યૂ",
  customerView: "ગ્રાહક વ્યૂ",
  save: "સેવ",
  travelInsurance: "મુસાફરી વીમા",
  no: "ના",
  addTravel: "યાત્રા ઉમેરો",
  plus: "પ્લસ",
  basic: "મૂળભૂત",
  inventoryMedicalExpenseTripCancellation: "તબીબી ખર્ચ, સફર રદ",
  inventoryAssistanceServices: "સહાયતા  સેવાઓ",
  inventoryLostBaggageMore: "લોસ્ટ બેગેજ + વધુ",
  audWithFloating: "એયુડી 00.00",
  inventoryComparsionAndTerms: "તુલના અને શરતો",
  customerCare: "ગ્રાહક સંભાળ",
  premium: "પ્રીમિયમ",
  inventoryExploreBenefitsOfPayingPremiumMsg: "પ્રીમિયમ ચૂકવવાના ફાયદાઓ શોધો",
  previous: "પાછલું",
  continue: "ચાલુ રાખો",
  saveAndContinue: "સાચવો & ચાલુ રાખો",
  BacktoInventory: "ઇન્વેન્ટરી પર પાછા જાઓ",
  AcceptandContinue: "સ્વીકારો અને ચાલુ રાખો",
  busBud: "બસબડ કા Deleteી-ડ-sન્સમાં",
  edit: "સંપાદિત કરો",
  delete: "નાંખો",
  checked: "ચકાસાયેલ",
  checkoutBusListINBusAddonMsg: "બસ",
  BaseFarePerPax: "બેઝ ભાડું (પેક્સ પેક્સ)",
  onexAdult: "1 x એડલ્ટ",
  oneRoom: "ઓરડો 1",
  CancellationPolicy: "રદ કરવાની નીતિ",
  expedia: "એક્સ્પેડિયા",
  roomSleep: "ઓરડામાં સ્લીપ",
  checkOutActivityListviator: "વાયેટર",
  checkOutActivityDetails: "પ્રવૃત્તિની વિગતો",
  option: "વિકલ્પ",
  groupInclusiveTourOptions: "જૂથ સમાવિષ્ટ ટૂર વિકલ્પો",
  matchCurrentRouteMsg:
    "હાલમાં 219 જૂથ છે તમારા વર્તમાન રૂટ સાથે મેળ ખાતી સમાવિષ્ટ ટૂર.",
  errorDisplayHere: "અહીં એરર ડિસ્પ્લે",
  tourFilters: "ટૂર ફિલ્ટર્સ",
  tailorMadeTours: "ટેલર-મેઇડ ટૂર્સ",
  tailorMadeOptionsMsg:
    "કૃપા કરીને તમે કયા વિકલ્પો પસંદ કરો છો ફક્ત દરજી દ્વારા બનાવેલા વિકલ્પો પ્રદર્શિત કરવા માટે ઇટિનરેરીઝ માટે ફક્ત",
  tailorMadeToursItineryMsg: "ટેલર-મેઇડ ટૂર્સ દર્શાવો",
  MonthOfDeparture: "પ્રસ્થાનનો મહિનો",
  selectDate: "પસંદ તારીખ",
  destination: "લક્ષ્યસ્થાન",
  tourExperience: "ટૂર એક્સપિરિયન્સ",
  preferredSupplier: "પ્રિફર્ડ સપ્લાયર",
  style: "સ્ટાઇલ",
  size: "કદ",
  start: "પ્રારંભ",
  notAvailbale: "એન / એ",
  inclusions: "સમાવિષ્ટો",
  day: "દિવસ",
  meals: "ભોજન",
  breakfast: "નાસ્તો",
  lunch: "લંચ",
  dinner: "ડિનર",
  tourDetailsNoMealMsg: "આ દિવસે કોઈ ભોજન શામેલ નથી.",
  locationsToVisit: "મુલાકાત",
  additionalInformation: "વધારાની માહિતી",
  departureNotFound: "માટેનાં સ્થળો પ્રસ્થાનો મળ્યાં નથી",
  additionalInformationNotFound: "વધુ માહિતી ઉપલબ્ધ નથી",
  departureAndPricing: "પ્રસ્થાન અને પ્રાઇસીંગ",
  departing: "પ્રસ્થાન",
  finishing: "સમાપ્ત",
  availability: "ઉપલબ્ધતા",
  totalFrom: "કુલમાંથી",
  perAdult: "દીઠ પુખ્ત વયના",
  perChild: "પ્રતિ બાળ",
  departureGuaranteed: "પ્રસ્થાનની બાંયધરી",
  onSale: "વેચાણ પર",
  onRequest: "વિનંતી પર",
  addToRoute: "રૂટમાં ઉમેરો",
  termsConditions: "શરતો અને શરતો",
  available: "ઉપલબ્ધ",
  finish: "સમાપ્ત",
  stars: "સ્ટાર્સ",
  reviews: "સમીક્ષાઓ",
  rating: "રેટિંગ",
  default: "ડિફaultલ્ટ",
  title: "શીર્ષક",
  lengthOfTour: "ટૂરની લંબાઈ",
  optionsSelected: "વિકલ્પો પસંદ કરેલા",
  tourNotFound: "ટૂર મળ્યું નહીં",
  validTo: "",
  lodging: "માન્યથી લોજિંગ",
  validFrom: "વેલિડ ફ્રોમ",
  perNight: "પેર નાઇટ",
  sortBy: "",
  viewItineraryGITtours: "જીઆઇટી ટૂર્સ",
  inventoryAddNewAccommodation: "બીજી હોટેલ પસંદ કરો",
  inventoryAddNewTransport: "ન્યુ ટ્રાન્સપોર્ટ ઉમેરો",
  passengersAvoidBoardingComplicationMsg:
    "બોર્ડિંગની કોઈપણ મુશ્કેલીઓ ટાળવા માટે પાસપોર્ટ / આઈડીમાં દેખાય તે પ્રમાણે બધા નામ (આપેલ અને અટક) વાપરો.",
  passengersRequiredFlightSegmentMsg:
    "જરૂરી આંતરરાષ્ટ્રીય ફ્લાઇટ સેગમેન્ટ માટે.",
  dateOfBirth: "જન્મ તારીખ",
  passengersTourSpecificField: "ટૂર સ્પેસિફિક ફીલ્ડ.",
  passengersTourSegmentMsg: "ટૂર `X` સેગમેન્ટ માટે જરૂરી",
  visaRequriements: "વિઝા રેક્યુરિયમેન્ટ્સ",
  passengersreferencePurposeMsg:
    "વિઝા અથવા એરપોર્ટ ટ્રાંઝિટ વિઝા મુસાફરી માટે જરૂરી હોઈ શકે છે ત્યારે પણ નહીં. લેઓવર દેશમાં બંધ. આ આવશ્યકતાઓ ફક્ત સંદર્ભ હેતુ માટે છે. વધુ માહિતી માટેઅથવા વિદેશ મંત્રાલયનો સંપર્ક કરો.",
  passengersDeniedDestinationIssueMsg:
    "દૂતાવાસી ઇ- રોમ કોઈપણ મુસાફરો માટે વિઝા મુદ્દાઓને લીધે ચingવા અથવા પ્રવેશવાની ના પાડી શકે તે માટે જવાબદાર નથી.",
  givenNames: "આપેલા નામો",
  passengerNames: "આપેલા નામો *",
  surname: "અટક",
  passengersurname: "અટક *",
  familyname: "કુટુંબનું નામ",
  PassengerGender: "લિંગ",
  paymenttotalprice: "કુલ ભાવ",
  paymentnewprice: "નવી કિંમત",
  emailAddress: "ઇમેઇલ સરનામું",
  passengeremailAddress: "ઇમેઇલ સરનામું",
  contactNumber: "સંપર્ક નંબર",
  passengercontactNumber: "સંપર્ક નંબર",
  passengersIndividualInventorySpecificationMsg:
    "નીચેના ક્ષેત્રો આવશ્યક છે વ્યક્તિગત ઈન્વેન્ટરી સ્પષ્ટીકરણો, જેમ કે આંતરિક ફ્લાઇટ્સ, ટૂર આવશ્યકતાઓ વગેરેને કારણે.",
  Nationality: "રાષ્ટ્રીયતા",
  PassengerNationality: "રાષ્ટ્રીયતા *",
  confirmBooking: "બુકિંગની પુષ્ટિ કરો",
  receiptNumber: "રસીદ નંબર",
  paymentAddReceiptNumberMsg: "પ્રક્રિયા કરેલી ચુકવણીની રસીદ નંબર ઉમેરો.",
  PaymentDeniedDestinationIssueMsg:
    "એ વિઝા અથવા એરપોર્ટ ટ્રાંઝિટ વિઝા મુસાફરી માટે જરૂરી હોઈ શકે છે. અહીં મળેલ રિક્યુરીમેન્ટ્સ ફક્ત રીફ્રેન્સ હેતુ માટે છે. વધુ માહિતી માટે દૂતાવાસ અથવા તમારા વિદેશ મંત્રાલયનો સંપર્ક કરો. ઇ-રોમ કોઈપણ મુસાફરો માટે જવાબદાર નથી કે જેને વિઝા મુદ્દાને કારણે બોર્ડિંગમાં જવા અથવા કોઈપણ ગંતવ્યમાં પ્રવેશવાનો ઇનકાર કરવામાં આવે છે.",
  amount: "રકમ *",
  paymentEnterAmountMsg: "જે રકમ થઈ રહી છે તે.",
  paymentEnterTypeMsg: "દાખલ કરો ચુકવણીનો પ્રકાર દાખલ કરો",
  paymentType: "ચુકવણી પ્રકાર *",
  paymentOnceSubmittedMsg:
    "એકવાર ચુકવણીની વિગતો સબમિટ થઈ ગયા પછી, બુકિંગની પુષ્ટિ કરવામાં આવશે.",
  payment: "ચુકવણી",
  printPDF: "પ્રિન્ટ પીડીએફ",
  custom: "કસ્ટમ",
  additionalNotes: "વધારાની નોંધો",
  list: "સૂચિ",
  checkinDash: "ચેક-ઇન",
  detailedSummary: "વિગતવાર સારાંશ",
  dailySummary: "દૈનિક સારાંશ",
  tripSummary: "ટ્રિપ સારાંશ",
  transportName: "પરિવહનનું નામ",
  activities: "પ્રવૃત્તિઓ",
  basicTransportDetails: "મૂળભૂત પરિવહન વિગતો",
  transportOwnArrangementRefundMsg:
    "આ પોતાની ગોઠવણ પરિવહન પ્રોડક્ટ રિફંડપાત્ર છે.",
  transportOwnArrangementPricingMsg:
    "અંતિમ ઇન્વoiceઇસમાં આ પોતાની ગોઠવણ પરિવહન કિંમત શામેલ કરો. .",
  salePrice: "વેચાણ કિંમત",
  transportOwnArrangementAddLegtoArrangement: "ગોઠવણીમાં પગ ઉમેરો",
  arrivalLocation: "આગમન સ્થાન",
  transportOwnArrangementAddNewLeg: "નવો પગ ઉમેરો",
  arrival: "આગમન",
  departure: "પ્રસ્થાન",
  fromTo: "થી - થી",
  selectThePlaceToReturn: "પરત ફરવા માટેનું સ્થળ પસંદ કરો",
  selectAReturn: "ફરો",
  returnToAnotherCity: "વળતર બીજા શહેરમાં પાછા",
  selectPlaceOrigin: "પસંદ કરો મૂળ સ્થાન પસંદ કરો.",
  selectOrigin: "એક મૂળ પસંદ કરો.",
  accomodationSummary: "આવાસનો સારાંશ",
  transportSummary: "પરિવહન સારાંશ",
  activitySummary: "પ્રવૃત્તિ સારાંશ",
  taskListConformDeleteMsg:
    "કૃપા કરીને પુષ્ટિ કરો કે તમને જોઈએ છે ટાસ્ક કા deleteી નાખવા માટે?",
  ConfirmActionTitle: "ક્રિયાની પુષ્ટિ કરો",
  passengertitle: "શીર્ષક *",
  hotelListTotalCost: "કુલ કિંમત",
  nonstop: "નોન સ્ટોપ",
  selected: "પસંદ કરેલી",
  inventoryAddNewHotels: "નવી",
  inventoryAddOwnArrangement: "હોટલો ઉમેરો પોતાની વ્યવસ્થા ઉમેરો",
  propertylocation: "સંપત્તિ સ્થાન",
  inventoryAddNewToursTickets: "બીજી ટૂર અને ટિકિટ પસંદ કરો",
  inventoryContactPhone: "સંપર્ક ફોન",
  inventoryContactEmail: "સંપર્ક ઇમેઇલ",
  flight: "ફ્લાઇટ",
  time: "સમય",
  bookingId: "બુકિંગ આઈડી",
  bookingNote: "બુકિંગ નોટ",
  stops: "સ્ટોપ્સ",
  layOverDuration: "લેઓવર અવધિ",
  itineraryPdfImageText:
    "અપલોડ કરવા માટે અહીં પ્રવાસની છબીને ક્લિક કરો અથવા ખેંચો",
  confirm: "પુષ્ટિ કરો",
  changePassword: "પાસવર્ડ બદલો",
  oldPasswordErrorMsg: "જૂનો પાસવર્ડ દાખલ કરો",
  newPasswordErrorMsg: "નવો પાસવર્ડ દાખલ કરો",
  confirmPasswordErrorMsg: "પાસવર્ડ પુષ્ટિકરણ મેળ ખાતો નથી",
  oldPassword: "ઓલ્ડ પાસવર્ડ",
  confirmPassword: "પાસવર્ડની પુષ્ટિ કરો",
  newPassword: "નવો પાસવર્ડ",
  visitedLocations: "મુલાકાત",
  checkoutFlightListINFlightAddonMsg: "ફ્લાઇટ એડ-ઓન્સ",
  checkoutmystifly: "લેવા માટેના સ્થળો રહસ્યમય",
  tourDetailstitle: "ટૂર ડિટેલ્સ",
  update_total_cost: "કુલ કિંમત અપડેટ કરો",
  updateCostConfirmCompleteMsg:
    "શું તમે ચોક્કસ છો, નેગેટિવ કમિશન સાથે ચાલુ રાખવા માંગો છો?",
  confirmButton: "પુષ્ટિ કરો",
  norating: "નો રેટિંગ",
  adult: "એડલ્ટ",
  child: "ચાઇલ્ડ",
  PrimaryPassenger: "પ્રાઈમરી પેસેન્જર (લીડ પેક્સ)",
  hotelbed: "હોટેલબેડ",
  activityownemail: "સપ્લાયર ઇમેઇલ",
  activityownweb: "વેબસાઇટ",
  activityownphone: "ફોન",
  activityownsupplier: "સપ્લાયર",
  hotelDetails: "હોટેલ ડિટેલ",
  room: "ઓરડો",
  ownArrangementActivityBasicDetails: "મૂળ પ્રવૃત્તિની વિગતો",
  booking: "બુકિંગ",
  tour: "ટૂર",
  oopsTheActivity: "ધી એક્ટિવિટી",
  oopsTheTour: "ટૂર",
  oopsTheFlight: "ધ ફ્લાઇટ",
  notAvailable: "હમણાં ઉપલબ્ધ નથી.",
  inventoryAddHotelOwnArrangement: "પોતાની એરેન્જમેન્ટ (આવાસ)",
  inventoryAddToursOwnArrangement: "પોતાની એરેન્જમેન્ટ (ટૂર એન્ડ ટિકિટ)",
  inventoryAddReplaceTransport: "બીજું ટ્રાન્સપોર્ટ પસંદ કરો",
  inventoryAddTransportOwnArrangement: "પોતાની એરેન્જમેન્ટ (પરિવહન)",
  noProductSelected: "કોઈ ઉત્પાદન પસંદ નથી",
  taskSelectDate: "તારીખ પસંદ કરો",
  taskSelecgender: "જાતિ પસંદ કરો",
  age: "વય",
  relation: "સંબંધ",
  activityTime: "સમય * ",
  activityDate: "તારીખ *",
  accountId: "એકાઉન્ટ આઈડી",
  nationality: "રાષ્ટ્રીયતા",
  city: "શહેર",
  address: "સરનામું",
  postcode: "પોસ્ટ કોડ",
  region: "પ્રદેશ",
  ontarioResident: "નિવાસી",
  prepaidGratuities: "ntન્ટારીયો પ્રીપેડ ગ્રેચ્યુટીઝ",
  mobilePhone: "સંપર્ક",
  titleBooking: "શીર્ષક",
  genderBooking: "લિંગ",
  emailBooking: "ઇમેઇલ સરનામું",
  paymentAmount: "ચુકવણીની રકમ",
  checkoutRceiptNumber: "રસીદ નંબર",
  loadPnr: "લોડ પીએનઆર oiceઇસમાં",
  activityownArrangementPropertyInvoice: "આ ભાવોને અંતિમ ઇન્વશામેલ કરો.",
  activityownArrangementPropertyRefund: "આ રીફંડ છે.",
  bookingVoucherfileType: "(સપોર્ટેડ ફાઇલ પ્રકારો .jpeg, .jpg, .png, .pdf)",
  bookingVouchermsg: "વાઉચર અપલોડ કરવા અહીં ક્લિક કરો",
  carrierName: "કેરીઅરનું નામ",
  departureDateandTime: "પ્રસ્થાનની તારીખ અને સમય",
  arrivalDateandTime: "આગમનની તારીખ અને સમય",
  airlineReference: "એરલાઇન સંદર્ભ",
  pnrNumber: "પી.એન.આર.",
  pnrInstructions:
    "દાખલ કરો કૃપા કરીને પ્રવાસ માર્ગ મેળવવા માટે માન્ય પી.એન.આર.નંબર દાખલ કરો",
  termsandconditions: "નિયમો અને શરતો",
  total: "કુલ",
  baggageAdult: "એડલ્ટ / ચાઇલ્ડ બેગેજ",
  baggageChild: "ચાઇલ્ડ બેગેજ",
  gitWarningMessage: "તમે આ શહેર માટે અમાન્ય ટૂર પસંદ કરી છે",
  summeryConfirmCompleteMsg:
    "શું તમને ખાતરી છે, અમાન્ય ટૂર પસંદગી સાથે ચાલુ રાખવા માંગો છો?",
  tourType: "ટૂર પ્રકાર",
  SearchPlace: "શોધ સ્થળ | શોધ સ્થાનો",
  SearchCity: "શોધ શહેર | શહેરો શોધો",
  manager: "વ્યવસ્થાપક",
  product: "ઉત્પાદન",
  finance: "ફાઇનાન્સ",
  admin: "સંચાલક",
  viewProductManager: "ઉત્પાદન વ્યવસ્થાપક જુઓ",
  viewfinanceManager: "ફાઇનાન્સ મેનેજર જુઓ",
  viewAdminManager: "સંચાલક વ્યવસ્થાપક જુઓ",
  viewsupplierManager: "સપ્લાયર વ્યવસ્થાપક જુઓ",
  viewmarketingManager: "માર્કેટિંગ વ્યવસ્થાપક જુઓ",
  DaterangeFrom: "તારીખ વિસ્તારમાંથી",
  DaterangeTo: "તારીખ ની સીમા",
  DataType: "માહિતી પ્રકાર",
  FileType: "ફાઇલ પ્રકાર",
  downloadNow: "હવે ડાઉનલોડ કરો",
  ConsultantName: "કન્સલ્ટન્ટ નામ",
  SupplierName: "સપ્લાયર નામ",
  ClientFile: "ક્લાયન્ટ ફાઇલ",
  pay: "ચૂકવણી",
  TypeofPayment: "ચુકવણીનો પ્રકાર",
  Login: "પ્રવેશ કરો",
  exportData: "માહિતીનિકાસ કરો",
  roomNumber: "રૂમ",
  storeName: "સંગ્રહ નામ",
  manageUsers: "વપરાશકર્તાઓનું સંચાલન કરો",
  manageBrands: "બ્રાન્ડ્સનું સંચાલન કરો",
  viewReports: "અહેવાલો જુઓ",
  brand: "બ્રાન્ડ",
  manageLicensee: "લાઇસન્સને સંચાલિત કરો",
  ManageLicensee: "શોધ લાઇસન્સ",
  OnboardNewLicensee: "ઓનબોર્ડ નવા લાઇસન્સી",
  CreateNewPackage: "નવું પેકેજ બનાવો",
  consultantArchived: "બુકિંગ",
  ArchivedData: "પેટી",
  consultantArchivedBlurb: "તમારી પેટી બુકીંગની યાદી જુઓ",
  consultantArchivedButton: "પેટી બુકીંગ જુઓ",
  consultantArchivedAgency: "પેટી એજન્સી બુકિંગ જુઓ",
  archivedItineraries: "પેટી થયેલ આઈટનીરીઝ",
  taskDay: "દિવસ પસંદ કરો",
  action: "ક્રિયા",
  mobile: "મોબાઇલ",
  taskSelectDay: "દિવસ પસંદ કરો",
  DateandTime: "તારીખ અને સમય",
  addtransport: "પરિવહન ઉમેરો",
  addhotels: "હોટેલ્સ ઉમેરો",
  addactivities: "પ્રવૃત્તિઓ ઉમેરો",
  manageclientpreferences: "ક્લાયન્ટ પસંદગીઓનું સંચાલન કરો",
  Return: "પાછા ફરો",
  oneway: "એક માર્ગ",
  multycity: "મુલ્ટી શહેર",
  flyingfrom: "ઉપરથી ઉડાન",
  selectclass: "વર્ગ પસંદ કરો",
  returndate: "પરત કરવાની તારીખ",
  comingsoon: "જલદી આવે છે",
  thingstodo: "કરવા માટેની બાબતો",
  extendedtouring: "વિસ્તૃત પ્રવાસ",
  selectinterest: "રસ પસંદ કરો",
  searchpnr: "PNR શોધો",
  googlesearch: "Google શોધ",
  package: "પેકેજ",
  addcustomer: "ગ્રાહક ઉમેરો",
  geoLocationManager: "જીઓ સ્થાન વ્યવસ્થાપક",
  supplieroperatorManager: "સપ્લાયર/ ઓપરેટર વ્યવસ્થાપક",
  yieldManager: "ઉપજ વ્યવસ્થાપક",
  packageBuilder: "પેકેજ બિલ્ડર",
  plotmaps: "પ્લોટ નકશાઓ",
  reporting: "અહેવાલ",
  CSVDownload: "CSV ડાઉનલોડ",
  makePayments: "ચૂકવણી કરો",
  packageName: "પેકેજ નામ",
  uploadImages: "ચિત્રો અપલોડ કરો",
  toursandtickets: "પ્રવાસો અને ટિકિટો",
  hotel: "હોટેલ",
  packageOverview: "પેકેજ દેખાવ",
  packageItinerary: "પેકેજ આઇટરી",
  Optional: "વૈકલ્પિક",
  createpackagefromtour: "પ્રવાસમાંથી પેકેજ બનાવો",
  Currency: "ચલણ",
  companyName: "કંપનીનું નામ",
  Phone: "ફોન (કન્ટ્રી કોડ સહિત)",
  url: "URL",
  uploadLogo: "લોગો અપલોડ કરો",
  selectProduct: "ઉત્પાદન પસંદ કરો",
  hotelSupplier: "હોટેલ સપ્લાયર",
  tourSupplier: "ટૂર સપ્લાયર",
  transportSupplier: "પરિવહન સપ્લાયર",
  otherSupplier: "અન્ય સપ્લાયર",
  selectSettings: "સુયોજનો પસંદ કરો",
  defaultLanguage: "મૂળભૂત ભાષા",
  defaultCurrency: "મૂળભૂત ચલણ",
  chooseTemplate: "ટેમ્પલેટ પસંદ કરો",
  paymentGateway: "ચૂકવણી ગેટવે",
  selectSubscription: "ઉમેદવારી પસંદ કરો",
  licenceType: "લાઇસન્સ પ્રકાર",
  howmanylicences: "કેટલાં લાઇસન્સ?",
  addons: "ઉમેરો",
  paymentTerms: "ચુકવણીની શરતો",
  licenseenameoremail: "લાઇસન્સીનામ અથવા ઇમેઇલ",
  createProfilesandClientDetails:
    "રૂપરેખાઓ બનાવો અને ક્લાયન્ટ વિગતોનું સંચાલન કરો",
  passportNumber: "પાસપોર્ટ નંબર",
  passportissuedate: "પાસપોર્ટ ઇશ્યૂ તારીખ",
  passportExpiryDate: "પાસપોર્ટ એક્સપાયરી ડેટ",
  passportissuingcountry: "પાસપોર્ટ જારી કરતો દેશ",
  clientContact: "ક્લાયન્ટ સંપર્ક",
  Phonehome: "ફોન (ઘર)",
  Phonework: "ફોન (કામ)",
  phonemobile: "ફોન (મોબાઇલ)",
  AddressLinOne: "સરનામું (લીટી એક)",
  AddressLineTwo: "સરનામું (લીટી બે)",
  StateProvinceRegion: "રાજ્ય/ પ્રાંત/ પ્રદેશ",
  zipPostalCode: "ZIP/ પોસ્ટલ કોડ",
  country: "દેશ",
  newsletter: "સમાચારપત્ર",
  companyABN: "કંપની એબીએન",
  paName: "PA નામ",
  clientLoyaltyPrograms: "ક્લાયન્ટ લોયલ્ટી કાર્યક્રમો",
  srno: "મિ. નં.",
  loyaltyType: "વફાદારીપ્રકાર",
  providerName: "પ્રદાતા નામ",
  membershipNumber: "સભ્યપદ નંબર",
  clientPreferences: "ક્લાયન્ટ પસંદગીઓ",
  airline: "એરલાઇન",
  airlines: "એરલાઇન(S)",
  travelClass: "મુસાફરી વર્ગ",
  preferredSeating: "પસંદગીની બેઠક",
  mealRequest: "ભોજન ની વિનંતી",
  Hotels: "હોટેલ(ઓ)",
  hotelChain: "હોટેલ ચેઇન",
  Cars: "કાર(ઓ)",
  carRental: "કાર ભાડે",
  CarType: "કાર પ્રકાર",
  interests: "રસ(ઓ)",
  linkedprofiles: "કડી થયેલ રૂપરેખાઓ",
  Preferreddestinations: "પસંદકરેલ સ્થાનો",
  businessPreferences: "વ્યાપાર પસંદગીઓ",
  otherPreferences: "અન્ય પસંદગીઓ",
  pendingBookings: "બાકી બુકિંગ",
  archiveBookings: "પેટી બુકિંગ્સ",
  activeBookings: "સક્રિય બુકિંગ",
  viewdetails: "વિગતો જુઓ",
  update: "સુધારો",
  bookingmanagement: "બુકિંગ વ્યવસ્થાપન",
  bookingDashboard: "ડેશબોર્ડ બુક કરી રહ્યા છીએ",
  bookingoverview: "બુકિંગ ઓવરવ્યૂ",
  Generalbookingdetails: "સામાન્ય બુકિંગની વિગતો.",
  type: "પ્રકાર",
  created: "બનાવેલ છે",
  segmentoverview: "સેગ્મેન્ટ દેખાવ",
  totalnumberofsegment:
    "ઉત્પાદનો/સેગ્મેન્ટની કુલ સંખ્યા, અને બાકી રહેલા ંપુષ્ટિ થયેલ સેગમેન્ટો.",
  segmentsconfirmedvstotalsegment:
    "સેગ્મેન્ટ્સ કન્ફર્મ થયેલ છે અને કુલ સેગમેન્ટ્સ બુકિંગમાં સામેલ છે.",
  amountremainingforclient:
    "બાકીની રકમ બાકી રહેલી બાકી નીતારીખ પહેલાં ચૂકવવાની બાકી છે.",
  outstandingAmount: "બાકી રકમ",
  outstanding: "બાકી",
  balanceDue: "બાકી બાકી",
  bookingDetails: "બુકિંગ વિગતો",
  user: "વપરાશકર્તા",
  consultantNotes: "સલાહકાર નોંધો",
  historynotfound: "ઇતિહાસ મળ્યો નથી",
  leadpaxdetails: "લીડ પેક્સ વિગતો",
  additionalpaxdetails: "વધારાની પેક્સ વિગતો",
  locationdetails: "સ્થાન વિગતો",
  selectedHotels: "પસંદ કરેલ હોટેલ્સ",
  confirmed: "ખાતરી થયેલ છે",
  snooze: "સ્નૂઝ",
  upload: "અપલોડ કરો",
  novoucherfound: "વાઉચર મળ્યુ નથી",
  Nopaymentsfound: "કોઈ ચુકવણી મળી નથી",
  newvoucher: "આ બુકિંગ માટે નવું વાઉચર અપલોડ કરો",
  allocation: "ફાળવણી",
  selectedtoursandtickets: "પસંદ કરેલ ટૂર્સ અને ટિકિટ",
  offeredIn: "અંદર ઓફર થયેલ છે",
  tofrom: "TO/ FROM",
  bookingRef: "બુકિંગ રેફ",
  selectedTransport: "પસંદ થયેલ પરિવહન",
  segmentmessage:
    "આ સેગમેન્ટની પુષ્ટિ કરીને, તમે સ્વીકારકરી રહ્યા છો કે આ પ્રોડક્ટ સપ્લાયર સાથે બુક થઈ ગઈ છે, આ એપ્લિકેશનની બહાર. ખાતરી પૂર્ણ કરવા માટે, કૃપા કરીને નીચેનું ક્ષેત્ર પૂર્ણ કરો.",
  bookingReference: "બુકિંગ સંદર્ભ",
  suppliersbookingreference: "સપ્લાયરનો બુકિંગ સંદર્ભ દાખલ કરો",
  confirmsegment: "સેગ્મેન્ટને ખાતરી કરો",
  pickupTime: "પિક-અપ સમય",
  totalDuration: "કુલ સમયગાળો",
  layoverTime: "સમય પર મૂકો",
  ticketType: "ટિકિટ પ્રકાર",
  class: "વર્ગ",
  provider: "પ્રદાતા",
  viewcustomer: "ગ્રાહક ને જુઓ",
  costofitinerary: "આઈટનરીની કુલ કિંમત",
  paid: "ચૂકવેલ",
  receiptofallpayments: "અત્યાર સુધીની તમામ ચૂકવણીની પ્રાપ્તિ",
  balanceduetocustomer: "ગ્રાહક પાસેથી એકત્રકરવાને કારણે બેલેન્સ",
  recordcustomerpayment: "નીચે ગ્રાહકની ચૂકવણીની નોંધ કરો.",
  completePayment: "સંપૂર્ણ ચુકવણી",
  cancel: "રદ્દ",
  accommodationORtransportation:
    "* કૃપા કરીને ઓછામાં ઓછા એક રહેવા અથવા પરિવહનપસંદ કરો.",
  preferredPropertyClass: "પસંદકરેલ ગુણધર્મ વર્ગ",
  transportation: "પરિવહન",
  preferredtransporttype: "પસંદીદા પરિવહન પ્રકાર",
  preferredtransportclass: "પસંદીદા પરિવહન વર્ગ",
  agegroup: "વય જૂથ",
  interest: "રસ",
  activityinterest: "પ્રવૃત્તિ રસ",
  applytoalllocations: "તમામ સ્થાનો પર લાગુ કરો",
  applythislocation: "આ સ્થાન લાગુ કરો",
  apply: "લાગુ કરો",
  hotelDescription: "હોટેલ વર્ણન",
  activityDescription: "પ્રવૃત્તિ વર્ણન",
  checkoutcancelationdescription:
    'આ એક લાંબી સ્થાપિત હકીકત છે કે જ્યારે તે નું લેઆઉટ જોઈરહ્યા હોય ત્યારે વાચક પૃષ્ઠની વાંચી શકાય તેવી સામગ્રીથી વિચલિત થઈ જશે. લોરમ ઇપ્સમનો ઉપયોગ કરવાનો મુદ્દો એ છે કે તેમાં પત્રોની સામાન્ય વહેંચણી થાય છે, જે \'અહીં સામગ્રી અહીં સામગ્રી, સામગ્રી વાપરવાની વિરુદ્ધમાં છે, જેતેને વાંચી શકાય તેવું અંગ્રેજી જેવું લાગે છે. ઘણા ડેસ્કટોપ પ્રકાશન પેકેજો અને વેબ પેજ સંપાદકો હવે લોરમ ઇપ્સમનો તેમના મૂળભૂત મોડેલ લખાણ તરીકે ઉપયોગ કરે છે, અને "લોરમ ઇપ્સમ" શોધકરવાથી તેમના બાળપણમાં પણ ઘણી વેબસાઇટો ખૂલી જશે. વર્ષોથી, ક્યારેક અકસ્માતે, ક્યારેક હેતુપૂર્વક (ઇન્જેGશન કરેલ રમૂજ અને જેવી) આવૃત્તિઓ નો વિકાસ થયો છે.',
  OverrideQuotation: "અવતરણ સંગ્રહો/ઓવરરાઇડ",
  DuplicateQuotation: "નકલ અવતરણ",
  OriginCity: "મૂળ શહેર",
  ReturnCity: "શહેર પરત ફરો",
  AddTransport: "પરિવહન ઉમેરો",
  AddHotels: "હોટેલ્સ ઉમેરો",
  AddActivities: "પ્રવૃત્તિઓ ઉમેરો",
  ManagePreferences: "ક્લાયન્ટ પસંદગીઓનું સંચાલન કરો",
  searchPNR: "PNR શોધો",
  oneWay: "એક માર્ગ",
  multiCity: "મલ્ટી સિટી",
};
