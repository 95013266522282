
import { Component, Vue, Watch } from "vue-property-decorator";
import { currencies, timeZone } from "@/plugins/constants";
import FlightSearch from "@/Module/Global/components/tabs/FlightSearch.vue";
import HotelSearch from "@/Module/Global/components/tabs/HotelSearch.vue";
import TourSearch from "@/Module/Global/components/tabs/TourSearch.vue";
import GoogleSearchTab from "@/Module/Global/components/tabs/GoogleSearchTab.vue";
import packagesTab from "@/Module/Global/components/tabs/packagesTab.vue";
import website from "@/Module/Global/Pages/website.vue";
import ApiRequest from "@/Module/Global/api/apiService.js";
import { BDropdown } from "bootstrap-vue";
import { VueEditor } from "vue2-editor";
import scss from "sass-loader";
import { indexOf } from "lodash";
import _ from "lodash";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    VueEditor,
    FlightSearch,
    HotelSearch,
    TourSearch,
    GoogleSearchTab,
    packagesTab,
    Swiper,
    SwiperSlide,
    website,
    Multiselect
  },
})
export default class ClientConfiguration extends Vue {    
  activeTabIndex: any = 0;
  urlName = "";
  selectedDomain = "co.au";
  defaultLogo:any = 'eRoam/dummyLogo.png';
  defaultBanner: any = 'eRoam/dummyBanner.png';
  defaultfavicon: any = 'eRoam/dummyFavicon.png';
  logoUrl: any = this.defaultLogo;
  logoUrlImg: any = [];
  faviconUrl: any = this.defaultfavicon;
  logoUrlDisplay: any = '';
  faviconUrlDisplay: any = '';
  onboardingUrlDisplay: any = "";
  FX_currency_buffer:any = "";
  pageTitle: any = "";
  domainNameAllocated = false;
  permitted_currency:any = [];
  suggestionUrl: any = ["krunal.parikh25", "krunal.parikh180"];

  customToolbar: any = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["clean"],
  ];

  domainName: any = [".co.au", ".com.au", ".com", ".travel"];
  template: any = null;
  templateOptions: any = [];
  pageId = "";
  widget: any = "";
  widgetOptions: any = [
    { value: "", text: "Select Widget Template" },
    { value: "t1", text: "Template 1" },
    { value: "t2", text: "Template 2" },
  ];
  fontStyle: any = "'Roboto', sans-serif";
  fontStyleSelected: any = this.fontStyle;
  fontStyleOption: any = [
    {
      id: 1,
      classValue: "roboto",
      value: "'Roboto', sans-serif",
      text: "Roboto",
    },
    {
      id: 2,
      classValue: "opensans",
      value: "'Open Sans', sans-serif",
      text: "Open sans",
    },
    { id: 3, classValue: "lato", value: "'Lato', sans-serif", text: "Lato" },
    {
      id: 4,
      classValue: "ptsans",
      value: "'PT Sans', sans-serif",
      text: "PT sans",
    },
    {
      id: 5,
      classValue: "sourcepro",
      value: "'Source Sans Pro', sans-serif",
      text: "Source Sans Pro",
    },
    { id: 6, classValue: "exo", value: "'Exo', sans-serif", text: "Exo" },
  ];

  primaryColor: any = "";
  secondaryColor: any = "";
  disableColor: any = "";
  inputLabelColor: any = "";
  inputNavbarColor: any = "";

  timezone: any = "";
  language: any = "";
  currency: any = "";
  languageOption: any = [
    { value: "", text: "Select Language" },
    { value: "en", text: "English" },
    { value: "es", text: "Español" },
    { value: "it", text: "Italiano" },
    { value: "de", text: "Deutsche" },
    { value: "fr", text: "French" },
    { value: "gu", text: "Gujarati" },
    { value: "gr", text: "Greek" },
    { value: "du", text: "Dutch" },
    { value: "da", text: "Danish" },
    { value: "de", text: "Deutsche" },
    { value: "nr", text: "Norwegion" },
    { value: "ru", text: "Russian" },
    { value: "sp", text: "Spanish" },
    { value: "sw", text: "Swedish" },
    { value: "tr", text: "Turkish" },
    { value: "po", text: "Portuguese" },
    { value: "ja", text: "Japanese" },
    { value: "ch", text: "Chinese" },
    { value: "ar", text: "Arabic" },
    { value: "hi", text: "Hindi" },
    { value: "in", text: "Indonesian" },
    { value: "ma", text: "Malay" },
  ];
  timeZoneOption: any = timeZone;
  currencyOption: any = [];
  domain_id: any = "";
  bannerHeroUrl: any = this.defaultBanner;
  bannerHeroUrlDisplay: any = '';
  about_us_text: any = "";
  facebook_url: any = "";
  twitter_url: any = "";
  linkedIn_url: any = "";
  pinterest_url: any = "";
  google_plus_url: any = "";
  youtube_url: any = "";
  own_domain_name: any = "";
  selectSuggestion(value: any) {
    this.urlName = value;
  }
  fxCurrencyList:any = [];
  fxCurrencyListforFilter:any = [];
  baseCurrency:any = '';
  toCurrency:any = '';
  rate:any = '';
  recordId:any = '';
  errorMessage:any ="";
  fxbaseFilter:any = null;
  addInverseValue:any=false;
  inverse:any = { base_currency: '', to_currency: '', rate: '',rateValue:'' };
  rateValue:any='';
  disabled:any = true;
  showNoRecordFound:any=false;
  google_api_key:number = 0;
  // google_api_key_value:string = '';

  googleKeyOptions: {text:string,value:number}[] = [
    {
      text:'eRoam Key',
      value:0
    },
    {
      text:'client Key',
      value:1
    },
    {
      text:'automation Key',
      value:2
    },
  ]

  //FX setting Api
  formatter(value) {
    return value.replace(/\s/g, "").toLowerCase()
  }

  fxinverseRate(){
    let vm = this;
    if(vm.toCurrency && vm.baseCurrency){
      if(vm.addInverseValue == true){
        vm.inverse.base_currency = vm.toCurrency;
        vm.inverse.to_currency = vm.baseCurrency;
        let requestData = {from: vm.inverse.base_currency , to:vm.inverse.to_currency }
        ApiRequest.getDynamicrate(requestData)
        .then((res: any) => {
            res = res.data;
            if (res.code == 200) {
              vm.inverse.rateValue = res.data.exchange_rate;
            }
        })
        .catch((error) => {
            if (error) {
              vm.$toasted.show("Something went wrong!!!", {
                  theme: "bubble",
                  position: "top-right",
                  duration: 2000,
              });
            }
        });
      }else{
        vm.inverse = { base_currency: '', to_currency: '', rate: '' ,rateValue:''};
      }
    }else{
      vm.addInverseValue = false;
      if(vm.baseCurrency == ""){
        vm.$toasted.error("Please select base currency!!!", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      }else{
        vm.$toasted.error("Please select to currency!!!", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      }
      
    }
    
  }

  filterFxbyBaseCurrency(fxbaseFilter){
    let vm = this;
    let updatedfxRecords = _.filter(vm.fxCurrencyListforFilter, function(fx,i) {
      return fx.base_currency == fxbaseFilter;
    });
    if(updatedfxRecords.length){
      vm.fxCurrencyList = updatedfxRecords;
    }else{
      vm.$toasted.info("No such record found", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
    }
  }

  clearFxFilter(){
    this.fxCurrencyList = this.fxCurrencyListforFilter;
    this.fxbaseFilter = null;
  }

  openFxadd(){
    let vm = this;
    vm.baseCurrency = '';
    vm.toCurrency = '';
    vm.rate = '';
    vm.addInverseValue = false;
    vm.inverse = { base_currency:'', to_currency:'', rate:'' ,rateValue:''};
    vm.rateValue='';
    vm.$bvModal.show('addFxModal');
  }

  getFxcurrencyList() {
    let vm = this;
    let requestData = {domain_id:vm.domain_id =='' ? vm.$store.state.domainId : vm.domain_id }
    ApiRequest.getFxcurrencyList(requestData)
    .then((res: any) => {
        res = res.data;
        vm.$store.dispatch("SetFxRecords", []);
        if (res.code == 200) {
          vm.fxCurrencyList = res.data;
          vm.fxCurrencyListforFilter = res.data;
          vm.$store.dispatch("SetFxRecords", vm.fxCurrencyList);
        } else {
            vm.$toasted.show(res.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
            });
        }
    })
    .catch((error) => {
        if (error) {
          vm.$toasted.show("Something went wrong!!!", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
          });
        }
    });
  }

  getDynamicrate(){
    let vm = this;
    let requestData = {from: vm.baseCurrency, to:vm.toCurrency }
    if(vm.baseCurrency && vm.toCurrency){
      ApiRequest.getDynamicrate(requestData)
      .then((res: any) => {
          res = res.data;
          if (res.code == 200) {
            vm.rateValue = res.data.exchange_rate;
            vm.fxinverseRate()
          } 
      }).catch((error) => {});
    }else{
      if(vm.baseCurrency == ''){
          vm.$toasted.show("please select base currency!!!", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      }
      if(vm.toCurrency == ''){
          vm.$toasted.show("please select to currency!!!", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      }
      
    }
  }

  addFxInLocal(){
    let vm = this;
    if(!vm.validInputsforFx()){
      vm.$toasted.error(this.errorMessage, {
      theme: "toasted-primary",
      position: "top-right",
      duration: 2000,
    });
      vm.errorMessage = "";
      return;
    }
    let fxnewRecord = {
      base_currency: vm.baseCurrency,
      to_currency: vm.toCurrency,
      rate: vm.rate,
      inverseRate: vm.addInverseValue,
      inverse:{ base_currency: vm.inverse.base_currency, to_currency: vm.inverse.to_currency, rate: vm.inverse.rate,rateValue:vm.inverse.rateValue},
      recordId: vm.fxCurrencyList.length + 1
    }
    let recordFound = false;
    _.forEach(vm.fxCurrencyList, (fxrecord,index) => {
        if(fxrecord.base_currency == vm.baseCurrency && fxrecord.to_currency == vm.toCurrency)
        {
          recordFound = true;
        }
        if(fxrecord.inverseRate == true && fxrecord.inverse.base_currency == vm.baseCurrency && fxrecord.inverse.to_currency == vm.toCurrency)
        {
          recordFound = true;
        }
        if(fxrecord.inverseRate == true && fxrecord.inverse.base_currency == vm.inverse.base_currency && fxrecord.inverse.to_currency == vm.inverse.to_currency)
        {
          recordFound = true;
        }
        if(fxrecord.base_currency == vm.inverse.base_currency && fxrecord.to_currency == vm.inverse.to_currency)
        {
          recordFound = true;
        }
    }); 
    if(recordFound == false){
      vm.fxCurrencyList.push(fxnewRecord);
      vm.$store.dispatch("SetFxRecords", vm.fxCurrencyList);
      vm.fxCurrencyListforFilter = vm.fxCurrencyList;
      vm.$toasted.success('Rate added successfully', {
        theme: "toasted-primary",
        position: "top-right",
        duration: 2000,
      });
      vm.$bvModal.hide("addFxModal");
    }else{
      vm.$toasted.error('Rate already exist.', {
        theme: "toasted-primary",
        position: "top-right",
        duration: 2000,
      });
    }
  }

  updateFxInLocal(index){
    let vm = this;
    if(!vm.validInputsforFx()){
      vm.$toasted.error(this.errorMessage, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 2000,
      });
      vm.errorMessage = "";
      return;
    }

    vm.fxCurrencyList[index].base_currency = vm.baseCurrency;
    vm.fxCurrencyList[index].to_currency = vm.toCurrency;
    vm.fxCurrencyList[index].rate = vm.rate;
    vm.fxCurrencyList[index].inverseRate = vm.addInverseValue;
    vm.fxCurrencyList[index].inverse = vm.inverse;
    vm.$store.dispatch("SetFxRecords", vm.fxCurrencyList);
    vm.$toasted.success('Rate updated successfully', {
      theme: "toasted-primary",
      position: "top-right",
      duration: 2000,
    });
    vm.$bvModal.hide("addFxModal-"+index);
  }

  deleteFxInLocal(index){
    let vm = this;
    let updatedfxRecords = _.filter(vm.fxCurrencyList, function(fx,i) { return index != i; });
    vm.fxCurrencyList = updatedfxRecords;
    vm.fxCurrencyListforFilter = updatedfxRecords;
    vm.$store.dispatch("SetFxRecords", updatedfxRecords);
    vm.$toasted.success('Rate deleted successfully', {
      theme: "toasted-primary",
      position: "top-right",
      duration: 2000,
    });
  }

  viewFxrecord(fxRecord,index){
    let vm = this;
    let id = fxRecord.id ? fxRecord.id : fxRecord.recordId;
    vm.recordId = index;
    let findfxrecords = _.find(this.$store.state.fxrecordsList, function(fxrecord) { 
      if(fxrecord.id){
        return fxrecord.id == id;
      }else {
        return fxrecord.recordId == id;
      }
    }); 
    setTimeout(function() {
      vm.$bvModal.show('addFxModal-'+index);
      vm.baseCurrency = findfxrecords.base_currency;
      vm.toCurrency = findfxrecords.to_currency;
      vm.rate = findfxrecords.rate;
      vm.addInverseValue = findfxrecords.inverseRate;
      vm.inverse = findfxrecords.inverse;
    }, 1000);
  }

  validInputsforFx(){
    let vm = this;
    let isValid = true;
    if(vm.baseCurrency == ""){
      vm.baseCurrency = "";
      vm.errorMessage = "Select base currency."
      isValid = false;
    }
    else if(vm.toCurrency == ""){
      vm.toCurrency = "";
      vm.errorMessage = "Select to currency."
      isValid = false;
    }
    else if(vm.rate == ""){
      vm.rate = "";
      vm.errorMessage = "Please enter rate."
      isValid = false;
    }
    else if(vm.addInverseValue == true && vm.inverse.base_currency == ""){
      vm.inverse.base_currency = "";
      vm.errorMessage = "Select Inverse base currency."
      isValid = false;
    }
    else if(vm.addInverseValue == true && vm.inverse.to_currency == ""){
      vm.inverse.to_currency  = "";
      vm.errorMessage = "Select Inverse to currency."
      isValid = false;
    }
    else if(vm.addInverseValue == true && (vm.inverse.rate == null || vm.inverse.rate == '')){
      vm.inverse.rate = "";
      vm.errorMessage = "Please enter Inverse rate."
      isValid = false;
    }
    return isValid;
  }

  async addFxInfomation(domain_id) {
    let vm = this;
    try {
      let requestData = {
        domain_id: domain_id,
        fxCurrencies:vm.fxCurrencyList,

      };
      const response = await ApiRequest.addFxInfomation(requestData);
      if (response && response.data.code == 200) {}
    } catch (err) {}
  }
  //End FX setting Api
  getSupportedCurrencies() {
        let vm = this;
        ApiRequest.getSupportedCurrencies()
        .then((res: any) => {
            res = res.data;
            if (res.code == 200) {
                const { data } = res;
                vm.currencyOption = data;
            } else {
                vm.$toasted.show(res.message, {
                    theme: "bubble",
                    position: "top-right",
                    duration: 2000,
                });
            }
        })
        .catch((error) => {
            if (error) {
                vm.$toasted.show("Something went wrong!!!", {
                    theme: "bubble",
                    position: "top-right",
                    duration: 2000,
                });
            }
        });
  }

  logoChange(e: { target: { files: any[] } }, value: any) {
    const file = e.target.files[0];
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.$toasted.show(
        "Please upload file having extensions .jpeg/.jpg/.png only.",
        {
          theme: "bubble",
          position: "top-right",
          duration: 5000,
          type: "fail",
        }
      );
      return false;
    } else {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e1: any) => {
          if (value == "logo") {
            this.logoUrl = e1.target.result;
            this.logoUrlDisplay = e1.target.result;
          }
          if (value == "hero") {
            this.bannerHeroUrl = e1.target.result;
            this.bannerHeroUrlDisplay = e1.target.result;
          }
          if (value == "favicon") {
            this.faviconUrl = e1.target.result;
            this.faviconUrlDisplay = e1.target.result;
          }
          // if(value == "background"){
          //     this.bannerBackgroundImage = e1.target.result;
          // }
        };
        reader.readAsDataURL(file);
      }
    }
  }
  bannerImageChange(e: { target: { files: any[] } }) {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!allowedExtensions.exec(files[i].name)) {
        this.$toasted.show(
          "Please upload file having extensions .jpeg/.jpg/.png only.",
          {
            theme: "bubble",
            position: "top-right",
            duration: 5000,
            type: "fail",
          }
        );
        // this.ImageURL = "";
        return false;
      } else {
        //Image preview
        if (files[i]) {
          let reader = new FileReader();
          reader.onload = (e1: any) => {
            this.bannerImage.push({ image: e1.target.result });
          };
          reader.readAsDataURL(files[i]);
        }
      }
    }
  }
  deleteOrganisationImg(id, i) {
    ApiRequest.deleteOrganisation({ id: id })
      .then((res: any) => {
        this.bannerImage.splice(i, 1);
        this.$toasted.show(res.data.message, {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
        //this.getbusinessInfomation();
      })
      .catch((error: any) => {
        this.$toasted.show("Something went wrong", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      });
  }

  selectedTemplate:any=''
  preivewImgOnSelect(val: any) {
    switch (val) {
      case "83":
        this.onboardingUrlDisplay =
          "https://static-graphics.eroam.com/eRoam/onboarding/template2.jpg";
        break;
      case "85":
        this.onboardingUrlDisplay =
          "https://static-graphics.eroam.com/eRoam/onboarding/template4.jpg";
        break;
    }
  }
  removeLogoImage(value: any) {
    if (value == "logo") {
      this.logoUrl = "";
      this.logoUrlDisplay = "";
    }
    if (value == "hero") {
      this.bannerHeroUrl = "";
      this.bannerHeroUrlDisplay = "";
    }
    if (value == "favicon") {
      this.faviconUrl = "";
      this.faviconUrlDisplay = "";
    }
  }

  livePreviewModal() {
    this.$bvModal.show("previewModal");
  }

  selectedWebType: any = "trade";
  licenseeType: any = "office";
  travelDashboardValue: any = "website";
  products: any = ["flights", "hotels", "tours", "activities"];
  productOptions: any = [
    { text: "flights", value: "flights", icon: "icon-plane" },
    { text: "hotels", value: "hotels", icon: "icon-hotel" },
    // {text:'car rental', value:'car rental', icon:'fa fa-car'},
    { text: "tours", value: "tours", icon: "fa fa-tag" },
    { text: "activities", value: "activities", icon: "fas fa-ticket-alt" },
    //{text:'cruises', value:'cruises', icon:'fas fa-ship'},
  ];
  bannerBackground: any = "color";
  bannerBackgroundOptions: any = [
    { text: "Color", value: "color" },
    { text: "Image", value: "image" },
  ];
  bannerBackgroundColor: any = "#0082ba";
  bannerImage: any = [];
  bannerImageDisplay: any = [];
  bannerBackgroundImage: any = [];
  backgroundAltValue = "";

  siteType: any = "public";
  search: any = true;

  mounted() {    
    let colors = {
      primary: this.primaryColor,
      secondary: this.secondaryColor,
      disable: this.disableColor,
      inputLabelColor: this.inputLabelColor,
      inputNavbarColor: this.inputNavbarColor,
    };
    this.setThemeColors(colors);
    this.setFontFamily(this.fontStyle);
    this.setHeroBackground(this.bannerHeroUrlDisplay);
    this.getbusinessInfomation();
    this.getCOBTemplateList();
    this.getSupportedCurrencies();
    this.getFxcurrencyList();
  }

  checkimageorNote(URLName){
    var fileName, fileExtension;
    fileName = URLName;
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
    return fileExtension;
  }

  async getCOBTemplateList() {
    try {
      const response = await ApiRequest.getCOBTemplateList({
        search_enabled: this.search,
      });
      if (response.data.code == 200) {
        this.templateOptions = _.map(
          response.data.data,
          ({ template_id, template_name }) => {
            return {
              value: template_id.toString(),
              text: template_name,
            };
          }
        );
        // this.templateOptions.unshift({
        //   value: "",
        //   text: "Select Website Template",
        // });
      }
    } catch (err) {}
  }

  async getbusinessInfomation() {
    let vm = this;
    try {
      let requestData = {
        user_id: vm.$store.state.loginInformation.id,
        organisation_id: vm.$store.state.loginInformation.organisation_id,
        package_id: vm.$store.state.loginInformation.package_id,
        cob_step: 2,
        domain_id:vm.domain_id =='' ? vm.$store.state.domainId : vm.domain_id 
      };
      const response = await ApiRequest.getbusinessInfomation(requestData);
      vm.domainNameAllocated = false;
      if (response && response.data.code == 200) {
        let responses = response.data;
        vm.urlName = responses.data.domain_name;
        vm.domainNameAllocated =
        vm.urlName != "" && vm.urlName != null ? true : false;
        vm.logoUrl = responses.data.logo_image ? responses.data.logo_image:this.defaultLogo;

        vm.faviconUrl = responses.data.favicon_image
          ? responses.data.favicon_image
          : this.defaultfavicon;

        vm.bannerHeroUrl = responses.data.background_image
          ? responses.data.background_image
          : this.defaultBanner;

        vm.logoUrlDisplay = responses.data.logo_image
          ? `https://static-graphics.eroam.com/${responses.data.logo_image}`
          : `https://static-graphics.eroam.com/${this.defaultLogo}`;

        vm.faviconUrlDisplay = responses.data.favicon_image
          ? `https://static-graphics.eroam.com/${responses.data.favicon_image}`
          : `https://static-graphics.eroam.com/${this.defaultfavicon}`;

        vm.bannerHeroUrlDisplay = responses.data.background_image
          ? `https://static-graphics.eroam.com/${responses.data.background_image}`
          : `https://static-graphics.eroam.com/${this.defaultBanner}`;

        vm.pageTitle = responses.data.browser_page_title;
        vm.fontStyle = responses.data.font_style;
        vm.primaryColor = responses.data.primary_colour
          ? responses.data.primary_colour
          : "#0082ba";
        vm.secondaryColor = responses.data.secondary_colour
          ? responses.data.secondary_colour
          : "#979797";
        vm.disableColor = responses.data.disable_colour
          ? responses.data.disable_colour
          : "#D5D5D5";
        vm.inputLabelColor = responses.data.input_label_color
          ? responses.data.input_label_color
          : "#0082ba";
        vm.inputNavbarColor = responses.data.navigation_colour
          ? responses.data.navigation_colour
          : "#0082ba";
        vm.timezone = responses.data.timezone;
        vm.language = responses.data.language;
        vm.FX_currency_buffer = responses.data.FX_currency_buffer;
        vm.currency = responses.data.currency;
        vm.domain_id = responses.data.domain_id;
        vm.$store.dispatch("SetDomainId", vm.domain_id);
        vm.getFxcurrencyList()
        vm.licenseeType =
          responses.data.licensee_type == null
            ? "office"
            : responses.data.licensee_type;
        vm.travelDashboardValue =
          responses.data.travel_shop == null
            ? "website"
            : responses.data.travel_shop;
        vm.siteType =
          responses.data.site_type == null || responses.data.site_type == ""
            ? "public"
            : responses.data.site_type;
        vm.search = responses.data.search;
        vm.products = responses.data.products
          ? responses.data.products
          : vm.products;
        vm.template = parseInt(responses.data.template);
        vm.pageId = responses.data.page_id;
        vm.bannerBackground = responses.data.banner_background;
        vm.backgroundAltValue = responses.data.background_alt_value;
        vm.widget = responses.data.widget;
        vm.metaTitle = responses.data.meta_title;
        vm.metaDescription = responses.data.meta_description;
        vm.metaKeyWords = responses.data.meta_keywords
          ? responses.data.meta_keywords
          : [];
        if (vm.bannerBackground == "color") {
          vm.bannerBackgroundColor = responses.data.banner_value;
        } else if (vm.bannerBackground == "image") {
          vm.bannerImage = responses.data.banner_background_image;
          // _.forEach(vm.bannerImage, (banner) => {
          //   banner.image = banner.image ? `https://static-graphics.eroam.com/${banner.image}`:'';
          // });
        }
        let colors = {
          primary: responses.data.secondary_colour,
          secondary: responses.data.secondary_colour,
          disable: responses.data.disable_colour,
          inputLabelColor: responses.data.input_label_color,
          inputNavbarColor: responses.data.navigation_colour,
        };
        vm.setThemeColors(colors);
        vm.setHeroBackground(vm.bannerHeroUrlDisplay);
        vm.about_us_text= responses.data.about_us_text ? responses.data.about_us_text : `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio aut dicta hic, autem quam cupiditate quibusdam beatae animi fugit explicabo quaerat amet. Cum dolor suscipit id, esse nobis, quas earum laboriosam neque voluptates quisquam nam incidunt voluptate atque nulla eum. Velit dignissimos doloremque animi, autem quaerat officiis id? Beatae, veritatis.`,
        vm.facebook_url= responses.data.facebook_url ? responses.data.facebook_url : "https://www.facebook.com/eroamFB",
        vm.twitter_url= responses.data.twitter_url ? responses.data.twitter_url : "https://twitter.com/EroamOfficial",
        vm.linkedIn_url= responses.data.linkedIn_url ? responses.data.linkedIn_url : "https://www.linkedin.com/company/eroam-pty-ltd/mycompany/",
        vm.pinterest_url= responses.data.pinterest_url ? responses.data.pinterest_url : "https://in.pinterest.com/",
        vm.google_plus_url= responses.data.google_plus_url ? responses.data.google_plus_url : "https://plus.google.com/",
        vm.youtube_url=responses.data.youtube_url ? responses.data.youtube_url : "https://www.youtube.com/channel/UCWH7aguzDMz1D7D80f86IIw",       
        /*vm.permitted_currency = _.find(currencies, function(obj) {
                                    return _.includes(responses.data.supported_currencies, obj.value)
                                }); */
        vm.permitted_currency = responses.data.supported_currencies;
        vm.google_api_key = Number(responses.data.google_api_key);
        // vm.google_api_key_value = responses.data.google_api_key_value
        vm.$store.dispatch("setStep2Value", {
          domainName: `${responses.data.domain_name}.eroam.com`,
        });
      }
    } catch (err) {}
  }

  @Watch("primaryColor") changePrimaryColor(newValue: any, oldValue: any) {
    let colors = { primary: this.primaryColor };
    this.setThemeColors(colors);
  }

  @Watch("inputLabelColor") changeinputLabelColor(
    newValue: any,
    oldValue: any
  ) {
    let colors = { inputLabelColor: this.inputLabelColor };
    this.setThemeColors(colors);
  }
  @Watch("inputNavbarColor") changeinputNavbarColor(
    newValue: any,
    oldValue: any
  ) {
    let colors = { inputNavbarColor: this.inputNavbarColor };
    this.setThemeColors(colors);
  }

  @Watch("fontStyle") changeFontFamily(newValue: any, oldValue: any) {
    this.setFontFamily(this.fontStyle);
  }
  @Watch("bannerHeroUrlDisplay") changeHeroImage(newValue: any, oldValue: any) {
    this.setHeroBackground(this.bannerHeroUrlDisplay);
  }

  setThemeColors(colors: any) {
    let style = document.documentElement.style;
    for (let key of Object.keys(colors)) {
      style.setProperty("--theme-color-" + key, colors[key]);
    }
  }

  setFontFamily(fontFamily: any) {
    let font = document.documentElement.style;
    font.setProperty("--font-family", fontFamily);
    let dropdown = this.$refs.dropdown as BDropdown;
    dropdown.hide(true);
  }

  setHeroBackground(heroImage: any) {
    document.documentElement.style.setProperty(
      "--background-image",
      "url(" + heroImage + ")"
    );
  }

  nextTab() {
    let vm = this;
    this.$validator.validate().then((valid) => {
      if (valid) {        
        //let pCurrency = _.map(vm.permitted_currency, 'value');
        let mCurrency = [];
        _.forEach(vm.permitted_currency, (val) => {
          mCurrency.push({flag:val.flag,text:val.text,value:val.value});
        });
        let requestData = {
          cob_step: 2,
          domain_id: vm.domain_id,
          domain_name: vm.urlName,
          user_id: vm.$store.state.loginInformation.id,
          organisation_id: vm.$store.state.loginInformation.organisation_id,
          client_name: "",
          logo_image: vm.logoUrl,
          favicon_image: vm.faviconUrl,
          browser_page_title: vm.pageTitle,
          font_style: vm.fontStyle,
          primary_colour: vm.primaryColor,
          secondary_colour: vm.secondaryColor,
          disable_colour: vm.disableColor,
          input_label_color: vm.inputLabelColor,
          navigation_colour: vm.inputNavbarColor, // backend had set key as navigation_colour 
          currency: vm.currency,
          language: vm.language,
          FX_currency_buffer : vm.FX_currency_buffer,
          timezone: vm.timezone,
          background_image: vm.bannerHeroUrl,
          licensee_type: vm.licenseeType,
          travel_shop: vm.travelDashboardValue,
          site_type: vm.siteType == null ? "" : vm.siteType,
          search: vm.search == null ? true : vm.search,
          products: vm.products,
          template_id: vm.licenseeType != 'office' ? parseInt(vm.template) : null,
          page_id: vm.pageId == null ? "" : vm.pageId,
          banner_background:
            vm.bannerBackground == null ? "" : vm.bannerBackground,
          banner_value:
            vm.bannerBackground == "color" ? vm.bannerBackgroundColor : "",
          banner_background_image:
            vm.bannerBackground == "image" ? vm.bannerImage : "",
          background_alt_value: vm.backgroundAltValue,
          widget: vm.widget,
          meta_title: vm.metaTitle == null ? "" : vm.metaTitle,
          meta_description:
            vm.metaDescription == null ? "" : vm.metaDescription,
          meta_keywords: vm.metaKeyWords,
          about_us_text: vm.about_us_text,
          facebook_url: vm.facebook_url,
          twitter_url: vm.twitter_url,
          linkedIn_url: vm.linkedIn_url,
          pinterest_url: vm.pinterest_url,
          google_plus_url: vm.google_plus_url,
          youtube_url: vm.youtube_url,
          own_domain_name: vm.own_domain_name,
          supported_currencies: mCurrency,
          google_api_key: vm.google_api_key,
          // google_api_places_key_value: vm.google_api_places_key_value,
        };
        vm.$store.dispatch("SetLoading", 1);
        ApiRequest.savebusinessInformation(requestData)
          .then(function (res) {
            vm.domainNameAllocated = false;
            if (res.data.code == 200) {
              vm.addFxInfomation(res.data.data.domain_id)
              vm.domainNameAllocated =
                vm.urlName != "" && vm.urlName != null ? true : false;
              vm.$store.dispatch("SetLoading", 0);
              vm.$store.dispatch("setStep2Value", {
                domainName: `${vm.urlName}.eroam.com`,
              });
              vm.$emit("nextTabData", 2);
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            } else {
              vm.$store.dispatch("SetLoading", 0);
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 2000,
              });
            }
          })
          .catch(function (error) {
            vm.$store.dispatch("SetLoading", 0);
            vm.$toasted.show("Something went wrong.", {
              theme: "bubble",
              position: "top-right",
              duration: 2000,
            });
          });
      }
    });
  }

  prevTab() {
    this.$emit("prevTabData", 2);
  }

  metaKeyword: any = "";
  metaKeyWords: any = [];
  metaTitle = "";
  metaDescription = "";
  addTag() {
    if (this.metaKeyword.length) {
      this.metaKeyWords.push(this.metaKeyword);
    }
    this.metaKeyword = "";
  }

  removeKeyword() {}

  TemplatePreviewImages: any = [
    // {
    //     largeImgSec:'https://static-graphics.eroam.com/eRoam/onboarding/template2.jpg',
    //     ThumbsImgSec:'https://static-graphics.eroam.com/eRoam/onboarding/template4-thumb.jpg'
    // },
    {
      largeImgSec:"https://static-graphics.eroam.com/eRoam/onboarding/template4.jpg",
      ThumbsImgSec:"https://static-graphics.eroam.com/eRoam/onboarding/template2-thumb.jpg",
      title:'Template A',
    },
  ];
  domainApi(){
    let vm = this;
    let domaindata = {domain :vm.urlName};
      ApiRequest.getdomainApi(domaindata)
        .then((res) => {
              vm.$toasted.show(res.data.message, {
                theme: "bubble",
                position: "top-right",
                duration: 4000,
              });
        })
        .catch((error) => {}); 
  }
  swiperSliderOption:any;
  swiperOptionThumbs:any;

  created(){
    this.swiperSliderOption = {
      loopedSlides: 5,
      slidesPerView: 1,
      autoHeight: true,
      breakpoints: {
        1024: {
          allowTouchMove: false,
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 1,
        },
        767: {
          slidesPerView: 1,
        },
        320: {
          slidesPerView: 1,
        },
      },
    };

    this.swiperOptionThumbs = {
      loopedSlides: 5,
      spaceBetween: 30,
      centeredSlides: true,
      slidesPerView: "auto",
      touchRatio: 0.2,
      slideToClickedSlide: true,
      breakpoints: {
        1280: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 3,
        },
        767: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: ".slide-next",
        prevEl: ".slide-prev",
      },
    };
  }
  previewTemplateModal() {
    this.$bvModal.show("previewTemplate");
    this.$nextTick(() => {
      const swiperTop = (this.$refs.swiperTopSlider as any).$swiper;
      const swiperThumbs = (this.$refs.swiperThumbnails as any).$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  }

}
