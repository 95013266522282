export const French: any = {
   creatingFor: 'Créer un itinéraire pour',
   creatingNew: 'Créer un nouvel itinéraire pour le client',
   searchTitleItinerary: 'Itinéraire',
   searchTitleFlights: 'Vols & Trains',
   searchTitleHotel: 'Hotels & BnB\'s',
   searchTitleTours: 'Tours & Tickets',
   searchTitlePackages: 'Paquets',
   searchTitleRentalcars: 'Voitures de location et camping-cars',
   searchTitleTransfers: 'Transferts',
   searchTitleCruises: 'Croisières',
   searchTitleEvents: 'Événements',
   searchTitleInsurance: 'Assurance',
   searchInstructions: 'Ajoutez votre lieu de départ puis sélectionnez \'Rechercher\'. Ajoutez les locations supplémentaires à l\'étape suivante pendant la confirmation de l\'itinéraire',
   searchAddPlaces: 'Lieu de départ',
   searcPlaceholder: 'Locations',
   searchError: '* Veuillez sélectionner deux lieux',
   searchBlurb: 'Créez votre itinéraire. Ajoutez chacun des lieux que vous souhaitez visiter en tapant votre destination et cliquez sur \'Entrée\' pour les ajouter',
   consultantProfile: 'Profil du consultant',
   consultantSearchCust: 'Clients',
   Searchdata: 'Rechercher',
   consultantSearchBlurb: 'Trouvez les réservations d\'un client',
   consultantSearchCustButton: 'Rechercher des clients',
   consultantSearchCont: 'Recherche de contact',
   consultantQuick: 'Accès rapide',
   consultantActive: 'Réservations',
   ActiveData: 'Actif',
   consultantActiveBlurb: 'Voir la liste de vos réservations actives',
   consultantActiveButton: 'Liste des réservations actives',
   consultantPending: 'Réservations',
   PendingData: 'En attente',
   consultantPendingBlurb: 'Voir la liste de vos réservations en attente\'',
   consultantPendingButton: 'Affichage des réservations en attente',
   consultantItineraries: 'Itinéraires actifs',
   consultantCustomers: 'Clients',
   consultantAccount: 'Résumé du compte',
   consultantNotices: 'Notifications et avis',
   consultantTaskList: 'Liste de tâches',
   consultantTaskYours: 'Vos tâches',
   consultantTaskNotFound: 'Tâche non trouvée',
   consultantTaskConfirmCompleteMsg: 'Êtes-vous sûr de vouloir terminer les tâches marquées comme étant terminées ?',
   consultantCustName: 'Nom du client',
   consultantActiveAgency: 'Affichage par agence des réservations actives',
   consultantPendingAgency: 'Affichage par agence des réservations en attente',
   consultantTaskCustName: 'Nom du client',
   consultantTaskBookingId: 'Numero de réservation',
   consultantTaskNote: 'Notes',
   consultantTaskDate: 'Selectionner la date',
   consultantTaskAddButton: 'Ajouter une tâche',
   consultantTaskEditButton: 'Modifier une tâche',
   consultantTaskCompleted: 'Terminé',
   consultantTaskactions: 'Actions',
   consultantTasksnoozed: 'en attente',
   consultantTasktasks: 'Tâches',
   consultantTasksNone: 'On dirait que vous êtes à jour bien fait',
   consultantMidOffice: 'Bureau central',
   consultantMidOfficeUpload: 'Télécharger des données vers le bureau central',
   sidebarHeading: 'Confirmer l\'itinéraire',
   siderbarInstructions: 'Ajoutez tous les lieux d\'intérêt et placer les dans l\'ordre souhaité. Sélectionnez une date de départ et une durée de séjour dans chaque lieu. Si vous optez pour l\'inventaire présélectionné affinez les résultats via les options d\'inventaire pour chaque lieu',
   sidebarOptionDeparture: 'Départ et voyageurs',
   sidebarDepDate: 'Date de départ',
   sidebarDepSelect: 'Sélectionnez la date de départ du premier lieu',
   sidebarDepError: 'Erreur veuillez sélectionner une date de départ',
   travellersNumber: 'Nombre de voyageurs',
   siderbarnewInstructions: 'Ajoutez tous les lieux d\'intérêt et définissez-les dans l\'ordre souhaité. Sélectionnez une date de départ et la durée du séjour dans chaque endroit. Siinventaire présélectionné affinez les résultats via les options d\'inventaire pour chaque emplacement.',
   sidebarnewOptionDeparture: 'Départ et voyageurs',
   depDate: 'Date de départ',
   sidebarnewDepSelect: 'Sélectionnez la date de départ du premier lieu',
   sidebarnewDepError: 'Erreur veuillez sélectionner une date de départ',
   sidebarTravellersNumber: 'Nombre de voyageurs',
   Rooms: 'Chambre | Chambres',
   adults: 'Adultes',
   children: 'Enfants',
   childAgeLimits: 'Ages (0-17)',
   Infants: 'Nourrissons',
   infantAgesLimit: 'Ages (1 et moins)',
   childAges: 'Quel âge ont les enfants avec lesquels vous voyagez (au moment du départ) ?',
   ageOfChild: 'Âge de l\'enfant',
   infantConfirm: 'Est un nourrisson de moins d\'un an (au moment du départ)',
   infantLengthPlural: 'Les nourrissons sont-ils',
   infantLengthSingular: 'Est le nourrisson',
   infantLengthEnd: 'moins d\'un an (au moment du départ)',
   infantLapStart: 'Est-ce que le',
   infantLapPlural: 'nourrissons',
   infantLapSingular: 'nourrisson',
   infantLapEnd: 'voyager sur les genoux des adultes',
   infantsLap: 'Les bébés voyageront-ils sur les genoux des adultes ?',
   sidebarnewInventoryOps: 'Options d\'inventaire et de localisation',
   sidebarViewTours: 'Voir les visites de groupes',
   sidebarnewRouteOptions: 'Veuillez sélectionner les options que vous souhaitez inclure dans cet itinéraire',
   siderbarnewAutoPopLoc: 'Auto-alimenter avec les lieux de tendance',
   siderbarnewAutoPopInv: 'Remplir automatiquement l\'inventaire en utilisant les préférences du client',
   arrivalDate: 'Date d\'arrivée',
   confirmRouteButton: 'Confirmer l\'itinéraire',
   redirectInventory: 'Inventaire des itinéraires',
   sidebarInventoryAlert: 'Ajoutez et supprimez l\'inventaire de voyage à l\'itinéraire sur la droite. </p> Veuillez noter: \'L\'ajout d\'un circuit de plusieurs jours verrouillera les dates des lieux précédents',
   redirectActivities: 'Choisissez les activités',
   sidebarActivitiesAlert: 'Gérer l\'inventaire dans l\'itinéraire en sélectionnant le segment à gauche. Pour ajouter supprimer ou réorganiser des lieux sélectionnez le bouton \'Voir la carte / Itinéraire',
   redirectHotelList: 'Choisissez l\'hôtel',
   sidebarHotelListAlert: 'Choisissez un nouvel hôtel dans la liste. </p>Vous êtes actuellement en tête de liste des caractéristiques de l\'hôtel sélectionné',
   redirectHotel: 'Choisissez votre chambre',
   sidebarHotelAlert: 'Choisissez votre chambre pour ajouter cet hôtel à l\'itinéraire',
   redirectToursList: 'Choisissez un circuit',
   sidebarToursListAlert: 'Choisissez un tour dans la liste. </p>Cliquez sur un circuit pour obtenir des informations plus détaillées connaître les prix et visualiser les départs',
   redirectTour: 'Choisissez un départ',
   sidebarTourAlert: 'Faites défiler la page vers le bas pour trouver une liste de dates de départ avec leur prix.</p> Ajoutez ce circuit à votre itinéraire ou réservez directement le circuit',
   redirectTransport: 'Choisissez le transport',
   sidebarTransportAlert: '',
   checkoutAlert: 'Entrez les données du passager pour compléter la réservation',
   checkout: 'départ de l\'hôtel',
   redirectPreview: 'Aperçu de l\'itinéraire',
   redirectPreviewPDF: 'Afficher PDF',
   sidebarnewInventoryOptions: 'Options d\'inventaire',
   errorMsg: 'Affichage des erreurs ici',
   remove: 'Supprimer',
   sidebarnewInclusiveTourButton: 'Visite de groupe',
   sidebanewNotes: 'Ajouter ce lieu à l\'itinéraire',
   sidebarnewReturnTripOption: 'Option de voyage aller-retour',
   sidebarnewRoundTripForm: 'Veuillez sélectionner une origine et un retour. Si vous souhaitez inclure les vols de retour',
   to: 'Vers',
   night: 'Nuits',
   tourDuration: 'Durée',
   contact: 'Contact',
   checkinDate: 'Date d\'arrivée',
   checkoutDate: 'Date de départ',
   supplierReference: 'Fournisseur',
   propertyReference: 'Référence de réservation',
   transport: 'Transport',
   service: 'Service',
   inventoryTravelClass: 'Classe de voyage',
   departureLocation: 'Lieu de départ',
   arrivalAirport: 'Lieu d\'arrivée',
   status: 'Statut',
   departuretime: 'Heure de départ',
   hotels: 'Hôtels',
   hotelPricePerNight: 'Prix par nuit',
   hotelPropertyRating: 'Evaluation de l\'établissement',
   searchbyPropertyName: 'Recherche par nom d\'un établissement',
   search: 'Recherche',
   refundtype: 'Type de remboursement',
   days: 'Jours',
   location: 'Lieu',
   pernight: 'par nuit',
   pendingItineraries: 'Itinéraires en cours',
   hotelownArrangementPropertyName: 'Nom de l\'établissement *',
   hotelownArrangementPropertyNotes: 'Notes de établissement *',
   validationMsg: 'Ce champ est obligatoire.',
   emailValidationMsg: 'Le champ de l\'adresse électronique doit être un courriel valide',
   contactMinValidationMsg: 'Le champ du numéro de contact doit comporter au moins 10 caractères',
   contactMaxValidationMsg: 'Le champ du numéro de contact doit comporter 11 caractères',
   ownArrangementImage: 'Image',
   ownArrangementHotelimage: 'Image de l\'hôtel',
   ownArrangementImgClick: 'Cliquez ou glissez l\'image de l\'hôtel ici pour la télécharger',
   ownArrangementFileTypeMsg: '(Type de fichier supporté .jpeg .jpg .png)',
   hotelownArrangementPropertyaddress: 'Adresse de l\'établissement: \'*',
   hotelownArrangementPropertyContact: 'Numéro de téléphone de contact de l\'établissement *',
   hotelownArrangementPropertyemail: 'Courriel de l\'établissement *',
   hotelownArrangementDurationDetails: 'Détails de la durée',
   hotelownArrangementPropertyCheckindate: 'Date d\'arrivée *',
   hotelownArrangementPropertyCheckintime: 'Date de départ *',
   hotelownArrangementPropertyCheckouttime: 'Heure de arrivée',
   hotelownArrangementPropertyCheckoutdate: 'Heure de départ *',
   ownArrangementPricingDetails: 'Détails des prix',
   ownArrangementCurrency: 'Dévise *',
   hotelownArrangementCurrency: 'Monnaie d\'achat *',
   hotelownArrangementPropertyPrice: 'Prix de vente *',
   hotelownArrangementPropertybookingref: 'Numéro de réservation *',
   ownArrangementPurchasingdetails: 'Détails des achats',
   ownArrangementPurchasePrice: 'Prix d\'achat *',
   hotelownArrangementPropertySupplier: 'Fournisseur *',
   hotelownArrangementPropertyInvoice: 'Inclure l\'hébergement sur mesure dans la facture finale',
   hotelownArrangementPropertyRefund: 'Ce hébergement sur mesure est remboursable',
   cancelButton: 'Annuler',
   addtoitinerary: 'Ajouter à l\'itinéraire',
   ownArrangementBasicDetails: 'Détails de base sur l\'hôtel',
   activityownArrangementActivityname: 'Nom de l\'établissement*',
   activityownArrangementAddress: 'Adresse *',
   activityownArrangementPhnno: 'Numéro de téléphone *',
   activityownArrangementEmail: 'Email *',
   activityownArrangementWeburl: 'URL du site web',
   activityownArrangementImage: 'Image',
   ownArrangementActivityImgClick: 'Cliquez ou faites glisser l\'image d\'activité ici pour la télécharger',
   activityownArrangementActivityImage: 'Image de l\'activité',
   activityownArrangementSellingPrice: 'Prix de vente *',
   activityownArrangementSupplierReference: 'Fournisseur',
   activityownArrangementPurchaseCurrency: 'Monnaie d\'achat *',
   activityownArrangementPropertyReference: 'Numéro de réservation *',
   ownArrangementComments: 'Commentaires',
   activityownArrangementComments: 'Commentaires',
   operator: 'Opérateur',
   ownArrangementTravelClass: 'Classe',
   ownArrngementArrivalTime: 'Heure d\'arrivée',
   ownArrangementAirlineReference: 'Référence de la compagnie aérienne',
   ownArrangementGdsReference: 'Référence GDS',
   downloadPDF: 'Télécharger le PDF',
   expandAll: 'Tout développer',
   collapseAll: 'Tout effacer',
   showMore: 'en savoir plus',
   showLess: 'montrer moins',
   accommodation: 'Accommodation',
   name: 'Nom',
   checkIn: 'Enregistrement',
   checkOut: 'Départ',
   checkOutDash: 'Départ',
   price: 'Prix',
   PerNight: 'Par nuit',
   roomDetails: 'Détails de la chambre',
   roomType: 'Type de chambre',
   pendingBookingNights: 'Nuits',
   nonRefundable: 'Non remboursable',
   refundable: 'Remboursable',
   ownArrangement: 'propre arrangement',
   toursActivities: 'Tours / Activités',
   viewitenaryActivityname: 'Nom de l\'activité',
   viewitenaryAddress: 'Adresse',
   viewitenaryPhone: 'Téléphone',
   viewitenarySellingPice: 'Prix de vente',
   email: 'Email',
   date: 'Date',
   duration: 'Durée',
   code: 'Code',
   startDate: 'Date de début',
   finishDate: 'Date de fin',
   endDate: 'Date de fin',
   aud: 'AUD',
   view: 'Vue',
   noDataFound: 'Aucun enregistrement trouvé',
   notProvided: 'Non fourni',
   pendingBookingPage: 'X',
   pendingBookingCustomerName: 'Nom du client',
   pendingBookingDepdate: 'Date de départ',
   pendingBookingCreatedOn: 'créé le',
   travellers: 'Travellers',
   pendingBookingTotalPrice: 'Prix total',
   ownArrangementContactDetails: 'Détails de contact',
   sortby: 'Trier par :',
   titleOfActivity: 'Titre',
   activityCode: 'Code d\'activité :',
   viator: 'Viator',
   from: 'De',
   perPerson: 'par personne',
   category: 'Catégorie',
   sidebarActivityName: 'Recherche par nom d\'activité',
   readmore: 'Lire la suite',
   hotelPickupIncluded: 'Transfert à l\'hôtel inclus:',
   activityDetaildeparturePoint: 'Point de départ :',
   overview: 'Vue d\'ensemble',
   importantInfo: 'Information importante',
   details: 'Détails',
   activityDetailInclusions: 'Inclusions',
   activityDetailExclusions: 'Exclusions',
   activityDetailAdditionalinfo: 'Informations complémentaires',
   activityDetailVoucherinfo: 'Bons d\'information',
   activityDetailLocalOperatorInformation: 'Informations sur les opérateurs locaux',
   activityDetailLocalOperatorInformationlope: 'Les informations complètes sur l\'opérateur y compris les numéros de téléphone locaux à votre destination figurent sur votre bon de confirmation. Nos chefs de produits sélectionnent uniquement les opérateurs les plus expérimentés et les plus fiables pour chaque destination ce qui vous évite de devoir deviner et vous assure une tranquillité d\'esprit',
   activityDetailCancellationPolicy: 'Politique d\'annulation',
   activityDetailScheduleAndPricing: 'Calendrier et prix',
   activityDetailScheduleAndPricingContent: 'Nos prix sont constamment mis à jour afin de vous garantir le prix le plus bas possible - nous le garantissons à 100%.',
   activityDetailBookActivity: '',
   activityDetailDeparts: 'Départs',
   activityDetailPickup: 'Ramassage :',
   activityDetailCheckedPickup: 'J\'ai besoin qu\'on vienne me chercher',
   activityDetailaddActivity: 'Ajouter une activité',
   activityDetailSpecialRequirements: 'Exigences particulières',
   activityDetailSelectLocation: 'Sélectionner un lieu',
   activityDetailEnterPickupLocation: 'Indiquer le lieu de ramassage *',
   noRecordFound: 'Aucun enregistrement trouvé',
   sidebarTransportCustomerPricing: 'Prix pour les clients',
   sidebarTransportCustomerPricingContent: 'Ventilation des prix des itinéraires y compris le coût total pour le client et par PAX',
   sidebarTransportHeading: 'AUD 4562',
   sidebarTransportContent: 'Coût total AUD taxes inclus',
   sidebarTransportPricePerPaxAudincTax: 'Prix par PAX AUD taxe comprise',
   sidebarTransportCostPerDay: 'Coût par jour :',
   sidebarTransportCost: '458.00',
   sidebarTransportTotalCost: 'Coût total:',
   sidebarTransportTotal: '785 dollars australiens',
   sidebarTransportAgencyCost: 'Coût de l\'agence',
   sidebarTransportOne: 'Un',
   sidebarTransportTwo: 'Deux',
   sidebarTransportThree: 'Trois',
   sidebarTransportFilters: 'Transport Filters',
   sidebarTransportType: 'Type de transport',
   return: 'retour',
   transportOnlyRates: 'Désactivez la vue de l\'agent pour afficher les tarifs de détail pour les clients',
   transportOnlySaveQuotation: 'Sauvegarder le devis',
   transportOnlyBookItinerary: 'Itinéraire du livre',
   transportOnlyCustomerPricing: 'Prix pour les clients',
   transportOnlyAgencyCost: 'Coût de l\'agence',
   transportOnlyFilters: 'Filtres',
   transportOnlyDepartureLocation: 'Lieu de départ',
   transportOnlyDestinationLocation: 'Lieu de destination',
   transportOnlyReturnDate: 'Date de retour',
   transportOnlyParistoRome: 'Paris à Rome',
   transportOnlyFilterContent: 'Lundi 1er avril 2019',
   change: 'Changement',
   transportOnlyvueling: 'Vueling',
   nonStop: 'Sans arrêt',
   departs: 'Départ',
   transportOnlyParisOrlyAirport: 'Aéroport de Paris Orly (ORY)',
   transportOnlyArrive: 'Arrive',
   transportOnlyFco: 'Gare ferroviaire Rome - Roma Tiburtina (FCO)',
   transportOnlyKiwi: 'Kiwi.com',
   transportOnlyMystifly: 'Mystifly',
   transportOnlySabre: 'Sabre',
   transportOnlyEconomy: 'Économie',
   transportOnlySelect: 'Select',
   transportOnlyRouteDetails: 'Détails de l\'itinéraire',
   transportOnlyCalendar: 'Lundi 1er avril 2019',
   transportOnlyFlight: 'Vol',
   transportOnlyShowDetails: 'Afficher les détails',
   transportOnlyParis: 'Paris - Gare De Lyon',
   transportOnlyMilan: 'Milan – Gare Centrale',
   transportOnlyBaggageDetails: 'Détails des bagages',
   transportOnlyPersonalBaggage: 'Bagages personnels',
   transportOnlyFree: 'Gratuit',
   transportOnlyCheckedBaggage: 'Bagages enregistrés',
   transportOnlyAddCheckedBaggage: 'Ajouter un bagage enregistré',
   transportOnlyAddCabinBaggage: 'Ajouter un bagage de cabine',
   transportOnlyInFlight: 'Compléments en vol',
   transportOnlyPassengerPricingDetails: 'détails sur les passagers et les prix',
   transportOnlyPassenger: 'Passager',
   transportOnlyonexAdult: '1 x Adulte',
   transportOnlyBaseFare: 'Tarif de base',
   transportOnlyBaggage: 'Bagages',
   transportOnlyFeesAndTaxes: 'Autres frais/taxes',
   transportOnlyTermsConditions: 'Conditions générales',
   transportOnlyNetRate: 'Taux net',
   transportOnlyCommision: 'Commission',
   checkinTime: 'Heure de contrôle',
   checkoutTime: 'Heure de départ',
   searchCustomerName: 'Recherche par nom de client',
   confirmroute: 'Confirmer l\'itinéraire',
   logout: 'Déconnexion',
   searchbypropertyname: 'Recherche par nom de établissement',
   hotelDetailshotelRoom: '(1 chambre)',
   hotelDetailRoomsRates: 'Chambres et tarifs',
   hotelDetailAmenities: 'Équipements de l\'hôtel',
   hotelDetailCheckInInstructions: 'Instructions pour l\'enregistrement',
   hotelDetailsFeesOptionalExtras: 'Frais / Extras optionnels',
   hotelDetailValueAddedServices: 'Services à valeur ajoutée',
   hotelDetailCheckin: 'Arrivée',
   hotelDetailhotelCheckout: 'Départ:',
   hotelDetailBedPreferencese: 'Choix de litrie :',
   hotelDetailViewCancellationPolicy: 'Voir la politique d\'annulation',
   hotelDetailRoomDetailAmenities: 'Détail des chambres',
   hotelDetailRoomAmenities: 'Équipements des chambres',
   hotelDetailGuests: 'Invités:',
   hotelDetailSubtotal: 'Sous-total:',
   hotelTotal: 'Total',
   hotelDetailTaxRecoveryServiceFee: 'Récupération fiscale et frais de service :',
   hotelDetailSpecialCheckInInstructions: 'Instructions spéciales pour l\'enregistrement',
   hotelDetailadditionalInformation: 'Informations complémentaires',
   headerLogin: 'Connexion',
   headerLogout: 'Déconnexion',
   hide: 'Cacher',
   depart: 'Départ',
   arrive: 'Arrive',
   flightNumber: 'Numéro de vol',
   routeDetails: 'Détails de l\'itinéraire',
   wifi: 'WIFI',
   included: 'INCLUS',
   airCondition: 'Climatisation',
   passengerPricingDetails: 'Détails sur les passagers et les prix',
   supplier: 'Fournisseur',
   passenger: 'Passenger',
   passengers: 'Passagers',
   flightMystifly: 'Mystifly',
   flight2Adult: '2 x Adulte',
   baggage: 'Bagages',
   otherFeesTaxes: 'Autres frais / taxes',
   free: 'GRATUIT',
   netRate: 'Taux net',
   select: 'Sélectionner',
   floatZero: '0.00',
   doubleZerofloat: '00.00',
   tv: 'TV',
   flightEconomy: 'Economie',
   viewMore: 'En savoir plus',
   flightOnBoardAddOns: 'Compléments à bord',
   loginSignIn: 'Se connecter',
   customerNameOrEmail: 'Nom ou adresse électronique du client',
   searchBy: 'Rechercher par',
   firstName: 'Prénom',
   lastName: 'Nom de famille',
   gender: 'Genre',
   phone: 'Téléphone',
   submitButton: 'Soumettre',
   your: 'Votre',
   createdOn: 'Créé le',
   nights: 'Nuits',
   totalPrice: 'Prix total',
   emailUserName: 'Email ou nom d\'utilisateur',
   userName: 'Nom d\'utilisateur',
   password: 'Mot de passe',
   enterPassword: 'Entrez votre mot de passe',
   enterCustomerName: 'Entrez le nom du client',
   taskEnterBookingId: 'Saisir l\'identifiant de réservation',
   commission: 'Commission',
   costPerDay: 'Coût par jour',
   pricePerPAX: 'Prix par PAX',
   totalCost: 'Coût total',
   netCost: 'Coût net',
   includingTax: 'y compris les taxes',
   priceSummary: 'Résumé des prix',
   ConsultantSidebarItineraryCustomerCostByPAXMsg: 'Ventilation des prix de l\'Itinéraire incluant le coût total pour le client et par PAX',
   sidebarHeadingPreviewItinerary: 'Voir l\'itinéraire',
   sidebarHeadingSaveQuotation: 'Sauvegarder la citation',
   sidebarHeadingBookItinerary: 'Commander itinéraire',
   sidebarHeadingTurnAgentViewDisplayRateForCustomerMsg: 'Désactiver la vue de l\'agent pour afficher les tarifs de détail pour le client',
   agentView: 'Vue de l\'agent',
   customerView: 'Vue du client',
   save: 'Sauvegarder',
   travelInsurance: 'Assurance voyage',
   no: 'Non',
   addTravel: 'Ajouter un voyage',
   plus: 'Plus',
   basic: 'de base',
   inventoryMedicalExpenseTripCancellation: 'Frais médicaux annulation de voyage',
   inventoryAssistanceServices: 'Services d\'assistance',
   inventoryLostBaggageMore: 'Bagages perdus + autres',
   audWithFloating: 'AUD',
   inventoryComparsionAndTerms: '00.00\' Comparaison et conditions',
   customerCare: 'Service clients',
   premium: 'Premium',
   inventoryExploreBenefitsOfPayingPremiumMsg: 'Explorez les avantages de payer pour Premium',
   previous: 'Précédent',
   continue: 'Continuer',
   saveAndContinue: 'Sauvegarder et continuer',
   BacktoInventory: 'Retour à l\'inventaire',
   AcceptandContinue: 'Accepter et continuer',
   busBud: 'BusBud',
   edit: 'Editer',
   delete: 'Supprimer',
   checked: 'Vérifié',
   checkoutBusListINBusAddonMsg: 'Compléments dans le bus',
   BaseFarePerPax: 'Tarif de base (par personne)',
   onexAdult: '1 x Adulte',
   oneRoom: 'Room 1',
   CancellationPolicy: 'Politique d\'annulation',
   expedia: 'Expedia',
   roomSleep: 'Dormir dans une chambre',
   checkOutActivityListviator: 'Viator',
   checkOutActivityDetails: 'Détails de l\'activité',
   option: 'Option',
   groupInclusiveTourOptions: 'Option de voyage en groupe',
   matchCurrentRouteMsg: 'Il y a actuellement 219 circuits de groupe qui correspondent à votre itinéraire actuel',
   errorDisplayHere: 'Erreur affichée ici',
   tourFilters: 'Filtres de visite',
   tailorMadeTours: 'Visites sur mesure',
   tailorMadeOptionsMsg: 'Veuillez sélectionner quelles options souhaitez vous afficher uniquement les options sur mesure',
   tailorMadeToursItineryMsg: 'Afficher uniquement les circuits sur mesure pour les itinéraires',
   MonthOfDeparture: 'Mois du départ',
   selectDate: 'Sélectionnez une date',
   destination: 'Destination',
   tourExperience: 'Expérience de la visite',
   preferredSupplier: 'Fournisseur privilégié',
   style: 'Style',
   size: 'Taille',
   start: 'Départ',
   notAvailbale: 'N/A',
   inclusions: 'Inclusions',
   day: 'Jour',
   meals: 'Repas',
   breakfast: 'Petit déjeuner',
   lunch: 'Déjeuner',
   dinner: 'Dîner',
   tourDetailsNoMealMsg: 'Il n\'y a pas de repas inclus ce jour-là',
   locationsToVisit: 'Lieux à visiter',
   additionalInformation: 'Informations supplémentaires',
   departureNotFound: 'Départs non trouvés',
   additionalInformationNotFound: 'Pas d\'autres informations disponibles',
   departureAndPricing: 'Départs et prix',
   departing: 'Départ',
   finishing: 'Arrivée',
   availability: 'Disponibilité',
   totalFrom: 'Total De',
   perAdult: 'par Adulte',
   perChild: 'par enfant',
   departureGuaranteed: 'départ garanti',
   onSale: 'en vente',
   onRequest: 'sur demande',
   addToRoute: 'Ajouter à la route',
   termsConditions: 'Termes et conditions',
   available: 'Disponible',
   finish: 'Terminer',
   stars: 'Etoiles',
   reviews: 'Revues',
   rating: 'Notation',
   default: 'Default',
   title: 'Titre',
   lengthOfTour: 'Durée de la visite',
   optionsSelected: 'options sélectionnées',
   tourNotFound: 'Tour non trouvé',
   validTo: 'Valable jusqu\'à',
   lodging: 'Logement',
   validFrom: 'Valable à partir de',
   perNight: 'Par nuit',
   sortBy: 'Trier par',
   viewItineraryGITtours: 'GIT visites',
   inventoryAddNewAccommodation: 'Choisissez un autre hôtel',
   inventoryAddNewTransport: 'Ajouter un nouveau transport',
   passengersAvoidBoardingComplicationMsg: 'Utilisez tous les noms (prénom et nom de famille) exactement comme ils figurent dans le passeport/la carte d\'identité pour éviter toute complication à l\'embarquement',
   passengersRequiredFlightSegmentMsg: 'Requis pour le segment de vol international',
   dateOfBirth: 'Date de naissance',
   passengersTourSpecificField: 'Domaine spécifique aux visites',
   passengersTourSegmentMsg: 'Requis pour le segment \'X\' du circuit',
   visaRequriements: 'Exigences en matière de visa',
   passengersreferencePurposeMsg: 'Un visa ou un visa de transit aéroportuaire peut être nécessaire pour voyager même si le voyage ne s\'arrête pas dans le pays d\'escale. Ces exigences sont uniquement à titre de référence. Contactez l\'ambassade ou le ministère des affaires étrangères pour plus d\'informations',
   passengersDeniedDestinationIssueMsg: 'eRoam n\'est pas responsable des passagers qui se voient refuser l\'embarquement ou l\'entrée dans une destination en raison de problèmes de visa',
   givenNames: 'Prénoms',
   passengerNames: 'Prénoms *',
   surname: 'Surnoms',
   passengersurname: 'Surnoms *',
   familyname: 'Nom de famille',
   PassengerGender: 'Sexe *',
   paymenttotalprice: 'Prix total',
   paymentnewprice: 'Nouveau prix',
   emailAddress: 'Adresse de courrier électronique',
   passengeremailAddress: 'Adresse de courrier électronique*',
   contactNumber: 'Numéro de contact',
   passengercontactNumber: 'Numéro de contact *',
   passengersIndividualInventorySpecificationMsg: 'Les champs ci-dessous sont obligatoires en raison des spécifications individuelles de l\'inventaire telles que les vols intérieurs les exigences des circuits etc',
   Nationality: 'Nationalité',
   PassengerNationality: 'Nationalité *',
   confirmBooking: 'Confirmer la réservation',
   receiptNumber: 'Numéro du reçu',
   paymentAddReceiptNumberMsg: 'Ajouter le numéro du reçu du paiement traité',
   PaymentDeniedDestinationIssueMsg: '`` A un visa ou un visa de transit aéroportuaire peut être nécessaire pour voyager. Les demandes trouvées ici sont uniquement à des fins de réfraction. Contactez l\'ambassade ou votre ministère des Affaires étrangères pour plus d\'informations. eRoam n\'est pas responsable des passagers qui se voient refuser l\'embarquement ou la saisie d\'une destination en raison de problèmes de visa. \'',
   amount: 'Montant: Montant *',
   paymentEnterAmountMsg: 'Saisissez un montant qui est en train d\'être effectué',
   paymentEnterTypeMsg: 'Saisissez le type de paiement',
   paymentType: 'Type de paiement: Mode de paiement *',
   paymentOnceSubmittedMsg: 'Une fois les détails de paiement soumis la réservation sera confirmée',
   payment: 'Paiement',
   printPDF: 'Print PDF',
   custom: 'personnalisé',
   additionalNotes: 'Notes supplémentaires',
   list: 'Liste',
   checkinDash: 'Arrivée',
   detailedSummary: 'Résumé détaillé',
   dailySummary: 'Résumé quotidien',
   tripSummary: 'Résumé voyage',
   transportName: 'Nom du transport',
   activities: 'Activités',
   basicTransportDetails: 'Détails de base sur le transport',
   transportOwnArrangementRefundMsg: 'L\'organisation du transport par vous-même donne lieu à un remboursement',
   transportOwnArrangementPricingMsg: 'Inclure ce prix de transport dans la facture finale',
   salePrice: 'Prix de vente',
   transportOwnArrangementAddLegtoArrangement: 'Ajouter une étape au voyage',
   arrivalLocation: 'Lieu d\'arrivée',
   transportOwnArrangementAddNewLeg: 'Ajouter une étape',
   arrival: 'Arrivée',
   departure: 'Départ',
   fromTo: 'De – A',
   selectThePlaceToReturn: 'Sélectionner le lieu de retour',
   selectAReturn: 'Selectionner un retour',
   returnToAnotherCity: 'Retour dans une autre ville',
   selectPlaceOrigin: 'Sélectionner le lieu d\'origine',
   selectOrigin: 'Sélectionner une origine',
   accomodationSummary: 'Accommodation Summary',
   transportSummary: 'Résumé des transports',
   activitySummary: 'Résumé des activités',
   taskListConformDeleteMsg: 'Veuillez confirmer que vous souhaitez supprimer la tâche',
   ConfirmActionTitle: 'Confirmer l\'action',
   passengertitle: 'Titre *',
   hotelListTotalCost: 'Coût total',
   nonstop: 'Sans arrêt',
   selected: 'Sélectionné',
   inventoryAddNewHotels: 'Ajouter de nouveaux hôtels',
   inventoryAddOwnArrangement: 'Ajouter son propre arrangement',
   propertylocation: 'Localisation des biens',
   inventoryAddNewToursTickets: 'Choisissez une autre excursion et des billets',
   inventoryContactPhone: 'Téléphone de contact',
   inventoryContactEmail: 'Courriel de contact',
   flight: 'Vol',
   time: 'Horaire',
   bookingId: 'Numéro de la réservation',
   bookingNote: 'Note de réservation',
   stops: 'Arrêts',
   layOverDuration: 'Durée de l\'escale',
   itineraryPdfImageText: 'Cliquez ou faites glisser l\'image de l\'itinéraire pour la télécharger',
   confirm: 'Confirmer',
   changePassword: 'Changer de mot de passe',
   oldPasswordErrorMsg: 'Entrez l\'ancien mot de passe',
   newPasswordErrorMsg: 'Entrez un nouveau mot de passe',
   confirmPasswordErrorMsg: 'Le mot de passe ne correspond pas',
   oldPassword: 'Ancien mot de passe',
   confirmPassword: 'Confirmer le mot de passe',
   newPassword: 'Nouveau mot de passe',
   visitedLocations: 'Lieux à visiter',
   checkoutFlightListINFlightAddonMsg: 'Compléments de vol',
   checkoutmystifly: 'Mystifly',
   tourDetailstitle: 'Détails du circuit',
   update_total_cost: 'Mettre à jour le coût total',
   updateCostConfirmCompleteMsg: 'Êtes-vous sûr de vouloir continuer avec une commission négative ?',
   confirmButton: 'Confirmer',
   norating: 'Pas de cotation',
   adult: 'Adulte',
   child: 'Enfant',
   PrimaryPassenger: 'Passager principal (Lead Pax)',
   hotelbed: 'Lit d\'hôtel',
   activityownemail: 'Courrier électronique du fournisseur',
   activityownweb: 'Site web',
   activityownphone: 'Téléphone',
   activityownsupplier: 'Fournisseur',
   hotelDetails: 'Détails de l\'hôtel',
   room: 'Chambre',
   ownArrangementActivityBasicDetails: 'Détails de l\'activité de base',
   booking: 'Réservation',
   tour: 'Circuit',
   oopsTheActivity: 'L\'activité',
   oopsTheTour: 'Le Circuit',
   oopsTheFlight: 'Le vol',
   notAvailable: 'n\'est pas disponible en ce moment',
   inventoryAddHotelOwnArrangement: 'Propre arrangement (logement)',
   inventoryAddToursOwnArrangement: 'Propre arrangement ( circuit et billet)',
   inventoryAddReplaceTransport: 'Choisissez un autre transport',
   inventoryAddTransportOwnArrangement: 'Own Arrangement (Transport)',
   noProductSelected: 'Aucun produit sélectionné',
   taskSelectDate: 'Sélectionnez une date',
   taskSelecgender: 'Sélectionner le sexe',
   age: 'Âge',
   relation: 'Relation',
   activityTime: 'Heure *',
   activityDate: 'Date *',
   accountId: 'Numéro de compte',
   nationality: 'Nationalité',
   city: 'Ville',
   address: 'Adresse',
   postcode: 'Code postal',
   region: 'Région',
   ontarioResident: 'Résident de l\'Ontario',
   prepaidGratuities: 'Gratuités prépayées',
   mobilePhone: 'Contact',
   titleBooking: 'Titre',
   genderBooking: 'Genre',
   emailBooking: 'Adresse électronique',
   paymentAmount: 'Montant du paiement',
   checkoutRceiptNumber: 'Numéro de reçu',
   loadPnr: 'Charger le PNR',
   activityownArrangementPropertyInvoice: 'Inclure cette tarification dans la facture finale',
   activityownArrangementPropertyRefund: 'Ceci est remboursable',
   bookingVoucherfileType: '(Types de fichiers pris en charge: \'.jpeg .jpg .png .pdf)',
   bookingVouchermsg: 'Cliquez ici pour télécharger le bon d\'achat',
   carrierName: 'Nom du transporteur',
   departureDateandTime: 'Date et heure de départ',
   arrivalDateandTime: 'Date et heure d\'arrivée',
   airlineReference: 'Référence de la compagnie aérienne',
   pnrNumber: 'Saisir le PNR',
   pnrInstructions: 'Veuillez entrer un numéro de PNR valide pour obtenir l\'itinéraire',
   termsandconditions: 'Termes et conditions',
   total: 'Total',
   baggageAdult: 'Bagage adulte/enfant',
   baggageChild: 'Bagages d\'enfants',
   gitWarningMessage: 'Vous avez sélectionné un tour non valable pour cette ville',
   summeryConfirmCompleteMsg: 'Êtes-vous sûr de vouloir continuer avec une sélection de tournée non valable ?',
   tourType: 'Tour Type',
   SearchPlace: 'Lieu de recherche | Rechercher des lieux',
   SearchCity: 'Rechercher City | Rechercher des villes',
   manager: 'Gestionnaire',
   product: 'Produit',
   finance: 'Finances',
   admin: 'Admin',
   viewProductManager: 'Afficher le gestionnaire de produit',
   viewfinanceManager: 'Afficher le gestionnaire financier',
   viewAdminManager: 'Afficher le Gestionnaire d’administrateurs',
   viewsupplierManager: 'Afficher le gestionnaire de fournisseurs',
   viewmarketingManager: 'Afficher Le Directeur Marketing',
   DaterangeFrom: 'Plage de dates à partir de',
   DaterangeTo: 'Plage de dates à',
   DataType: 'Type de données',
   FileType: 'Type de fichier',
   downloadNow: 'Télécharger maintenant',
   ConsultantName: 'Nom du consultant',
   SupplierName: 'Nom du fournisseur',
   ClientFile: 'Fichier client',
   pay: 'Payer',
   TypeofPayment: 'Type de paiement',
   Login: 'Connectez-vous',
   exportData: 'Données d’exportation',
   roomNumber: 'Chambre',
   storeName: 'Nom du magasin',
   manageUsers: 'Gérer les utilisateurs',
   manageBrands: 'Gérer les marques',
   viewReports: 'Afficher les rapports',
   brand: 'Marque',
   manageLicensee: 'Gérer la licenciée',
   ManageLicensee: 'Licencié de recherche',
   OnboardNewLicensee: 'À bord d’une nouvelle licenciée',
   CreateNewPackage: 'Créer un nouveau package',
   consultantArchived: 'Réservations',
   ArchivedData: 'Archivés',
   consultantArchivedBlurb: 'Afficher la liste de vous Réservations archivées',
   consultantArchivedButton: 'Afficher les réservations archivées',
   consultantArchivedAgency: 'Afficher les réservations d’agences archivées',
   archivedItineraries: 'Itinéraires archivés',
   taskDay: 'Sélectionner le jour',
   action: 'Action',
   mobile: 'Mobile',
   taskSelectDay: 'Sélectionner le jour',
   DateandTime: 'Date et heure',
   addtransport: 'Ajouter le transport',
   addhotels: 'Ajouter des hôtels',
   addactivities: 'Ajouter des activités',
   manageclientpreferences: 'Gérer les préférences des clients',
   Return: 'Retour',
   oneway: 'One Way',
   multycity: 'Ville de Multy',
   flyingfrom: 'Voler à partir de',
   selectclass: 'Sélectionner la classe',
   returndate: 'Date de retour',
   comingsoon: 'À venir',
   thingstodo: 'Choses à faire',
   extendedtouring: 'Tournée prolongée',
   selectinterest: 'Sélectionner l’intérêt',
   searchpnr: 'Rechercher PNR',
   googlesearch: 'Recherche Google',
   package: 'Paquet',
   addcustomer: 'Ajouter un client',
   geoLocationManager: 'Gestionnaire de localisation GEO',
   supplieroperatorManager: 'Fournisseur / Gestionnaire d’opérateur',
   yieldManager: 'Gestionnaire de rendement',
   packageBuilder: 'Générateur de packages',
   plotmaps: 'Cartes de parcelle',
   reporting: 'Rapports',
   CSVDownload: 'CSV Télécharger',
   makePayments: 'Effectuer des paiements',
   packageName: 'Nom du package',
   uploadImages: 'Télécharger des images',
   toursandtickets: 'Tours et billets',
   hotel: 'Hôtel',
   packageOverview: 'Vue d’ensemble du package',
   packageItinerary: 'Itinéraire du forfait',
   Optional: 'Optionnel',
   createpackagefromtour: 'Créer un package à partir de Tour',
   Currency: 'Monnaie',
   companyName: 'Nom de l’entreprise',
   Phone: 'Téléphone (y compris le code de pays)',
   url: 'Url',
   uploadLogo: 'Logo de téléchargement',
   selectProduct: 'Sélectionner le produit',
   hotelSupplier: 'Fournisseur d’hôtel',
   tourSupplier: 'Fournisseur de tour',
   transportSupplier: 'Fournisseur de transport',
   otherSupplier: 'Autre fournisseur',
   selectSettings: 'Sélectionner les paramètres',
   defaultLanguage: 'Langue par défaut',
   defaultCurrency: 'Devise par défaut',
   chooseTemplate: 'Choisir le modèle',
   paymentGateway: 'Passerelle de paiement',
   selectSubscription: 'Sélectionner l’abonnement',
   licenceType: 'Licence Type',
   howmanylicences: 'Combien de permis?',
   addons: 'Modules complémentaires',
   paymentTerms: 'Conditions de paiement',
   licenseenameoremail: 'Nom ou courriel du titulaire de licence',
   createProfilesandClientDetails: 'Créer des profils et gérer les détails du client',
   passportNumber: 'Numéro de passeport',
   passportissuedate: 'Date de délivrance du passeport',
   passportExpiryDate: 'Date d’expiration du passeport',
   passportissuingcountry: 'Pays émetteur de passeports',
   clientContact: 'Client Contact',
   Phonehome: 'Téléphone (maison)',
   Phonework: 'Téléphone (travail)',
   phonemobile: 'Téléphone (mobile)',
   AddressLinOne: 'Adresse (Ligne 1)',
   AddressLineTwo: 'Adresse (ligne deux)',
   StateProvinceRegion: 'État / Province / Région',
   zipPostalCode: 'ZIP / Postal Code',
   country: 'Pays',
   newsletter: 'Newsletter',
   companyABN: 'Société ABN',
   paName: 'Nom pa',
   clientLoyaltyPrograms: 'Programmes de fidélisation des clients',
   srno: 'M. No',
   loyaltyType: 'Type de fidélité',
   providerName: 'Nom du fournisseur',
   membershipNumber: 'Numéro d’adhésion',
   clientPreferences: 'Préférences du client',
   airline: 'Compagnie aérienne',
   airlines: 'Compagnie aérienne(S)',
   travelClass: 'Classe de voyage',
   preferredSeating: 'Sièges préférés',
   mealRequest: 'Demande de repas',
   Hotels: 'Hôtel(s)',
   hotelChain: 'Chaîne d’hôtel',
   Cars: 'Voiture(s)',
   carRental: 'Location de voiture',
   CarType: 'Type de voiture',
   interests: 'Intérêts',
   linkedprofiles: 'Profils liés',
   Preferreddestinations: 'Destinations privilégiées',
   businessPreferences: 'Préférences d’entreprise',
   otherPreferences: 'Autres préférences',
   pendingBookings: 'Réservations en attente',
   archiveBookings: 'Réservations d’archives',
   activeBookings: 'Réservations actives',
   viewdetails: 'Afficher les détails',
   update: 'mettre à jour',
   bookingmanagement: 'Gestion des réservations',
   bookingDashboard: 'Tableau de bord de réservation',
   bookingoverview: 'vue d’ensemble de la réservation',
   Generalbookingdetails: 'Détails généraux de réservation.',
   type: 'Type',
   created: 'Créé',
   segmentoverview: 'Vue d’ensemble du segment',
   totalnumberofsegment: 'Le nombre total de produits/segments, et les segments confirmés exceptionnels.',
   segmentsconfirmedvstotalsegment: 'Segments confirmés par rapport aux segments totaux inclus dans la réservation.',
   amountremainingforclient: 'Montant restant à payer pour le client avant la date d’échéance du solde.',
   outstandingAmount: 'Montant impayé',
   outstanding: 'Exceptionnelle',
   balanceDue: 'Solde dû',
   bookingDetails: 'Détails de la réservation',
   user: 'Utilisateur',
   consultantNotes: 'Consultant Notes',
   historynotfound: 'Histoire introuvable',
   leadpaxdetails: 'Lead Pax Détails',
   additionalpaxdetails: 'Détails supplémentaires de Pax',
   locationdetails: 'Détails de l’emplacement',
   selectedHotels: 'Hôtels sélectionnés',
   confirmed: 'Confirmé',
   snooze: 'Snooze',
   upload: 'Télécharger',
   novoucherfound: 'Aucun bon trouvé',
   Nopaymentsfound: 'Aucun paiement trouvé',
   newvoucher: 'Téléchargez un nouveau bon pour cette réservation',
   allocation: 'Allocation',
   selectedtoursandtickets: 'Tours et billets sélectionnés',
   offeredIn: 'Offert en',
   tofrom: 'À / DE',
   bookingRef: 'Réservation Ref',
   selectedTransport: 'Transport sélectionné',
   segmentmessage: 'En confirmant ce segment, vous reconnaissez que ce produit a été réservé au fournisseur, en dehors de cette application. Pour compléter la confirmation, veuillez compléter le champ ci-dessous.',
   bookingReference: 'Référence de réservation',
   suppliersbookingreference: 'Entrez la référence de réservation du fournisseur',
   confirmsegment: 'Confirmer le segment',
   pickupTime: 'Heure de ramassage',
   totalDuration: 'Durée totale',
   layoverTime: 'Lay over Time',
   ticketType: 'Type de billet',
   class: 'Classe',
   provider: 'Fournisseur',
   viewcustomer: 'Afficher le client',
   costofitinerary: 'Coût total de l’itinéraire',
   paid: 'Payé',
   receiptofallpayments: 'Reçus de tous les paiements à ce jour',
   balanceduetocustomer: 'Solde dû à la collecte auprès du client',
   recordcustomerpayment: 'Enregistrez un paiement client ci-dessous.',
   completePayment: 'Paiement complet',
   cancel: 'Annuler',
   accommodationORtransportation: '* Veuillez sélectionner au moins un hébergement ou transport.',
   preferredPropertyClass: 'Classe de propriété préférée',
   transportation: 'Transport',
   preferredtransporttype: 'Type de transport préféré',
   preferredtransportclass: 'Classe de transport privilégiée',
   agegroup: 'Groupe d’âge',
   interest: 'Intérêt',
   activityinterest: 'Intérêt d’activité',
   applytoalllocations: 'Appliquer à tous les emplacements',
   applythislocation: 'Appliquer cet emplacement',
   apply: 'Appliquer',
   hotelDescription: 'Description de l’hôtel',
   activityDescription: 'Description de l’activité',
   checkoutcancelationdescription: 'Il est un fait établi depuis longtemps qu’un lecteur sera distrait par le contenu lisible d’une page en regardant sa mise en page. Le point d’utiliser Lorem Ipsum est qu’il a une distribution plus ou moins normale des lettres, par opposition à l’utilisation de « a contenu ici, le contenu ic », ce qui en fait ressembler à l’anglais lisible. De nombreux paquets de publication de bureau et éditeurs de pages Web utilisent maintenant Lorem Ipsum comme texte modèle par défaut, et une recherche de « erem ipsu » permettra de découvrir de nombreux sites Web encore à leurs balbutiements. Diverses versions ont évolué au fil des ans, parfois par accident, parfois exprès (humour injecté et autres).',
   OverrideQuotation: 'Enregistrer/Remplacer la citation',
   DuplicateQuotation: 'Citation en double',
   OriginCity: 'Origin City',
   ReturnCity: 'Ville de retour',
   AddTransport: 'Ajouter le transport',
   AddHotels: 'Ajouter des hôtels',
   AddActivities: 'Ajouter des activités',
   ManagePreferences: 'Gérer les préférences des clients',
   searchPNR: 'Rechercher PNR',
   oneWay: 'One Way',
   multiCity: 'Multi City',
};
