
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
// import PassengersAdults from "@/Module/Global/Components/Sidebar/Passengers-adults.vue";
import { applicationName } from "@/plugins/constants";
// import eMap from "@/Module/Global/js/eroam-map.js";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
// import ApiRequest from "@/Module/Global/api/apiService.js";
// import Itinerary from "@/Module/Global/Models/Itinerary";
// import IntializeRepository from "@/Module/Global/Repository/IntializeRepository";
@Component({
  components: {
    // Datepicker,
    // PassengersAdults,
  },
})
export default class FlightMultiCity extends Vue {
  flightClassselect: any = 0;
  flightClass: any = [
    { value: 0, text: "Economy" },
    { value: 1, text: "Premium Economy" },
    { value: 2, text: "Business" },
    { value: 3, text: "First" },
  ];
  isPreferenceModalVisible: boolean = false;
  applicationName: any = applicationName;
  rows: any = [];
  searchValue: any = [];
  searchValueRecord: any = [];
  searchRequest = [];
  departureDates = [];
  searching: boolean = false;

  datePicker = {
    disabledDates: {
      to: new Date(Date.now()),
    },
  };

  // @Watch("$store.state.search.loginUserInformation") changeView(
  //   newValue,
  //   oldValue
  // ) {
  //   if (_.isEmpty(this.$store.state.search.loginUserInformation)) {
  //     this.searchValueRecord = [];
  //   }
  // }

  // addRow() {
  //   this.rows.push({
  //     FlyingFrom: "",
  //     Destination: "",
  //     DepartureDate: "",
  //   });
  // }

  // removeCity(index) {
  //   this.searchRequest.splice(index, 1);
  //   this.departureDates.splice(index, 1);
  // }

  // mounted() {
  //   this.initialSearch();
  // }

  // initialSearch = () => {
  //   let vm = this;
  //   let input = document.getElementById("autocompleteMultiCity");
  //   //console.log(input, "input");

  //   let google = eMap.googleObj();
  //   let autocomplete = new google.maps.places.SearchBox(input, {
  //     types: ["(cities)"],
  //   });
  //   google.maps.event.addListener(autocomplete, "places_changed", function() {
  //     let place = autocomplete.getPlaces();
  //     if (place.length == 0) {
  //       return;
  //     }
  //     let lat = place[0].geometry.location.lat();
  //     let lng = place[0].geometry.location.lng();
  //     let source_northwest_lat = place[0].geometry.viewport.toJSON().north;
  //     let source_northwest_lng = place[0].geometry.viewport.toJSON().east;
  //     let source_southeast_lat = place[0].geometry.viewport.toJSON().south;
  //     let source_southeast_lng = place[0].geometry.viewport.toJSON().west;
  //     let filtered_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("country");
  //     });
  //     let filtered_city_array = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("locality");
  //     });
  //     let filtered_city_array_2 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_2");
  //     });

  //     let filtered_city_array_1 = place[0].address_components.filter(function(
  //       address_component
  //     ) {
  //       return address_component.types.includes("administrative_area_level_1");
  //     });

  //     let country_name = filtered_array.length
  //       ? filtered_array[0].long_name
  //       : "";
  //     // let city_name1 = filtered_city_array.length
  //     //   ? filtered_city_array[0].long_name
  //     //   : filtered_city_array_1.length ? filtered_city_array_1[0].long_name : place[0].name;
  //     let city_name = place[0].name
  //       ? place[0].name
  //       : filtered_city_array.length
  //       ? filtered_city_array[0].long_name
  //       : place[0].name;

  //     let imageUrl = place[0].photos
  //       ? place[0].photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
  //       : "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference";
  //     vm.searchValueRecord.push({
  //       index: vm.searchValueRecord.length,
  //       name: city_name,
  //       country_name: country_name,
  //       status: true,
  //     });
  //     vm.searchValue.push(place);
  //     vm.searchRequest.push({
  //       ...place[0],
  //       country_name: country_name,
  //       small_image: imageUrl,
  //     });
  //     vm.departureDates.push("");
  //     $("#autocompleteMultiCity").val("");
  //   });
  // };

  // search() {
  //   const searchLength = this.searchRequest.length;
  //   let error = false;
  //   this.departureDates.forEach((date, index) => {
  //     if (index > 0 && index < (this.departureDates.length-1) && !error) {
  //       if (!moment(this.departureDates[index]).isAfter(moment(this.departureDates[index-1  ]))) {
  //         this.$toasted.error("Please select departure dates in proper order ", {
  //           theme: "toasted-primary",
  //           position: "top-right",
  //           duration: 2000,
  //         });
  //         error = true;
  //       }
  //     } 
  //   });

  //   if (error) {
  //     return false;
  //   } else {
  //     error = false;
  //   }

  //   if (searchLength < 3) {
  //     this.$toasted.error("Please select atleast 3 cities", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //     return;
  //   }
  //   let invalidDate = false;
  //   for (let i = 0; i < this.departureDates.length - 1; i++) {
  //     if (typeof this.departureDates[i] == "string") {
  //       invalidDate = true;
  //       break;
  //     }
  //   }
  //   if (invalidDate) {
  //     this.$toasted.error("Please enter departure date", {
  //       theme: "toasted-primary",
  //       position: "top-right",
  //       duration: 2000,
  //     });
  //     return;
  //   }
  //   this.searching = true;
  //   this.$store.dispatch(
  //     "setCheckinDate",
  //     moment(this.searchRequest[0].check_in_date).format("YYYY-MM-DD")
  //   );
  //   this.$store.dispatch("SetSearchType", "transport");
  //   ApiRequest.getGooglePlaceCityInfo(this.searchRequest)
  //     .then((res) => {
  //       res = res.data;
  //       if (res.code == 200 && res.data) {
  //         this.$store.dispatch("setSelectedCities", res.data);
  //         let routes = _.cloneDeep(res.data);
  //         _.each(routes, (city, i) => {
  //           city.check_out_date = moment(this.departureDates[i]).format(
  //             "YYYY-MM-DD"
  //           );
  //           if(this.departureDates[i] == '') {
  //             let t= parseInt(i)-1;
  //             city.check_out_date = moment(this.departureDates[t]).format(
  //               "YYYY-MM-DD"
  //             );
  //           }
            
  //           if (Number(i) < searchLength - 1) {
  //             routes[i + 1].check_in_date = city.check_out_date;
  //           }
  //           if (Number(i) == 0) {
  //             city.check_in_date = moment(city.check_out_date).format(
  //               "YYYY-MM-DD"
  //             );
  //           }
  //           city.default_nights = moment(city.check_out_date).diff(
  //             moment(city.check_in_date),
  //             "days",
  //             true
  //           );
  //           city.isMultiCity = true;
  //         });
  //         let itinerary = [];
  //         for (let i = 0; i < routes.length; i++) {
  //           let iti = new Itinerary({}, {}, {}, {}, {});
  //           let initialize = new IntializeRepository();
  //           iti.activity = initialize.activityInitialize();
  //           iti.transport = initialize.transportInitialize();
  //           iti.transport.default = {
  //             own_arrangement: 1,
  //             isMultiCity: true,
  //           };
  //           iti.hotel = initialize.hotelInitialize();
  //           itinerary.push(iti);
  //         }
  //         this.$store.dispatch("SetItinerary", itinerary);
  //         this.$store.dispatch("SetTransportTypeCall", 3);
  //         this.$store.dispatch("SetRoute", routes);
  //         this.$store.dispatch("SetInitalRoute", routes);
  //         this.$store.dispatch("SetLoading", 1);
  //         this.$router.push("/transports");
  //       }
  //       this.searching = false;
  //     })
  //     .catch((error) => {
  //       this.searching = false;
  //       this.$toasted.error("Something went wrong", {
  //         theme: "toasted-primary",
  //         position: "top-right",
  //         duration: 2000,
  //       });
  //     });
  // }
}
