export const SET_STEP1_VALUE='SET_STEP1_VALUE';
export const SET_STEP2_VALUE='SET_STEP2_VALUE';
export const SET_LOADING='SET_LOADING';
export const SET_LOGIN_INFORMATION_VALUE='SET_LOGIN_INFORMATION_VALUE';
export const DOMAIN_ID ='DOMAIN_ID';
export const FX_RECORD_ADD ='FX_RECORD_ADD'






























