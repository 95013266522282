import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import home from '@/Module/Global/Pages/home.vue'
import congratulation from '@/Module/Global/Pages/congratulation.vue'
import websiteTemplate from '@/Module/Global/Pages/website.vue'
import ChangePassword from '@/Module/Global/Pages/ChangePassword.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/congratulation',
    name: 'congratulation',
    component: congratulation
  },
  {
    path: '/website',
    name: 'websiteTemplate',
    component: websiteTemplate
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: ChangePassword
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
