
    import { Component, Vue } from 'vue-property-decorator';
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";
    import packageCard from "@/Module/Global/components/website/cards/packageCard.vue";
    @Component({
        components:{
            Swiper, 
            SwiperSlide,
            packageCard
        }
    })
    export default class topPackages extends Vue {        
        topPackagesList:any=[
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
            {
                heading:'Thailand Best Beaches',
                ImgSrc:'https://static-graphics.eroam.com/tusharsinhparmar9/banner-background-image/banner_background_img_1632735593.jpeg',
                altTag:'img',
                discription:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard ...',
            },
        ];

        swiperSliderOption: any = {
            slidesPerView: 3,
            spaceBetween: 10,    
            breakpoints: { 
            1024: {
                allowTouchMove:false,
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
            },
            },
            navigation: {
            nextEl: ".slide-next",
            prevEl: ".slide-pre",
            },
        };
    }
