
import {Vue, Component, Prop } from "vue-property-decorator";
import $ from "jquery";

@Component
export default class CitySearch extends Vue{
    location:any="";
    searchRequest: any = [];
    google:any='';
    @Prop() placeholder:any;
    @Prop(Boolean) multiple:boolean;
    @Prop(Boolean) oneway:boolean;
    @Prop() id:any;    
}
